import React, {
  createContext,
  useContext,
  useState,
} from 'react'

type Props = {
  children: JSX.Element | JSX.Element[];
}

type ScenarioSliderType = {
  animation: Animations.DRAG | Animations.SLIDE;
  currentSlideIndex: number;
  move: number;
  handleSwitching: (move: number, type: string) => void;
  handleSlideChange: (index: number) => void;
  handleMove: () => void;
  handleTouchEnd: () => void;
}

enum Animations {
  DRAG = 'none',
  SLIDE = '0.4s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
}

const DEFAULT_SLIDE_INDEX = 0
const DEFAULT_MOVE = 0

const ScenarioSliderContext = createContext<ScenarioSliderType>({} as ScenarioSliderType)
const useScenarioSlider = (): ScenarioSliderType => useContext(ScenarioSliderContext)

const ScenarioSliderProvider = ({children}: Props): JSX.Element => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(DEFAULT_SLIDE_INDEX)
  const [animation, setAnimation] = useState<Animations.DRAG | Animations.SLIDE>(Animations.DRAG)
  const [move, setMove] = useState<number>(DEFAULT_MOVE)

  const handleMove = (): void => setAnimation(Animations.DRAG)

  const handleTouchEnd = (): void => setAnimation(Animations.SLIDE)

  const handleSwitching = (m: number): void => setMove(m)

  const handleSlideChange = (index: number): void => {
    setAnimation(Animations.SLIDE)
    setCurrentSlideIndex(index)
  }

  const value = {
    animation,
    currentSlideIndex,
    move,
    handleSwitching,
    handleSlideChange,
    handleMove,
    handleTouchEnd,
  }

  return (
    <ScenarioSliderContext.Provider value={value}>
      {children}
    </ScenarioSliderContext.Provider>
  )
}

export {
  ScenarioSliderProvider,
  useScenarioSlider,
}
