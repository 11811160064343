// Add route paths with dynamic params to core/hooks/usePageViewTracking

export const RoutePaths = {
  ADVISER_SEARCH: '/home',
  ACCEPT_INVITE: '/accept-invite',
  VERIFY_EMAIL: '/verify-email',
  WELCOME_BACK: '/welcome-back',
  ADVISOR_SESSIONS: '/advisor-sessions',
  ADVISOR_SESSIONS_QUESTIONNAIRE: '/advisor-sessions/questionnaire/:questionnaireId/:section?/',
  ADVISOR_SESSIONS_FILES_UPLOAD: '/advisor-sessions/files-upload',
  ADVISOR_SESSIONS_DASHBOARD: '/advisor-sessions/dashboard',
  ENGAGEMENT_DASHBOARD: '/advisor-sessions/dashboard/:salesStatusId',
  ADVISOR_SESSIONS_SIGN_AGREEMENT: '/advisor-sessions/sign-agreement',
  ADVISOR_SESSIONS_DOCUMENTS: '/advisor-sessions/documents',
  ADVISOR_SESSIONS_SIGN_AGREEMENT_V2: '/advisor-sessions/sign-agreement/:engagementId',
  ADVISOR_SESSIONS_SIGN_AGREEMENT_V3: '/advisor-sessions/sign-agreement/:engagementId/:taskId',
  EQUITY_TAX_PLANNING_LANDING_PAGE: '/services/equity-tax-planning-session',
  TAX_PREP_PAGE: '/services/tax-preparation',
  AGREEMENT_SIGNING: '/agreement/:engagementId/:signatureId',
  ADD_NEW_COMPANY: '/company/new',
  ADD_EQUITY_COMPANY_ENTITY: '/company/:companyId',
  ADD_EQUITY_STOCK_OPTIONS: '/company/:companyId/add-equity/stock-options/:grantId?',
  ADD_EQUITY_RSU_RSA: '/company/:companyId/add-equity/rsu-rsa/:grantId?',
  ADD_EQUITY_RSU_RSA_PRIOR_SALE: '/company/:companyId/add-equity/rsu-rsa/:grantId/sales',
  ADD_EQUITY_DIRECT_INVESTMENTS: '/company/:companyId/add-equity/direct-investments/:scenarioId?',
  ADD_EQUITY_DIRECT_INVESTMENTS_PRIOR_SALE: '/company/:companyId/add-equity/direct-investments/:scenarioId/sales',
  ADD_EQUITY_PRIOR_EXERCISE: '/company/:companyId/add-equity/stock-options/:grantId/prior-exercise',
  ADD_EQUITY_PRIOR_SALE: '/company/:companyId/add-equity/stock-options/:grantId/prior-exercise/sales',
  REAL_ESTATE_PROPERTY_DETAIL: '/real-estate/property/:propertyId',
  REAL_ESTATE_PROPERTY_FORM: '/real-estate/property-form/:propertyId?',
  REAL_ESTATE_VALUATIONS_FORM: '/real-estate/property-form/:propertyId/valuations/',
  REAL_ESTATE_SALE_FORM: '/real-estate/property-form/:propertyId/sale/',
  ASSETS_AND_DEBTS: '/assets-debts',
  FEEDBACK: '/feedback',
  FIRM_PROFILE_PAIRING: '/match/firm-pairing/:firmId/:matchRequestId',
  FIRM_PROFILE: '/firm-profile/:firmId',
  LINKEDIN_SSO: '/auth/linkedIn',
  LOGIN: '/login',
  LOGIN_VERIFY: '/login/verify',
  MATCH_REVIEW: '/match/review',
  MARKETPLACE_DASHBOARD: '/marketplace/home',
  MATCH_CONFIRMATION: '/match/confirm',
  MATCH_PROFILE_COMPLETE: '/match/profile-complete',
  NEW_MATCH_REVIEW: '/match/review/new',
  NEW_MATCH_CONFIRMATION: '/match/confirm/new',
  PROFILE: '/profile/',
  PROFILE_FORM_ROOT: '/profile',
  PROFILE_SUMMARY: '/profile/client/:clientId',
  PROFILE_V2: {
    FORM_ILLIQUID_TYPE: '/profile/illiquid-type/',
    FORM_MILESTONES: '/profile/milestones/',
    FORM_WINDFALL: '/profile/windfall/',
    FORM_HOUSEHOLD_INCOME: '/profile/income/',
    FORM_INVESTABLE_ASSETS: '/profile/investable/',
    FORM_ILLIQUID_AMOUNT: '/profile/illiquid-amount/',
    FORM_LOCATION_PREFERENCE: '/profile/location/',
    FORM_ZIP_CODE: '/profile/zipcode/',
    FORM_COUNTRY: '/profile/country/',
    FORM_CITIZEN: '/profile/citizen/',
    FORM_SERVICE_NEEDS: '/profile/needs/',
    FORM_FILINGS: '/profile/filings/',
    FORM_PERSONAL_FINANCES_MANAGEMENT: '/profile/current-solutions/',
    FORM_K1S: '/profile/k1s/',
    FORM_RESIDENCE: '/profile/residence/',
  },
  PASSWORD_RESET: '/password/reset',
  EMAIL_VERIFY: '/email/verify',
  PERSONAL_BALANCE: '/personal-balance',
  PROFILE_START: '/profile-start',
  REGISTER: '/register',
  REGISTER_PROXY: '/register/success',
  ONBOARDING_ROOT: '/onboarding',
  ONBOARDING: {
    FORM_MILESTONES: '/onboarding/milestones/',
    FORM_WINDFALL: '/onboarding/windfall/',
    FORM_WINDFALL_TIMING: '/onboarding/windfall-timing/',
    FORM_WINDFALL_AMOUNT: '/onboarding/windfall-amount/',
    FORM_EMPLOYMENT: '/onboarding/employment/',
    FORM_PROFESSIONS: '/onboarding/professions/',
    FORM_SMALL_BUSINESS_TYPE: '/onboarding/small-business/type/',
    FORM_SMALL_BUSINESS_REVENUE: '/onboarding/small-business/revenue/',
    FORM_ILLIQUID_TYPE: '/onboarding/illiquid-type/',
    FORM_GET_STARTED: '/onboarding/get-started/',
    FORM_TAX_PLANNING: '/onboarding/get-started/equity-tax-planning-solution',
  },
  WELCOME_INVITED_CLIENT: '/welcome',
  LIQUIDITY_EVENT_ROOT: '/liquidity-event/:companyId',
  LIQUIDITY_EVENT_START: '/liquidity-event/:companyId/introduction',
  LIQUIDITY_EVENT: {
    FORM_EXIT_TYPE: '/liquidity-event/:companyId/exit-type/',
    FORM_EVENT_TIMING: '/liquidity-event/:companyId/event-timing/',
    FORM_EVENT_LOCKUP: '/liquidity-event/:companyId/lock-up/',
    FORM_EVENT_SALE_PRICE: '/liquidity-event/:companyId/sale-price/',
    FORM_TAX_RESIDENCY: '/liquidity-event/:companyId/tax-residency/',
    FORM_TAX_FILING_STATUS: '/liquidity-event/:companyId/tax-filing-status/',
    FORM_SUMMARY: '/liquidity-event/:companyId/summary/',
  },
  LIQUIDITY_EVENT_RESULTS: '/liquidity-event/:companyId/results',
  EQUITY_INSIGHTS_RESULTS: '/company/:companyId/equity-insights-results',
  SETTINGS: '/settings',
  TOAST_DEMO: '/toast-demo',
  NOT_FOUND: '/oops', // TODO(raylen): this should maintain the 404 url

}

export const ExternalRoutePaths: {[key: string]: string} = {
  HOME: '/',
  ABOUT_US: '/about-us/',
  CREATE_A_WILL: '/create-a-will/',
  FIRM_EVALUATION_PROCESS: '/firm-evaluation-process',
  GETTING_A_DIVORCE: '/getting-a-divorce/',
  HAVING_A_CHILD: '/having-a-child/',
  HOW_WE_WORK: '/how-it-works/',
  PLANNING: '/planning/',
  HARNESS_TAX: '/tax/',
  ARTICLES: '/articles/',
  RETIREMENT_PLANNING: '/retirement-planning/',
  STARTING_A_BUSINESS: '/starting-a-business/',
  SERVICES: '/services/',
}
