import {createTheme} from '@mui/material/styles'
import {TypographyOptions} from '@mui/material/styles/createTypography'

const typography: TypographyOptions = {
  fontFamily: 'Theinhardt, Open Sans, Arial, sans-serif;',
  h1: {
    fontWeight: 400,
    fontSize: '6rem',
    letterSpacing: '-0.094rem',
    fontFamily: 'Media77',
  },
  h2: {
    fontWeight: 400,
    fontSize: '3.75rem',
    letterSpacing: '-0.031rem',
    fontFamily: 'Media77',
    lineHeight: '4.5rem',
  },
  h3: {
    fontWeight: 400,
    fontSize: '3rem',
    fontFamily: 'Media77',
  },
  h4: {
    fontWeight: 400,
    fontSize: '2.125rem',
    fontFamily: 'Media77',
    letterSpacing: '0.016rem',
    '@media (max-width:600px)': {
      fontSize: '1.5rem',
    },
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.5rem',
    fontFamily: 'Media77, serif',
  },
  h6: {
    fontWeight: 500,
    fontSize: '1.25rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.009rem',
    lineHeight: '2rem',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.009rem',
    lineHeight: '1.75rem',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.006rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.009rem',
    lineHeight: '1.5rem',
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.875rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.009rem',
  },
  button: {
    fontWeight: 500,
    fontSize: '0.875rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.125rem',
    textTransform: 'uppercase',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.875rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.011rem',
  },
  overline: {
    fontWeight: 400,
    fontSize: '0.875rem',
    fontFamily: 'Theinhardt, sans-serif',
    letterSpacing: '0.011rem',
    textTransform: 'uppercase',
  },
}

const theme = createTheme({
  typography,
  palette: {
    divider: '#0000000f',
    background: {
      default: '#f4f3ef',
      paper: '#FFFFFF',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
    },
    text: {
      primary: '#082042',
      secondary: '#626262',
      disabled: '#808080',
    },
    primary: {
      main: '#E49B3D',
      dark: '#DB8A21',
      light: '#EFC289',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#082042',
      dark: '#031732',
      light: '#6A7A8E',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#CD5437',
      dark: '#A51C0C',
      light: '#D7765F',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9215',
      dark: '#FF6F06',
      light: '#FFC149',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#276FFF',
      dark: '#0949C9',
      light: '#4D9AFF',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#348C46',
      dark: '#1D6E2B',
      light: '#5EB273',
      contrastText: '#FFFFFF',
    },
    action: {
      // rgba(0, 0, 0, 0.54)
      active: '#0000008a',
      // rgba(0, 0, 0, 0.04)
      hover: '#0000000a',
      // rgba(0, 0, 0, 0.08)
      selected: '#00000014',
      // rgba(0, 0, 0, 0.26)
      disabled: '#00000042',
      // rgba(0, 0, 0, 0.12);
      disabledBackground: '#0000001f',
      // rgba(0, 0, 0, 0.12)
      focus: '#0000001f',
    },
    grey: {
      200: '#D5D5D5',
      300: '#AAAAAA',
    },
    blue: {
      50: '#EDF7FF',
      200: '#CFEEFF',
    },
    darkBlue: {
      500: '#082042',
      300: '#6A7A8E',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'large',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: '0px 0px 5px #ddd;',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
  },
})

export default theme
