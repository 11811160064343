import {createSelector} from '@reduxjs/toolkit'

import {
  Scenario, ScenarioType,
} from './types'
import {
  generateEquityGuideContent,
  generateScenarioBreakdownContent,
  generateScenarioData,
  generateScenarioHeaderContent,
} from './utils'

// TODO(zare): move all needed types to local type folder
import {
  LiquidityEventWithResults,
} from 'models/liquidityEvent'
import {apiSlice} from 'store/main/apiSlice'

interface LiquidityEvent {
  id: string;
  isStale: boolean;
  company: {
    name: string;
    id: string;
  };
  scenarios: Scenario[];
}

const scenarioApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLiquidityEvent: builder.query<LiquidityEvent, string>({
      query: (companyId) => `/v1/liquidity_event/${companyId}/?results=true`,
      transformResponse: (liquidityEvent: LiquidityEventWithResults) => ({
        id: liquidityEvent.id,
        isStale: liquidityEvent.isStale,
        company: {
          name: liquidityEvent.companyName,
          id: liquidityEvent.company,
        },
        scenarios: generateScenarioData(liquidityEvent),
      }),
      extraOptions: {maxRetries: 0},
      providesTags: ['Scenario'],
      // TODO:(zare) Keeping this as part of future improvements
      // providesTags: (liquidityEvent, _, id) => [
      //   {type: 'LiquidityEvent', id: liquidityEvent?.id},
      //   {type: 'Company', id},
      // ],
    }),
    invalidateScenarios: builder.mutation<LiquidityEventWithResults, string>({
      query: (companyId) => ({
        url: `/v1/liquidity_event/${companyId}/scenario/`,
        method: 'POST',
      }),
      invalidatesTags: ['Scenario'],
    }),
  }),
})

export const selectScenarioByType = createSelector(
  (data: Scenario[] | undefined): Scenario[] | undefined => data,
  (_: any, type: ScenarioType): ScenarioType => type,
  (data, type): Scenario | undefined => data?.find((scenario) => type === scenario.type),
)

export const selectScenarioHeaderData = createSelector(
  selectScenarioByType,
  generateScenarioHeaderContent,
)

export const selectEquityGuideData = createSelector(
  (data: Scenario[]) => data,
  generateEquityGuideContent,
)

export const selectScenarioBreakdownData = createSelector(
  selectScenarioByType,
  generateScenarioBreakdownContent,
)

export const {
  useLazyGetLiquidityEventQuery,
  useGetLiquidityEventQuery,
  useInvalidateScenariosMutation,
} = scenarioApiSlice
