import {RoutePaths} from 'core/constants'

export const UNAUTHENTICATED_ROUTE_PATH_SET = new Set([
  RoutePaths.LOGIN,
  RoutePaths.LOGIN_VERIFY,
  RoutePaths.REGISTER,
  RoutePaths.PASSWORD_RESET,
  RoutePaths.WELCOME_BACK,
  RoutePaths.ACCEPT_INVITE,
])

export const OTP_LENGTH = 6
export const INVALID_TOKEN_MSG = 'The code entered is not valid. Please make sure you’re entering the most recent code and try again.'
export const INVALID_BACKUP_CODE_MSG = 'The code entered is not valid. Please make sure you’re entering the correct code and try again.'
export const DEFAULT_ERROR_MSG = 'Something went wrong.'

export const BACKUP_CODE_LENGTH = 16
export const SESSION_EXPIRED = 'session_expired'
