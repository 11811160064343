import React from 'react'

import Modal from 'core/components/modal/Modal'

import styles from './_inviteFriend.module.scss'
import usePrefinery from './hooks/usePrefinery'
import InviteFriendModalV2 from './InviteFriendModalV2'

import {useGetFeatureFlagsQuery} from 'store/feature-flag'

type Props = {
  onClose: () => void;
  open: boolean;
  prefineryTesterHash?: string;
}

function InviteFriendModal({
  onClose,
  open,
  prefineryTesterHash,
}: Props): JSX.Element {
  const isLoaded = usePrefinery()
  return (
    <Modal id="invite-a-friend-modal" open={isLoaded && open} onBackdropClick={onClose}>
      <div>
        <div className={styles.inviteFriendModalHeader}>
          <h3>Invite A Friend</h3>
        </div>
        <hr className={styles.inviteFriendModalDivider} />
        <div
          className="prefinery-invite-a-friend-embed"
          data-prefinery-tester-hash={prefineryTesterHash}
        />
      </div>
    </Modal>
  )
}

export default function InviteFriendModalProxy(props: Props): JSX.Element {
  const {data: flags, isLoading: isLoadingFlags} = useGetFeatureFlagsQuery()

  if (isLoadingFlags) return <></>

  // Give $250, Get $250
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (flags?.referralCode) return <InviteFriendModalV2 {...props} />

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InviteFriendModal {...props} />
}
