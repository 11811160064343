import React, {useMemo} from 'react'
import {parseISO} from 'date-fns'

import GrantTable from '../tables/GrantTable'

import styles from './_taxBreakdown.module.scss'
import {INCOME_FROM_EXERCISES as l} from './locale'

import {
  formatNumberWithCommas,
  isoDateFormatter,
  numberFormatter,
} from 'core/formatters'
import {
  IncomeSource,
  OptionDisposition,
  OptionDispositionOptionType,
  OptionDispositionOptionTypeLabel,
  OptionDispositionType,
} from 'models'

type Props = {
  exercises: IncomeSource[];
  isExercise: boolean;
  purchaseDate: string;
  scenarioNumber: number;
  taxYear: number;
  isPriorExercise?: boolean;
  hasPriorExercise?: boolean;
  isNSO?: boolean;
  isAMT?: boolean;
}

export default function IncomeFromExercises({
  exercises,
  isExercise,
  purchaseDate,
  scenarioNumber,
  taxYear,
  isPriorExercise = false,
  hasPriorExercise = false,
  isNSO = false,
  isAMT = false,
}: Props): JSX.Element {
  let validExercises: IncomeSource[] = exercises.filter(({incomeSource: {quantity}}) => quantity)
  if (!isExercise) {
    // filter all exercises that are not sold
    validExercises = validExercises.filter(({incomeSource}) => (
      (incomeSource as OptionDisposition).saleDate))
  }

  const totalIncome = useMemo(() => {
    if ((isNSO && isExercise) || (isAMT && isExercise)) {
      return validExercises.reduce((acc, {incomeSource}) => {
        const exercise = incomeSource as OptionDisposition
        if (exercise.dispositionType === OptionDispositionType.NON_QUALIFIED) {
          return acc
        }

        return acc + (exercise.company409a - exercise.exercisePrice) * exercise.quantity
      }, 0)
    }
    return validExercises.reduce((acc, curr) => acc + curr.grossIncome, 0)
  }, [isExercise, isNSO, validExercises, isAMT])

  let optionTypePrefix = isNSO ? 'NSO' : 'ISO'
  if (isAMT) {
    optionTypePrefix = `${optionTypePrefix} AMT`
  }

  const exerciseTitle = (
    isPriorExercise ? l.TITLE_PRIOR : l.TITLE
  ).replace('[YEAR]', taxYear.toString()).replace('[TYPE]', optionTypePrefix)

  const title = isExercise
    ? exerciseTitle
    : l.TITLE_SALE
      .replace('[YEAR]', taxYear.toString())
      .replace('[TYPE]', optionTypePrefix)

  const headerRows = React.useMemo(() => ([{
    id: `${title}-${scenarioNumber}-header`,
    cells: [
      {label: l.HEADER.GRANT_NAME},
      {label: l.HEADER.HOLDING_PERIOD},
      ...(!isNSO ? [{label: l.HEADER.DISPOSITION}] : []),
      {label: isNSO ? l.HEADER.TAX_TREATMENT_NSO : l.HEADER.TAX_TREATMENT},
      {label: l.HEADER.FMV_AT_EXERCISE},
      {label: l.HEADER.EXERCISE_PRICE},
      {label: l.HEADER.TAXABLE_COMPENSATION.replace('[TYPE]', optionTypePrefix)},
      {label: l.HEADER.QUANTITY},
      {label: isAMT ? l.HEADER.AMT_TAX_LIABILITY : l.HEADER.TAX_LIABILITY},
    ],
  }]), [
    isNSO,
    optionTypePrefix,
    scenarioNumber,
    title,
    isAMT,
  ])

  const bodyRows = React.useMemo(() => {
    if (!validExercises.length) {
      return [{
        id: `${title}-${scenarioNumber}-body`,
        cells: [
          {
            label: l.NO_OPTIONS,
            colSpan: isNSO ? 8 : 9,
          },
        ],
      }]
    }

    const isExercisedInTaxYear = (exerciseDate): boolean => (
      taxYear === parseISO(exerciseDate).getFullYear() && isExercise
    )
    const getTreatment = ({dispositionType, exerciseDate}): string => {
      if (!dispositionType) return isNSO ? l.TAX_TREATMENT_EXERCISE : l.TAX_TREATMENT_SALE
      if (dispositionType === OptionDispositionType.QUALIFIED) {
        return isExercisedInTaxYear(exerciseDate) ? l.TAX_TREATMENT_AMT : l.TAX_TREATMENT_EXERCISE_2
      }
      return isExercisedInTaxYear(exerciseDate) ? l.TAX_TREATMENT_SALE : l.TAX_TREATMENT_AMT
    }

    return (validExercises.map(({incomeSource, grossIncome}) => {
      const exercise = incomeSource as OptionDisposition
      const displayGrantDate = isoDateFormatter(exercise.grantDate, 'M/d/yy')
      const displayExerciseDate = isoDateFormatter(exercise.exerciseDate, 'M/d/yy')
      const displaySaleDate = exercise.saleDate
        ? isoDateFormatter(exercise.saleDate, 'M/d/yy')
        : ''
      const isPriorExerciseLabel = exercise.exerciseDate !== purchaseDate ? '*' : ''
      const {dispositionType} = exercise
      const disposition = dispositionType ? l.DISPOSITION[dispositionType] : l.DISPOSITION.NA
      const dispositionTagClass = dispositionType
        ? styles[dispositionType.toLowerCase().replace(/_/g, '')]
        : styles.notSold
      const treatment = getTreatment(exercise)
      const DispositionTag = (): React.ReactElement => <span className={dispositionTagClass}>{disposition}</span>
      const optionType = OptionDispositionOptionTypeLabel.get(exercise.optionType) ?? 'N/A'
      const grant = `${optionType} ${displayGrantDate}`
      const held = displaySaleDate
        ? `${isPriorExerciseLabel}${displayExerciseDate} to ${displaySaleDate}`
        : `${isPriorExerciseLabel}${displayExerciseDate}`
      let generatedIncome = (isExercise && exercise.optionType === OptionDispositionOptionType.INCENTIVE_STOCK)
        ? 'N/A'
        : numberFormatter(grossIncome).currency

      if (isAMT && !isExercise && exercise.dispositionType === OptionDispositionType.QUALIFIED) {
        generatedIncome = 'N/A'
      }

      if (
        (isNSO && isExercise)
        || (isAMT && isExercise && exercise.dispositionType === OptionDispositionType.QUALIFIED)
      ) {
        generatedIncome = numberFormatter((exercise.company409a - exercise.exercisePrice) * exercise.quantity).currency
      }

      return {
        id: `${title}-${scenarioNumber}`,
        cells: [
          {label: grant},
          {label: held},
          ...(!isNSO ? [{label: disposition, Element: DispositionTag}] : []),
          {label: treatment},
          {label: numberFormatter(exercise.company409a).currency},
          {label: numberFormatter(exercise.exercisePrice).currency},
          {label: numberFormatter(exercise.company409a - exercise.exercisePrice).currency},
          {label: formatNumberWithCommas(exercise.quantity)},
          {label: generatedIncome},
        ],
      }
    }))
  },
  [
    isExercise,
    isNSO,
    purchaseDate,
    scenarioNumber,
    taxYear,
    title,
    validExercises,
    isAMT,
  ])

  const footerRows = useMemo(() => ([{
    id: `${title}-${scenarioNumber}-footer`,
    cells: [
      {
        colSpan: isNSO ? 7 : 8,
        label: isExercise
          ? l.TOTAL_INCOME.replace('[TYPE]', optionTypePrefix)
          : l.TOTAL_INCOME_SALE.replace('[TYPE]', optionTypePrefix),
      },
      {label: numberFormatter(isExercise && !isNSO && !isAMT ? 0 : totalIncome).currency},
    ],
  }]), [
    isExercise,
    isNSO,
    optionTypePrefix,
    title,
    totalIncome,
    scenarioNumber,
    isAMT,
  ])

  const grantTableTitle = isExercise
    ? exerciseTitle
    : l.TITLE_SALE
      .replace('[YEAR]', taxYear.toString())
      .replace('[TYPE]', optionTypePrefix)

  return (
    <GrantTable
      bodyRows={bodyRows}
      headerRows={headerRows}
      footerRows={footerRows}
      footNote={hasPriorExercise ? l.PRIOR_EXERCISE_INDICATOR : ''}
      id={`${title}-${scenarioNumber}`}
      title={grantTableTitle}
    />
  )
}
