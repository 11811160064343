import React, {forwardRef} from 'react'
import cx from 'classnames'
import Tippy, {TippyProps} from '@tippy.js/react'

import styles from './_textTooltip.module.scss'

/**
 * Event handler prevents triggering other click/touch/change events when text control is rendered within another
 * interactive element, such as a checkbox.
 * */
function handleTextClick(e: React.SyntheticEvent<HTMLSpanElement>): void {
  e.preventDefault()
}

type TextProps = {
  className?: string;
  children: React.ReactNode;
}

const Text = forwardRef<HTMLSpanElement, TextProps>((
  {
    className,
    children,
  }: TextProps,
  ref,
) => (
  <span
    className={cx(styles.control, className)}
    ref={ref}
    role="button"
    tabIndex={0}
    onClick={handleTextClick}
    onKeyPress={handleTextClick}
  >
    {children}
  </span>
))

export interface Props extends Partial<TippyProps> {
  /** Content of the tooltip control. */
  children?: React.ReactElement;
  /** Space-separated list of classes passed to the tooltip control. */
  className?: string;
  /** Content of the tooltip. */
  content: React.ReactChild | React.ReactChild[];
  /** Text content shorthand for the tooltip control. */
  text?: string;
}

/**
 * Accessibly presents hidden, secondary information about a particular text control element upon interaction with the
 * control via click, focus or hover. Dashed underline beneath the text control provides a visual cue of the hidden
 * information's existence.
 * */
export default function TextTooltip(
  {
    children,
    className,
    content,
    placement = 'top',
    text,
    theme = 'light',
    ...rest
  }: Props,
): JSX.Element {
  const ref = React.useRef<HTMLSpanElement>(null)

  return (
    <Tippy
      content={content}
      placement={placement}
      theme={theme}
      trigger="focus mouseenter"
      interactive
      {...rest}
    >
      <Text className={className} ref={ref}>
        {children || text}
      </Text>
    </Tippy>
  )
}
