import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react'

type Returned = [boolean, () => void, Dispatch<SetStateAction<boolean>>]

export const useBoolean = (
  initialValue: boolean,
): Returned => {
  const [value, setValue] = useState<boolean>(initialValue || false)

  const toggleValue = useCallback(() => {
    setValue((currentValue) => !currentValue)
  }, [])

  return [value, toggleValue, setValue]
}
