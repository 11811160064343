/* eslint-disable max-len */
export default {
  HEADER: 'Your [EVENT_TYPE] results',
  SUBHEADER: 'The below scenarios are based on your tax profile, but is not fully comprehensive. Talk with an equity expert to create a personalized, holistic plan to fit your personal milestones, cash flow, and risk tolerance.',
  SUMMARY: {
    EDIT_CTA: 'Edit',
    EVENT_DATE: 'Est. [EVENT_TYPE] Date',
    SALE_PRICE: 'Est. Sale Price',
    ZIP_CODE: 'Zip Code', // TODO(kevin): Replace with Tax Residence (City + State)
    PRETAX_INCOME: 'Pre-tax Income',
    CURRENT_409A: 'Curr. Fair Market Value (409A)',
  },
  RERUN_SCENARIO_CTA: 'Rerun Scenario',
  EDIT_MENU: {
    LIQUIDITY_EVENT: 'Edit Liquidity Event',
    TAX_PROFILE: 'Edit Tax Profile',
  },
  SECTION_2: {
    HEADER: 'Scenario breakdown',
  },
  SCENARIO_ANALYSIS_SECTION: {
    HEADER: 'Quick Analysis',
    INTRO_COPY: 'The following factors will affect the outcome of each scenario.',
  },
  HTA_OFFBOARDING_SECTION: {
    HEADER: 'How could an advisor help?',
    SUBHEADER: 'Talking with an advisor can help you personalize your equity plan to your financial position, goals, and other life milestones.',
  },
  INVITE_A_FRIEND: {
    CARD_HEADER: 'Refer a coworker or friend',
    CARD_BODY: 'Share Harness with coworkers or friends that need help analyzing their equity or planning for an upcoming IPO or acquisition.',
    CTA: 'Invite a Friend',
  },
  FEEDBACK_INSIGHT: {
    HEADER: 'Feature request? Bug to report?',
    TEXT: 'Visit our feedback page to see our product roadmap and make yourself heard.',
    LINK_TEXT: 'Provide Feedback',
  },
  ACCOUNT_SUCCESS_1: 'Success! Your account has been connected.',
  ACCOUNT_SUCCESS_2: 'View your Personal Balance Sheet.',
  ACCOUNT_ERROR: 'We experienced an error when connecting to your institution using Plaid. Please contact the Concierge if you experience this issue again.',
  SCENARIO_STALE_BANNER: {
    STRONG_LABEL: 'Scenario out of date.',
    COPY: 'Your results were last updated on [DATE] and are no longer up to date.',
    IPO_COPY: 'Your estimated IPO date has passed. Please [CTA] to a future date.',
    IPO_CTA: 'update',
    CTA: 'Rerun results',
  },
  SCENARIO_STALE_MODAL: {
    TITLE: 'Your scenario is out of date',
    BODY: 'Your results were last updated on [DATE] and are no longer up to date. Please rerun your equity tax planning scenario.',
    IPO_BODY: 'Your results were last updated on [DATE]. Your estimated IPO date has passed. Please update this to a future date and rerun your equity tax planning scenario.',
    CANCEL: 'Cancel',
    CONFIRM: 'Rerun',
    IPO_CONFIRM: 'Update',
  },
  ERROR: 'There was an error loading this page. Please refresh the page to try again.',
  UNSUPPORTED_STATES_BANNER: {
    MESSAGE: 'Scenario couldn’t load. We are working on tax support for [FILING_STATUS] Filers in [STATES]. Check back soon or ',
    CTA: 'Update Zip code',
  },
}
