import React from 'react'
import {Markup} from 'interweave'

import styles from './_taxBreakdownEducation.module.scss'
import {ExerciseAndSaleFederalMedicareTax as locale} from './locale'

import {numberFormatter} from 'core/formatters'

type Props = {
  normalTaxRate: number;
}

const ExerciseAndSaleFederalMedicareTaxEdu = ({normalTaxRate}: Props): JSX.Element => (
  <div className={styles.educationContainer}>
    <p className={styles.educationText}>
      <Markup
        noWrap
        content={
        locale.PARAGRAPH_1
          .replace('[NORMAL_RATE]', numberFormatter(normalTaxRate).percent)
        }
      />
    </p>
    <p className={styles.educationText}>{locale.PARAGRAPH_2}</p>
  </div>
)

export default ExerciseAndSaleFederalMedicareTaxEdu
