import React, {useCallback} from 'react'
import ReactSelect, {Styles} from 'react-select'

import Icon, {IconName} from 'core/components/icon/Icon'

import styles from './_input.module.scss'

type ReactSelectValue = {
  value: string | number;
  label: string | undefined;
}

type ReactSelectStyles = Partial<Styles<ReactSelectValue, boolean>>

type Props = {
  /** Supporting text beneath the input to aid usability, such as, formatting instructions. */
  helperText?: string;
  name: string;
  onChange: ({value}: any) => void;
  placeholder: string;
  options: ReactSelectValue[];
  styles: ReactSelectStyles;
  error?: string;
  value: Nullable<ReactSelectValue>;
  isSearchable?: boolean;
  htmlFor?: string;
  // Optional function to handle onBlur events
  // TODO(Steph): less prescriptive signature
  handleBlur?: (name: string, value: Nullable<string | number>) => void;
  'data-cy'?: string;
}

export default function Select({
  helperText,
  name,
  onChange,
  placeholder,
  options,
  styles: selectStyles,
  error,
  value,
  isSearchable,
  htmlFor,
  handleBlur,
  'data-cy': dataCy,
}: Props): JSX.Element {
  let val: Nullable<string | number> = null

  if (value?.value || value?.value === '') {
    val = value.value
  }

  const onBlur = useCallback((): KeyedObject | null => {
    if (name && handleBlur) {
      handleBlur(name, val)
    }

    return null
  }, [name, handleBlur, val])

  return (
    <div data-cy={dataCy}>
      <ReactSelect
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        styles={selectStyles}
        error={error}
        value={value}
        isSearchable={isSearchable}
        htmlFor={htmlFor}
        onBlur={onBlur}
        blurInputOnSelect={false}
      />
      {helperText && <div className={styles.helperText}>{helperText}</div>}
      {error && (
        <div className={styles.error}>
          <Icon name={IconName.Warning} className={styles.errorIcon} size="0.75rem" />
          {error}
        </div>
      )}
    </div>
  )
}
