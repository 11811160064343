export enum CryptoAccountConnectionStatus {
  GOOD = 'GOOD',
  DISCONNECTED = 'DISCONNECTED',
}

export interface GetAllCryptoAccountsResponse {
  id: string;
  name: string;
  customName: string;
  disconnectedFromProvider: string;
  connectionStatus: CryptoAccountConnectionStatus;
  lastSuccessfulConnection: string;
  lastFailedConnection: string; // TODO(zare): This is null or string
  provider: string;
  sourceId: string;
  closedFromApplication: string | null; // date
  allAssetsVerified: boolean;
  allFiatAssetsVerified: boolean;
}

export interface CryptoAccount extends GetAllCryptoAccountsResponse {
  statusCopy: {
    connection: string;
    verification: string;
  };
}
