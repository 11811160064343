import {isAfter, parseISO} from 'date-fns'

import {GrantLifecycleData} from '../equityLiquidity'

import {
  IncomeSource,
  IncomeSourceCategory,
  IncomeSourceSubCategory,
  OptionDisposition,
  TaxBracketOrdering,
  TaxBracketType,
  TaxBreakdown,
  TaxScenarioResult,
  TaxTypeTotals,
} from './types'

export type AmtIncomeSources = {
  shortTermCapitalGainsIncomeSources: IncomeSource[];
  longTermCapitalGainsIncomeSources: IncomeSource[];
  amtIsoPriorExerciseIncomeSources: IncomeSource[];
  amtIsoExerciseIncomeSources: IncomeSource[];
  amtNsoPriorExerciseIncomeSources: IncomeSource[];
  amtNsoExerciseIncomeSources: IncomeSource[];
}

type Totals = {
  federal: number;
  federalOrdinary: number;
  federalLongTermCapGains: number;
  medicare: number;
  socialSecurity: number;
  netInvestmentIncome: number;
  state: number;
  stateOrdinary: number;
  stateLongTermCapGains: number;
  total: number;
  amt: number;
}

function partitionPriorExercise(exercises: IncomeSource[], exerciseDate: string): [IncomeSource[], IncomeSource[]] {
  const priorExerciseIncomeSources = exercises.filter(({incomeSource}) => (
    (incomeSource as OptionDisposition).exerciseDate !== exerciseDate
  ))
  const nonPriorExercises = exercises.filter(({incomeSource}) => (
    (incomeSource as OptionDisposition).exerciseDate === exerciseDate
  ))
  return [priorExerciseIncomeSources, nonPriorExercises]
}

type IncomeSources = {
  shortTermCapitalGainsIncomeSources: IncomeSource[];
  longTermCapitalGainsIncomeSources: IncomeSource[];
  isoPriorExerciseIncomeSources: IncomeSource[];
  isoExerciseIncomeSources: IncomeSource[];
  nsoPriorExerciseIncomeSources: IncomeSource[];
  nsoExerciseIncomeSources: IncomeSource[];
}
export function getIncomeSourcesFromTaxCategory(
  scenarioExerciseDate: string,
  taxCategory: {capitalGains: TaxBreakdown; ordinaryIncome: TaxBreakdown},
): IncomeSources {
  const isoExercises = taxCategory.ordinaryIncome.incomeSources
    // TODO(raylen): rename to ISO_EXERCISE when value is released
    .filter((incomeSource) => incomeSource.subCategory === IncomeSourceSubCategory.ISO_EXERCISE)
  const nsoExercises = taxCategory.ordinaryIncome.incomeSources
    .filter((incomeSource) => incomeSource.subCategory === IncomeSourceSubCategory.NSO_EXERCISE)
  const shortTermCapitalGainsIncomeSources = taxCategory.ordinaryIncome.incomeSources
    .filter((incomeSource) => incomeSource.subCategory === IncomeSourceSubCategory.EQUITY_SALE)
  const longTermCapitalGainsIncomeSources = taxCategory.capitalGains.incomeSources
    .filter((incomeSource) => incomeSource.subCategory === IncomeSourceSubCategory.EQUITY_SALE)

  const [isoPriorExerciseIncomeSources, isoExerciseIncomeSources] = partitionPriorExercise(
    isoExercises,
    scenarioExerciseDate,
  )
  const [nsoPriorExerciseIncomeSources, nsoExerciseIncomeSources] = partitionPriorExercise(
    nsoExercises,
    scenarioExerciseDate,
  )
  return {
    nsoPriorExerciseIncomeSources,
    nsoExerciseIncomeSources,
    isoPriorExerciseIncomeSources,
    isoExerciseIncomeSources,
    shortTermCapitalGainsIncomeSources,
    longTermCapitalGainsIncomeSources,
  }
}

export function getAmtIncomeSources(scenarioExerciseDate: string, amt: TaxBreakdown): AmtIncomeSources {
  if (!amt || !amt.incomeSources) {
    return {
      amtIsoPriorExerciseIncomeSources: [],
      amtIsoExerciseIncomeSources: [],
      amtNsoPriorExerciseIncomeSources: [],
      amtNsoExerciseIncomeSources: [],
      shortTermCapitalGainsIncomeSources: [],
      longTermCapitalGainsIncomeSources: [],
    }
  }
  const incomeSources = amt.incomeSources
    .filter((incomeSource) => incomeSource.subCategory === IncomeSourceSubCategory.ISO_EXERCISE)
  const nsoExercises = amt.incomeSources
    .filter((incomeSource) => incomeSource.subCategory === IncomeSourceSubCategory.NSO_EXERCISE)
  const shortTermCapitalGainsIncomeSources = amt.incomeSources
    .filter((incomeSource) => incomeSource.category === IncomeSourceCategory.ORDINARY_INCOME
          && incomeSource.subCategory === IncomeSourceSubCategory.EQUITY_SALE)
  const longTermCapitalGainsIncomeSources = amt.incomeSources
    .filter((incomeSource) => incomeSource.category === IncomeSourceCategory.CAPITAL_GAINS
          && incomeSource.subCategory === IncomeSourceSubCategory.EQUITY_SALE)

  const [amtIsoPriorExerciseIncomeSources, amtIsoExerciseIncomeSources] = partitionPriorExercise(
    incomeSources,
    scenarioExerciseDate,
  )

  const [amtNsoPriorExerciseIncomeSources, amtNsoExerciseIncomeSources] = partitionPriorExercise(
    nsoExercises,
    scenarioExerciseDate,
  )
  return {
    amtIsoPriorExerciseIncomeSources,
    amtIsoExerciseIncomeSources,
    amtNsoPriorExerciseIncomeSources,
    amtNsoExerciseIncomeSources,
    shortTermCapitalGainsIncomeSources,
    longTermCapitalGainsIncomeSources,
  }
}

export function getLifecycleForDate(
  date: Date,
  grantLifeCycle: GrantLifecycleData[],
): Nullable<GrantLifecycleData> {
  return [...grantLifeCycle].reverse().find((data) => !isAfter(parseISO(data.date), date)) || null
}

export function getTaxTypeTotals(
  {taxBreakdown, taxProfile, taxSummary}: TaxScenarioResult,
  key: IncomeSourceSubCategory | string,
  useAMT = false,
): TaxTypeTotals {
  const state = taxProfile.states[0]
  const totals: Totals = {
    federal: 0,
    federalOrdinary: taxBreakdown.federal.ordinaryIncome?.taxesOwedByOrdering?.[key] as number || 0,
    federalLongTermCapGains: taxBreakdown.federal.capitalGains?.taxesOwedByOrdering?.[key] as number || 0,
    medicare: taxBreakdown.medicare?.taxesOwedByOrdering?.[key] as number || 0,
    socialSecurity: taxBreakdown.socialSecurity?.taxesOwedByOrdering?.[key] as number || 0,
    netInvestmentIncome:
      key === IncomeSourceSubCategory.EQUITY_SALE ? taxBreakdown.netInvestmentIncome.taxesOwed : 0,
    state: 0,
    stateOrdinary: taxBreakdown.state[state].ordinaryIncome?.taxesOwedByOrdering?.[key] as number || 0,
    stateLongTermCapGains:
      taxBreakdown.state[state].capitalGains?.taxesOwedByOrdering?.[key] as number || 0,
    total: 0,
    amt: taxSummary.amtTax || 0,
  }
  totals.federal = (
    totals.federalOrdinary
    + totals.federalLongTermCapGains
    + totals.medicare
    + totals.socialSecurity
    + totals.netInvestmentIncome
  )

  if (useAMT) {
    totals.federal += Math.min(taxBreakdown.federal.amt?.taxesOwedByOrdering?.[key] || 0, taxSummary.amtTax)
  }
  totals.state = (totals.stateOrdinary + totals.stateLongTermCapGains)
  totals.total = Math.round(totals.state + totals.federal)
  return totals
}

export function getTaxTotals(
  result: TaxScenarioResult,
  isExercise: boolean,
): TaxTypeTotals {
  if (isExercise) {
    return getTaxTypeTotals(result, TaxBracketOrdering.SCENARIO_EXERCISE, isExercise)
  }
  const isoTaxes = getTaxTypeTotals(result, TaxBracketOrdering.SCENARIO_EXERCISE, isExercise)
  const saleTaxes = getTaxTypeTotals(result, TaxBracketOrdering.SCENARIO_SALE, true)

  Object.entries(saleTaxes).forEach(([taxType, taxesOwed]) => {
    if (taxType === TaxBracketType.AMT) return
    isoTaxes[taxType] += taxesOwed
  })
  return isoTaxes
}
