import React, {memo} from 'react'

import Button from 'core/components/button/Button'

import {ToastProps} from './types'
import {
  CtaWrapper,
  DismissButton,
  DotSeparator,
  DropZoneToastContent,
  IconWrapperError,
  IconWrapperInfo,
  IconWrapperSuccess,
  ToastContent, ToastWrapper,
} from './styled'

import UploadCloudIcon from 'assets/icons/upload-cloud.svg?react'

const IconDismiss = <i className="fa-light fa-xmark" />

const iconWrapperLookup = {
  success: <IconWrapperSuccess><i className="fa-light fa-circle-check" /></IconWrapperSuccess>,
  error: <IconWrapperError><i className="fa-light fa-hexagon-exclamation" /></IconWrapperError>,
  info: <IconWrapperInfo><UploadCloudIcon /></IconWrapperInfo>,
}

export const ToastNotification = memo(({
  id,
  intent = 'success',
  title,
  description,
  ctaLabel,
  onCtaClick,
  ctaLabelSecondary,
  onSecondaryCtaClick,
  onDismiss,
  animate = false,
  open = false,
  isDropZone = false,
}: ToastProps): JSX.Element => {
  if (isDropZone) {
    return (
      <ToastWrapper id={id} animate={animate} open={open}>
        {iconWrapperLookup[intent]}
        <DropZoneToastContent>
          <h5>{title}</h5>
        </DropZoneToastContent>
      </ToastWrapper>
    )
  }
  return (
    <ToastWrapper id={id} animate={animate} open={open}>
      {iconWrapperLookup[intent]}
      <ToastContent>
        <DismissButton fill={false} onClick={(): void => onDismiss?.()} aria-label="Dismiss Toast">
          {IconDismiss}
        </DismissButton>
        <h5>{title}</h5>
        {description && <p>{description}</p>}
        {(ctaLabel || ctaLabelSecondary) && (
        <CtaWrapper>
          {ctaLabel && <Button fill={false} onClick={onCtaClick}>{ctaLabel}</Button>}
          <DotSeparator />
          {ctaLabelSecondary && <Button fill={false} onClick={onSecondaryCtaClick}>{ctaLabelSecondary}</Button>}
        </CtaWrapper>
        )}
      </ToastContent>
    </ToastWrapper>
  )
})
