/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components'

type FontStyles = {
  bold?: boolean | number;
  underlined?: boolean;
  italic?: boolean;
  $fontFamily?: 'primary' | 'secondary';
  color?: string;
  align?: 'center' | 'right' | 'left';
}

const mapProps = ({
  bold,
  underlined,
  italic,
  $fontFamily,
  color,
  align,
}: FontStyles): string => `
  font-weight: ${bold ? 'bold' : 'normal'};
  color: ${color || '#082042'};

  ${underlined ? 'text-decoration: underline;' : ''}
  ${italic ? 'font-style: italic;' : ''}
  ${$fontFamily === 'primary' ? 'font-family: Theinhardt, sans-serif;' : ''}
  ${$fontFamily === 'secondary' ? 'font-family: Media77, serif;' : ''}

  ${align ? `text-align: ${align};` : ''}

`

export const H1 = styled.h1<FontStyles>(({theme: {above, breakpoints, fontSize}, ...rest}) => `
  ${mapProps({...rest})}

  font-size: ${fontSize.mobile.h1};

  ${above(breakpoints.largePhone)} {
    font-size: ${fontSize.desktop.h1};
  }
`)

export const H2 = styled.h2<FontStyles>(({theme: {above, breakpoints, fontSize}, ...rest}) => `
  ${mapProps({...rest})}

  font-size: ${fontSize.mobile.h2};

  ${above(breakpoints.largePhone)} {
    font-size: ${fontSize.desktop.h2};
  }
`)

export const H3 = styled.h3<FontStyles>(({theme: {above, breakpoints, fontSize}, ...rest}) => `
  ${mapProps({...rest})}

  font-size: ${fontSize.mobile.h3};

  ${above(breakpoints.largePhone)} {
    font-size: ${fontSize.desktop.h3};
  }
`)

export const H4 = styled.h4<FontStyles>(({theme: {above, breakpoints, fontSize}, ...rest}) => `
  ${mapProps({...rest})}

  font-size: ${fontSize.mobile.h4};

  ${above(breakpoints.largePhone)} {
    font-size: ${fontSize.desktop.h4};
  }
`)

export const H5 = styled.h5<FontStyles>(({theme: {above, breakpoints, fontSize}, ...rest}) => `
  ${mapProps({...rest})}

  font-size: ${fontSize.mobile.h5};

  ${above(breakpoints.largePhone)} {
    font-size: ${fontSize.desktop.h5};
  }
`)

export const Subheader = styled.h6<FontStyles>(({theme: {fontSize}, ...rest}) => `
  ${mapProps({...rest})}

   font-size: ${fontSize.desktop.h5};
   line-height: 24px;
   font-weight: 500;
`)

export const Overline = styled.span<FontStyles>(({theme, ...rest}) => `
  ${mapProps({...rest})}

  font-size: ${theme.fontSize.desktop.note};
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 12px;
`)

export const RegularText = styled.p<FontStyles>(({theme, ...rest}) => `
  ${mapProps({...rest})}

  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSize.desktop.body};
  line-height: 22px;
`)

export const LightRegularText = styled.p<FontStyles>(({theme, ...rest}) => `
  ${mapProps({...rest})}

  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSize.desktop.footNote};
  line-height: 14px;
`)
