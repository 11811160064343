import {
  ScenarioResultType,
  TaxScenario,
  TaxScenarioResults,
  TaxTypeTotals,
} from 'models'

export type NormalizedScenario = {
  [key in ScenarioResultType]: ScenarioData
}

export type ScenarioData = {
  grossProceeds: number;
  exerciseCost: number;
  scenario: TaxScenario;
  scenarioType: ScenarioResultType;
  taxTotalsFromExercise: Nullable<TaxTypeTotals>;
  taxTotalsFromSale: TaxTypeTotals;
  taxTotalsFromRsuIncome: Nullable<TaxTypeTotals>;
  isEmpty: boolean;
  optionsAllExpired: boolean;
  optionsNotVested: boolean;
  optionsCancelled: boolean;
  mixType: ScenarioGrantMixType;
  netProfit: number;
  optionsExercised: number;
  optionsLeft: number;
  sharesSold: number;
  sharesLeft: number;
  purchaseDate: string | null;
  saleDate: string;
}

export type ScenarioOrderArgs = {
  index: number;
  type: ScenarioResultType;
}

export type ScenarioArgs = {
  results: TaxScenarioResults;
}

export type ScenarioHeader = {
  overline: string;
  title: string;
  description: string;
}

export enum TaxEfficiencyScore {
  NA = 'N/A',
  LOW = 'Low efficiency',
  MEDIUM = 'Medium efficiency',
  HIGH = 'High efficiency',
}

export type QuickAnalysis = {
  netProfit: {
    value: number;
  };
  exerciseTaxLiability?: {
    value: string;
    subText: string[];
  };
  saleTaxLiability: {
    value: string;
    subText: string[];
  };
  investmentRisk: {
    value: string;
    subText: string;
  };
  cashFlowFromExercise?: {
    value: number;
    subText: string;
  };
}

// To support RSU scenarios, we will need to tailor the scenario copy based on whether a
// user holds only options, only RSUs, or a mix of both.
export enum ScenarioGrantMixType {
  ONLY_OPTIONS = 'ONLY_OPTIONS',
  ONLY_RSU = 'ONLY_RSU',
  MIXED = 'MIXED',
}

export type VestedRsuQuantity = {
  [ScenarioResultType.HOLD_OVER_A_YEAR]: {
    vestedQuantity: number;
  };
  [ScenarioResultType.HOLD_UNDER_A_YEAR]: {
    vestedQuantity: number;
  };
}
