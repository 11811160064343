import React, {KeyboardEvent, useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'

import Icon, {IconName} from 'core/components/icon/Icon'

import TextModal from 'core/legacy-components/TextModal/TextModal'
import {RoutePaths} from 'core/constants/RoutePaths'
import {MixpanelAgent} from 'core/utils'
import {ColumnContainer, RowContainer} from 'core/styled/blocks'

export const ServicesContainer = styled(ColumnContainer)(({theme}) => `
  ${theme.above(theme.breakpoints.aboveMobile)} {
    flex-direction: row;
  }
  margin-left: ${theme.spacing.m};
`)

export const Service = styled(ColumnContainer)(({theme}) => `
    width: 100%;
    margin: 0 0 ${theme.spacing.m};
    padding: ${theme.spacing.l};
    border: 1px solid ${theme.colors.darkBlue100};
    border-radius: 4px;
    background-color: ${theme.colors.white100};
    cursor: pointer;
    flex: 0 0 49%;

    ${theme.above(theme.breakpoints.aboveMobile)} {
      flex: 0 0 31%;
      margin: 0 ${theme.spacing.m} ${theme.spacing.m} 0;

      &:nth-child(3n) {
        margin: 0 0 ${theme.spacing.m};
      }
    }

    &:hover {
      border: 1px solid  ${theme.colors.darkBlue500};
    }
`)

export const Header = styled.div(({theme}) => `
    margin: 0 0 ${theme.spacing.l};
    color: ${theme.colors.darkBlue300};
    font-size: ${theme.fontSize.desktop.note};
    letter-spacing: 2px;
    text-transform: uppercase;
`)

export const SIcon = styled(Icon)(({theme}) => `
  margin: 0  ${theme.spacing.xs} 0 0;
`)

const services = [
  {
    header: 'Comprehensive Tax Planning',
    title: 'Ongoing tax guidance',
    icon: IconName.Road,
  },
  {
    header: 'Tax Preparation',
    title: 'Advisor help filing your taxes',
    icon: IconName.MoneyBook,
  },
  {
    header: 'Standalone Financial Planning',
    title: 'Re-invest your equity',
    icon: IconName.Plant,
  },
]

export default function AdvisorServices(): JSX.Element {
  const history = useHistory()
  const [findAdvisorModalOpen, setFindAdvisorModalOpen] = useState<boolean>(false)

  const toggleFindAdvisorModal = useCallback((serviceName?: string) => {
    if (!findAdvisorModalOpen) {
      MixpanelAgent.track('global_explore-other-services-CTA_click', {
        serviceName,
      })
    }
    if (findAdvisorModalOpen) MixpanelAgent.track('global_explore-other-services_cancel')
    setFindAdvisorModalOpen((prev) => !prev)
  }, [findAdvisorModalOpen])

  const openProfileStart = (): void => {
    MixpanelAgent.track('global_explore-other-services-get-started_click')
    history.push(`${RoutePaths.PROFILE_START}`)
  }

  const handleKeyPress = (e: KeyboardEvent): void | boolean => e.key === 'Enter' && openProfileStart()

  return (
    <ServicesContainer justify="space-between" wrap="wrap">
      {services.map((service) => (
        <Service
          aria-label={service.title}
          tabIndex={0}
          onClick={(): void => toggleFindAdvisorModal(service.header)}
          key={service.header}
          role="button"
          onKeyPress={handleKeyPress}
        >
          <Header>{service.header}</Header>
          <RowContainer align="center">
            <SIcon name={service.icon} />
            <span>{service.title}</span>
          </RowContainer>
        </Service>
      ))}
      {findAdvisorModalOpen && (
        <TextModal
          open={findAdvisorModalOpen}
          id="advisor"
          header="Find an advisor?"
          cancelText="Cancel"
          confirmText="Get started"
          onClose={(): void => toggleFindAdvisorModal()}
          onCancel={(): void => toggleFindAdvisorModal()}
          onConfirm={openProfileStart}
        >
          We’ll help you find and evaluate top-tier advisory firms based on your profile.
          Your concierge will assist you in scheduling interviews with your selected firms and to answer any questions.
        </TextModal>
      )}
    </ServicesContainer>
  )
}
