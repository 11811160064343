import track from '../track'
import {BackupCodeFrom, ContactSupportStep} from '../types'

type TwoFactorMethod = 'email' | 'auth_app'
type TwoFactorCodeUsed = 'otp' | 'backup'

export const twoFactorEvents = {
  // eslint-disable-next-line max-len
  global2FACodeEnteredErrorReturned: (props: {method: TwoFactorMethod; errorMsg: string; codeUsed: TwoFactorCodeUsed; path: string}): void => {
    track.mixpanel('global_2FA_code-entered_error-returned', props)
  },
  global2FALoginContactSupportClick: (props: {method: TwoFactorMethod; step: ContactSupportStep}): void => {
    track.mixpanel('global_2FA-login_contact-support_click', props)
  },
  global2FABackupCodesBannerCTAClick: (props: {cta: string}): void => {
    track.mixpanel('global_2FA-backup-codes-banner_CTA-click', props)
  },
  global2FABackupCodesRegenerateCodes: (props: {from: BackupCodeFrom}): void => {
    track.mixpanel('global_2FA-backup-codes_regenerate-codes', props)
  },
  globalAccountSettings2FAMethodToggleClick: ({method, enabled}: {method: TwoFactorMethod; enabled: boolean}): void => {
    track.mixpanel('global_account-settings_2FA-method-toggle_click', {
      currentState: enabled ? 'enabled' : 'disabled',
      method,
    })
  },
  globalAccountSettings2FATurnOff: ({method}: {method: TwoFactorMethod}): void => {
    track.mixpanel('global_account-settings_2FA_turn-off', {method})
  },
  globalAccountSettings2FASetupSomplete: ({method}: {method: TwoFactorMethod}): void => {
    track.mixpanel('global_account-settings_2FA-setup_complete', {method})
  },
  globalAccountSettings2FASetupGetStarted: ({method}: {method: TwoFactorMethod}): void => {
    track.mixpanel('global_account-settings_2FA-setup_get-started', {method})
  },
  globalAccountSettings2FASetupNextStepClick: ({method}: {method: TwoFactorMethod}): void => {
    track.mixpanel('global_account-settings_2FA-setup_next-step-click', {method})
  },
  globalAccountSettings2FASetupCodeEntered: ({method, result}: {method: TwoFactorMethod; result: 'success' | 'error'}): void => {
    track.mixpanel('global_account-settings_2FA-setup_code-entered', {method, result})
  },
  globalAccountSettings2FASetupGoBack: ({
    method, qrCodeGenerated, backTo,
  }: {
    method: TwoFactorMethod;
    qrCodeGenerated: boolean;
    backTo: string;
  }): void => {
    track.mixpanel('global_account-settings_2FA-setup_go-back', {method, qrCodeGenerated, backTo})
  },
  globalAccountSettings2FABackupCodesPageView: (props: {from: BackupCodeFrom}): void => {
    track.mixpanel('global_account-settings_2FA-backup-codes_page-view', props)
  },
  global2FABackupCodesCopyCodes: (): void => {
    track.mixpanel('global_2FA-backup-codes_copy-codes')
  },
  global2FABackupCodesDownloadCodes: (): void => {
    track.mixpanel('global_2FA-backup-codes_download-codes')
  },
  global2FABackupCodesDoneClick: (props: {from: BackupCodeFrom}): void => {
    track.mixpanel('global_2FA-backup-codes_done_click', props)
  },

}
