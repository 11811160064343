import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'

import styles from './_displayComponents.module.scss'

export type Props = {
  /** Contents of the component. */
  children?: React.ReactNode | React.ReactNode[];
  /** Space-separated list of classes to pass to the component. */
  className?: string;
  /** Optional contents of the component. */
  text?: string;
}

const Overline = ({
  children,
  className,
  text,
}: Props): JSX.Element => (
  <span
    className={cx(
      styles.overline,
      className,
    )}
  >
    {text}
    {children}
  </span>
)

export default styled(Overline)<{color?: string}>(({color}) => `
  ${color ? `color: ${color};` : ''}
`)
