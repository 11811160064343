import {createSelector} from '@reduxjs/toolkit'
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query'
import {QueryReturnValue} from '@reduxjs/toolkit/dist/query/baseQueryTypes'

import {Company} from './types'
import {clearProgress, showSubmitLoader} from './actions'

import {apiSlice} from 'store/main/apiSlice'
import {showDefaultErrorAlert, showDefaultSuccessAlert} from 'store/alert/actions'
import {RootState} from 'store/main'
import {toYmd} from 'core/formatters'
import {PriceType} from 'api/company'
import {Analytics} from 'modules/analytics'

const companyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanies: builder.query<Company[], void>({
      query: () => 'v1/equity_liquidity/company/',
      providesTags: ['Company'],
    }),
    createCompany: builder.mutation<Company, void>({
      queryFn: async (_aa: void, {getState, dispatch}, _extraOptions, baseQuery) => {
        dispatch(showSubmitLoader())
        const {company: {addCompanyForm}} = getState() as RootState
        const {
          companyName, hasBeenEmployed, stillEmployed, employmentEndDate, postEmploymentOptionExpiration,
          price, pricingDate,
        } = addCompanyForm

        const companyResponse = await baseQuery({
          url: '/v1/equity_liquidity/company/',
          method: 'POST',
          body: {
            name: companyName,
            hasBeenEmployed: hasBeenEmployed || stillEmployed,
            employmentEndDate: toYmd(employmentEndDate) || null,
            postEmploymentOptionExpiration: Number(postEmploymentOptionExpiration) || 0,
          },
        }) as QueryReturnValue<Company, FetchBaseQueryError, {}>

        const pricingInfo = companyResponse.data && await baseQuery({
          url: `/v1/equity_liquidity/company/${companyResponse?.data?.id}/pricing/`,
          method: 'POST',
          body: {
            price: price ?? 0,
            priceType: PriceType['409A'],
            pricingDate: toYmd(pricingDate),
          },
        })

        if (companyResponse.error || pricingInfo?.error) {
          Analytics.addCompanyFailed()
          return {error: companyResponse.error || pricingInfo?.error as FetchBaseQueryError}
        }

        Analytics.companyAdded()
        dispatch(clearProgress())
        return {data: companyResponse.data}
      },
      invalidatesTags: ['Company', 'Entity'],
    }),
    updateCompany: builder.mutation<Company, Pick<Company, 'id'> & Partial<Company>>({
      query: ({id, ...rest}) => ({
        url: `v1/equity_liquidity/company/${id}/`,
        method: 'PATCH',
        body: {...rest},
      }),
      onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled

          const keys = Object.keys(arg)
          if (keys.includes('closedFromApplication')) {
            dispatch(showDefaultSuccessAlert('company', `${arg.closedFromApplication ? 'closed' : 're-opened'}`))
          } else {
            dispatch(showDefaultSuccessAlert('company', 'updated'))
          }
        } catch (e) {
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['Company', 'Entity'],
    }),
    deleteCompany: builder.mutation<void, string>({
      query: (id) => ({
        url: `v1/equity_liquidity/company/${id}/`,
        method: 'DELETE',
      }),
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled
          dispatch(showDefaultSuccessAlert('company', 'removed'))
        } catch (e) {
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['Company', 'Entity'],
    }),
  }),
})

export const {
  useGetAllCompaniesQuery,
  useLazyGetAllCompaniesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companyApiSlice

export const companiesSelector = companyApiSlice.endpoints.getAllCompanies.select()
export const selectAllCompanies = createSelector(companiesSelector, ({
  data: companies = [],
}) => [...companies].sort((a, b) => a.name.localeCompare(b.name)))
