import {
  combineReducers,
  createReducer,
  unwrapResult,
} from '@reduxjs/toolkit'

import * as ScenarioActions from './actions'
import {normalizeScenarioData} from './utils'
import {NormalizedScenario, VestedRsuQuantity} from './types'

import {LiquidityEvent, ScenarioResultType, TaxScenarioResults} from 'models'

const defaultOrder = [
  ScenarioResultType.HOLD_OVER_A_YEAR,
  ScenarioResultType.HOLD_UNDER_A_YEAR,
  ScenarioResultType.AMT_BREAKEVEN,
]

const taxScenarioResults = createReducer({} as TaxScenarioResults, (builder) => {
  builder
    .addCase(ScenarioActions.setScenarios, (_state, action) => {
      const {results} = unwrapResult(action)
      return results
    })
})

const scenarios = createReducer({} as NormalizedScenario, (builder) => {
  builder
    .addCase(ScenarioActions.setScenarios, (_state, action) => {
      const {results} = unwrapResult(action)
      return Object.entries(results)
        .reduce((acc, [type, scenario]) => {
          acc[type] = normalizeScenarioData(type, scenario)
          return acc
        }, {} as NormalizedScenario)
    })
})

const order = createReducer(defaultOrder, (builder) => {
  builder
    .addCase(ScenarioActions.setScenarioOrder, (state, action) => {
      const {index, type} = unwrapResult(action)
      const scenarioTypes = [...state]
      const fromIndex = scenarioTypes.indexOf(type)

      scenarioTypes[fromIndex] = scenarioTypes[index]
      scenarioTypes[index] = type

      return scenarioTypes
    })
    .addCase(ScenarioActions.setScenarios, () => defaultOrder)
})

const info = createReducer({} as LiquidityEvent, (builder) => {
  builder.addCase(ScenarioActions.setEventData, (_state, action) => action.payload)
})

const doubleTriggerRSUQuantity = createReducer({} as VestedRsuQuantity, (builder) => {
  builder.addCase(
    ScenarioActions.fetchDoubleTriggerRSUQuantity.fulfilled,
    (_state, action) => action.payload,
  )
})

const liquidityEventReducer = combineReducers({
  scenarios,
  info,
  order,
  taxScenarioResults,
  doubleTriggerRSUQuantity,
})

export default liquidityEventReducer
