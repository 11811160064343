import React, {useCallback, useState} from 'react'
import {useParams} from 'react-router-dom'
import {isPast, isToday, parseISO} from 'date-fns'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'

import {useEquityInsights, useTaxProfileV2} from '../hooks'

import {apiSlice} from 'store/main/apiSlice'
import styles from 'pages/equity-insights-results/_results.module.scss'
import {
  LiquidityEventSummary, TaxProfileModal, useLiquidityEventWithResults,
} from 'pages/equity-insights-results'
import {LiquidityEventWithResults, TaxProfile, TaxProfileResidency} from 'models'
import {EditLiquidityEventModal} from 'modules/edit-liquidity-event-modal'
import {MixpanelAgent} from 'core/utils'

type Props = {
  handleNetworkError: () => void;
}

export const LiquidityEventSummaryBar = ({handleNetworkError}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const {showEditLiquidityEvent, setShowEditLiquidityEvent} = useEquityInsights()
  const [showEditTaxProfile, setShowEditTaxProfile] = useState<boolean>(false)

  const {companyId} = useParams<{companyId: string}>()

  const {
    taxProfile, setTaxProfile, taxProfileResidency, setTaxProfileResidency,
  } = useTaxProfileV2(companyId)

  const {
    eventWithResults,
    rerunScenario,
    setLiquidityEvent,
    unsupportedStates,
  } = useLiquidityEventWithResults(companyId, handleNetworkError)

  const onToggleEditTaxProfile = useCallback(() => {
    if (!showEditTaxProfile) MixpanelAgent.track('equity-scenario-results_harness-tax-profile-click')
    setShowEditTaxProfile((prev) => !prev)
  }, [showEditTaxProfile])

  const onUpdateTaxProfile = useCallback(async (
    updatedTaxProfile: TaxProfile,
    updatedTaxProfileResidency: TaxProfileResidency,
  ) => {
    setTaxProfile(updatedTaxProfile)
    setTaxProfileResidency(updatedTaxProfileResidency)
    onToggleEditTaxProfile()
    await rerunScenario()
    dispatch(apiSlice.util.invalidateTags(['Scenario']))
  }, [
    setTaxProfile,
    setTaxProfileResidency,
    onToggleEditTaxProfile,
    rerunScenario,
    dispatch,
  ])

  const liquidityEventDatePassed = Boolean(eventWithResults.estimatedEventDate)
    && isPast(parseISO(eventWithResults.estimatedEventDate))
    && !isToday(parseISO(eventWithResults.estimatedEventDate))

  return (
    <>
      <StyledEventSummaryContainer>
        <div className={styles.pageHeader}>
          <LiquidityEventSummary
            companyId={companyId}
            zipCode={taxProfileResidency?.zipCode}
            eventWithResults={eventWithResults}
            onToggleEditTaxProfile={onToggleEditTaxProfile}
            onToggleEditLiquidityEvent={(): void => setShowEditLiquidityEvent((prev) => !prev)}
            householdIncome={taxProfile?.householdIncome || 0}
            unsupportedStates={unsupportedStates}
            liquidityEventDatePassed={liquidityEventDatePassed}
          />
        </div>
      </StyledEventSummaryContainer>
      {(showEditLiquidityEvent) && (
      <EditLiquidityEventModal
        companyId={companyId}
        liquidityEvent={eventWithResults}
        onClose={(): void => setShowEditLiquidityEvent((prev) => !prev)}
        onSubmitAndRerun={(event: LiquidityEventWithResults): void => {
          setLiquidityEvent(event)
          setShowEditLiquidityEvent((prev) => !prev)
          dispatch(apiSlice.util.invalidateTags(['Scenario']))
        }}
        includeResults
      />
      )}
      {showEditTaxProfile && taxProfile && taxProfileResidency && (
      <TaxProfileModal
        taxProfile={taxProfile}
        taxProfileResidency={taxProfileResidency}
        onCancel={onToggleEditTaxProfile}
        onSubmit={onUpdateTaxProfile}
      />
      )}
    </>
  )
}

const StyledEventSummaryContainer = styled.div`
  >div:first-child {
    padding: 0;
  }
`
