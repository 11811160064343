import React, {useLayoutEffect, useRef} from 'react'
import cx from 'classnames'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import styles from './_app.module.scss'
import AppFooter from './AppFooter'
import {useGetCurrentUserProfileQuery} from './store/profile'

import {AppSession} from 'core/utils'
import AppNavigation from 'modules/navigation/AppNavigation'
import {AlertBanner} from 'modules/alert-banner'
import {ToastContainer} from 'modules/toast'
import {RoutePaths} from 'core/constants/RoutePaths'
import {UNAUTHENTICATED_ROUTE_PATH_SET} from 'modules/auth'
import {removeAlert, showErrorAlert} from 'store/alert/actions'
import {AlertSelector} from 'store/alert'

const isUsingInternetExplorer = /MSIE|Trident/.test(window.navigator.userAgent)

type AppLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  session: AppSession;
}

const GLOBAL_ONBOARDING_ROUTES = [
  RoutePaths.ONBOARDING.FORM_MILESTONES,
  RoutePaths.ONBOARDING.FORM_WINDFALL,
  RoutePaths.ONBOARDING.FORM_WINDFALL_TIMING,
  RoutePaths.ONBOARDING.FORM_WINDFALL_AMOUNT,
  RoutePaths.ONBOARDING.FORM_EMPLOYMENT,
  RoutePaths.ONBOARDING.FORM_PROFESSIONS,
  RoutePaths.ONBOARDING.FORM_SMALL_BUSINESS_TYPE,
  RoutePaths.ONBOARDING.FORM_SMALL_BUSINESS_REVENUE,
  RoutePaths.ONBOARDING.FORM_ILLIQUID_TYPE,
  RoutePaths.ONBOARDING.FORM_GET_STARTED,
  RoutePaths.ONBOARDING.FORM_TAX_PLANNING,
]

const ROOT_ROUTES = [
  '/',
  RoutePaths.REGISTER,
  RoutePaths.REGISTER_PROXY,
  RoutePaths.ACCEPT_INVITE,
  RoutePaths.ONBOARDING_ROOT,
  RoutePaths.WELCOME_BACK,
]

function AppLayout({
  children,
  session,
}: AppLayoutProps): JSX.Element {
  const isPrevAcceptInv = useRef(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const message = useSelector(AlertSelector.getAlert)

  const {isEditMode} = useGetCurrentUserProfileQuery(undefined, {
    selectFromResult: ({data, ...rest}) => ({
      isEditMode: data?.meta.didCompleteShortOnboarding && (data?.meta.didCompleteProfile || data?.meta.hasViewedCompleteProfile),
      ...rest,
    }),
  })

  const isAcceptInvite = !!useRouteMatch({
    path: RoutePaths.ACCEPT_INVITE,
    exact: true,
  })

  const isDashboard = !!useRouteMatch({
    path: RoutePaths.ADVISER_SEARCH,
    exact: true,
  })

  const showGlobalOnboardingNavigation = !!isEditMode

  // Using useLayoutEffect to preserve banners that are render on initial load in other component
  useLayoutEffect(() => {
    if (isAcceptInvite) {
      isPrevAcceptInv.current = true
    }

    if (!isPrevAcceptInv.current && !message?.preserve) {
      dispatch(removeAlert())
    }

    if (isPrevAcceptInv.current && isDashboard) {
      isPrevAcceptInv.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch, isAcceptInvite, isPrevAcceptInv, isDashboard, session])

  const ROUTES_WITHOUT_APP_NAV = [
    ...ROOT_ROUTES,
    ...(!showGlobalOnboardingNavigation ? GLOBAL_ONBOARDING_ROUTES : []),
    RoutePaths.ADVISOR_SESSIONS_QUESTIONNAIRE,
    RoutePaths.ADVISOR_SESSIONS_FILES_UPLOAD,
    RoutePaths.WELCOME_INVITED_CLIENT,
    RoutePaths.LOGIN_VERIFY,
  ]

  const match = useRouteMatch({
    path: ROUTES_WITHOUT_APP_NAV,
    exact: true,
  })
  const fullPage = Boolean(match)

  const isAuthenticatedRoute = !useRouteMatch({
    path: Array.from(UNAUTHENTICATED_ROUTE_PATH_SET),
    exact: true,
  })

  const isEmailVerifyRoute = !!useRouteMatch({
    path: RoutePaths.EMAIL_VERIFY,
    exact: true,
  })

  if (isUsingInternetExplorer) {
    dispatch(showErrorAlert(`
      We currently do not support Internet Explorer.
      Please access us on Chrome, Safari, Microsoft Edge, Firefox, or Opera.`))
  }

  // EmailVerification will either render an error or redirect on success. Error state takes over whole page
  // and is a special case, no navigation, hamburger menu, and it needs to take over whole viewport.
  // EmailVerification is accessible by both logged in and out users.
  // http://localhost:3000/email/verify?t=179ef5fb-018e-4507-8360-2087cb98c2b8
  if (isEmailVerifyRoute) {
    return (
      <div style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
      }}
      >
        {children}
      </div>
    )
  }

  return (
    <div className={cx(
      styles.appGrid,
      styles.fullHeight,
      fullPage && styles.fullPage,
      session.userIsAuthenticated && styles.sidebarNavigationLayout,
    )}
    >
      {!fullPage && (
        <AppNavigation
          onInternetExplorer={isUsingInternetExplorer}
          session={session}
          isAuthenticatedRoute={isAuthenticatedRoute}
        />
      )}
      <div className={cx(session.userIsAuthenticated && styles.contentContainer)}>
        <div className={cx(styles.mainContentArea, styles.fullHeight, session.userIsAuthenticated && styles.sidebarLayout)}>
          <AlertBanner />
          <main className={cx(styles.mainContent, styles.fullHeight)}>
            {children}
          </main>
        </div>
        <AppFooter />
        {session.userIsAuthenticated && (
          <ToastContainer />
        )}
      </div>
    </div>
  )
}

export default AppLayout
