import React from 'react'

import TwoColumnTable, {GridRow} from './TwoColumnTable'

import {numberFormatter} from 'core/formatters/numberFormatter'
import {FILING_STATUS_LABELS} from 'models'
import {TaxScenarioResult} from 'models/liquidityEvent'

function buildNIIThresholdRows({taxBreakdown: {netInvestmentIncome}, taxProfile}: TaxScenarioResult): GridRow[] {
  return [
    {
      label: 'MAGI',
      value: numberFormatter(netInvestmentIncome.adjustedGrossIncome).currency,
    },
    {
      label: `Statutory Threshold (${FILING_STATUS_LABELS[taxProfile.filingStatus]} Filer)`,
      value: numberFormatter(netInvestmentIncome.threshold).currency,
    },
    {
      label: 'NIIT Threshold',
      value: numberFormatter(netInvestmentIncome.agiIncomeOverThreshold).currency,
    },
  ]
}

export default function NIITThresholdTable({result}: {result: TaxScenarioResult}): JSX.Element {
  const rows = React.useMemo(() => buildNIIThresholdRows(result), [result])
  return (
    <TwoColumnTable rows={rows} title="Net Investment Income Tax Threshold" />
  )
}
