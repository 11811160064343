import {
  format,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
} from 'date-fns'

import {convertFormattedStringToNumber, parseMdy} from 'core/formatters'

export function getToday(): string {
  return format(new Date(), 'MM/dd/yyyy')
}

export function isPriceValid(price: string, min = 0.01): boolean {
  const max = 1000000.00

  const parsed = convertFormattedStringToNumber(price)

  return parsed >= min && parsed <= max
}

export const DATE_FORMAT_REGEX = /^\d{2}\/\d{2}\/\d{4}$/

export const getDateFormatError = (date: string): Nullable<string> => {
  // Date argument is in MM/DD/YYYY format
  if (!DATE_FORMAT_REGEX.test(date)) {
    return 'Date must be in MM/DD/YYYY format'
  }

  const [monthString, dayString, yearString] = date.split('/')

  // Year should start with 19 or 20
  if (!/^(19|20)/.test(yearString)) {
    return 'Year must start with 19 or 20'
  }

  const month = Number(monthString)

  if (month < 1 || month > 12) {
    return 'Month must be between 01 and 12'
  }

  const day = Number(dayString)

  if (day < 1 || day > 31) {
    return 'Day must be between 01 and 31'
  }

  const daysInMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const year = Number(yearString)

  // Add day to February if leap year
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    daysInMonths[1] = 29
  }

  // Validate that the day exists on the calendar
  if (day > daysInMonths[month - 1]) {
    return 'Date is not a valid calendar date'
  }

  // Validate min date
  if (!isAfter(parseMdy(date), parseISO('1980-01-01'))) {
    return 'Date must be after 01/01/1980'
  }

  return null
}

export type ValidationFunction = (
  name: string,
  value: string
) => Nullable<string>

export type ValidationFunctionWithDependencies = (
  name: string,
  value: string,
  deps: {[key: string]: string}
) => Nullable<string>

export type ValidationInstruction = {
  deps: {[key: string]: string};
  fn: ValidationFunctionWithDependencies;
} | ValidationFunction

type ValidationSchemaRequiredInstruction = boolean | {
  allowEmptyString?: boolean;
  ifCondition?: boolean;
}

export type ValidationsSchemaValidators = [
  string,
  ValidationSchemaRequiredInstruction,
  ValidationInstruction[],
]

export type ValidationsSchema = {
  [key: string]: ValidationsSchemaValidators;
}

export const isInPast: ValidationFunction = (name: string, value: string) => {
  if (isAfter(parseMdy(value), new Date())) {
    return `${name} must be in the past`
  }

  return null
}

export const isAfterDate: ValidationFunctionWithDependencies = (
  name: string,
  value: string,
  deps: {[key: string]: string},
) => {
  const after = isAfter(parseMdy(
    value,
  ), parseMdy(deps.afterDate))

  if (!after) {
    return deps.errorMessage
  }

  return null
}

export const validatePrice: ValidationFunction = (_name: string, price: string) => {
  if (!(isPriceValid(price))) {
    return 'Please enter a price between $0.01 and $1,000,000'
  }

  return null
}

export const isGreaterThanZero: ValidationFunction = (name: string, quantity: string) => {
  if (Number(quantity) <= 0) {
    return `Please enter a ${name.toLowerCase()} of 1 or more`
  }

  return null
}

export const validateDateFormat: ValidationFunction = (name: string, date: string) => getDateFormatError(date)

export const getFieldValidationErrors = (
  fieldName: string,
  required: ValidationSchemaRequiredInstruction,
  fns: ValidationInstruction[],
  value: Nullable<string>,
): string[] => {
  if (typeof required === 'object') {
    const allowEmptyStringInvalid = required.allowEmptyString && !value && value !== ''

    if (Object.prototype.hasOwnProperty.call(required, 'ifCondition')) {
      if (!required.ifCondition) {
        return []
      }

      if (allowEmptyStringInvalid || !value) {
        return [`${fieldName} is required`]
      }
    }

    if (allowEmptyStringInvalid) {
      return [`${fieldName} is required`]
    }
  } else {
    if (required && !value) {
      return [`${fieldName} is required`]
    }

    if (!required && !value) {
      return []
    }
  }

  if (value === null) {
    return []
  }

  let foundError: Nullable<string> = null

  for (let i = 0; i < fns.length; i += 1) {
    const validator = fns[i]

    if (typeof validator === 'function') {
      foundError = validator(fieldName, value)
    } else {
      const {fn, deps} = validator
      foundError = fn(fieldName, value, deps)
    }

    // Return first found error to avoid multiple error messages in UI
    if (foundError) {
      return [foundError]
    }
  }

  return []
}

export const getFormErrors = (
  validations: ValidationsSchema,
  formData: KeyedObject,
): KeyedObject => Object.keys(validations).reduce((acc, curr) => {
  const [fieldName, required, fns] = validations[curr]
  const errs = getFieldValidationErrors(fieldName, required, fns, formData[curr])

  if (errs.length > 0) {
    acc[curr] = errs.join(', ')
  }

  return acc
}, {})

export const isOnOrAfterDate: ValidationFunctionWithDependencies = (
  name: string,
  value: string,
  deps: {[key: string]: string},
) => {
  // debugger
  const onDate = isEqual(parseMdy(value), parseMdy(deps.onOrAfterDate))

  if (onDate) {
    return null
  }

  const afterDate = isAfter(parseMdy(value), parseMdy(deps.onOrAfterDate))

  if (afterDate) {
    return null
  }

  return deps.errorMessage
}

export const isOnOrBeforeDate: ValidationFunctionWithDependencies = (
  name: string,
  value: string,
  deps: {[key: string]: string},
) => {
  const onDate = isEqual(parseMdy(value), parseMdy(deps.onOrBeforeDate))

  if (onDate) {
    return null
  }
  const beforeDate = isBefore(parseMdy(value), parseMdy(deps.onOrBeforeDate))

  if (beforeDate) {
    return null
  }

  return deps.errorMessage
}
