import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Location} from 'history' // eslint-disable-line import/no-extraneous-dependencies

import {MixpanelAgent} from 'core/utils'
import {UtmParameters, getUtmParameters} from 'core/utils/UtmParametersManager'

export default function usePageViewTracking(): void {
  const location: Location<{blockBrowserBack?: boolean}> = useLocation()

  useEffect(() => {
    if (!location.state?.blockBrowserBack) {
      MixpanelAgent.track('page_view')
      const utmParams = getUtmParameters() as UtmParameters

      // Hubspotwarn
      const qs = Object.entries(utmParams).map(([key, value]) => `${key}=${value}`).join('&')
      let prefixedPath = `/app${location.pathname}${location.search}`
      if (qs) prefixedPath += `?${qs}`

      // HubSpot pixel to track in-app pages
      const hsq = window._hsq || [] // eslint-disable-line no-underscore-dangle
      hsq.push(['setPath', prefixedPath])
      hsq.push(['trackPageView'])
    }
    // Do NOT include match objects here -- we only want to trigger effect on location change
  }, [location.pathname, location.state]) // eslint-disable-line
}
