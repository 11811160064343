import React from 'react'
import cx from 'classnames'

import styles from './_tag.module.scss'

export type Props = {
  /** Contents of the component. */
  children?: React.ReactNode;
  /** Space-separated list of classes to pass to the component. */
  className?: string;
  /** Optional contents of the component. */
  text?: string;
  /** Optional style variant. */
  variant?: 'light' | 'medium' | 'dark';
}

function Tag({
  children,
  className,
  text,
  variant = 'light',
}: Props): JSX.Element {
  return (
    <span className={cx(styles.tag, styles[variant], className)}>
      {text}
      {children}
    </span>
  )
}

export default React.memo(Tag)
