import styled, {css, keyframes} from 'styled-components'

import Button from 'core/components/button/Button'

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const fadeOutDown = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
`

export const ToastWrapper = styled.div<{animate: boolean; open: boolean}>(({theme, animate, open}) => css`
  display: flex;
  position: relative;
  background: ${theme.colors.white100};

  ${animate ? css`animation: 0.2s ease-in ${fadeInUp};` : ''}
  ${open ? '' : css`animation: 0.2s ease-in ${fadeOutDown} forwards;`}

  ${theme.above(theme.breakpoints.aboveMobile)} {
    width: min-content;
    min-width: 472px;
  }

  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  overflow: hidden;
`)

const IconWrapperBase = styled.div`
  width: 64px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
`

export const IconWrapperSuccess = styled(IconWrapperBase)(({theme}) => `
  background: ${theme.colors.success200};
  color: ${theme.colors.success500};
`)

export const IconWrapperError = styled(IconWrapperBase)(({theme}) => `
  background: ${theme.colors.error200};
  color: ${theme.colors.error500};
`)

export const IconWrapperInfo = styled(IconWrapperBase)(({theme}) => `
  background: ${theme.colors.info};
`)

export const ToastContent = styled.div(({theme}) => `
  padding: ${theme.spacing.l} ${theme.spacing.xl} ${theme.spacing.l} ${theme.spacing.l};
  color: ${theme.colors.darkBlue300};

  h5 {
    color: ${theme.colors.darkBlue400};
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: ${theme.fontFamily.primary};
    font-weight: bold;
    margin-bottom: ${theme.spacing.m};
  }

  p {
    margin: 0 0 ${theme.spacing.m};

    :last-child {
      margin: 0;
    }
  }
`)

export const DropZoneToastContent = styled.div(({theme}) => `
  padding: ${theme.spacing.l};
  color: ${theme.colors.darkBlue300};

  h5 {
    color: ${theme.colors.darkBlue400};
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: ${theme.fontFamily.primary};
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
`)

export const DotSeparator = styled.span(({theme}) => `
  display: none;
  ${theme.above(theme.breakpoints.aboveMobile)} {
    :not(:first-child):not(:last-child) {
      display: inline;
    }
  }
  ::before {
    content: "·";
  }
`)

export const CtaWrapper = styled.div(({theme}) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${theme.colors.darkBlue500};
  font-weight: bold;
  gap: ${theme.spacing.m};
  white-space: nowrap;

  ${theme.above(theme.breakpoints.aboveMobile)} {
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  font-size: 0.75rem;

  button {
    font-weight: bold;
    letter-spacing: 2px;
    text-underline-offset: 4px;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-transform: uppercase;
  }
`)

export const DismissButton = styled(Button)(({theme}) => `
  position: absolute;
  font-size: 1.5rem;
  line-height: 1.5rem;
  top: ${theme.spacing.m};
  right: ${theme.spacing.m};
  color: ${theme.colors.darkBlue200};
`)
