import React from 'react'
import cx from 'classnames'

import Icon, {IconName} from 'core/components/icon/Icon'
import {InsightComponent} from 'core/components/insight/Insight'

import ProgressBar from '../progressBar/ProgressBar'
import Button from '../button/Button'
import Overline from '../display/Overline'

import styles from './_onboardingLayout.module.scss'

import colors from 'core/styles/_colors.module.scss'

enum IconColors {
  WHITE = '#FFFFFF',
  BRAND_BLUE = '#082042',
  BLUE = '#384e68',
}

export type Props = {
  /** Removes header and footer */
  isIntroPage?: boolean;
  /** Whether to fix position below main navigation */
  positionBelowAppNavMenu?: boolean;
  /** Show/Hide back button */
  hasBackBtn?: boolean;
  /** Show/Hide close button */
  hasCloseBtn?: boolean;
  /** Show/Hide footer */
  hasFooter?: boolean;
  /** Show/Hide header */
  hasHeader?: boolean;
  /** Show/Hide Insights component */
  hasInsight?: boolean;
  /** Show/Hide progress bar */
  hasProgressBar?: boolean;
  /** Text to be displayed as insight description */
  insightDescription?: string | JSX.Element;
  /** Text to be displayed as insight title */
  insightTitle?: string | JSX.Element;
  /** Max. Number of steps on flow */
  steps?: number;
  /** Current step in flow */
  currentStep?: number;
  /** Text to be displayed on footer button, default Next */
  nextBtnLabel?: string | JSX.Element;
  /** Show/Hide next button */
  hasNextBtn?: boolean;
  /** Whether to disable footer action or not */
  isSubmitDisabled?: boolean;
  /** Is loading in progress */
  isLoading?: boolean;
  /** Cypress button tag */
  buttonCy?: string;
  /** Event handler */
  onBackClick?: () => void;
  /** Event handler */
  onNextClick?: () => void;
  /** Event handler */
  onCloseClick?: () => void;
  /** Content */
  children?: React.ReactNode;
  /** Widen content container */
  isWide?: boolean;
}

const OnboardingLayout = ({
  isWide = false,
  isIntroPage = false,
  positionBelowAppNavMenu = false,
  insightDescription,
  insightTitle,
  steps = undefined,
  currentStep = undefined,
  hasBackBtn = false,
  hasCloseBtn = false,
  hasFooter = true,
  hasHeader = true,
  hasInsight = true,
  hasProgressBar = true,
  nextBtnLabel = 'next',
  hasNextBtn = true,
  isSubmitDisabled = false,
  isLoading = false,
  buttonCy = 'next-button',
  onBackClick,
  onNextClick,
  onCloseClick,
  children,
}: Props): JSX.Element => {
  const showInsight = (Boolean(insightDescription) || Boolean(insightTitle)) && hasInsight
  const hasProgress = steps && currentStep !== undefined && hasProgressBar

  return (
    <div className={styles.onboardingContainer} data-cy="onboarding-layout">
      {hasHeader && !isIntroPage && (
      <div className={cx(styles.header, {
        [styles.positionBelowAppNavMenu]: positionBelowAppNavMenu,
      })}
      >
        <div className={styles.backContainer}>
          <button
            type="button"
            className={cx(hasBackBtn && styles.showBackBtn, styles.backCTA)}
            onClick={onBackClick}
            data-cy="onboarding-layout-back-click"
          >
            <Icon name={IconName.CaretRight} fill={IconColors.BRAND_BLUE} size={20} />
            <Overline className={styles.backLabel} text="Back" />
          </button>
        </div>
        <div className={cx(styles.contentContainer)}>
          {hasProgress && (
          <ProgressBar
            wrapperClassName={styles.progressBar}
            currentProgress={currentStep}
            totalProgress={steps}
            id="stepper-progress-bar"
          />
          )}
        </div>
        <button
          type="button"
          className={cx(hasCloseBtn && styles.showCloseBtn, styles.closeCTA)}
          onClick={onCloseClick}
        >
          <Icon
            name={IconName.MenuCancel}
            fill={IconColors.BLUE}
            size={16}
          />
        </button>
      </div>
      )}
      <div className={styles.main}>
        <div className={cx(
          styles.contentContainer,
          !isWide && styles.sm,
          isWide && styles.wide,
          isIntroPage && styles.introPage,
        )}
        >
          {children}
        </div>
      </div>
      {!isIntroPage && (
        <div className={styles.insight}>
          <div className={cx(styles.contentContainer, styles.sm)}>
            {showInsight && (
            <InsightComponent
              className={styles.insightComponent}
              backgroundColor={colors.beige200}
              description={insightDescription}
              icon={IconName.HWLogo}
              iconBackground={IconColors.WHITE}
              iconFill={IconColors.BRAND_BLUE}
              title={insightTitle}
            />
            )}
          </div>
        </div>
      )}
      {hasFooter && !isIntroPage && (
        <div className={styles.footer}>
          <div className={cx(styles.contentContainer)}>
            {hasNextBtn && (
            <Button
              loading={isLoading}
              disabled={isSubmitDisabled}
              className={styles.nextCTA}
              data-cy={buttonCy}
              intent="primary"
              onClick={onNextClick}
            >
              {nextBtnLabel}
            </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(OnboardingLayout)
