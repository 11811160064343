import React from 'react'
import {Markup} from 'interweave'
import styled, {useTheme} from 'styled-components'
import {useParams} from 'react-router-dom'

import {ChecklistIcon} from 'assets/icons'
import {
  ScenarioType, selectScenarioBreakdownData, selectScenarioByType, useGetLiquidityEventQuery,
} from 'store/scenario'
import {
  H4, H5, RegularText, Subheader,
} from 'core/theme'
import {RowContainer} from 'core/styled/blocks'

type BreakdownNumberItemProps = {
  title: string;
  value: string;
}

const BreakdownNumberItem = ({title, value}: BreakdownNumberItemProps): JSX.Element => {
  const theme = useTheme()

  return (
    <StyledBreakdownNumberItemContainer>
      <RegularText color={theme.colors.darkBlue300}>{title}</RegularText>
      <Subheader color={theme.colors.darkBlue500}>{value}</Subheader>
    </StyledBreakdownNumberItemContainer>
  )
}

type BreakdownRowProps = {
  title: string;
  content: string;
}

const BreakdownRow = ({title, content}: BreakdownRowProps): JSX.Element => (
  <StyledRow>
    <SmallH5 bold $fontFamily="primary">{title}</SmallH5>
    <SmallH5 $fontFamily="primary"><Markup noWrap content={content} /></SmallH5>
  </StyledRow>
)

type ScenarioBreakdownProps = {
  scenarioType: ScenarioType;
}

const ScenarioBreakdown = ({scenarioType}: ScenarioBreakdownProps): JSX.Element => {
  const {companyId} = useParams<{companyId: string}>()

  const {scenario, scenarioBreakdownData} = useGetLiquidityEventQuery(companyId, {
    selectFromResult: (result) => ({
      scenario: selectScenarioByType(result.data?.scenarios, scenarioType),
      scenarioBreakdownData: selectScenarioBreakdownData(result.data?.scenarios, scenarioType),
    }),
  })

  if (!scenario || !scenarioBreakdownData) {
    return <></>
  }

  return (
    <BreakdownContainer>
      <StyledRowContainer align="center">
        <ChecklistIcon />
        <H4 align="left">Scenario Breakdown</H4>
      </StyledRowContainer>
      {scenarioBreakdownData.exerciseLabel && (
        <>
          <BreakdownRow title="Exercise" content={scenarioBreakdownData.exerciseLabel} />
          <StyledBreakdownNumbersContainer>
            <BreakdownNumberItem title="Exercise cost" value={scenario.exercise.costFormatted} />
            <BreakdownNumberItem title="Taxes from exercise" value={scenario.exercise.taxTotalsFormatted} />
          </StyledBreakdownNumbersContainer>
          <HorizontalLine />
        </>
      )}
      <BreakdownRow title="Sale" content={scenarioBreakdownData.saleLabel} />
      <StyledBreakdownNumbersContainer>
        <BreakdownNumberItem title="Total proceeds" value={scenario.sale.grossProceedsFormatted} />
        <BreakdownNumberItem title="Taxes from sale" value={scenario.sale.taxTotalsFormatted} />
        <BreakdownNumberItem title="Net profit" value={scenario.netProfitFormatted} />
      </StyledBreakdownNumbersContainer>
      <HorizontalLine />
      <BreakdownRow title="Remaining potential" content={scenarioBreakdownData.remainingLabel} />
      <StyledBreakdownNumbersContainer>
        <BreakdownNumberItem title="Remaining equity value" value={scenario.remainingEquityFormatted} />
      </StyledBreakdownNumbersContainer>
    </BreakdownContainer>
  )
}

export default ScenarioBreakdown

const StyledRow = styled.div(({theme}) => `
    margin-bottom: ${theme.spacing.m};
`)

const BreakdownContainer = styled.div(({theme}) => `
  padding: ${theme.spacing.xl} 30px ${theme.spacing.xl} 80px;
  
  ${theme.below(theme.breakpoints.largePhone)} {
    padding: ${theme.spacing.xl} 24px;
  }

  ${StyledRow} {
    &:last-child {
      border-bottom: none;
    }
  }
`)

const HorizontalLine = styled.div(({theme}) => `
    background-color: ${theme.colors.darkBlue100};
    margin: ${theme.spacing.l} 0;
    height: 1px;
`)

const SmallH5 = styled(H5)`
  font-size: 1.125rem;
`

const StyledBreakdownNumbersContainer = styled.div(({theme}) => `
    display: flex;
    width: fit-content;
    background-color: ${theme.colors.grey100};
    border-radius: ${theme.spacing.xs};
`)

const StyledBreakdownNumberItemContainer = styled.div(({theme}) => `
    margin: ${theme.spacing.xs} ${theme.spacing.m};
`)

export const StyledRowContainer = styled(RowContainer)(({theme}) => `
 margin-bottom: ${theme.spacing.l};

  > svg {
        margin-right: ${theme.spacing.m}
  }
`)
