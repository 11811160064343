/**
 *
 * @param value
 *
 * Converts a price of type number to a string value that is more suitable
 * for form input fields. Ex. '1' becomes '1.00', '1.2' becomes '1.20', etc.
 * Permits up to 5 decimals so founder and advisor shares, e.g., 0.00001,
 * don't get rounded to 0.00
 */
const priceFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
})
export function convertPriceToFormInput(value = 0, prefix?: string): string {
  const formattexValue = priceFormatter.format(value)
  return prefix ? `${prefix}${formattexValue}` : formattexValue
}
