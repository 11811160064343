import HttpClient, {FetchResponse} from '../helpers/httpClient'

import {ServiceOffering} from 'api/service'

export enum FirmType {
  INVESTMENT = 'INVESTMENT',
  TAX = 'TAX',
  TRUST_ESTATE = 'TRUST_ESTATE',
}

export const FIRM_TYPE_DISPLAYS: KeyedObject<string> = {
  [FirmType.INVESTMENT]: 'Financial',
  [FirmType.TAX]: 'Tax',
  [FirmType.TRUST_ESTATE]: 'Legal',
}

export const FIRM_TYPE_DISPLAYS_V2: KeyedObject<string> = {
  [FirmType.INVESTMENT]: 'Financial',
  [FirmType.TAX]: 'Tax',
  [FirmType.TRUST_ESTATE]: 'Law',
}

export const FIRM_TYPE_DISPLAYS_V3: KeyedObject<string> = {
  [FirmType.INVESTMENT]: 'Financial Firm',
  [FirmType.TAX]: 'Tax firm',
  [FirmType.TRUST_ESTATE]: 'Law firm',
}

export enum FinancialServiceInterest {
  financialPlanning = 'Financial Planning',
  investmentManagement = 'Investment Management',
  alternativeInvestments = 'Alternative Investments',
  insurance = 'Insurance',
}

export enum FirmDifferentiatorHeader {
  FUNCTIONAL_SPECIALIZATION = 'Functional Specialization',
  TEAM = 'Team',
  ADVISOR_CLIENT_ALIGNMENT = 'Advisor-client alignment',
  FEES = 'Fees',
  FIRM_VALUES = 'Firm Values',
  CLIENT_BASE = 'Client base',
  DIGITAL_AND_EDU_TOOLS = 'Digital & Educational tools',
  FIRM_STRUCTURE = 'Firm Structure',
  INVESTMENT_APPROACH = 'Investment Approach',
  INDUSTRY_RECOGNITION = 'Industry Recognition',
  FUN_FACT = 'Fun fact',
}

export type OfficeLocation = {
  city: string;
  state: string;
  isHeadquarters: boolean;
  zipcode: string;
}

export type FirmFeeRange = {
  lower: number;
  upper: Nullable<number>;
}

export type FirmFeeRangeMap = KeyedObject<Optional<FirmFeeRange>>

export async function getFirmFees(
  firmIds: string[],
  financialPlanning: boolean,
): Promise<FetchResponse<FirmFeeRangeMap>> {
  return HttpClient.post<FirmFeeRangeMap>('/v2/adviser/fee_estimate/', {
    firmIds,
    financialPlanning,
  })
}

export enum FirmFeeBenchmark {
  LOW = 'Below Industry Average',
  AVERAGE = 'Near Industry Average',
  HIGH = 'Above Industry Average',
}

export async function getFeeBenchmark(firmIds: string[]): Promise<FetchResponse<KeyedObject<FirmFeeBenchmark>>> {
  return HttpClient.post<KeyedObject<FirmFeeBenchmark>>('/v2/adviser/fees_benchmark_comparison/', {firmIds})
}

export type AdviserV2 = {
  id: string;
  firmId: string;
  adviserFirstName: string;
  adviserLastName: string;
  adviserTitle: string;
  adviserOfficeLocation: string;
  adviserYearsOfExperience: number;
  adviserCertifications: string;
  gender: string;
  adviserHeadshot: string; // url
  linkedinUrl: string;
}

export async function findAdvisersV2ForFirmId(firmId: string): Promise<FetchResponse<AdviserV2[]>> {
  return HttpClient.get<AdviserV2[]>(`/v2/adviser/?firm=${firmId}`)
}

type FirmOfficeLocationV2 = {
  city: string;
  state: string;
  isHeadquarters: boolean;
  zipcode: string;
}

type FirmServiceV2 = {
  name: string;
  offersFlatFee: boolean;
  serviceOffering?: ServiceOffering;
  pid: string;
}

export type Employer = {
  name: string;
  website: string;
  logo: string;
}

export type DifferentiatorHeader = {
  headerCategory: FirmDifferentiatorHeader;
  icon: string; // url to icon
}

export type FirmV2 = {
  firmName: string;
  clientProfessionsExpertise: string[];
  minimumAnnualClientFee: number;
  firmType: FirmType;
  id: string;
  hasFemales: boolean;
  feeDisclaimer: string;
  officeLocations: FirmOfficeLocationV2[];
  services: FirmServiceV2[];
  firmWebsite: string;
  fees: {
    fee: string;
    name: string;
  }[];
  firmEstablished: string;
  employers: Employer[];
  tip1Category?: DifferentiatorHeader;
  tip1Subcategory?: string;
  tip2Category?: DifferentiatorHeader;
  tip2Subcategory?: string;
  tip3Category?: DifferentiatorHeader;
  tip3Subcategory?: string;
  assetClassExpertise: string[];
  employmentTypeExpertise: string[];
}

export type AUMFeesV2 = {
  aumRange: {
    lower: string;
    upper: Nullable<string>;
    bounds: string;
  };
  feePercent: number;
}

export type AumFeesDescriptionV2 = {
  aumDescription: string;
  fee: number;
}

export type FinancialFirmV2 = FirmV2 & {
  meta: {
    aumLastFiledAdv: number;
    numberOfHouseholdsServing: number;
    tip1: string;
    tip2: string;
    tip3: string;
    showFirmFeeTable: string;
    feeGraphSource: string;
    adviserProfileCompliance: string;
    firmAttributesCompliance: string;
    aumFees: AUMFeesV2[];
    aumFeesDescriptions: AumFeesDescriptionV2[];
    urlAdvPartTwo: string;
    numberOfFinancialAdvisers: number;
    averageSizeOfClient: number;
    medianTotalHouseholdAccount: number;
  };
}

export type TaxFirmV2 = FirmV2 & {
  meta: {
    tip1: string;
    tip2: string;
    tip3: string;
    numberOfFirmCpas: number;
    numberOfOperationsStaff: number;
    showFirmFeeTable: undefined;
  };
}

export type LegalFirmV2 = FirmV2 & {
  meta: {
    tip1: string;
    tip2: string;
    tip3: string;
    numberOfFirmAttorneys: number;
    estatePlanningFee: number;
    numberOfTrustAndEstateAttorneys: number;
    showFirmFeeTable: undefined;
  };
}

export type AnyFirmV2 = FinancialFirmV2 | LegalFirmV2 | TaxFirmV2

// User Defined Type guards to cast AnyFirm types to their correct FirmType
// since Union types only expose common properties
export function isFinancialFirm(firm?: AnyFirmV2): firm is FinancialFirmV2 {
  return Boolean(firm) && (firm as FinancialFirmV2).firmType === FirmType.INVESTMENT
}

export function isTaxFirm(firm: AnyFirmV2): firm is TaxFirmV2 {
  return (firm as TaxFirmV2).firmType === FirmType.TAX
}

export function isLegalFirm(firm: AnyFirmV2): firm is LegalFirmV2 {
  return (firm as LegalFirmV2).firmType === FirmType.TRUST_ESTATE
}

export async function getFirmV2(firmId: string): Promise<FetchResponse<AnyFirmV2>> {
  return HttpClient.get<AnyFirmV2>(`/v2/adviser/firm/${firmId}/`)
}

export async function bulkGetFirmV2(firmIds: string[]): Promise<FetchResponse<AnyFirmV2[]>> {
  return HttpClient.get<AnyFirmV2[]>(`/v2/adviser/firm/?ids=${firmIds.join(',')}`)
}
