import {
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import {parseISO} from 'date-fns'

import {
  ScenarioArgs,
  ScenarioOrderArgs,
  VestedRsuQuantity,
} from './types'

import {
  GrantType,
  fetchGrantLifecycle,
  fetchGrants,
} from 'api/grant'
import {
  LiquidityEvent,
  LiquidityEventWithResults,
  TaxScenario,
  getLifecycleForDate,
} from 'models'

export const setScenarios = createAction<ScenarioArgs>('SET_SCENARIOS')
export const setEventData = createAction<LiquidityEvent>('SET_EVENT_DATA')
export const setScenarioOrder = createAction<ScenarioOrderArgs>('SET_SCENARIO_ORDER')

/**
  Find all RSU grants with double trigger provision and their life-cycles.
  For each life-cycle, find the vested quantity at sale date.
*/
export const fetchDoubleTriggerRSUQuantity = createAsyncThunk(
  'FETCH_DOUBLE_TRIGGER_RSU_QUANTITY',
  async (event: LiquidityEventWithResults, {rejectWithValue}) => {
    const result: VestedRsuQuantity = {
      HOLD_OVER_A_YEAR: {
        vestedQuantity: 0,
      },
      HOLD_UNDER_A_YEAR: {
        vestedQuantity: 0,
      },
    }

    try {
      const grants = await fetchGrants(event.company)
      const doubleTriggerRSUGrants = grants.filter((grant) => (
        grant.grantType === GrantType.RSU && grant.isDoubleTrigger
      ))
      const lifecycles = await Promise.all(
        doubleTriggerRSUGrants.map((grant) => fetchGrantLifecycle(grant.id)),
      )

      Object.keys(result).forEach((scenarioType) => {
        const saleDate = (event.results[scenarioType] as TaxScenario)?.saleDate
        if (saleDate) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          result[scenarioType].vestedQuantity = lifecycles.reduce((quantity, lifecycle) => {
            const lifecycleDatum = getLifecycleForDate(parseISO(saleDate), lifecycle)
            return quantity + (lifecycleDatum?.vestedAwardsQuantity ?? 0)
          }, 0)
        }
      })
    } catch (err) {
      return rejectWithValue(err)
    }
    return result
  },
)
