import {TaxBracketType} from 'models/liquidityEvent'

export default {
  SHORT_TERM_CAPITAL_GAINS: {
    TITLE: 'Income from short-term capital gains',
    HEADER: {
      GRANT_NAME: 'Grant name',
      HOLDING_PERIOD: 'Held',
      CAPITAL_GAIN_PER_SHARE: 'Capital gain per share',
      COMPENSATION_ADJUSTMENT: 'Compensation adjustment',
      QUANTITY: 'Quantity sold',
      TAX_LIABILITY: 'Income generated',
      PRICE_AT_SALE: 'Price at sale',
      COST_BASIS: 'Cost basis',
      TAX_TREATMENT: 'Tax',
    },
    TOTAL_INCOME: 'Taxable short-term capital gains',
    NO_GAINS: 'No shares sold qualify as short-term capital gains',
    NO_SHARES: 'No shares sold',
    TAX_TREATMENT: 'Ordinary income rate',
    TAX_TREATMENT_AMT: 'Added to AMT income',
  },
  LONG_TERM_CAPITAL_GAINS: {
    TITLE: 'Long-term capital gains',
    HEADER: {
      GRANT_NAME: 'Grant name',
      HOLDING_PERIOD: 'Held',
      CAPITAL_GAIN_PER_SHARE: 'Capital gain per share',
      COMPENSATION_ADJUSTMENT: 'Compensation adjustment',
      QUANTITY: 'Quantity sold',
      TAX_LIABILITY: 'Capital gains generated',
      PRICE_AT_SALE: 'Price at sale',
      COST_BASIS: 'Cost basis',
      TAX_TREATMENT: 'Tax',
    },
    TOTAL_INCOME: 'Long-term capital gains',
    NO_GAINS: 'No shares sold qualify as long-term capital gains',
    NO_SHARES: 'No shares sold',
    TAX_TREATMENT: 'LTCG rate',
    TAX_TREATMENT_AMT: 'Added to AMT income',
  },
}

export const INCOME_FROM_EXERCISES = {
  TITLE: '[TYPE] income from exercise [YEAR]',
  TITLE_PRIOR: '[TYPE] income from prior [YEAR] exercise',
  TITLE_SALE: '[TYPE] income from sale [YEAR]',
  HEADER: {
    GRANT_NAME: 'Grant name',
    HOLDING_PERIOD: 'Held',
    TAXABLE_COMPENSATION: 'Taxable compensation per [TYPE]',
    QUANTITY: 'Quantity exercised',
    TAX_LIABILITY: 'Income generated',
    FMV_AT_EXERCISE: 'FMV at exercise',
    EXERCISE_PRICE: 'Exercise (strike) price',
    TAX_TREATMENT: 'Tax',
    TAX_TREATMENT_NSO: 'Ordinary income tax',
    DISPOSITION: 'Disposition',
    AMT_TAX_LIABILITY: 'AMT Liability',
  },
  TOTAL_INCOME: '[TYPE] Income from exercising',
  TOTAL_INCOME_SALE: '[TYPE] Income from sale',
  PRIOR_EXERCISE_INDICATOR: '*Indicates prior exercise',
  NO_OPTIONS: 'No options exercised',
  TAX_TREATMENT_EXEMPT: 'Exempt',
  TAX_TREATMENT_INCOME: 'Ordinary income rate',
  TAX_TREATMENT_SALE: 'Determined at sale',
  TAX_TREATMENT_EXERCISE: 'Determined at exercise',
  TAX_TREATMENT_EXERCISE_2: 'Taxed at exercise',
  TAX_TREATMENT_AMT: 'Added to AMT income',
  DISPOSITION: {
    QUALIFIED: 'Qualified',
    NON_QUALIFIED: 'Disqualified',
    NA: 'Not sold',
  },
}

export const TAX_BRACKET = {
  BRACKET_TITLE: {
    [TaxBracketType.FEDERAL_ORDINARY]: 'Fed. Ordinary Income Brackets',
    [TaxBracketType.FEDERAL_LTCG]: 'Long-Term Capital Gains Brackets',
    [TaxBracketType.SOCIAL_SECURITY]: 'Social Security Tax Bracket',
    [TaxBracketType.MEDICARE]: 'Medicare Tax Bracket',
    [TaxBracketType.STATE_LTCG]: 'State Long-term Cap. Gains Brackets',
    [TaxBracketType.STATE_ORDINARY]: 'State Ordinary Income Brackets',
    [TaxBracketType.AMT]: 'AMT income bracket (single filer)',
  },
  FILING_STATUS: ' ([FILING_STATUS] Filer)',
  INCOME_BETWEEN: {
    [TaxBracketType.FEDERAL_ORDINARY]: 'Any ordinary income between',
    [TaxBracketType.FEDERAL_LTCG]: 'LTCG between',
    [TaxBracketType.SOCIAL_SECURITY]: 'Income between',
    [TaxBracketType.MEDICARE]: 'Income between',
    [TaxBracketType.STATE_LTCG]: 'LTCG between',
    [TaxBracketType.STATE_ORDINARY]: 'Any ordinary income between',
    [TaxBracketType.AMT]: 'AMT income between',
  },
  TAXES_FILLED: {
    [TaxBracketType.FEDERAL_LTCG]: 'Your LTCG',
    [TaxBracketType.FEDERAL_ORDINARY]: 'Taxable income in bracket',
    [TaxBracketType.SOCIAL_SECURITY]: 'Your income',
    [TaxBracketType.MEDICARE]: 'Your income',
    [TaxBracketType.STATE_LTCG]: 'Your LTCG',
    [TaxBracketType.STATE_ORDINARY]: 'Taxable income in bracket',
    [TaxBracketType.AMT]: 'Taxable AMT income in bracket',
  },
  TAX_RATE: 'Tax rate',
  TAXES_OWED: 'Liable',
  SUBCATEGORY_COPY: 'Taxes from [INCOME_TYPE] income ([TOTAL_INCOME])',
  AMT_SUBCATEGORY_COPY: 'AMT from [INCOME_TYPE] income ([TOTAL_INCOME])',
  AMT_SCENARIO_COPY: 'AMT from scenario sale ([TOTAL_INCOME])',
  SUBCATEGORY_LTCG_COPY: 'Taxes on capital gains from [INCOME_TYPE] ([TOTAL_INCOME])',
  SUBCATEGORY_LTCG_ORDINARY_INCOME_COPY: 'Ordinary income to determine LTCG bracket ([TOTAL_INCOME])',
  TOTAL_TAX: {
    [TaxBracketType.FEDERAL_LTCG]: 'Liable long-term capital gains tax',
    [TaxBracketType.FEDERAL_ORDINARY]: 'Federal income tax',
    [TaxBracketType.SOCIAL_SECURITY]: 'Liable Social Security tax',
    [TaxBracketType.MEDICARE]: 'Liable Medicare tax',
    [TaxBracketType.STATE_LTCG]: 'Liable state long-term capital gains tax',
    [TaxBracketType.STATE_ORDINARY]: 'Liable state income tax',
    [TaxBracketType.AMT]: 'Total alternative minimum tax',
  },
  THRESHOLD_DISCLAIMER: {
    [TaxBracketType.SOCIAL_SECURITY]: '*[THRESHOLD] Social Security Income threshold met',
  },
  EFFECTIVE_TAX_RATE: 'Effective tax rate',
}
