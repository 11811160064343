import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'

import LoadingSpinner from 'core/components/loaders/LoadingSpinner'
import Banner from 'core/components/banner/Banner'

import {RoutePaths} from 'core/constants'
import {session} from 'core/utils/AppSession'
import {useGetUserQuery} from 'store/user/userSlice'
import {
  ClientProfile,
  getClientProfile,
} from 'models'
import * as l from 'routes/landing/locale'
import styles from 'routes/landing/_styles.module.scss'

type Props = {
  children: React.ReactNode;
}

const OnboardingGuard = ({children}: Props): JSX.Element => {
  const [clientProfile, setClientProfile] = useState<{data: Partial<ClientProfile>; error: boolean; loading: boolean}>({
    data: {},
    error: false,
    loading: true,
  })
  const {data: user} = useGetUserQuery()

  useEffect(() => {
    async function getClient(): Promise<void> {
      const {data, error} = await getClientProfile(session.getClientId())
      if (error || !data) {
        setClientProfile({data: {}, error: true, loading: false})
      } else {
        setClientProfile({data, error: false, loading: false})
      }
    }

    if (clientProfile.loading) {
      void getClient()
    }
  }, [clientProfile.loading])

  if (!user) {
    return (
      <LoadingSpinner size="large" />
    )
  }
  if (clientProfile.error) {
    return <div><Banner id="error" intent="error" sticky>{l.ERROR}</Banner></div>
  }

  if (clientProfile.loading) {
    return <div className={styles.loadingContainer}><LoadingSpinner size="large" /></div>
  }

  const {data: profile} = clientProfile

  const didCompleteShortOnboarding: boolean = !!profile?.meta?.didCompleteShortOnboarding
  if (!didCompleteShortOnboarding) {
    return <Redirect to={RoutePaths.ONBOARDING_ROOT} />
  }

  return <>{children}</>
}

export default React.memo(OnboardingGuard)
