import {useEffect, useState} from 'react'

import {CompanyPricingInfo, PriceType, findCompanyPricingInfo} from 'models'

type CompanyPricingInfoReturn = {
  current409A: Nullable<CompanyPricingInfo>;
  currentPreferredPrice: Nullable<CompanyPricingInfo>;
  pricingInfoError: boolean;
  loading: boolean;
}

// TODO(kevin): implement refetch logic
export default function useCompanyPricingInfo(companyId: string): CompanyPricingInfoReturn {
  const [loading, setLoading] = useState<boolean>(true)
  const [current409A, setCurrent409A] = useState<Nullable<CompanyPricingInfo>>(null)
  const [currentPreferredPrice, setCurrentPreferredPrice] = useState<Nullable<CompanyPricingInfo>>(null)
  const [pricingInfoError, setPricingInfoError] = useState<boolean>(false)

  useEffect(() => {
    const getCompanyPricingInfo = async (): Promise<void> => {
      const {data, error} = await findCompanyPricingInfo(companyId)

      if (!data || error) {
        setPricingInfoError(true)
        setLoading(false)
        return
      }

      if (data.length > 0) {
        const four09A = data.filter((pricingInfo) => pricingInfo.priceType === PriceType['409A']).reverse()
        const preferredPrices = data.filter((pricingInfo) => pricingInfo.priceType === PriceType.PREFERRED).reverse()
        setCurrent409A(four09A[0])
        setCurrentPreferredPrice(preferredPrices[0])
      }

      setLoading(false)
    }

    if (loading) {
      void getCompanyPricingInfo()
    }
  }, [companyId, loading])

  return {
    current409A,
    currentPreferredPrice,
    pricingInfoError,
    loading,
  }
}
