import api from '../api'

import {Grant, GrantLifecycleData} from './types'

export const fetchGrants = (
  companyId: string,
): Promise<Grant[]> => api.get<Grant[]>(
  `v1/equity_liquidity/grant/?company_id=${companyId}`,
)

export const fetchGrant = (
  {companyId, grantId}: {companyId: string; grantId: string},
): Promise<Grant> => api.get(`v1/equity_liquidity/grant/${grantId}/?company_id=${companyId}`)

export const createGrant = (
  grant: Partial<Grant>,
): Promise<Grant> => api.post('v1/equity_liquidity/grant/', grant)

export const updateGrant = (
  {id, ...grant}: Grant,
): Promise<Grant> => api.patch(`v1/equity_liquidity/grant/${id}/`, grant)

export const deleteGrant = (
  id: string,
): Promise<void> => api.delete(`v1/equity_liquidity/grant/${id}/`)

export const fetchGrantLifecycle = (
  grantId: string,
): Promise<GrantLifecycleData[]> => api.get(
  `v1/equity_liquidity/grant/${grantId}/lifecycle/`,
)
