import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Markup} from 'interweave'

import Button from 'core/components/button/Button'
import {Banner} from 'core/components/banner'

import styles from './_styles.module.scss'
import {BackupCodeBanner} from './BackupCodeBanner'

import {AlertMessageType} from 'store/alert/types'
import {AlertAction, AlertSelector} from 'store/alert'

const AlertBanner = (): JSX.Element => {
  const dispatch = useDispatch()
  const message = useSelector(AlertSelector.getAlert)

  const handleRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  const handleDismiss = useCallback(() => {
    dispatch(AlertAction.removeAlert())
  }, [dispatch])

  if (!message) {
    return <></>
  }

  let parts = ['Please', 'or contact concierge.']
  if (message.refreshMsg) {
    parts = message.refreshMsg.split('refresh')
  }

  if (message.type === AlertMessageType.BACKUP_CODE_USED) {
    return (
      <BackupCodeBanner />
    )
  }

  return (
    <Banner
      id={message.type}
      intent={message.type}
      sticky
      onDismiss={handleDismiss}
    >
      <Markup content={message.content} />
      {
        message.hasRefreshBtn && (
          <>
            {' '}
            {parts[0]}
            {' '}
            <Button
              className={styles.refreshBtn}
              fill={false}
              onClick={handleRefresh}
              label="refresh"
            />
            {' '}
            {parts[1]}
          </>
        )
      }
    </Banner>
  )
}

export default React.memo(AlertBanner)
