/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import {createAction, createAsyncThunk} from '@reduxjs/toolkit'

import {
  CompanyValuationFormType,
  EmploymentFormType,
  GrantTypeForm,
  NameFormValueType,
  PostEmploymentForm,
  ProgressStatus,
  Tasks,
} from './types'

import {
  findAccountBalancesFromPlaid, findCompanies,
} from 'models'
import {fetchCryptoAccounts} from 'api/crypto'

export const setCompanyName = createAction<NameFormValueType>('SET_COMPANY_NAME')
export const setEmploymentStatus = createAction<EmploymentFormType>('SET_EMPLOYMENT_STATUS')
export const setExpirationPostEmployment = createAction<PostEmploymentForm>('SET_EXPIRATION_POST_EMPLOYMENT')
export const setPrice = createAction<CompanyValuationFormType>('SET_409A_PRICE')
export const setGrantType = createAction<GrantTypeForm>('SET_GRANT_TYPE')
export const showSubmitLoader = createAction('SHOW_SUBMIT_LOADER')
export const clearProgress = createAction('CLEAN_ONBOARDING_PROGRESS')

export const navigateNextStep = createAction('NAVIGATE_NEXT_STEP')
export const navigatePreviousStep = createAction('NAVIGATE_PREVIOUS_STEP')

export const getUserCompanies = createAsyncThunk('GET_USER_COMPANIES', findCompanies)
export const clearTasks = createAction('CLEAR_TASKS')
export const toggleEquityModal = createAction('TOGGLE_MODAL')
export const closeEquityModal = createAction('CLOSE_EQUITY_MODAL')

type TaskArgs = {
  hasEquity: boolean;
  hasTaxProfile: boolean;
}

export const setTasks = createAsyncThunk<Tasks, TaskArgs, {rejectValue: unknown}>('SET_TASKS', async ({hasEquity, hasTaxProfile}, {rejectWithValue}) => {
  try {
    const cryptoAccounts = await fetchCryptoAccounts()
    const {data} = await findAccountBalancesFromPlaid()

    const equityTask = hasEquity ? ProgressStatus.COMPLETED : ProgressStatus.IN_PROGRESS
    const scenarioTask = hasTaxProfile ? ProgressStatus.COMPLETED : (hasEquity ? ProgressStatus.IN_PROGRESS : ProgressStatus.NOT_COMPLETED)
    const accountsTask = [...cryptoAccounts, ...data!].length ? ProgressStatus.COMPLETED : (hasTaxProfile ? ProgressStatus.IN_PROGRESS : ProgressStatus.NOT_COMPLETED)

    return {
      equityTask,
      scenarioTask,
      accountsTask,
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
