import React from 'react'
import cx from 'classnames'

import Button from '../button/Button'
import Icon, {IconName} from '../icon/Icon'

import styles from './_banner.module.scss'

const CROSS_COLOR = {
  error: '#FFF',
  success: '#FFF',
  warning: '#084220',
}

export type BannerIntent = 'success' | 'warning' | 'error'

export type BannerProps = {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  id: string;
  intent?: BannerIntent;
  onDismiss?: () => void;
  sticky?: boolean;
}

export default function Banner(
  {
    className,
    children,
    id = '',
    intent,
    onDismiss,
    sticky,
  }: BannerProps,
): JSX.Element {
  return (
    <div
      className={cx(styles.banner, intent && styles[intent], sticky && styles.sticky, className)}
      data-cy={`banner-${id}`}
      data-theme="default"
    >
      <div className={styles.bannerContentContainer}>
        <div className={styles.bannerContent}>{children}</div>
        {onDismiss && (
          <Button
            aria-label={`Close Banner ${id}`}
            className={styles.bannerDismissBtn}
            data-cy={`btn-dismiss-banner-${id}`}
            fill={false}
            id={`btn-dismiss-banner-${id}`}
            onClick={onDismiss}
          >
            <Icon name={IconName.MenuCancel} fill={intent ? CROSS_COLOR[intent] : '#FFF'} size={16} />
          </Button>
        )}
      </div>
    </div>
  )
}
