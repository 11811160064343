import React from 'react'

import TwoColumnTable, {GridRow} from './TwoColumnTable'
import {AMT_LIABILITY_TABLE as l} from './locale'

import {numberFormatter} from 'core/formatters'
import {TaxScenarioResult} from 'models/liquidityEvent'

function buildIncomeRows({result}: {result: TaxScenarioResult}): GridRow[] {
  const {taxBreakdown: {federal}, taxProfile, taxSummary} = result
  const {taxYear} = taxProfile

  const fedLiablity = (
    taxSummary.totalFederalTax
    - taxSummary.socialSecurityTax
    - taxSummary.medicareTax
  )

  const hasLTCG = !!result.taxBreakdown.federal.capitalGains.incomeSources.length

  return [
    {
      label: l.TOTAL_AMT.replace('[YEAR]', taxYear.toString()),
      value: numberFormatter(federal.amt.taxesOwed).currency,
    },
    {
      label: l.FEDERAL_LIABILITY.replace('[YEAR]', taxYear.toString()),
      value: `-${numberFormatter(fedLiablity).currency}`,
      subrows: [
        {
          label: 'Ordinary income tax',
          value: numberFormatter(result.taxBreakdown.federal.ordinaryIncome.taxesOwed).currency,
        },
        {
          label: 'Long-term capital gains tax',
          value: (
            hasLTCG
              ? numberFormatter(result.taxBreakdown.federal.capitalGains.taxesOwed).currency
              : `No sale in ${taxYear}`
          ),
        },
      ],
    },
    {
      label: l.TOTAL_AMT_LIABILITY,
      value: numberFormatter(taxSummary.amtTax).currency,
    },
  ]
}

type Props = {
  result: TaxScenarioResult;
  title?: string;
  hasEducation?: boolean;
}

export default function AMTLiabilityTable({
  result,
  title = 'AMT liability',
  hasEducation = false,
}: Props): JSX.Element {
  const rows = React.useMemo(() => buildIncomeRows({
    result,
  }), [
    result,
  ])
  return (
    <TwoColumnTable rows={rows} title={title} hasEducation={hasEducation} />
  )
}
