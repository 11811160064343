import React, {Suspense, lazy, useCallback} from 'react'
import cx from 'classnames'

import Button from 'core/components/button/Button'

import styles from './_hubspotButton.module.scss'

const HelpModal = lazy(() => import(/* webpackChunkName: "HelpModal" */ '../HelpModal/HelpModal'))

export type HubSpotConversations = {
  HubSpotConversations?: {
    widget: {
      open(): void;
    };
  };
}

type Props = {
  bold?: boolean;
  children?: string | React.ReactNode;
  className?: string;
  fill?: boolean;
  intent?: 'primary' | 'secondary' | 'tertiary';
  label?: string;
  onClick?(): any;
  small?: boolean;
  underlined?: boolean;
  hideModal?: boolean;
}

export default function HubspotChatButton(
  {
    bold,
    children,
    className,
    fill = true,
    intent,
    label = '',
    onClick,
    small,
    underlined,
    hideModal = false,
  }: Props,
): JSX.Element {
  const [showModal, setShowModal] = React.useState(false)

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleClick = useCallback(() => {
    if (onClick) onClick()
    const {HubSpotConversations: HubSpot} = (window as HubSpotConversations)
    if (HubSpot) HubSpot.widget.open()
    else setShowModal(true)
  }, [onClick])

  return (
    <>
      {!hideModal && (
        <Suspense fallback={null}>
          <HelpModal
            onConfirm={onModalClose}
            onCancel={onModalClose}
            open={showModal}
          />
        </Suspense>
      )}
      <Button
        bold={bold}
        className={cx(styles.btn, className)}
        fill={fill}
        intent={intent}
        onClick={handleClick}
        small={small}
        underlined={underlined}
      >
        {children ?? label}
      </Button>
    </>
  )
}
