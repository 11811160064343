import React, {useState} from 'react'

import Insight from 'core/components/insight/Insight'
import Button from 'core/components/button/Button'

import {useGetCurrentUserProfileQuery} from 'store/profile'
import {InviteFriendModal} from 'modules/invite-friend-modal'
import IconReferAFriend from 'assets/icons/refer-a-friend.svg?react'

export const InviteFriendInsight = ({
  onDismiss,
  className,
}: {
  onDismiss: () => void;
  className?: string;
}): JSX.Element => {
  const {data: profileData} = useGetCurrentUserProfileQuery()
  const [showInviteFriendModal, setShowInviteFriendModal] = useState<boolean>(false)

  return (
    <>
      <Insight
        className={className}
        action={(
          <Button onClick={(): void => setShowInviteFriendModal(true)}>
            Refer Friends
          </Button>
            )}
        backgroundColor="#fff"
        iconBackground="#D1F6C7"
        title="Refer friends. Give $250, Get $250."
        customIcon={<IconReferAFriend style={{color: '#194524'}} />}
        description="For every referral who signs up with a Harness advisor, you’ll both get $250 towards Harness services."
        onDismiss={onDismiss}
      />
      {showInviteFriendModal && (
        <InviteFriendModal
          onClose={(): void => setShowInviteFriendModal(false)}
          open={showInviteFriendModal}
          prefineryTesterHash={profileData?.prefineryTesterHash.primary}
        />
      )}
    </>
  )
}
