import React from 'react'
import {Markup} from 'interweave'

import styles from './_taxBreakdownEducation.module.scss'
import {ExerciseFederalAlternativeMinimumTax as locale} from './locale'

const ExerciseFederalAlternativeMinimumTaxEdu = (): JSX.Element => (
  <div className={styles.educationContainer}>
    <p className={styles.educationText}>
      <Markup noWrap content={locale.PARAGRAPH_1} />
    </p>
    <p className={styles.educationText}>
      <Markup noWrap content={locale.PARAGRAPH_2} />
    </p>
    <p className={styles.educationText}>{locale.PARAGRAPH_3}</p>
  </div>
)

export default ExerciseFederalAlternativeMinimumTaxEdu
