import React from 'react'
import {Markup} from 'interweave'

import styles from './_taxBreakdownEducation.module.scss'
import {ExerciseAndSaleFederalSocialSecurityTax as locale} from './locale'

import {numberFormatter} from 'core/formatters'

type Props = {
  currentTaxRate: number;
  socialSecurityThreshold: number;
}

const ExerciseAndSaleFederalSocialSecurityTaxEdu = ({currentTaxRate, socialSecurityThreshold}: Props): JSX.Element => (
  <div className={styles.educationContainer}>
    <p className={styles.educationText}>
      <Markup
        noWrap
        content={
          locale.PARAGRAPH_1
            .replace('[CURRENT_TAX_RATE]', numberFormatter(currentTaxRate).percent)
            .replace('[SOCIAL_SECURITY_THRESHOLD]', numberFormatter(socialSecurityThreshold).intCurrency)
        }
      />
    </p>
    <p className={styles.educationText}>{locale.PARAGRAPH_2}</p>
  </div>
)

export default ExerciseAndSaleFederalSocialSecurityTaxEdu
