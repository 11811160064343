export enum EducationTypes {
  EXERCISE_FEDERAL_ALTERNATIVE_MINIMUM_TAX = 'EXERCISE_FEDERAL_ALTERNATIVE_MINIMUM_TAX',
  EXERCISE_STATE_ALTERNATIVE_MINIMUM_TAX = 'EXERCISE_STATE_ALTERNATIVE_MINIMUM_TAX',
  SALE_FEDERAL_LONG_TERM_CAPITAL_GAINS_TAX = 'SALE_FEDERAL_LONG_TERM_CAPITAL_GAINS_TAX',
  SALE_FEDERAL_NET_INVESTMENT_INCOME_TAX = 'SALE_FEDERAL_NET_INVESTMENT_INCOME_TAX',
  SALE_STATE_LONG_TERM_CAPITAL_GAINS_TAX = 'SALE_STATE_LONG_TERM_CAPITAL_GAINS_TAX',
  EXERCISE_AND_SALE_STATE_ORDINARY_INCOME_TAX = 'EXERCISE_AND_SALE_STATE_ORDINARY_INCOME_TAX',
  EXERCISE_AND_SALE_FEDERAL_MEDICARE_TAX = 'EXERCISE_AND_SALE_FEDERAL_MEDICARE_TAX',
  EXERCISE_AND_SALE_FEDERAL_ORDINARY_INCOME_TAX = 'EXERCISE_AND_SALE_FEDERAL_ORDINARY_INCOME_TAX',
  EXERCISE_AND_SALE_FEDERAL_SOCIAL_SECURITY_TAX = 'EXERCISE_AND_SALE_FEDERAL_SOCIAL_SECURITY_TAX',
}
