/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, {useCallback, useState} from 'react'
import {
  format,
  parseISO,
} from 'date-fns'

import Select from 'core/components/input/Select'
import Input from 'core/components/input/Input'
import NumericInput from 'core/components/input/NumericInput'

import l from './locale'
import localStyles from './_styles.module.scss'

import styles from 'core/legacy-components/FormModal/_styles.module.scss'
import {CURRENT_TAX_YEAR} from 'core/constants'
import {
  validateFilingStatus,
  validateZipCode,
} from 'modules/validators'
import FormModal from 'core/legacy-components/FormModal/FormModal'
import FormSubmit from 'core/legacy-components/Form/FormSubmit'
import {FetchResponse} from 'models/helpers/httpClient'
import {
  FILING_STATUS_LABELS,
  FilingStatus,
  TaxProfile,
  TaxProfileResidency,
  TaxProfileResidencyErrorResponse,
  createTaxProfile,
  createTaxProfileResidency,
  updateTaxProfile,
  updateTaxProfileResidency,
} from 'models'
import FormField from 'core/legacy-components/Form/FormField'
import {selectStyles} from 'core/legacy-components/Form/types'
import {convertFormattedStringToNumber, formatNumberWithCommas} from 'core/formatters'
import {ValidationsSchema, getFieldValidationErrors, getFormErrors} from 'core/legacy-components/Form/validators'

const FILING_STATUS_OPTIONS = Object.keys(FILING_STATUS_LABELS).map((key) => ({
  label: FILING_STATUS_LABELS[key] as string,
  value: key,
}))

type Props = {
  taxProfile: TaxProfile;
  taxProfileResidency: TaxProfileResidency;
  onCancel: () => void;
  onSubmit: (taxProfile: TaxProfile, taxProfileResidency: TaxProfileResidency) => void;
}

type FormState = {
  taxYear: number;
  filingStatus: Nullable<FilingStatus>;
  householdIncome: string;
  zipCode: string;
  errors: {
    taxYear: string;
    filingStatus: string;
    householdIncome: string;
    zipCode: string;
  };
  networkError: string;
  submitting: boolean;
}

const validations: ValidationsSchema = {
  filingStatus: ['Filing Status', true, [validateFilingStatus]],
  householdIncome: ['Pre-tax income', true, [
    (name: string, value: string): null | string => {
      const income = Number(value.replace(/,/g, ''))

      if (income <= 0 || income > 10000000) {
        return 'Please enter a number between $1 and $10,000,000'
      }

      return null
    },
  ]],
  zipCode: ['Zip code', true, [validateZipCode]],
}

export const TaxProfileModal = ({
  taxProfile,
  taxProfileResidency,
  onCancel,
  onSubmit,
}: Props): JSX.Element => {
  const [formState, setFormState] = useState<FormState>({
    taxYear: taxProfile.taxYear ?? CURRENT_TAX_YEAR,
    filingStatus: taxProfile.filingStatus,
    householdIncome: taxProfile.householdIncome ? formatNumberWithCommas(taxProfile.householdIncome) : '',
    zipCode: taxProfileResidency.zipCode,
    errors: {
      taxYear: '',
      filingStatus: '',
      householdIncome: '',
      zipCode: '',
    },
    networkError: '',
    submitting: false,
  })

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      errors: {
        ...prev.errors,
        [e.target.name]: '',
      },
    }))
  }, [])

  const onFilingStatusChange = useCallback(({value}) => {
    setFormState((prev) => ({
      ...prev,
      filingStatus: value as FilingStatus,
      errors: {
        ...prev.errors,
        filingStatus: '',
      },
    }))
  }, [])

  const handleBlur = useCallback((name: string, value: Nullable<string>) => {
    const [fieldName, required, fns] = validations[name]
    const newErrors = getFieldValidationErrors(fieldName, required, fns, value)

    setFormState((prev) => ({
      ...prev,
      errors: {
        ...prev.errors,
        [name]: newErrors.length ? newErrors.join(', ') : '',
      },
    }))
  }, [])

  const handleSubmit = useCallback<React.FormEventHandler>(async (e) => {
    e.preventDefault()

    const errors = getFormErrors(validations, formState)

    if (Object.keys(errors).length > 0) {
      setFormState((prev) => ({
        ...prev,
        errors: {
          ...prev.errors,
          ...errors,
        },
      }))

      return
    }

    setFormState((prev) => ({...prev, submitting: true, networkError: ''}))

    const {
      taxYear,
      filingStatus,
      householdIncome,
      zipCode,
    } = formState

    // tax profile outdated, create a new one for current tax year
    if (taxYear !== CURRENT_TAX_YEAR) {
      const {error: createTaxProfileError} = await createTaxProfile(CURRENT_TAX_YEAR)
      if (createTaxProfileError) {
        setFormState((prev) => ({...prev, submitting: false, networkError: l.NETWORK_ERROR}))
        return
      }
    }

    const {data: taxProfileData, error} = await updateTaxProfile(CURRENT_TAX_YEAR, {
      filingStatus: filingStatus as FilingStatus,
      householdIncome: convertFormattedStringToNumber(householdIncome),
    })

    if (!taxProfileData || error) {
      setFormState((prev) => ({...prev, submitting: false, networkError: l.NETWORK_ERROR}))
      return
    }

    let response: FetchResponse<TaxProfileResidency, TaxProfileResidencyErrorResponse>

    // when residency data is outdated, create new entry for current tax profile, otherwise update
    if (taxYear !== CURRENT_TAX_YEAR) {
      response = await createTaxProfileResidency(CURRENT_TAX_YEAR, {
        taxProfileId: taxProfileData.id,
        startDate: `${CURRENT_TAX_YEAR}-01-01`,
        endDate: `${CURRENT_TAX_YEAR}-12-31`,
        zipCode,
      })
    } else {
      response = await updateTaxProfileResidency(
        taxYear,
        taxProfileResidency.id,
        {zipCode},
      )
    }

    const {data: residencyData, error: residencyError} = response

    if (residencyData) {
      onSubmit(taxProfileData, residencyData)
      return
    }

    const zipCodeErrors = residencyError?.data?.zipCode.map((res) => res.message) ?? []

    if (zipCodeErrors.length > 0) {
      setFormState((prev) => ({
        ...prev,
        submitting: false,
        errors: {
          ...prev.errors,
          zipCode: zipCodeErrors.join(', '),
        },
      }))
    } else {
      setFormState((prev) => ({...prev, submitting: false, networkError: l.NETWORK_ERROR}))
    }
  }, [formState, taxProfileResidency.id, onSubmit])

  return (
    <FormModal id="tax-profile-modal" onCancel={onCancel} title={l.FORM_TITLE}>
      {!!taxProfile.updatedAt && (
        <p className={localStyles.taxProfileTimestamp}>
          {l.TAX_PROFILE_UPDATED.replace('[DATE]', format(parseISO(taxProfile.updatedAt), 'MM/dd/yyyy'))}
        </p>
      )}
      <form onSubmit={handleSubmit} noValidate>
        <div className={styles.modalForm}>
          <FormField
            label={l.FILING_STATUS}
            labelFor="filingStatus"
            tooltip={l.FILING_STATUS_TOOLTIP}
            tooltipLabel="Filing Status Tooltip"
          >
            <Select
              name="filingStatus"
              placeholder={l.FILING_STATUS}
              onChange={onFilingStatusChange}
              options={FILING_STATUS_OPTIONS}
              styles={selectStyles}
              isSearchable={false}
              value={
                    formState.filingStatus ? {
                      value: formState.filingStatus,
                      label: FILING_STATUS_LABELS[formState.filingStatus],
                    } : null
                  }
              error={formState.errors.filingStatus}
              data-cy="tax-profile-filing-status"
            />
          </FormField>

          <FormField
            label={l.HOUSEHOLD_INCOME}
            labelFor="householdIncome"
            tooltip={l.HOUSEHOLD_INCOME_TOOLTIP}
            tooltipLabel="Pre-tax Income Tooltip"
          >
            <NumericInput
              name="householdIncome"
              id="householdIncome"
              value={formState.householdIncome}
              placeholder={l.HOUSEHOLD_INCOME}
              onChange={onChange}
              prefix="$"
              error={formState.errors.householdIncome}
              handleBlur={handleBlur}
            />
          </FormField>

          <FormField
            label={l.ZIP_CODE}
            labelFor="zipCode"
            tooltip={l.ZIP_CODE_TOOLTIP}
            tooltipLabel="Zip code Tooltip"
          >
            <Input
              name="zipCode"
              id="tax-profile-zip-code"
              maxLength={5}
              onChange={onChange}
              placeholder={l.ZIP_CODE}
              type="tel"
              value={formState.zipCode}
              handleBlur={handleBlur}
              error={formState.errors.zipCode}
            />
          </FormField>
        </div>

        {formState.networkError && <div className={styles.generalErrorContainer}>{formState.networkError}</div>}

        <FormSubmit
          onCancel={onCancel}
          cancelText={l.CANCEL_CTA}
          proceedText={l.SAVE_CTA}
          disableCancel={formState.submitting}
          disableConfirm={formState.submitting}
          loading={formState.submitting}
          isInModal
        />
      </form>

    </FormModal>
  )
}
