import {createApi} from '@reduxjs/toolkit/query/react'
import {createSelector} from '@reduxjs/toolkit'

import {AppStrings, DynamicStringsApp} from './types'

import baseQuery from 'store/main/baseQuery'

export const dynamicStringsApi = createApi({
  reducerPath: 'dynamicStringsApi',
  baseQuery,
  endpoints: (build) => ({
    getStrings: build.query<AppStrings | null, string>({
      query: (appName) => ({
        url: `/v1/strings/?app=${appName}`,
      }),
      extraOptions: {
        triggerGlobalError: true,
      },
    }),
  }),
})

export const {
  useGetStringsQuery,
} = dynamicStringsApi

const getProfileStrings = dynamicStringsApi.endpoints.getStrings.select(DynamicStringsApp.PROFILE)

export const selectProfileStrings = createSelector(getProfileStrings, (profileStrings) => ({
  strings: profileStrings?.data ? profileStrings.data.profile : {},
  isError: profileStrings.isError,
  isLoading: profileStrings.isLoading,
}))
