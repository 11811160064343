import React, {useEffect, useRef} from 'react'
import SwipeableViews from 'react-swipeable-views'

import styles from '../_results.module.scss'
import {useScenarioSlider} from '../hooks/ScenarioSliderProvider'

const SLIDER_THRESHOLD = 5
const DEFAULT_ANIMATION = false

type Props = {
  children: JSX.Element | JSX.Element[];
}

const Slider = ({children}: Props): JSX.Element => {
  const sliderRef = useRef<SwipeableViews>(null)

  const {
    animation,
    currentSlideIndex,
    move,
    handleSlideChange,
    handleSwitching,
    handleMove,
    handleTouchEnd,
  } = useScenarioSlider()

  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.containerNode.style.transform = `translate3d(${move * -100}%, 0px, 0px)`
    }
  }, [move])

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.containerNode.style.transition = animation
    }
  }, [animation])
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */

  return (
    <SwipeableViews
      ref={sliderRef}
      enableMouseEvents
      animateTransitions={DEFAULT_ANIMATION}
      onSwitching={handleSwitching}
      threshold={SLIDER_THRESHOLD}
      slideClassName={styles.slide}
      onChangeIndex={handleSlideChange}
      index={currentSlideIndex}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleMove}
      onMouseUp={handleTouchEnd}
      onMouseMove={handleMove}
    >
      {children}
    </SwipeableViews>
  )
}

export default React.memo(Slider)
