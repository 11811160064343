import React from 'react'
import cx from 'classnames'

import Icon, {IconName} from 'core/components/icon/Icon'
import Overline from 'core/components/display/Overline'

import styles from './_button.module.scss'

import colors from 'core/styles/_colors.module.scss'

export type Props = React.AriaAttributes & {
  cypressTag?: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
}

const BackButton = ({cypressTag, handleClick, text}: Props): JSX.Element => (
  <button
    type="button"
    className={cx(styles.backButton)}
    onClick={handleClick}
    data-cy={cypressTag}
  >
    <Icon name={IconName.CaretRight} fill={colors.darkBlue500} size={20} />
    <Overline className={styles.backLabel} text={text || 'Back'} />
  </button>
)

export default BackButton
