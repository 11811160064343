import React from 'react'

import Banner from 'core/components/banner/Banner'
import Button from 'core/components/button/Button'

import l from '../locale'
import styles from '../_results.module.scss'

type Props = {
  isVisible: boolean;
  liquidityEventDatePassed: boolean;
  showRerunModal: boolean;
  scenarioRerunLoading: boolean;
  resultsUpdatedAtMdy: string;
  onToggleEditLiquidityEvent: () => void;
  onRerunScenario: () => void;

}

const ResultsStaleBanner = ({
  isVisible,
  liquidityEventDatePassed,
  showRerunModal,
  scenarioRerunLoading,
  resultsUpdatedAtMdy,
  onToggleEditLiquidityEvent,
  onRerunScenario,
}: Props): JSX.Element => {
  const staleBannerCopySplit = l.SCENARIO_STALE_BANNER.IPO_COPY.split('[CTA]')

  if (!isVisible) return (<></>)

  return (
    <Banner id="scenario-stale-banner" intent="error">
      <strong className={styles.strongLabel}>{l.SCENARIO_STALE_BANNER.STRONG_LABEL}</strong>
      {' '}
      {liquidityEventDatePassed
        ? (
          <>
            {staleBannerCopySplit[0]}
            <Button
              fill={false}
              onClick={onToggleEditLiquidityEvent}
              className={styles.errorBannerCta}
            >
              {l.SCENARIO_STALE_BANNER.IPO_CTA}
            </Button>
            {staleBannerCopySplit[1]}
          </>
        )
        : (
          <>
            {l.SCENARIO_STALE_BANNER.COPY
              .replace('[DATE]', resultsUpdatedAtMdy)}
            {' '}
            <Button
              fill={false}
              onClick={onRerunScenario}
              className={styles.errorBannerCta}
              loading={!showRerunModal && scenarioRerunLoading}
            >
              {l.SCENARIO_STALE_BANNER.CTA}
            </Button>
          </>
        )}
    </Banner>
  )
}

export default React.memo(ResultsStaleBanner)
