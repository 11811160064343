import React from 'react'
import {Markup} from 'interweave'

import Icon, {IconName} from 'core/components/icon/Icon'

import styles from './_taxBreakdownEducation.module.scss'
import {ExerciseStateAlternativeMinimumTax as locale} from './locale'

import {Status} from 'core/components'

const IconProps = {
  FILL: '#ffc149',
  SIZE: 16,
}

const ExerciseStateAlternativeMinimumTaxEdu = (): JSX.Element => (
  <div className={styles.educationContainer}>
    <p className={styles.educationText}>
      <Markup noWrap content={locale.PARAGRAPH_1} />
    </p>

    <Status
      icon={<Icon name={IconName.Warning} fill={IconProps.FILL} size={IconProps.SIZE} />}
      containerClassName={styles.infoContainer}
      contentClassName={styles.content}
      content={(<Markup noWrap content={locale.COMING_SOON} />)}
    />
  </div>
)

export default ExerciseStateAlternativeMinimumTaxEdu
