import {combineReducers, createReducer} from '@reduxjs/toolkit'

import {OnboardingData} from './types'
import * as ClientProfileActions from './actions'

import {ClientProfile} from 'models'

export const profile = createReducer({} as OnboardingData, (builder) => {
  builder
    .addCase(ClientProfileActions.setInitialClientProfile, (_state, action) => action.payload)
    .addCase(ClientProfileActions.updateClientProfile, (_state, action) => action.payload)
})

export const onboarding = createReducer({} as ClientProfile, (builder) => {
  builder
    .addCase(ClientProfileActions.setInitialProfile, (_state, action) => action.payload)
})

const profileOnboardingReducer = combineReducers({
  profile,
  onboarding,
})

export default profileOnboardingReducer
