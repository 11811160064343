import {OptionType} from '../equityLiquidity'

export enum FilingStatus {
  SINGLE = 'SINGLE',
  MARRIED_FILING_JOINTLY = 'MARRIED_FILING_JOINTLY',
  MARRIED_FILING_SEPARATELY = 'MARRIED_FILING_SEPARATELY',
  HEAD_OF_HOUSEHOLD = 'HEAD_OF_HOUSEHOLD',
  QUALIFYING_WIDOW = 'QUALIFYING_WIDOW',
}

export const FILING_STATUS_LABELS = {
  [FilingStatus.SINGLE]: 'Single',
  [FilingStatus.HEAD_OF_HOUSEHOLD]: 'Head of household',
  [FilingStatus.MARRIED_FILING_JOINTLY]: 'Married filing jointly',
  [FilingStatus.QUALIFYING_WIDOW]: 'Qualifying widow(er)',
  [FilingStatus.MARRIED_FILING_SEPARATELY]: 'Married filing separately',
}

export enum TaxBracketType {
  FEDERAL = 'federal',
  FEDERAL_LTCG = 'federalLongTermCapGains',
  FEDERAL_ORDINARY = 'federalOrdinary',
  STATE = 'state',
  STATE_LTCG = 'stateLongTermCapGains',
  STATE_ORDINARY = 'stateOrdinary',
  LOCAL = 'local',
  MEDICARE = 'medicare',
  SOCIAL_SECURITY = 'socialSecurity',
  NET_INVESTMENT = 'netInvestment',
  AMT = 'amt',
}

export type TaxProfile = {
  id: string;
  taxYear: Nullable<number>;
  filingStatus: Nullable<FilingStatus>;
  householdIncome: Nullable<number>;
  updatedAt?: string;
}

export type TaxProfileResidency = {
  id: string;
  taxProfile: string;
  zipCode: string;
  startDate: string;
  endDate: string;
}

// TODO: Move tax profile methods/types to tax profile api model
export type TaxSummary = {
  effectiveTaxRate: number;
  medicareTax: number;
  netInvestmentIncomeTax: number;
  nonFicaTaxFromSubCategory: {
    [key in IncomeSourceSubCategory]?: number
  };
  socialSecurityTax: number;
  totalFederalTax: number;
  totalFicaTax: number;
  totalIncomeFromSubCategory: {
    [key in IncomeSourceSubCategory]?: number
  };
  totalPostTaxIncome: number;
  totalStateTax: number;
  totalTaxesOwed: number;
  amtTax: number;
}

export type TaxRate = {
  rate: number;
  taxesOwed: number;
  subCategory: IncomeSourceSubCategory;
  amountInBracket: number;
  bracket: [number, Nullable<number>];
}

export type NumberedTaxRate = TaxRate & {
  bracketNumber: number;
}

export type TaxDeduction = {
  name: string;
  amount: number;
}

export type NetInvestmentTaxBreakdown = {
  adjustedGrossIncome: number;
  agiIncomeOverThreshold: number;
  amountUsed: string;
  grossCapGains: number;
  netInvestmentIncome: number;
  rate: number;
  stateTaxesOwed: number;
  taxesOwed: number;
  threshold: number;
}

export enum ScenarioResultType {
  HOLD_OVER_A_YEAR = 'HOLD_OVER_A_YEAR',
  HOLD_UNDER_A_YEAR = 'HOLD_UNDER_A_YEAR',
  AMT_BREAKEVEN = 'AMT_BREAKEVEN',
}

export enum IncomeSourceCategory {
  ORDINARY_INCOME = 'ORDINARY_INCOME',
  CAPITAL_GAINS = 'CAPITAL_GAINS',
}

export enum IncomeSourceSubCategory {
  INCOME = 'INCOME',
  VESTING = 'VESTING',
  ISO_EXERCISE = 'ISO_EXERCISE',
  NSO_EXERCISE = 'NSO_EXERCISE',
  EQUITY_SALE = 'EQUITY_SALE',
}

export enum TaxBracketOrdering {
  REGULAR_INCOME = 'REGULAR_INCOME',
  SCENARIO_EXERCISE = 'SCENARIO_EXERCISE',
  SCENARIO_SALE = 'SCENARIO_SALE',
  RSU_VESTING = 'RSU_VESTING',
}

export enum OptionDispositionOptionType {
  INCENTIVE_STOCK = 'INCENTIVE_STOCK',
  NON_QUALIFIED = 'NON_QUALIFIED',
}

export const OptionDispositionOptionTypeLabel = new Map<OptionDispositionOptionType, string>([
  [OptionDispositionOptionType.INCENTIVE_STOCK, 'ISO'],
  [OptionDispositionOptionType.NON_QUALIFIED, 'NSO'],
])

export enum TaxScenarioGrantType {
  RSU = 'RSU',
  RSA = 'RSA',
  INCENTIVE_STOCK = 'INCENTIVE_STOCK',
  NON_QUALIFIED = 'NON_QUALIFIED',
}

export type EquitySale = {
  purchasePrice: number;
  saleDate: string;
  purchaseDate: string;
  salePrice: number;
  quantity: number;
  grantDate: string;
  grantType: TaxScenarioGrantType;
  dispositionType: OptionDispositionType;
}

export enum OptionDispositionType {
  QUALIFIED = 'QUALIFIED',
  NON_QUALIFIED = 'NON_QUALIFIED',
  NA = '',
}

export type VestingEvent = {
  company409a: number;
  quantity: number;
  vestedValue: number;
  vestedDate: string;
  grantDate: string;
}

export type OptionDisposition = {
  shareSales: any;
  company409a: number;
  quantity: number;
  exerciseDate: string;
  saleDate: string;
  grantDate: string;
  optionType: OptionDispositionOptionType;
  exercisePrice: number;
  dispositionType: OptionDispositionType;
  phantomIncome: number;
}

export type IncomeSource = {
  category: IncomeSourceCategory;
  subCategory: IncomeSourceSubCategory;
  grossIncome: number;
  incomeCompensation: number;
  incomeSource: OptionDisposition | EquitySale | VestingEvent;
  orderingLabel: TaxBracketOrdering;
}

enum DeductionName {
  STANDARD = 'STANDARD',
}

type Deduction = {
  name: DeductionName.STANDARD;
  amount: number;
}

// TODO(ray): implement rest of data types
export type TaxBreakdown = {
  incomeSources: IncomeSource[];
  taxesOwed: number;
  taxesOwedBySubCategory: {
    [key in IncomeSourceSubCategory]?: number
  };
  taxesOwedByOrdering: {
    [key in TaxBracketOrdering]?: number
  };
  taxRates: TaxRate[];
  effectiveTaxRate: number;
  marginalTaxRate: number;
  deductions: Deduction[];
  adjustedGrossIncome: number;
  postIncomeTaxIncome: number;
}

export type Exercise = {
  exercisePrice: number;
  quantity: number;
  exerciseDate: string;
  shareSales: EquitySale[];
}

export type EquityGrant = {
  shareSales: EquitySale[];
  grantDate: string;
  grantType: TaxScenarioGrantType;
}

export type OptionGrant = {
  exercises: Exercise[];
  grantDate: string;
  optionType: OptionType;
}

type OrdinaryIncome = {
  amount: number;
}

export type RawIncomeSource = {
  equityGrant: EquityGrant[];
  equitySale: EquitySale[];
  optionGrant: OptionGrant[];
  ordinaryIncome: OrdinaryIncome[];
}

// TODO(kevin): implement data types for results profile, summary, breakdown
export type TaxScenarioResult = {
  incomeSources: RawIncomeSource;
  taxProfile: {
    filingStatus: FilingStatus;
    states: string[];
    taxYear: number;
    zipcodes: string[];
  };
  taxSummary: TaxSummary;
  taxBreakdown: {
    federal: {
      capitalGains: TaxBreakdown;
      ordinaryIncome: TaxBreakdown;
      amt: TaxBreakdown;
    };
    medicare: TaxBreakdown;
    netInvestmentIncome: NetInvestmentTaxBreakdown;
    socialSecurity: TaxBreakdown;
    state: {
      [key: string]: { // key is the state in which these tax breakdowns apply e.g. NY
        capitalGains: TaxBreakdown;
        ordinaryIncome: TaxBreakdown;
      };
    };
  };
}

type GrantSummary = {
  grantDate: string;
  grantType: TaxScenarioGrantType;
  vestedOptions: number;
  vestedShares: number;
  unvestedOptions: number;
  unvestedShares: number;
  cancelledOptions: number;
  exercisableOptions: number;
  isEarlyExercisable: number;
}

export type TaxGrantExerciseSummary = GrantSummary & {
  optionsExercisedForScenario: number;
  exerciseCostForScenario: number;
  exercisePrice: number;
}

export type TaxGrantSaleSummary = GrantSummary & {
  sharesSoldForScenario: number;
  saleProceedsForScenario: number;
  salePrice: number;
  isDoubleTrigger?: boolean;
}

export type TaxScenario = {
  purchaseDate: string;
  saleDate: string;
  grantSummary: {
    purchase: TaxGrantExerciseSummary[];
    sale: TaxGrantSaleSummary[];
  };
  [year: number]: TaxScenarioResult;
}

export type TaxScenarioResults = {
  [key in ScenarioResultType]: TaxScenario
}

export enum EventQuarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

type EventQuarterLabel = {
  basic: string;
  withMonthRange: string;
  numerical: string;
  onlyMonthRange: string;
}

export const EventQuarterLabels: Readonly<Record<EventQuarter, EventQuarterLabel>> = {
  Q1: {
    basic: 'Quarter 1',
    withMonthRange: 'Quarter 1 (Jan. - Mar.)',
    numerical: '1st Quarter',
    onlyMonthRange: 'Jan. - Mar.',
  },
  Q2: {
    basic: 'Quarter 2',
    withMonthRange: 'Quarter 2 (Apr. - Jun.)',
    numerical: '2nd Quarter',
    onlyMonthRange: 'Apr. - Jun.',
  },
  Q3: {
    basic: 'Quarter 3',
    withMonthRange: 'Quarter 3 (Jul. - Sep.)',
    numerical: '3rd Quarter',
    onlyMonthRange: 'Jul. - Sep.',
  },
  Q4: {
    basic: 'Quarter 4',
    withMonthRange: 'Quarter 4 (Oct. - Dec.)',
    numerical: '4th Quarter',
    onlyMonthRange: 'Oct. - Dec.',
  },
}

export enum EventType {
  ACQUISITION = 'Acquisition',
  IPO = 'Public offering',
  SECONDARY_OFFERING = 'Secondary offering',
  EXERCISE_OPTIONS_ONLY = 'Exercise only',
}
// https://harness-wealth.atlassian.net/browse/HWEPD-2788
// https://phabricator.harnesswealth.com/D7180
// todo: alucic document usage and talk to design
// IPO is intentionally lowercase, Secondary Offering and Exercise Options Only did not exist in the first map.
export const EventTypeLabels: Readonly<Record<EventType, string>> = {
  [EventType.IPO]: 'public offering',
  [EventType.ACQUISITION]: 'Acquisition',
  [EventType.SECONDARY_OFFERING]: 'Secondary offering',
  [EventType.EXERCISE_OPTIONS_ONLY]: 'Exercise only',
}

export type LiquidityEvent = {
  id: string;
  company: string;
  companyName: string;
  taxProfile: string;
  eventType: EventType;
  eventQuarter: EventQuarter;
  eventYear: number;
  lockupPeriod: Nullable<number>;
  salePrice: Nullable<number>;
  resultsUpdatedAt: string;
  isStale: boolean;
  estimatedEventDate: string;
}

export type LiquidityEventWithResults = LiquidityEvent & {
  results: TaxScenarioResults;
}

export type LiquidityEventPayload = {
  eventType: EventType;
  eventQuarter: Nullable<EventQuarter>;
  eventYear: Nullable<number>;
  lockupPeriod: number;
  salePrice: number;
  taxProfile: string;
}

export type TaxProfileResidencyPayload = {
  taxProfileId: string;
  zipCode: string;
  startDate: string;
  endDate: string;
}

export type UpdateTaxProfilePayload = {
  filingStatus: FilingStatus;
  householdIncome: number;
}

export type TaxTypeTotals = {
  federal: number;
  federalOrdinary: number;
  federalLongTermCapGains: number;
  socialSecurity: number;
  medicare: number;
  netInvestmentIncome: number;
  state: number;
  stateOrdinary: number;
  stateLongTermCapGains: number;
  total: number;
  amt: number;
}
