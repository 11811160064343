import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'

import Modal from 'core/components/modal/Modal'

import styles from './_styles.module.scss'

import {RegularText} from 'core/theme'

const Description = styled(RegularText)(({theme: {spacing}}) => `
  margin-bottom: ${spacing.m};
`)

type Props = {
  /* Content of the modal card. */
  children: React.ReactNode;
  /* Unique ID passed to the underlying Modal component */
  id: string;
  /* Layout variant of the modal card. */
  intent?: 'bottomHalfScreen' | 'fullScreen' | 'fullScreenMobile'; // TODO(Steph): rename intent
  /* Callback function to specify how to handle cancel actions */
  onCancel?: () => void;
  /* Displays as a header (h4) within the FormModal component */
  title: string;
  /* CSS class to append to Modal component */
  className?: string;
  description?: string;
}

// TODO(kevin): Consider moving the form element/form submit inside this component instead
// of defining it inside each FormModal
export default function FormModal({
  intent,
  children,
  id,
  title,
  onCancel,
  className = '',
  description = '',
}: Props): JSX.Element {
  return (
    <Modal
      className={cx(styles.modalCard, className)}
      id={id}
      data-cy={id}
      intent={intent}
      onBackdropClick={onCancel}
      open
    >
      <div className={styles.modalCardContent}>
        <h4 className={styles.modalTitle}>{title}</h4>
        <Description>{description}</Description>
        <hr className={styles.horizontalRule} />
        {children}
      </div>
    </Modal>
  )
}
