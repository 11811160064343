import {format, parse, parseISO} from 'date-fns'

// TODO(raylen): probably delete this pattern - not useful

export const DateFns = {
  formatISODate: (date: string, dateFormat: string): string => format(parseISO(date), dateFormat),
}

export const isoDateFormatter = (date?: string, dateFormat?: string): string => (
  (date && dateFormat) ? DateFns.formatISODate(date, dateFormat) : ''
)

/**
 * @param value
 *
 * Parses date in front end display format (mm/dd/yyyy)
 * */
export function parseMdy(value: string): Date {
  return parse(value, 'MM/dd/yyyy', new Date())
}

/**
 * @param value
 *
 * Converts date string from api format to front end display format
 * yyyy-mm-dd => mm/dd/yyyy
 */
export function toMdy(value?: string | null): string | null | undefined {
  if (!value) return value
  const [y = '', m = '', d = ''] = value.split('-')
  return `${m}/${d}/${y}`
}

/**
 * @param value
 *
 * Converts date string from front end display format to api format
 * mm/dd/yyyy => mm/dd/yyyy
 */
export function toYmd(value: string): string {
  if (!value) return value
  const [m = '', d = '', y = ''] = (value || '').split('/')
  return `${y}-${m}-${d}`
}
