import React from 'react'
import {parseISO} from 'date-fns'

import TwoColumnTable, {GridRow} from './TwoColumnTable'
import l from './locale'

import {numberFormatter} from 'core/formatters'
import {
  EquityGrant,
  EquitySale,
  Exercise,
  IncomeSource,
  OptionDisposition,
  OptionGrant,
  TaxBracketOrdering,
  TaxScenarioGrantType,
  TaxScenarioResult,
  getIncomeSourcesFromTaxCategory,
} from 'models/liquidityEvent'

function getExerciseOnlyFromTaxYear(exercises: IncomeSource[], taxYear: number): IncomeSource[] {
  return exercises.filter(({incomeSource}) => (
    parseISO((incomeSource as OptionDisposition).exerciseDate).getFullYear() === taxYear))
}

function buildIncomeRows({
  result,
  isFederal,
  removeDeductions,
  hideShortTermGains,
  hideLongTermGains,
  hideISOs,
  hideNSOs,
  showRSUs,
  totalLabel,
  scenarioNumber,
  purchaseDate,
  incomeFromSaleLabel,
}: {
  result: TaxScenarioResult;
  isFederal: boolean;
  removeDeductions: boolean;
  hideShortTermGains: boolean;
  hideLongTermGains: boolean;
  hideISOs: boolean;
  hideNSOs: boolean;
  showRSUs: boolean;
  totalLabel: string;
  scenarioNumber: number;
  purchaseDate: string;
  incomeFromSaleLabel: string;
}): GridRow[] {
  const {
    taxBreakdown, taxSummary, taxProfile, incomeSources: {optionGrant, equityGrant},
  } = result
  const stateKey = Object.keys(taxBreakdown.state)[0]
  const breakdown = isFederal ? taxBreakdown.federal : taxBreakdown.state[stateKey]
  const {taxYear} = taxProfile

  const noSaleText = l.NO_SALE_TEXT.replace('[YEAR]', taxYear.toString())
  const noExerciseText = l.NO_EXERCISE_TEXT.replace('[YEAR]', taxYear.toString())

  const {
    nsoPriorExerciseIncomeSources,
    nsoExerciseIncomeSources,
    shortTermCapitalGainsIncomeSources,
    longTermCapitalGainsIncomeSources,
  } = getIncomeSourcesFromTaxCategory(purchaseDate, breakdown)
  const nsoPriorExerciseCurrentYearOnly = getExerciseOnlyFromTaxYear(nsoPriorExerciseIncomeSources, taxYear)

  const nsoPriorExerciseGains = nsoPriorExerciseCurrentYearOnly.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const nsoExerciseGains = nsoExerciseIncomeSources.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const shortTermCapGains = shortTermCapitalGainsIncomeSources.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const longTermCapGains = longTermCapitalGainsIncomeSources.reduce((sum, {grossIncome}) => sum + grossIncome, 0)

  const allExercises = optionGrant
    .reduce((acc: Exercise[], {exercises}: OptionGrant) => ([...acc, ...exercises]), [])
  const hasExerciseInTaxYear = allExercises
    .some((exercise: Exercise) => parseISO(exercise.exerciseDate).getFullYear() === taxYear)
  const allShareSales = allExercises
    .reduce((acc: EquitySale[], {shareSales}: Exercise) => [...acc, ...shareSales], [])
    .concat(equityGrant.reduce((acc: EquitySale[], {shareSales}: EquityGrant) => [...acc, ...shareSales], []))
  const hasSaleInTaxYear = allShareSales
    .some((shareSale: EquitySale) => parseISO(shareSale.saleDate).getFullYear() === taxYear)

  let incomeFromSale = (taxSummary.totalIncomeFromSubCategory.ISO_EXERCISE ?? 0)

  if (!hideLongTermGains) {
    incomeFromSale += longTermCapGains
  }

  if (!hideShortTermGains) {
    incomeFromSale += shortTermCapGains
  }

  const rsuGrants = result.incomeSources.equityGrant.filter((grant: EquityGrant) => grant.grantType === TaxScenarioGrantType.RSU)
  const incomeFromVestingRSUs = breakdown.ordinaryIncome.incomeSources
    .filter(({orderingLabel}) => orderingLabel === TaxBracketOrdering.RSU_VESTING)
    .reduce((sum, {grossIncome}) => sum + grossIncome, 0)

  return [
    {
      label: l.REGULAR_INCOME_LABEL.replace('[YEAR]', taxYear.toString()),
      value: numberFormatter(
        (taxSummary.totalIncomeFromSubCategory.INCOME ?? 0)
          + nsoPriorExerciseGains
          + (showRSUs ? incomeFromVestingRSUs : 0),
      ).currency,
      subrows: [
        {
          label: l.HOUSEHOLD_INCOME_LABEL,
          value: numberFormatter(taxSummary.totalIncomeFromSubCategory.INCOME ?? 0).currency,
        },
        ...nsoPriorExerciseCurrentYearOnly.length ? [{
          label: l.INCOME_FROM_PRIOR_EXERCISE_LABEL.replaceAll('[YEAR]', taxYear.toString()),
          value: numberFormatter(nsoPriorExerciseGains).currency,
        }] : [],
        ...showRSUs && !!rsuGrants.length ? [{
          label: 'RSU income from vesting',
          value: numberFormatter(incomeFromVestingRSUs).currency,
        }] : [],
      ],
    },
    ...(!removeDeductions
      ? [{
        label: l.DEDUCTIONS_LABEL,
        value: numberFormatter(breakdown.ordinaryIncome.deductions[0].amount * -1 || 0).currency,
      }]
      : []
    ),
    ...(!hideNSOs
      ? [{
        label: l.INCOME_FROM_SCENARIO_EXERCISE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
        value: hasExerciseInTaxYear ? numberFormatter(nsoExerciseGains).currency : noExerciseText,
        notApplicable: !hasExerciseInTaxYear,
        ...(hasExerciseInTaxYear && {
          subrows: [
            {
              label: l.NSO_INCOME_FROM_SCENARIO_EXERCISE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
              value: numberFormatter(nsoExerciseGains).currency,
            },
          ],
        }),
      }] : []
    ),
    {
      label: incomeFromSaleLabel.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
      value: hasSaleInTaxYear ? numberFormatter(incomeFromSale).currency : noSaleText,
      notApplicable: !hasSaleInTaxYear,
      subrows: [
        ...(!hideISOs
          ? [{
            label: l.ISO_INCOME_FROM_SCENARIO_SALE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
            value: hasSaleInTaxYear
              ? numberFormatter(taxSummary.totalIncomeFromSubCategory.ISO_EXERCISE ?? 0).currency
              : noSaleText,
          }] : []
        ),
        ...(!hideLongTermGains
          ? [{
            label: l.LTCG_FROM_SCENARIO_SALE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
            value:
              hasSaleInTaxYear
                ? numberFormatter(longTermCapGains).currency
                : noSaleText,
          }]
          : []
        ),
        ...(!hideShortTermGains
          ? [{
            label: l.STCG_FROM_SCENARIO_SALE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
            value:
              hasSaleInTaxYear
                ? numberFormatter(shortTermCapGains || 0).currency
                : noSaleText,
          }]
          : []
        ),
      ],
    },
    {
      label: totalLabel || '',
      value: numberFormatter(
        (breakdown.ordinaryIncome.adjustedGrossIncome
          + (hideLongTermGains ? 0 : breakdown.capitalGains.adjustedGrossIncome)
          + (removeDeductions ? breakdown.ordinaryIncome.deductions[0].amount : 0))
        || 0,
      ).currency,
    },
  ]
}

type Props = {
  result: TaxScenarioResult;
  isFederal?: boolean;
  removeDeductions?: boolean;
  hideShortTermGains?: boolean;
  hideLongTermGains?: boolean;
  hideISOs?: boolean;
  hideNSOs?: boolean;
  showRSUs?: boolean;
  title?: string;
  totalLabel?: string;
  scenarioNumber: number;
  purchaseDate: string;
  incomeFromSaleLabel?: string;
  hasEducation?: boolean;
}

export default function IncomeDisplayTable({
  result,
  isFederal = false,
  removeDeductions = false,
  hideShortTermGains = false,
  hideLongTermGains = false,
  hideISOs = false,
  hideNSOs = false,
  showRSUs = false,
  title = 'Taxable Ordinary Income',
  totalLabel = 'Taxable ordinary income',
  scenarioNumber,
  purchaseDate,
  incomeFromSaleLabel = l.INCOME_FROM_SCENARIO_SALE_LABEL,
  hasEducation = false,
}: Props): JSX.Element {
  const rows = React.useMemo(() => buildIncomeRows({
    result,
    isFederal,
    removeDeductions,
    hideShortTermGains,
    hideLongTermGains,
    hideISOs,
    hideNSOs,
    showRSUs,
    totalLabel,
    scenarioNumber,
    purchaseDate,
    incomeFromSaleLabel,
  }), [
    result,
    isFederal,
    removeDeductions,
    hideShortTermGains,
    hideLongTermGains,
    hideISOs,
    hideNSOs,
    showRSUs,
    totalLabel,
    scenarioNumber,
    purchaseDate,
    incomeFromSaleLabel,
  ])
  return (
    <TwoColumnTable rows={rows} title={title} hasEducation={hasEducation} />
  )
}
