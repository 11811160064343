import {Markup} from 'interweave'
import React, {useCallback} from 'react'
import {useParams} from 'react-router-dom'

import Tooltip from 'core/components/tooltip/TooltipV2'
import Link from 'core/components/link/Link'

import {useEquityInsights} from '../hooks'

import {formatNumberWithCommas} from 'core/formatters'
import {Warning} from 'core/ui'
import {
  GrantType,
  OptionsStatusType,
  ScenarioType, selectScenarioByType, useGetLiquidityEventQuery,
} from 'store/scenario'
import locale from 'pages/equity-insights-results/ScenarioBreakdown/locale'
import {RoutePaths} from 'core/constants'
import {Button} from 'core/components'
import {useGetFeatureFlagsQuery} from 'store/feature-flag'

const DOUBLE_TRIGGER_TOOLTIP = `
Most private companies have a “double-trigger provision” on RSUs they grant.
Double-trigger RSU grants aren’t taxable until two specific events occur,
as opposed to most public company RSUs in which shares are taxable immediately upon vesting.
The two events that are usually considered as part of the double-trigger provision are:
<ul>
  <li>The RSU grant time-based vesting schedule</li>
  <li>The company has a liquidity event (IPO, acquisition, etc).</li>
</ul>
`

type Props = {
  scenarioType: ScenarioType;
}

export const ScenarioWarnings = ({scenarioType}: Props): JSX.Element => {
  const {companyId} = useParams<{companyId: string}>()
  const {data: flags} = useGetFeatureFlagsQuery()
  const {data} = useGetLiquidityEventQuery(companyId, {
    selectFromResult: (result) => ({
      data: selectScenarioByType(result.data?.scenarios, scenarioType),
    }),
  })

  const {setShowEditLiquidityEvent} = useEquityInsights()

  const handleEditLiquidityEventClick = useCallback(() => {
    setShowEditLiquidityEvent((prev) => !prev)
  }, [setShowEditLiquidityEvent])

  const unsupportedCopy = ((): string => {
    if (!data?.warnings.unsupportedAssets?.RSA && !data?.warnings.unsupportedAssets?.RSU) return ''

    const assets = data.warnings.unsupportedAssets

    let unsupportedAssetsCopy = Object.keys(assets).filter((asset) => !!assets[asset])
    if (flags?.rsuSupport) {
      unsupportedAssetsCopy = unsupportedAssetsCopy.filter((asset) => asset !== GrantType.RSU)
    }
    if (!unsupportedAssetsCopy.length) {
      return ''
    }
    const assetCopy = unsupportedAssetsCopy.join(' and ')
    const asset2Copy = unsupportedAssetsCopy.map((asset) => `${asset}s`).join(' and ')
    const assetAmountCopy = unsupportedAssetsCopy.map((asset) => (
      `${formatNumberWithCommas(assets[asset])} ${asset}s`
    )).join(' and ')
    return locale.UNSUPPORTED_ASSET_DISCLAIMER
      .replace('[ASSET]', assetCopy)
      .replace('[ASSET2]', asset2Copy)
      .replace('[ASSET_AMOUNT]', assetAmountCopy)
  })()

  return (
    <div>
      {flags?.rsuSupport && !!data?.warnings.doubleTriggerRSUsSupport && (
        <Warning>
          <Tooltip content={<Markup content={DOUBLE_TRIGGER_TOOLTIP} />} placement="top">
            <>
              <b>Double Trigger RSU support coming soon.</b>
              {' '}
              Double trigger RSUs are not factored into your sale proceeds or taxes.
              Upon the above sale date you will have
              {' '}
              {formatNumberWithCommas(data?.warnings.doubleTriggerRSUsSupport)}
              {' '}
              vested double trigger RSUs eligible for sale.
            </>
          </Tooltip>
        </Warning>
      )}
      {unsupportedCopy && <Warning content={unsupportedCopy} />}
      {!!data?.warnings.optionsStatusType && (
        <Warning
          content={locale.SCENARIO_WARNINGS[data?.warnings.optionsStatusType].INFO}
        >
          {data?.warnings.optionsStatusType === OptionsStatusType.OPTIONS_NOT_VESTED ? (
            <button
              type="button"
              onClick={handleEditLiquidityEventClick}
            >
              {locale.SCENARIO_WARNINGS[data?.warnings.optionsStatusType].BTN_TEXT}
            </button>
          ) : (
            <Link
              bold
              underlined
              to={RoutePaths.ADD_EQUITY_COMPANY_ENTITY.replace(':companyId', companyId)}
            >
              {locale.SCENARIO_WARNINGS[data?.warnings.optionsStatusType].BTN_TEXT}
            </Link>
          )}
          {locale.SCENARIO_WARNINGS[data?.warnings.optionsStatusType].INFO_2}
          <Link bold underlined to={RoutePaths.FEEDBACK}>
            {locale.SCENARIO_WARNINGS[data?.warnings.optionsStatusType].LINK_TEXT}
          </Link>
        </Warning>
      )}
      {flags?.rsuSupport && (
      <>
        {data?.warnings.noAvailableRSUsShares && (
          <Warning>
            <b>No shares available for sale.</b>
            {' '}
            This might happen if no shares have vested or if all equity has been canceled.
            {' '}
            To project a scenario, try changing the
            {' '}
            <Button bold underlined onClick={handleEditLiquidityEventClick}>
              public offering date
            </Button>
            {' '}
            or
            {' '}
            <Link bold underlined to={RoutePaths.FEEDBACK}>leave us feedback.</Link>
          </Warning>
        )}

        {data?.warnings.noLongTermCapitalGainsRSUs && (
          <Warning>
            <b>None of your RSUs will qualify for long-term capital gains upon the liquidity date.</b>
            {' '}
            This might happen if your shares have not been held for at least 1 year.
            {' '}
            To project a scenario, try changing the
            {' '}
            <Button bold underlined onClick={handleEditLiquidityEventClick}>
              sale date
            </Button>
            {' '}
            or
            {' '}
            <Link bold underlined to={RoutePaths.FEEDBACK}>leave us feedback.</Link>
          </Warning>
        )}
      </>
      )}
    </div>
  )
}
