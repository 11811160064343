import {useHistory, useLocation} from 'react-router-dom'

import {UNAUTHENTICATED_ROUTE_PATH_SET} from '../const'

import {AppSession} from 'core/utils'
import {RoutePaths} from 'core/constants'

type Props = {
  session: AppSession;
  children: JSX.Element;
}

export const UnauthenticatedRoutes = ({session, children}: Props): Nullable<JSX.Element> => {
  const history = useHistory()
  const location = useLocation()

  if (session.userIsAuthenticated && location.pathname !== RoutePaths.ACCEPT_INVITE) {
    // Only push if the user is attempting to hit an UNAUTHENTICATED ROUTE path WHILE AUTHENTICATED
    // Push to '/' (landing proxy) instead of ADVISER_SEARCH (dashboard)
    // to determine appropriate redirect route
    if (UNAUTHENTICATED_ROUTE_PATH_SET.has(location.pathname)) history.push('/')
    return null
  }
  return children
}
