import {EnvConfig, Environment} from './types'

const result: EnvConfig = {
  ENV: Environment.GCLOUD_PROD,
  HELLO_SIGN_CLIENT_ID: '7176fd0f48e79785378ace601d663983',
  SENTRY_HOST: 'https://a33d8c8538a14398b967c083d810ae16@sentry.io/1250568',
  MIXPANEL_TOKEN: '4c9200ece6cdf4d1020cc8a9d2ec7064',
  MIXPANEL_ACTIVE: true,
  GA_TOKEN: 'UA-128787511-1',
  LINKEDIN_CLIENTID: '786bkox3pl2t80',
  OPEN_HUBSPOT_CHAT: true,
  USE_GTM: true,
  CANNY_APP_ID: '5f6cebd1b792fa54f0b9e6c7',
  SENTRY_TRACING_RATE: 0.5,
  VEZGO_CLIENT_ID: '1gse42uph5u09he2sujges929q',
  HUBSPOT_QUESTIONNAIRE_FORM_ID: '7293a247-5bda-4ced-884e-72440339f19d',
}

export default result
