import React from 'react'
import {parseISO} from 'date-fns'

import TwoColumnTable, {GridRow} from './TwoColumnTable'
import {AMT_INCOME_DISPLAY_TABLE as l} from './locale'

import {numberFormatter} from 'core/formatters'
import {
  EquitySale,
  Exercise,
  IncomeSource,
  OptionDisposition,
  OptionDispositionType,
  OptionGrant,
  TaxBreakdown,
  TaxSummary,
} from 'models/liquidityEvent'
import {AmtIncomeSources} from 'models/liquidityEvent/utils'

function getExerciseOnlyFromTaxYear(exercises: IncomeSource[], taxYear: number): IncomeSource[] {
  return exercises.filter(({incomeSource}) => (
    parseISO((incomeSource as OptionDisposition).exerciseDate).getFullYear() === taxYear))
}

function buildIncomeRows({
  amtBreakdown,
  amtIncomeSources,
  taxSummary,
  taxYear,
  optionGrant,
  scenarioNumber,
  incomeFromSaleLabel,
}: {
  amtBreakdown: TaxBreakdown;
  amtIncomeSources: AmtIncomeSources;
  taxYear: number;
  taxSummary: TaxSummary;
  optionGrant: OptionGrant[];
  scenarioNumber: number;
  incomeFromSaleLabel: string;
}): GridRow[] {
  const {
    amtIsoExerciseIncomeSources,
    amtIsoPriorExerciseIncomeSources,
    amtNsoExerciseIncomeSources,
    amtNsoPriorExerciseIncomeSources,
    shortTermCapitalGainsIncomeSources,
    longTermCapitalGainsIncomeSources,
  } = amtIncomeSources

  const calculateIncome = (sum: number, exercise: IncomeSource): number => {
    const incomeSource = exercise.incomeSource as OptionDisposition
    return sum
    + Math.max((incomeSource.company409a - incomeSource.exercisePrice), 0)
    * incomeSource.quantity
  }

  const noSaleText = l.NO_SALE_TEXT.replace('[YEAR]', taxYear.toString())
  const noExerciseText = l.NO_EXERCISE_TEXT.replace('[YEAR]', taxYear.toString())
  const isoPriorExerciseCurrentYearOnly = getExerciseOnlyFromTaxYear(amtIsoPriorExerciseIncomeSources, taxYear)
  const nsoPriorExerciseCurrentYearOnly = getExerciseOnlyFromTaxYear(amtNsoPriorExerciseIncomeSources, taxYear)
  const hasQualifiedISOs = amtIsoExerciseIncomeSources.some(({incomeSource}) => (
    (incomeSource as OptionDisposition).dispositionType === OptionDispositionType.QUALIFIED
  ))
  const hasQualifiedISOPrior = isoPriorExerciseCurrentYearOnly.some(({incomeSource}) => (
    (incomeSource as OptionDisposition).dispositionType === OptionDispositionType.QUALIFIED
  ))
  const isoPriorExerciseGains = hasQualifiedISOPrior ? isoPriorExerciseCurrentYearOnly.reduce(calculateIncome, 0) : 0
  const nsoPriorExerciseGains = nsoPriorExerciseCurrentYearOnly.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const isoExerciseGains = hasQualifiedISOs ? amtIsoExerciseIncomeSources.reduce(calculateIncome, 0) : 0
  const nsoExerciseGains = amtNsoExerciseIncomeSources.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const shortTermCapGains = shortTermCapitalGainsIncomeSources.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const longTermCapGains = longTermCapitalGainsIncomeSources.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const allExercises = optionGrant
    .reduce((acc: Exercise[], {exercises}: OptionGrant) => ([...acc, ...exercises]), [])
  const hasExerciseInTaxYear = allExercises
    .some((exercise: Exercise) => parseISO(exercise.exerciseDate).getFullYear() === taxYear)
  const allShareSales = allExercises
    .reduce((acc: EquitySale[], {shareSales}: Exercise) => [...acc, ...shareSales], [])
  const hasSaleInTaxYear = allShareSales
    .some((shareSale: EquitySale) => parseISO(shareSale.saleDate).getFullYear() === taxYear)

  const incomeFromSale = (taxSummary.totalIncomeFromSubCategory.ISO_EXERCISE ?? 0)
    + longTermCapGains + shortTermCapGains

  const regularIncome = (taxSummary.totalIncomeFromSubCategory.INCOME ?? 0)
    + isoPriorExerciseGains + nsoPriorExerciseGains

  return [
    {
      label: l.REGULAR_INCOME_LABEL.replace('[YEAR]', taxYear.toString()),
      value: numberFormatter(regularIncome).currency,
      subrows: [
        {
          label: l.HOUSEHOLD_INCOME_LABEL,
          value: numberFormatter(taxSummary.totalIncomeFromSubCategory.INCOME ?? 0).currency,
        },
        ...amtIsoPriorExerciseIncomeSources.length ? [{
          label: l.ISO_INCOME_FROM_PRIOR_EXERCISE_LABEL.replaceAll('[YEAR]', taxYear.toString()),
          value: numberFormatter(isoPriorExerciseGains).currency,
        }] : [],
        ...amtNsoPriorExerciseIncomeSources.length ? [{
          label: l.NSO_INCOME_FROM_PRIOR_EXERCISE_LABEL.replaceAll('[YEAR]', taxYear.toString()),
          value: numberFormatter(nsoPriorExerciseGains).currency,
        }] : [],
      ],
    },
    {
      label: l.INCOME_FROM_SCENARIO_EXERCISE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
      value: hasExerciseInTaxYear ? numberFormatter(nsoExerciseGains + isoExerciseGains).currency : noExerciseText,
      notApplicable: !hasExerciseInTaxYear,
      ...(hasExerciseInTaxYear && {
        subrows: [
          {
            label: l.ISO_INCOME_FROM_SCENARIO_EXERCISE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
            value: numberFormatter(isoExerciseGains).currency,
          },
          {
            label: l.NSO_INCOME_FROM_SCENARIO_EXERCISE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
            value: numberFormatter(nsoExerciseGains).currency,
          },
        ],
      }),
    },
    {
      label: incomeFromSaleLabel.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
      value: hasSaleInTaxYear ? numberFormatter(incomeFromSale).currency : noSaleText,
      notApplicable: !hasSaleInTaxYear,
      subrows: [
        {
          label: l.ISO_INCOME_FROM_SCENARIO_SALE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
          value: hasSaleInTaxYear
            ? numberFormatter(taxSummary.totalIncomeFromSubCategory.ISO_EXERCISE ?? 0).currency
            : noSaleText,
        },

        {
          label: l.LTCG_FROM_SCENARIO_SALE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
          value:
              hasSaleInTaxYear
                ? numberFormatter(longTermCapGains).currency
                : noSaleText,
        },
        {
          label: l.STCG_FROM_SCENARIO_SALE_LABEL.replace('[SCENARIO_NUMBER]', scenarioNumber.toString()),
          value:
              hasSaleInTaxYear
                ? numberFormatter(shortTermCapGains || 0).currency
                : noSaleText,
        },
      ],
    },
    {
      label: l.DEDUCTIONS_LABEL,
      value: numberFormatter(amtBreakdown.deductions[0]?.amount * -1 || 0).currency,
    },
    {
      label: `${taxYear.toString()} AMT Income` || '',
      value: numberFormatter(amtBreakdown.adjustedGrossIncome).currency,
    },
  ]
}

type Props = {
  amtBreakdown: TaxBreakdown;
  amtIncomeSources: AmtIncomeSources;
  taxYear: number;
  taxSummary: TaxSummary;
  optionGrant: OptionGrant[];
  scenarioNumber: number;
  incomeFromSaleLabel?: string;
  hasEducation?: boolean;
}

export default function IncomeDisplayTable({
  amtBreakdown,
  amtIncomeSources,
  scenarioNumber,
  taxSummary,
  taxYear,
  optionGrant,
  incomeFromSaleLabel = l.INCOME_FROM_SCENARIO_SALE_LABEL,
  hasEducation = false,
}: Props): JSX.Element {
  const rows = React.useMemo(() => buildIncomeRows({
    amtBreakdown,
    amtIncomeSources,
    taxSummary,
    taxYear,
    optionGrant,
    scenarioNumber,
    incomeFromSaleLabel,
  }), [
    amtBreakdown,
    amtIncomeSources,
    taxSummary,
    taxYear,
    optionGrant,
    scenarioNumber,
    incomeFromSaleLabel,
  ])
  return (
    <TwoColumnTable rows={rows} title={`${taxYear} Ordinary income`} hasEducation={hasEducation} />
  )
}
