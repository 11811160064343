import {utcToZonedTime} from 'date-fns-tz'
import {formatDistanceToNowStrict, parseISO} from 'date-fns'

import {CryptoAccount, CryptoAccountConnectionStatus} from './types'

export const getItemConnectionCopy = ({
  connectionStatus,
  name,
  lastSuccessfulConnection,
  lastFailedConnection,
}: CryptoAccount): string => {
  const time = formatDistanceToNowStrict(
    utcToZonedTime(
      parseISO(
        connectionStatus === CryptoAccountConnectionStatus.DISCONNECTED
          ? lastFailedConnection
          : lastSuccessfulConnection,
      ),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    ),
  )

  if (connectionStatus === CryptoAccountConnectionStatus.DISCONNECTED) {
    return `We lost the connection to your account ${time} ago. Please reconnect to ${name}.`
  }

  return `Good connection as of ${time} ago.`
}
