import React from 'react'
import {Markup} from 'interweave'

import styles from './_taxBreakdownEducation.module.scss'
import {SaleFederalLongTermCapitalGainsTax as locale} from './locale'

type Props = {
  isBracket: boolean;
}

const SaleFederalLongTermCapitalGainsTaxEdu = ({isBracket}: Props): JSX.Element => {
  if (isBracket) {
    return (
      <div className={styles.bracketContainer}>
        <p className={styles.educationText}>
          <Markup noWrap content={locale.BRACKET} />
        </p>
      </div>
    )
  }

  return (
    <div className={styles.educationContainer}>
      <p className={styles.educationText}>
        <Markup noWrap content={locale.PARAGRAPH_1} />
      </p>
      <p className={styles.educationText}>
        <Markup noWrap content={locale.PARAGRAPH_2} />
      </p>
      <p className={styles.educationText}>
        <Markup noWrap content={locale.PARAGRAPH_3} />
      </p>
    </div>
  )
}

export default SaleFederalLongTermCapitalGainsTaxEdu
