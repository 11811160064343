import {FeatureFlagName, FeatureFlagResponse, FeatureFlags} from './types'

import {apiSlice} from 'store/main/apiSlice'

const featureFlagApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<FeatureFlags, void>({
      query: () => 'v1/toggles/',
      transformResponse: (
        response: FeatureFlagResponse[],
      ) => {
        const flags = response.reduce((obj, curr) => ({...obj, [curr.name]: curr.toggleActive}), {})

        return {
          conciergeClientProfile: !!flags[FeatureFlagName.CONCIERGE_CLIENT_PROFILE],
          registrationReferralCode: !!flags[FeatureFlagName.REGISTRATION_REFERRAL_CODE],
          rsuSupport: !!flags[FeatureFlagName.RSU_SUPPORT],
          pbsRedesign: !!flags[FeatureFlagName.PBS_REDESIGN],
          referralCode: !!flags[FeatureFlagName.REFERRAL_CODE],
          twoFactor: !!flags[FeatureFlagName.TWO_FACTOR],
          fileAutoCategorization: !!flags[FeatureFlagName.FILE_AUTO_CATEGORIZATION],
          suggestedUploads: !!flags[FeatureFlagName.SUGGESTED_UPLOADS],
          prefillForSelfEmployment: !!flags[FeatureFlagName.PREFILL_FOR_SELF_EMPLOYMENT],
        }
      },
    }),
    /**
     * NOTE: This query is only used in FeatureProxy component!
     */
    getFeatureFlagByName: builder.query<boolean, string>({
      query: (flagName: FeatureFlagName) => ({
        url: (`v1/toggles/${flagName}/`),
        validateStatus: (): boolean => true, // Set all status codes to valid
      }),
      transformResponse: (response: FeatureFlagResponse) => !!response.toggleActive,
    }),
  }),
})

export const {
  useGetFeatureFlagsQuery,
  useLazyGetFeatureFlagsQuery,
  useGetFeatureFlagByNameQuery,
  reducer: featureFlagApiSliceReducer,
  reducerPath: featureFlagApiSliceReducerPath,
  middleware: featureFlagApiSliceMiddleware,
  util: featureFlagApiSliceUtil,
  endpoints: featureFlagApiSliceEndpoints,
} = featureFlagApiSlice
