import HttpClient, {FetchResponse} from '../helpers/httpClient'
import {IntegerRangeField} from '../common'
import {AnyFirmV2, FirmType} from '../firm'
import {ServicesKey} from '../service'

export type Education = {
  level: string;
  university: string;
  degree: string;
  honors: string;
  major: string;
}

type BulkError = {
  error: boolean;
}

export type AdviserProposalSummary = {
  recommendationId?: string;
  id: string;
  name: string;
  displayType: 'Financial Firm' | 'Legal Firm' | 'Tax Firm';
  routePath: string;
}

export type RangeValue = {
  lower: Nullable<number>;
  upper: Nullable<number>;
}

export enum ProfileServiceNeedsFormKey {
  advisersSeeking = 'advisersSeeking',
  financialServiceInterests = 'financialServiceInterests',
  taxServiceInterests = 'taxServiceInterests',
  legalServiceInterests = 'legalServiceInterests',
  statesForEstatePlanning = 'statesForEstatePlanning',
  otherServiceInterests = 'otherServiceInterests',
}

export type ProfileServiceNeedsFormData = {
  [ProfileServiceNeedsFormKey.advisersSeeking]: FirmType[];
  [ProfileServiceNeedsFormKey.financialServiceInterests]?: ServicesKey[];
  [ProfileServiceNeedsFormKey.taxServiceInterests]?: ServicesKey[];
  [ProfileServiceNeedsFormKey.legalServiceInterests]?: ServicesKey[];
  [ProfileServiceNeedsFormKey.statesForEstatePlanning]?: string[];
  [ProfileServiceNeedsFormKey.otherServiceInterests]?: string[];
}

export type RecommendationSnippet = {
  createdAt: string;
  firm: string;
  hasViewed?: boolean;
  id: string;
  matchRequestId: string;
  status: ProposalStatus;
  updatedAt: string;
  serviceOfferingIds?: string[];
}

export type Recommendation = RecommendationSnippet & {
  adviser: string;
  // TODO(raylen): these fields will no longer be in use
  // TODO: to get firms the id will be in the 'firm' field
  suggestedFirm: AnyFirmV2;
}

export type SubmittedRecommendation = {
  firmId: string;
  matchRequestId: string;
  firmServiceId: string;
  firmName: string;
  vertical: string;
  source: string;
}

export type FirmServicePairing = {
  firm: string;
  firmServiceId?: string;
}

export type AdviserProposal = ProfileServiceNeedsFormData & {
  id: string;
  recommendations: Recommendation[];
  adviserSeeking: FirmType;
  adviserSearchWorkflow: string;
  createdAt: string;
  isClosed: boolean;
  firmServiceOfferingRecommendations: {
    [key: string]: Recommendation[];
  };

  // financial
  alternativeInvestmentOptionality: Optional<string>;

  // tax
  internationalTaxSituation: Optional<boolean>;

  // legal
  legalCostComfort: Optional<IntegerRangeField>;
  countriesForEstatePlanning: Optional<string[]>;
  otherServiceInterests: string[];

  // general
  hasAdviserLocationPreference: Optional<boolean>;
  hasAdditionalIndustryConsideration: Optional<boolean>;
  secondaryIndustry: string;
  limit?: number; // max number of recommendations per vertical
}

export async function createAdviserProposals(
  adviserSearchWorkflow: string,
  advisersSeeking: string[],
  proposalData: Partial<AdviserProposal>,
  shouldSkipFailureReport?: boolean,
): Promise<FetchResponse<AdviserProposal[]>> {
  const bodyData = advisersSeeking.map((adviserSeeking) => ({
    ...proposalData,
    adviserSearchWorkflow,
    adviserSeeking,
  }))
  const param = shouldSkipFailureReport ? '?should_send_failure_report=False' : ''
  const url = `/v2/matching/adviser-search/search/${param}`

  return HttpClient.post<AdviserProposal[]>(url, bodyData)
}

// The Array<AdviserProposal> you get back are all the OPEN proposals (due to sending is_closed=false).
// Each AdviserProposal occurrence in the list corresponds to a particular firm vertical (FINANCIAL, TAX, LEGAL).
// This means that you can only receive a MAX of THREE AdviserProposal instances in the returned list.
export async function findOpenAdviserProposals(workflowId: string): Promise<FetchResponse<AdviserProposal[]>> {
  const url = `/v2/matching/adviser-search/search/?adviser_search_workflow__pid=${workflowId}&is_closed=false`
  return HttpClient.get<AdviserProposal[]>(url)
}

async function updateDataOnProposal(
  proposalId: string, data: KeyedObject,
): Promise<FetchResponse<RecommendationSnippet>> {
  return HttpClient.patch<RecommendationSnippet>(`/v1/matching/adviser-search/adviser-match/${proposalId}/`, data)
}

export enum ProposalStatus {
  PENDING = 'PENDING',
  DENIED = 'DENIED',
  PROGRESS = 'PROGRESS',
  SAVED = 'SAVED',
  ACCEPTED = 'ACCEPTED',
}

export async function rejectAdviserProposal(recId: string): Promise<FetchResponse<RecommendationSnippet>> {
  return updateDataOnProposal(recId, {status: ProposalStatus.DENIED})
}

export async function saveAdviserProposal(recId: string): Promise<FetchResponse<RecommendationSnippet>> {
  return updateDataOnProposal(recId, {status: ProposalStatus.SAVED})
}

export async function pendingAdviserProposal(recId: string): Promise<FetchResponse<RecommendationSnippet>> {
  return updateDataOnProposal(recId, {status: ProposalStatus.PENDING})
}

export async function setReadReceiptForRecommendation(recId: string): Promise<FetchResponse<RecommendationSnippet>> {
  return updateDataOnProposal(recId, {hasViewed: true})
}

export async function readAndSaveRecommendation(recId: string): Promise<FetchResponse<RecommendationSnippet>> {
  return updateDataOnProposal(recId, {hasViewed: true, status: ProposalStatus.SAVED})
}

export async function readAndRejectRecommendation(recId: string): Promise<FetchResponse<RecommendationSnippet>> {
  return updateDataOnProposal(recId, {hasViewed: true, status: ProposalStatus.DENIED})
}

export async function bulkRejectAdviserProposals(proposalIds: string[]): Promise<BulkError> {
  const response = await Promise.all(proposalIds.map((proposalId) => rejectAdviserProposal(proposalId)))
  const errors = response.filter(({error}) => Boolean(error))
  return {error: errors.length > 0}
}

export async function bulkSetReadReceiptForRecommendations(recommendationIds: string[]): Promise<BulkError> {
  const response = await Promise.all(recommendationIds.map((id) => setReadReceiptForRecommendation(id)))
  const errors = response.filter(({error}) => Boolean(error))
  return {error: errors.length > 0}
}

export async function getProposalById(id: string): Promise<FetchResponse<AdviserProposal>> {
  return HttpClient.get<AdviserProposal>(`/v2/matching/adviser-search/search/${id}/`)
}
