import React from 'react'
import cx from 'classnames'

import {useScenarioSlider} from '../hooks/ScenarioSliderProvider'
import styles from '../_results.module.scss'

const Slides = {
  ONE: 0,
  TWO: 1,
}

const ScenarioPagination = (): JSX.Element => {
  const {
    currentSlideIndex,
    handleSlideChange,
  } = useScenarioSlider()

  return (
    <div className={styles.pagination}>
      <button type="button" className={styles.paginationBtn} onClick={(): void => handleSlideChange(Slides.ONE)}>
        <span className={cx(styles.dot, {[styles.active]: currentSlideIndex === Slides.ONE})} />
      </button>
      <button type="button" className={styles.paginationBtn} onClick={(): void => handleSlideChange(Slides.TWO)}>
        <span className={cx(styles.dot, {[styles.active]: currentSlideIndex === Slides.TWO})} />
      </button>
    </div>
  )
}

export default React.memo(ScenarioPagination)
