export type AppStrings = KeyedObject<KeyedObject<KeyedObject<string>>>

export enum DynamicStringsApp {
  AGREEMENTS_AND_DISCLOSURES = 'agreements_and_disclosures',
  ADVISOR_SESSIONS = 'advisor_sessions',
  DASHBOARD = 'dashboard',
  FIRM_PROFILE = 'firm_profile',
  GLOBAL_ONBOARDING = 'global_onboarding',
  GRANT_ADMIN = 'grant_admin',
  MARKETPLACE_ONBOARDING = 'marketplace_onboarding',
  NOTIFICATION_CENTER = 'notification_center',
  PBS = 'pbs',
  PROFILE = 'profile',
  REGISTRATION = 'registration',
  REVIEW = 'review',
  TAX_PLANNING = 'tax_planning',
  SCENARIOS = 'scenarios',
  EQUITY_INSIGHTS_PLANNING = 'equity_insights_planning',
}
