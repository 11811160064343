import React, {KeyboardEvent, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import cx from 'classnames'

import Button from '../button/Button'
import Icon, {IconName} from '../icon/Icon'

import styles from './_modal.module.scss'

type DOMBodyPortalProps = {
  children: React.ReactNode | React.ReactChild[];
  id: string;
  open: boolean;
}

/*
  This component is used when you want to render a div element OUTSIDE of the DOM heirarchy of the
  parent component -- in this case, that is our #root div element where our React app is injected into.
  Specifically, it will create your div element within the body element as a SIBLING of #root.
 */
const DOMBodyPortal = ({children, id, open}: DOMBodyPortalProps): React.ReactPortal => {
  const [portalRootElement] = useState<HTMLElement>(document.createElement('div'))

  useEffect(() => {
    portalRootElement.setAttribute('id', id)
    document.body.appendChild(portalRootElement) // Append portal element to the <body>

    return (): void => {
      document.body.removeChild(portalRootElement) // Cleanup -- remove portal from <body> on unmount
    }
  }, [portalRootElement, id])

  useEffect(() => {
    if (!open) {
      portalRootElement.setAttribute('aria-hidden', 'true')
    } else {
      portalRootElement.removeAttribute('aria-hidden')
    }
  }, [open, portalRootElement])

  // For more info on Portals: https://reactjs.org/docs/portals.html
  return createPortal(children, portalRootElement) // inject children into the portal element
}

export type Props = {
  /** Contents of the modal. */
  children: React.ReactNode | React.ReactNode[];
  /** Space-separated list of classes to pass to the modal card. */
  className?: string;
  /** @ignore */
  'data-cy'?: string;
  /** Unique identifier for the modal. */
  id: string;
  /** Layout variants for the modal. */
  intent?:
  'bottomHalfScreen' |
  'fullScreen' |
  'fullScreenMobile' |
  'bottomHalfScreenMobile' |// TODO(steph): rename
  'rightHalfScreen';
  /** whether to display full sized modal on desktop */
  largeDesktopSize?: boolean;
  /** Callback to invoke when backdrop or close button is clicked. */
  onBackdropClick?: () => void;
  /** Callback to invoke when close button is clicked. */
  onClose?: () => void;
  /** Callback to invoke on when modal is displayed. */
  onOpen?: () => void;
  /** Whether to display the modal. Use `open` rather than conditionally rendering the modal for accessibility. */
  open: boolean;
  /** Whether to remove default padding from the modal card. */
  unpadded?: boolean;
  /** Space-separated list of classes to pass to the modal backdrop. */
  wrapperClassName?: string;
  /** Callback to invoke when the Esc key is pressed */
  onEscKey?: () => void;

  /** Optional color style for the close button */
  closeButtonColor?: string;
}

// TODO(Steph): Capture focus
export default function Modal(
  {
    className,
    children,
    'data-cy': dataCy = '',
    id,
    intent,
    largeDesktopSize = false,
    onBackdropClick,
    onClose,
    onOpen,
    open = false,
    unpadded,
    wrapperClassName,
    onEscKey,
    closeButtonColor,
  }: Props,
): JSX.Element {
  useEffect(() => {
    /**
     * `Overflow: hidden` has no effect on touch devices
     * `Position fixed; width: 100%` is necessary to hide overflow on touch devices
     * Otherwise, background scrolls behind the modal, impeding the user's ability to scroll within a modal and
     * unintentionally altering their background scroll position
     * However, `position: fixed` causes scroll position to be lost entirely, so `top` is utilized to preserve scroll
     * position and return the user to that position on close
     */
    if (open) {
      document.body.setAttribute(
        'style',
        `overflow: hidden; position: fixed; width: 100%; top: -${window.scrollY || 0}px`,
      )
      if (onOpen) onOpen()
    }

    return (): void => {
      /**
       * Unblock scrolling and return the user to their scroll position on close or unmount for cases that navigate to
       * a new page from within an open modal
       */
      const scrollY = (document.body.style.top || '0').replace('px', '')
      if (open) {
        document.body.removeAttribute('style')
        window.scrollTo(0, Number(scrollY) * -1)
      }
    }
  }, [onClose, onOpen, open])

  const handleKey = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Escape' && onEscKey) onEscKey()
  }

  return (
    <DOMBodyPortal id={`portal:${id}`} open={open}>
      <div
        aria-describedby={id}
        data-cy="modal-wrapper"
        className={cx(styles.modalWrapper, intent && styles[intent], open && styles.open, wrapperClassName)}
      >
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <div
          aria-modal="true"
          className={cx(
            styles.modalCard,
            open && styles.open,
            largeDesktopSize && styles.largeDesktopSize,
            unpadded && styles.noPadding,
            !intent && !unpadded && styles.defaultPadding,
            intent && styles[intent],
            className,
          )}
          data-cy={dataCy}
          id={id}
          role="dialog"
          onKeyDown={handleKey}
          tabIndex={-1}
        >
          {/* eslint-enable */}
          {(onBackdropClick || onClose) && (
            <Button
              className={styles.closeModalButton}
              fill={false}
              onClick={onBackdropClick || onClose}
              data-cy={`${id}-close-modal`}
            >
              <Icon name={IconName.MenuCancel} fill={closeButtonColor ?? '#9CA6B4'} size={16} />
            </Button>
          )}
          {children}
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
        <div className={cx(styles.modalBackDrop, open && styles.open)} onClick={onBackdropClick} />
      </div>
    </DOMBodyPortal>
  )
}
