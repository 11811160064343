import {useEffect, useState} from 'react'

import {UseTaxProfileV2ReturnType} from '../../equity-insights-results/types'

import {CURRENT_TAX_YEAR} from 'core/constants'
import {TaxProfile, TaxProfileResidency} from 'models'
import {useGetTaxProfileResidencyQuery, useGetTaxProfilesQuery} from 'store/tax-profile'

export const useTaxProfileV2 = (companyId: string): UseTaxProfileV2ReturnType => {
  const [taxProfile, setTaxProfile] = useState<Nullable<TaxProfile>>(null)
  const [taxProfileResidency, setTaxProfileResidency] = useState<Nullable<TaxProfileResidency>>(null)

  const {data: taxProfileData} = useGetTaxProfilesQuery(companyId)
  const {data: taxProfileResidencyData} = useGetTaxProfileResidencyQuery({taxYear: taxProfileData?.taxYear ?? CURRENT_TAX_YEAR, companyId})

  useEffect(() => {
    if (taxProfileData) {
      setTaxProfile(taxProfileData)
    }
  }, [taxProfileData])

  useEffect(() => {
    if (taxProfileData && taxProfileResidencyData) {
      setTaxProfileResidency(taxProfileResidencyData)
    }
  }, [taxProfileData, taxProfileResidencyData])

  return {
    taxProfile,
    setTaxProfile,
    taxProfileResidency,
    setTaxProfileResidency,
  }
}
