import React, {
  Dispatch,
  createContext,
  useReducer,
} from 'react'

import {ToastProps} from './types'

type AddToast = {
  type: 'ADD_TOAST';
  payload: ToastProps;
}

type CloseToast = {
  type: 'CLOSE_TOAST';
  payload: string;
}

type RemoveToast = {
  type: 'REMOVE_TOAST';
  payload: string;
}

export type ActionType = AddToast | CloseToast | RemoveToast

const toastsReducer: React.Reducer<ToastProps[], ActionType> = (state: ToastProps[], action: ActionType) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return [action.payload, ...state]
    case 'CLOSE_TOAST':
      return state.map((obj) => {
        if (obj.id === action.payload) {
          return {...obj, open: false}
        }
        return obj
      })
    case 'REMOVE_TOAST':
      return state.filter(({id}) => id !== action.payload)
    default: {
      return state
    }
  }
}

export const ToastContext = createContext<ToastProps[]>([])
export const ToastDispatchContext = createContext<Dispatch<ActionType>>({} as Dispatch<ActionType>)

export type Props = {
  children: React.ReactNode;
}

const initialState: ToastProps[] = []

export const ToastProvider = ({children}: Props): JSX.Element => {
  const [toasts, dispatch] = useReducer(toastsReducer, initialState)
  return (
    <ToastContext.Provider value={toasts}>
      <ToastDispatchContext.Provider value={dispatch}>
        {children}
      </ToastDispatchContext.Provider>
    </ToastContext.Provider>
  )
}
