import React from 'react'

// TODO(Zare): Add lazy imports and update tests
import {
  ExerciseAndSaleFederalMedicareTaxEdu,
  ExerciseAndSaleFederalOrdinaryIncomeTaxEdu,
  ExerciseAndSaleFederalSocialSecurityTaxEdu,
  ExerciseAndSaleStateOrdinaryIncomeTaxEdu,
  ExerciseFederalAlternativeMinimumTaxEdu,
  ExerciseStateAlternativeMinimumTaxEdu,
  SaleFederalLongTermCapitalGainsTaxEdu,
  SaleFederalNetInvestmentIncomeTaxEdu,
  SaleStateLongTermCapitalGainsTaxEdu,
} from './Education'
import {EducationTypes} from './types'

type Props = {
  educationType: string;
  isBracket?: boolean;
  normalTaxRate?: number;
  currentTaxRate?: number;
  socialSecurityThreshold?: number;
}

const TaxBreakdownEducationContainer = ({
  educationType,
  isBracket = false,
  normalTaxRate = 0,
  currentTaxRate = 0,
  socialSecurityThreshold = 0,
}: Props): JSX.Element | null => {
  switch (educationType) {
    case EducationTypes.EXERCISE_AND_SALE_STATE_ORDINARY_INCOME_TAX:
      return <ExerciseAndSaleStateOrdinaryIncomeTaxEdu />
    case EducationTypes.EXERCISE_AND_SALE_FEDERAL_MEDICARE_TAX:
      return <ExerciseAndSaleFederalMedicareTaxEdu normalTaxRate={normalTaxRate} />
    case EducationTypes.EXERCISE_AND_SALE_FEDERAL_ORDINARY_INCOME_TAX:
      return <ExerciseAndSaleFederalOrdinaryIncomeTaxEdu isBracket={isBracket} />
    case EducationTypes.EXERCISE_AND_SALE_FEDERAL_SOCIAL_SECURITY_TAX:
      return (
        <ExerciseAndSaleFederalSocialSecurityTaxEdu
          currentTaxRate={currentTaxRate}
          socialSecurityThreshold={socialSecurityThreshold}
        />
      )
    case EducationTypes.EXERCISE_FEDERAL_ALTERNATIVE_MINIMUM_TAX:
      return <ExerciseFederalAlternativeMinimumTaxEdu />
    case EducationTypes.EXERCISE_STATE_ALTERNATIVE_MINIMUM_TAX:
      return <ExerciseStateAlternativeMinimumTaxEdu />
    case EducationTypes.SALE_FEDERAL_LONG_TERM_CAPITAL_GAINS_TAX:
      return <SaleFederalLongTermCapitalGainsTaxEdu isBracket={isBracket} />
    case EducationTypes.SALE_FEDERAL_NET_INVESTMENT_INCOME_TAX:
      return <SaleFederalNetInvestmentIncomeTaxEdu />
    case EducationTypes.SALE_STATE_LONG_TERM_CAPITAL_GAINS_TAX:
      return <SaleStateLongTermCapitalGainsTaxEdu />
    default:
      return null
  }
}

export default React.memo(TaxBreakdownEducationContainer)
