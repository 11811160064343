import {createSelector} from '@reduxjs/toolkit'

import {CryptoAccount, CryptoAccountConnectionStatus} from './types'
import {getItemConnectionCopy} from './utils'

import {apiSlice} from 'store/main/apiSlice'
import {showDefaultErrorAlert, showDefaultSuccessAlert, showSuccessAlert} from 'store/alert/actions'
import {Analytics} from 'modules/analytics'

const cryptoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCryptoAccounts: builder.query<CryptoAccount[], void>({
      query: () => 'v2/crypto/account/',
      providesTags: ['CryptoAccount'],
    }),
    createVezgoAccount: builder.mutation<{accountId: string}, string>({
      query: (accountId) => ({
        url: 'v2/crypto/account/',
        method: 'POST',
        body: {accountId},
      }),
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled
          Analytics.walletConnected()
          dispatch(showSuccessAlert('Success! You added crypto account'))
        } catch (e) {
          Analytics.connectWalletFailed()
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['CryptoAccount', 'Entity'],
    }),
    updateCryptoAccount: builder.mutation<CryptoAccount, Pick<CryptoAccount, 'id'> & Partial<CryptoAccount>>({
      query: ({id, ...rest}) => ({
        url: `v2/crypto/account/${id}/`,
        method: 'PATCH',
        body: {...rest},
      }),
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled
          dispatch(showDefaultSuccessAlert('crypto account', 'updated'))
        } catch (e) {
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['CryptoAccount', 'Entity'],
    }),
    deleteCryptoAccount: builder.mutation<void, string>({
      query: (id) => ({
        url: `v2/crypto/account/${id}/`,
        method: 'DELETE',
      }),
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled
          dispatch(showDefaultSuccessAlert('crypto account', 'removed'))
        } catch (e) {
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['CryptoAccount', 'Entity'],
    }),
  }),
})

export const {
  useGetAllCryptoAccountsQuery,
  useLazyGetAllCryptoAccountsQuery,
  useUpdateCryptoAccountMutation,
  useDeleteCryptoAccountMutation,
  useCreateVezgoAccountMutation,
} = cryptoApiSlice

// TODO(zare): Move selectors to selectors.ts
const cryptoSelector = cryptoApiSlice.endpoints.getAllCryptoAccounts.select()
export const selectAllCryptoAccounts = createSelector(cryptoSelector, ({data: accounts}) => accounts?.map((account) => ({
  ...account,
  statusCopy: {
    connection: getItemConnectionCopy(account),
    verification: 'Some tokens within this wallet were not recognized. We are working with our data provider to resolve the issue.',
  },
})) || [])

export const selectNumberOfDisconnectedCryptoAccounts = createSelector(
  selectAllCryptoAccounts,
  (accounts) => accounts.filter((account) => account.connectionStatus === CryptoAccountConnectionStatus.DISCONNECTED).length || 0,
)
