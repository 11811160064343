const REGEX_INTEGERS = new RegExp('^[0-9]\\d*$') // positive numbers (including 0)
const ZIPCODE_LENGTH = 5

/** Validate that ZIP code is a 5-digit number  */
export const validateZipCode = (_name: string, value: string): Nullable<string> => {
  const isValid = REGEX_INTEGERS.test(value) && value.length === ZIPCODE_LENGTH

  if (!isValid) {
    return 'Zip code must be a five-digit number'
  }

  return null
}
