import {
  ScenarioResultType,
  TaxBracketOrdering,
  TaxScenarioResults,
  getTaxTotals,
  getTaxTypeTotals,
} from 'models'

export type ScenarioResultSummary = {
  netProfit: number;
  saleQuantity: number;
  scenarioType: ScenarioResultType;
}

export function processScenarioResults(results?: TaxScenarioResults): ScenarioResultSummary[] {
  return Object.entries(results ?? {})
    .sort(([scenarioTypeA], [scenarioTypeB]) => (scenarioTypeA < scenarioTypeB ? -1 : 1))
    .map(([scenarioType, scenario]) => {
      const saleQuantity = scenario.grantSummary.sale.reduce((accu, {sharesSoldForScenario}) => (
        accu + sharesSoldForScenario), 0)
      const grossProceeds = scenario.grantSummary.sale.reduce((accu, {saleProceedsForScenario}) => (
        accu + saleProceedsForScenario), 0)
      const exerciseCost = scenario.grantSummary.purchase.reduce((accu, {exerciseCostForScenario}) => (
        accu + exerciseCostForScenario), 0)

      const purchaseDate = new Date(scenario.purchaseDate)
      const purchaseYear = purchaseDate.getFullYear()
      const saleDate = new Date(scenario.saleDate)
      const saleYear = saleDate.getFullYear()
      const totalExerciseTax = !scenario.purchaseDate || !scenario[purchaseYear] ? 0 : getTaxTotals(
        scenario[purchaseYear],
        true,
      ).total
      const totalSaleTax = getTaxTotals(
        scenario[saleYear],
        false,
      ).total
      const rsuIncomeTaxes = getTaxTypeTotals(
        scenario[saleYear],
        TaxBracketOrdering.RSU_VESTING,
        false,
      ).total

      return {
        netProfit: Math.round(grossProceeds) - Math.round(exerciseCost) - totalExerciseTax - totalSaleTax - rsuIncomeTaxes,
        saleQuantity,
        scenarioType: scenarioType as ScenarioResultType,
      }
    })
}

/**
 * Right now there's a difference in SummaryHeader copies between the Dashboard Widget and My Equity
 * on the Company page. Eventually we'll have the same labels for all SummaryScenario types.
 * We use this constant to reflect different label copies in SummaryHeader.
 *
 * Once the product and design give us a green light to have the same label across all pages, we'll get rid
 * of this const.
 */
export const CustomSummaryScenarioLabels: Readonly<Record<ScenarioResultType, string>> = {
  HOLD_OVER_A_YEAR: 'Exercise 1 year before selling',
  HOLD_UNDER_A_YEAR: 'Wait to exercise',
  AMT_BREAKEVEN: 'AMT break even', // todo: copy for this case
}
