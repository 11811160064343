import {EnvConfig, Environment} from './types'

const result: EnvConfig = {
  ENV: Environment.GCLOUD_STAGE,
  HELLO_SIGN_CLIENT_ID: '6e3a9551a30438c7b7a53e44bc87287b',
  LINKEDIN_CLIENTID: '77wmpdotlfurkm',
  MIXPANEL_ACTIVE: true,
  MIXPANEL_TOKEN: '17151ba843dbc436c62f705a5afb2ad5',
  SENTRY_HOST: 'https://c1e4c277fb5d41208b939caf2e4eae5a@sentry.io/1250562',
  OPEN_HUBSPOT_CHAT: false,
  SENTRY_TRACING_RATE: 0.5,
  VEZGO_CLIENT_ID: '1gse42uph5u09he2sujges929q',
  HUBSPOT_QUESTIONNAIRE_FORM_ID: '7293a247-5bda-4ced-884e-72440339f19d',
}

export default result
