import React from 'react'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {useMediaQuery} from 'react-responsive'

import {ScenarioFlyoutProvider} from '../../equity-insights-results/ScenarioTaxBreakdown'

import {ScenarioHeader} from './ScenarioHeader'
import {ScenarioInsight} from './ScenarioInsight'
import ScenarioDetails from './ScenarioDetails'
import {ScenarioWarnings} from './ScenarioWarnings'

import {Tab, Tabs} from 'core/ui'
import {useGetLiquidityEventQuery} from 'store/scenario'
import {LiquidityEventSelectors} from 'store/liquidity-event'
import {ScenarioResultType} from 'models/liquidityEvent/types'
import {ColumnContainer} from 'core/styled/blocks'

export const ScenarioResultsOverview = (): JSX.Element => {
  const isMobile = useMediaQuery({maxWidth: 640})
  const oldScenarios = useSelector(LiquidityEventSelectors.getScenarios)

  const {companyId} = useParams<{companyId: string}>()
  const {data: liquidityEvent, isSuccess} = useGetLiquidityEventQuery(companyId)

  if (!liquidityEvent?.scenarios?.length || !isSuccess || isEmpty(oldScenarios)) return <></>

  return (
    <ScenarioFlyoutProvider scenarioResultsData={Object.values(oldScenarios)}>
      <StyledContainer>
        <Tabs>
          {liquidityEvent.scenarios.map(({
            type, name, isGrantRSUOnly, nameShort, order,
          }): JSX.Element => (
            <Tab title={isMobile ? nameShort : name} key={name}>
              <ScenarioHeader scenarioType={type} />
              <OrderingContainer>
                <ScenarioWarnings scenarioType={type} />
                <ScenarioDetails scenarioType={type} isGrantRSUOnly={isGrantRSUOnly} />
              </OrderingContainer>
              <ScenarioInsight scenarioType={type as unknown as ScenarioResultType} scenarioIndex={order} />
            </Tab>
          ))}
        </Tabs>
      </StyledContainer>
    </ScenarioFlyoutProvider>
  )
}

const StyledContainer = styled.div(({theme}) => `
  width: 100%;
  background-color: ${theme.colors.white100};
  border-top: 1px solid ${theme.colors.darkBlue100};
  border-bottom: 1px solid ${theme.colors.darkBlue100};


  ${theme.above(theme.breakpoints.largePhone)} {
    border-radius: ${theme.spacing.m};
    border-left: 1px solid ${theme.colors.darkBlue100};
    border-right: 1px solid ${theme.colors.darkBlue100};
  }
`)

const OrderingContainer = styled(ColumnContainer)(({theme}) => `
    flex-direction: column-reverse;
  ${theme.above(theme.breakpoints.largePhone)} {
    flex-direction: column;
  }
`)
