import React from 'react'

import {TaxesList} from './components'
import styles from './_scenarioTaxBreakdown.module.scss'

import {TaxScenario, TaxTypeTotals} from 'models'

type Props = {
  scenario: TaxScenario;
  taxTotalsFromExercise: Nullable<TaxTypeTotals>;
  taxTotalsFromSale: TaxTypeTotals;
  index: number;
}
const ScenarioTaxBreakdownCard = ({
  scenario,
  taxTotalsFromExercise,
  taxTotalsFromSale,
  index,
}: Props): JSX.Element => (
  <div className={styles.taxBreakdownCollapsible}>
    {taxTotalsFromExercise && (
      <TaxesList
        index={index}
        scenario={scenario[new Date(scenario.purchaseDate).getFullYear()]}
        purchaseDate={new Date(scenario.purchaseDate).getFullYear().toString()}
        taxTotalsFromEvent={taxTotalsFromExercise}
        isExercise
      />
    )}
    <TaxesList
      index={index}
      scenario={scenario[new Date(scenario.saleDate).getFullYear()]}
      purchaseDate={new Date(scenario.saleDate).getFullYear().toString()}
      taxTotalsFromEvent={taxTotalsFromSale}
    />
  </div>
)

export default React.memo(ScenarioTaxBreakdownCard)
