import isEmpty from 'lodash/isEmpty'

import {ErrorCapture} from '../utils/ErrorCapture'

import BaseConfig from './base'
import LocalEnv from './local'
import DemoEnv from './demo'
import GcloudStageEnv from './gcloud_stage'
import GcloudProdEnv from './gcloud_prod'
import TestEnv from './tests'
import FeatureEnv from './gcloud_feature'
import {EnvConfig, Environment} from './types'

// TODO: deprecate in favor of Environment enum
const APP_ENVS = {
  LOCAL: Environment.LOCAL,
  DEMO: Environment.DEMO,
  GCLOUD_STAGE: Environment.GCLOUD_STAGE,
  GCLOUD_PROD: Environment.GCLOUD_PROD,
  TEST: Environment.TEST,
  GCLOUD_FEATURE: Environment.GCLOUD_FEATURE,
}

const CONFIGS = {
  [Environment.LOCAL]: LocalEnv,
  [Environment.DEMO]: DemoEnv,
  [Environment.GCLOUD_STAGE]: GcloudStageEnv,
  [Environment.GCLOUD_PROD]: GcloudProdEnv,
  [Environment.TEST]: TestEnv,
  [Environment.GCLOUD_FEATURE]: FeatureEnv,
}

const reactEnv: string | undefined = import.meta.env.REACT_APP_ENV || import.meta.env.STORYBOOK_ENV

if (!reactEnv || isEmpty(reactEnv)) {
  ErrorCapture(
    'REACT_APP_ENV missing',
    'Environment variable REACT_APP_ENV is not set. If developing locally set REACT_APP_ENV=local in your bashrc or zshrc file', /* eslint-disable-line max-len */
  )
}

const config = (reactEnv ? CONFIGS[reactEnv] : null) as Nullable<EnvConfig>
if (isEmpty(config)) {
  ErrorCapture(
    'Invalid environment variable',
    `${reactEnv ?? ''} is not a valid environment`,
  )
}

if (config) {
  config.RELEASE_ID = import.meta.env.REACT_APP_RELEASE_ID
  config.MIXPANEL_PROXY_DOMAIN = import.meta.env.REACT_APP_MIXPANEL_PROXY_DOMAIN
}

const completedConfig = {...config, ...BaseConfig}

export default completedConfig
export {APP_ENVS}
export {Environment}
