import {createApi} from '@reduxjs/toolkit/query/react'

import baseQuery from 'store/main/baseQuery'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'ClientProfile',
    'CryptoAccount',
    'Company',
    'Entity',
    'HarnessFile',
    'PlaidItem',
    'Property',
    'User',
    'Service',
    'HarnessFileWithMeta',
    'TaxProfile',
    'TaxProfileResidency',
    'Scenario',
    'LiquidityEvent',
    'Questionnaire',
    'DeleteTaskFile',
    'AuthFactors',
    'Engagement',
    'Task',
    'SuggestedUploads',
  ],
  endpoints: () => ({}),
})
