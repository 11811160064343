import React from 'react'
import styled, {useTheme} from 'styled-components'

import Button from 'core/components/button/Button'

import {H3} from 'core/theme'
import {ColumnContainer, RowContainer} from 'core/styled/blocks'

const StyledHeader = styled(H3)``

const StyledSubheader = styled.p(({theme}) => `
  margin-bottom: ${theme.spacing.m};
`)

const Container = styled(ColumnContainer)(({
  theme: {
    spacing, colors, above, breakpoints,
  },
}) => `
  background-color: ${colors.white100};
  padding: ${spacing.l};
  border-radius: 8px;
  ${above(breakpoints.aboveMobile)} {
    flex-direction: row;
  }
`)

const CurvedImg = styled.img`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
`

const PaddedColumnContainer = styled(ColumnContainer)(({theme: {spacing}}) => `
  padding: ${spacing.m};
`)

const DetailsRow = styled(RowContainer)(({theme: {colors, spacing}}) => `
  padding: ${spacing.s} ${spacing.m} ${spacing.xs};
  border-radius: 4px;
  background: ${colors.grey100};
  color: ${colors.darkBlue300};
`)

const ImgContainer = styled.div``

type Props = {
  image: string;
  title: string;
  subTitle: React.ReactNode;
  cta: VoidFunc;
  ctaText: string;
  description: string;
  time: string;
  price: string;
  className?: string;
}

export default function MarketingWidget({
  image,
  title,
  subTitle,
  cta,
  ctaText,
  description,
  price,
  time,
  className,
}: Props): JSX.Element {
  const {spacing} = useTheme()
  return (
    <Container gap={spacing.s} className={className}>
      <ImgContainer>
        <CurvedImg src={image} alt="ad" />
      </ImgContainer>
      <PaddedColumnContainer gap={spacing.s} justify="space-between">
        <ColumnContainer>
          <StyledHeader>{title}</StyledHeader>
          <StyledSubheader>{subTitle}</StyledSubheader>
          <p>{description}</p>
        </ColumnContainer>
        <ColumnContainer gap={spacing.s}>
          <DetailsRow justify="space-between">
            <p>
              {time}
              {' '}
              session
            </p>
            <p>
              $
              {price}
            </p>
          </DetailsRow>
          <Button
            intent="tertiary"
            onClick={cta}
            label={ctaText}
          />
        </ColumnContainer>
      </PaddedColumnContainer>
    </Container>
  )
}
