import {
  combineReducers, createReducer, isAnyOf, unwrapResult,
} from '@reduxjs/toolkit'
import {format} from 'date-fns'
import {PersistConfig, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as CompanyActions from './actions'
import {AddCompanyFormType, Tasks} from './types'

import {Company} from 'models/equityLiquidity'

const userCompanies = createReducer([] as Company[], (builder) => {
  builder.addCase(CompanyActions.getUserCompanies.fulfilled, (_, action) => unwrapResult(action).data ?? [])
})

const companyErrors = createReducer('', (builder) => {
  builder.addMatcher(isAnyOf(
    CompanyActions.getUserCompanies.rejected,
  ),
  () => '')
  // TODO(zare): Will be implemented as part of global banner implementation
})

const companyFormDefaultData = {
  companyName: '',
  stillEmployed: true,
  hasBeenEmployed: false,
  employmentEndDate: '',
  postEmploymentOptionExpiration: null,
  price: undefined,
  priceType: '409A',
  pricingDate: format(new Date(), 'MM/dd/yyyy'),
  grantType: '',
}

const addCompanyForm = createReducer(companyFormDefaultData as AddCompanyFormType, (builder) => {
  builder
    .addCase(CompanyActions.setEmploymentStatus, (state, action) => {
      const {stillEmployed, hasBeenEmployed, employmentEndDate} = unwrapResult(action)

      return {
        ...state,
        stillEmployed: !!stillEmployed,
        hasBeenEmployed: !stillEmployed ? !!hasBeenEmployed : false,
        employmentEndDate: !stillEmployed && hasBeenEmployed ? employmentEndDate : '',
        postEmploymentOptionExpiration: stillEmployed ? null : state.postEmploymentOptionExpiration,
      }
    })
    .addCase(CompanyActions.setPrice, (state, action) => ({
      ...state,
      ...unwrapResult(action),
      pricingDate: format(new Date(), 'MM/dd/yyyy'),
    }))
    .addCase(CompanyActions.clearProgress, () => companyFormDefaultData)
    .addMatcher(isAnyOf(
      CompanyActions.setCompanyName,
      CompanyActions.setExpirationPostEmployment,
      CompanyActions.setGrantType,
    ), (state, action) => ({
      ...state,
      ...unwrapResult(action),
    }))
})

const currentStep = createReducer(1, (builder) => {
  builder
    .addCase(CompanyActions.navigateNextStep, (state) => state + 1)
    .addCase(CompanyActions.navigatePreviousStep, (state) => state - 1)
    .addCase(CompanyActions.clearProgress, () => 1)
})

const isSubmitInProgress = createReducer(false, (builder) => {
  builder
    .addCase(CompanyActions.showSubmitLoader, () => true)
    .addCase(CompanyActions.clearTasks, () => false)
})

const tasks = createReducer<Tasks | null>(null, (builder) => {
  builder
    .addCase(CompanyActions.setTasks.fulfilled, (_, action) => unwrapResult(action))
    .addCase(CompanyActions.clearTasks, () => null)
})

const equityModal = createReducer(false, (builder) => {
  builder
    .addCase(CompanyActions.toggleEquityModal, (state) => !state)
    .addCase(CompanyActions.closeEquityModal, () => false)
})

const companyReducer = combineReducers({
  userCompanies,
  companyErrors,
  addCompanyForm,
  currentStep,
  isSubmitInProgress,
  tasks,
  equityModal,
})

export type CompanyReducer = ReturnType<typeof companyReducer>;
const persistConfig: PersistConfig<CompanyReducer> = {
  key: 'company',
  storage,
  blacklist: ['userCompanies', 'companyErrors', 'isSubmitInProgress'],
}

export default persistReducer(persistConfig, companyReducer)
