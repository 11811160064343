import React, {useEffect} from 'react'
import {Redirect, useHistory, useLocation} from 'react-router-dom'

import {UNAUTHENTICATED_ROUTE_PATH_SET} from '../const'

import {AppSession, AppStorage} from 'core/utils'
import {RoutePaths} from 'core/constants'
import {logOut} from 'models'
import {useQuery} from 'core/hooks'

const THREE_SECONDS_IN_MS = 3000

type Props = {
  session: AppSession;
  storage: AppStorage;
  children: JSX.Element;
}

function SessionWatcher({
  session, storage, children, isAcceptInvite,
}: Props & {isAcceptInvite: boolean}): JSX.Element {
  const history = useHistory()

  useEffect(() => {
    const agent: ReturnType<typeof setInterval> = setInterval(() => {
      if (!session.userIsAuthenticated && !isAcceptInvite) {
        session.endSession()
        history.push(RoutePaths.LOGIN)
      }
    }, THREE_SECONDS_IN_MS)

    return (): void => {
      clearInterval(agent)
    }
  }, [history, session, storage, isAcceptInvite])

  return children
}

export const AuthenticatedRoutes = ({session, storage, children}: Props): Nullable<JSX.Element> => {
  const history = useHistory()
  const location = useLocation()
  const param = useQuery('token')
  const isAcceptInvite = location.pathname === RoutePaths.ACCEPT_INVITE

  if (!session.userIsAuthenticated) {
    session.endSession()

    // special case, a route that's available for both authenticated & unauthenticated -> don't redirect
    // EmailVerification component will redirect if needed
    if (location.pathname === RoutePaths.EMAIL_VERIFY) return null

    // Only push to LOGIN if the user is attempting to hit an AUTHENTICATED ROUTE path WHILE NOT AUTHENTICATED
    if (!UNAUTHENTICATED_ROUTE_PATH_SET.has(location.pathname)) {
      const {pathname, search} = location
      const redirectUrl = encodeURIComponent(`${pathname}${search}`)

      history.push({
        pathname: RoutePaths.LOGIN,
        // We often deep link to authenticated URLs while a user session does not exist (e.g. email links).
        // So set the redirect query arg to that destination. It will be checked upon successful login by the LoginView.
        ...(location.pathname !== RoutePaths.ADVISER_SEARCH ? {search: `redirect=${redirectUrl}`} : {}),
      })
    }

    return null
  }

  const logOutUser = async (): Promise<void> => {
    await logOut()
  }

  if (isAcceptInvite && session.userIsAuthenticated) {
    session.endSession()
    void logOutUser()

    return <Redirect to={`${RoutePaths.ACCEPT_INVITE}?token=${param}`} />
  }

  return (
    <SessionWatcher session={session} storage={storage} isAcceptInvite={isAcceptInvite}>
      {children}
    </SessionWatcher>
  )
}
