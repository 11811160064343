import {EnvConfig, Environment} from './types'

const result: EnvConfig = {
  ENV: Environment.GCLOUD_FEATURE,
  HELLO_SIGN_CLIENT_ID: '6e3a9551a30438c7b7a53e44bc87287b',
  LINKEDIN_CLIENTID: '77wmpdotlfurkm',
  MIXPANEL_ACTIVE: true,
  MIXPANEL_TOKEN: '17151ba843dbc436c62f705a5afb2ad5',
  SENTRY_HOST: 'https://250de239fc7f428fa90b04e7de0249e8@o155756.ingest.sentry.io/6731217',
  OPEN_HUBSPOT_CHAT: false,
  VEZGO_CLIENT_ID: '1gse42uph5u09he2sujges929q',
  HELLO_SIGN_SKIP_DOMAIN_VERIFY: true,
  HUBSPOT_QUESTIONNAIRE_FORM_ID: '7293a247-5bda-4ced-884e-72440339f19d',
}

export default result
