/* eslint-disable max-len */
export default {
  FORM_TITLE: 'Tax Profile',
  CANCEL_CTA: 'Cancel',
  SAVE_CTA: 'Save & Rerun',
  FILING_STATUS: 'Filing Status',
  FILING_STATUS_TOOLTIP: 'Your tax filing status helps estimate your tax liability.',
  HOUSEHOLD_INCOME: 'Pre-tax Income',
  HOUSEHOLD_INCOME_TOOLTIP: 'Your pre-tax income includes wages, bonuses, capital gains, dividends and interest.',
  ZIP_CODE: 'Zip code',
  ZIP_CODE_TOOLTIP: 'Your zip code helps estimate your state tax liability when exercising, vesting, purchasing and/or selling your options and shares.',
  NETWORK_ERROR: 'There was an error updating your tax profile. Please refresh the page and try again.',
  TAX_PROFILE_UPDATED: 'Last updated on [DATE]',
}
