/* eslint-disable max-len */
import React from 'react'

type Props = {
  fill?: string;
  size?: string | number;
}

export default function Info(
  {
    fill = '#7390B0',
    size = 20,
    ...rest
  }: Props,
): JSX.Element {
  return (
    <svg height={size} width={size} viewBox="0 0 20 20" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 17.313a7.312 7.312 0 100-14.625 7.312 7.312 0 000 14.624zM10 19a9 9 0 100-18 9 9 0 000 18z" fill={fill} />
      <path d="M9.1 9.1a.9.9 0 011.8 0v4.5a.9.9 0 01-1.8 0V9.1zM9.1 5.95a.9.9 0 111.8 0 .9.9 0 01-1.8 0z" fill={fill} />
    </svg>
  )
}
