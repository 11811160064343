import {IntegerRangeField} from '../common'
import {FirmType} from '../firm'

import {FirmService} from 'api/service'
import HttpClient, {FetchResponse} from 'models/helpers/httpClient'
import {RangeValue} from 'models/adviserProposal'

export const DEFAULT_COUNTRY = 'United States'

export enum WindfallTiming {
  PAST = 'It already happened',
  SHORT_TERM = 'Next 12 months',
  LONG_TERM = 'More than a year from now',
  NOT_SURE = 'I’m not sure',
}

export enum WindfallTypes {
  CRYPTO = 'Crypto',
  EQUITY_LIQUIDITY_EVENT = 'Equity Liquidity Event',
  INHERITANCE = 'Inheritance',
  MAJOR_PROMOTION = 'Major Promotion',
  NONE = 'None',
  // the below EQUITY_EVENTs should be categorized as EQUITY_LIQUIDITY_EVENT
  // when ingested by the API
  EQUITY_EVENT_IPO = 'Equity IPO',
  SECONDARY_SALE = 'Secondary sale',
  FINANCIAL_PLANNING = 'Financial planning',
  EXERCISE_OPTIONS = 'Exercise options',
  ACQUISITION = 'Acquisition',
  VESTING = 'Vesting',
  RETIREMENT_PLANNING = 'Retirement planning',
}

export enum EmploymentType {
  EMPLOYEE = 'Employee',
  EQUITY_PARTNER = 'Equity Partner',
  FOUNDER = 'Founder',
  RETIRED = 'Retired',
  SMALL_BUSINESS_OWNER = 'Small Business Owner',
}

export enum KeyLifeEvents {
  CHANGING_JOBS = 'Changing Jobs',
  NEW_JOB = 'New Job',
  PROMOTION = 'Promotion',
  STARTING_COMPANY = 'Starting a Company',
  RETIRED = 'Becoming Retired',
  ALREADY_RETIRED = 'Already Retired',
  CHILDREN = 'Having Children',
  DIVORCED = 'Getting Divorced',
  MOVING = 'Moving International/Between States',
  MARRIED = 'Getting Married',
  BUYING_HOUSE = 'Buying a House',
  BUSINESS_ACCOUNT = 'Business account',
}

export enum HouseholdIndustries {
  BIOTECH_PHARMA = 'Biotech & Pharma',
  ENTERTAINMENT_MEDIA = 'Entertainment & Media',
  HEALTHCARE = 'Health Care',
  LAW = 'Law',
  CONSULTING = 'Management Consulting',
  REAL_ESTATE = 'Real Estate',
  SPORTS = 'Sports',
  TECHNOLOGY = 'Technology',
  ASSET_MANAGEMENT = 'Asset Management',
  OTHER = 'Other/Not Listed',
}

export enum Subindustries {
  VENTURE_CAPITAL = 'Venture Capital',
  PRIVATE_EQUITY = 'Private Equity',
  HEDGE_FUNDS = 'Hedge Funds',
  MUTUAL_FUNDS = 'Mutual Funds & ETFs',
  REITS = 'REITS',
  OTHER = 'Other',
}

export enum SmallBusinessFunding {
  CROWDFUNDED = 'Crowdfunded',
  FRIENDS_AND_FAMILY = 'Friends & Family',
  LOAN = 'Small Business Loan',
  PROFESSIONAL_INVESTORS = 'Professional Investors',
  SELF_FUNDED = 'Self Funded',
  OTHER = 'Other',
}

export enum IlliquidAssets {
  COLLECTIBLES = 'Collectibles',
  COMMODITIES = 'Commodities',
  CRYPTO = 'Cryptocurrency',
  PRIVATE_FUNDS = 'Private funds',
  PRIVATE_SHARES = 'Private stock/options',
  REAL_ESTATE = 'Real estate',
  NONE = 'None',
}

export enum PersonalFinancesManagement {
  PERSONAL_FINANCE_APP = 'Personal finance apps',
  SELF_DIRECTED_INVESTMENTS = 'Self-directed investments',
  TRACK_IN_SPREADSHEETS = 'Track in spreadsheets',
  DIY_TAX_PREP_SOFTWARE = 'DIY tax prep software',
  TAX_ADVISOR = 'Tax Advisor',
  FINANCIAL_PLANNER = 'Financial planner',
  WEALTH_MANAGER = 'Wealth manager',
  NONE = 'Nothing right now',
}

export enum MetadataCtaField {
  EQUITY = 'hasDismissedEquityCta',
  BALANCE_SHEET = 'hasDismissedUpdateBalanceSheetCta',
  PBS_CONNECT_ACCOUNTS = 'hasDismissedPbsConnectAccounts',
  PBS_TRY_EQUITY_TAX_PLANNING = 'hasDismissedPbsTryEquityTaxPlanning',
  PBS_FEEDBACK = 'hasDismissedPbsFeedback',
  PBS_INVITE_FRIEND = 'hasDismissedPbsInviteFriend',
  PBS_STARTUP_EQUITY = 'hasDimissedPbsStartupEquity',
  PBS_WEEKLY_UPDATE = 'hasDismissedPbsWeeklyUpdate',
  DASHBOARD_INVITE_FRIEND = 'hasDismissedDashboardInviteFriend',
  DASHBOARD_CONNECT_ACCOUNT = 'hasDismissedDashboardConnectAccount',
  SCENARIO_INVITE_FRIEND = 'hasDismissedScenarioInviteFriend',
  DASHBOARD_FEEDBACK = 'hasDismissedDashboardFeedback',
  SCENARIO_FEEDBACK = 'hasDismissedScenarioFeedback',
  NW_CHART_FLAT_VALUE = 'hasDismissedFlatNetWorthMessage',
}

export enum ProfileFormKey {
  keyLifeEvents = 'keyLifeEvents',
  majorFinancialChanges = 'majorFinancialChanges',
  otherMilestones = 'otherMilestones',
  otherWindfalls = 'otherWindfalls',
  businessAccount = 'businessAccount',
  windfallAmount = 'windfallAmount',
  windfallTiming = 'windfallTiming',
  expectsAdditionalInvestableAssets = 'expectsAdditionalInvestableAssets',

  employmentType = 'employmentType',
  otherEmployment = 'otherEmployment',
  smallBusinessType = 'smallBusinessType',
  smallBusinessRevenue = 'smallBusinessRevenue',
  smallBusinessFunding = 'smallBusinessFunding',
  householdIndustries = 'householdIndustries',
  subindustries = 'subindustries',
  otherHouseholdIndustries = 'otherHouseholdIndustries',
  householdIncome = 'householdIncome',
  totalInvestableAssets = 'totalInvestableAssets',
  otherAssets = 'otherAssets',
  illiquidAssets = 'illiquidAssets',
  otherIlliquidAssets = 'otherIlliquidAssets',
  statesForTaxFiling = 'statesForTaxFiling',
  k1sForTaxFiling = 'k1sForTaxFiling',
  personalFinancesManagement = 'personalFinancesManagement',
  otherPersonalFinancesManagement = 'otherPersonalFinancesManagement',

  adviserAgePreferences = 'adviserAgePreferences',
  hasAdviserLocationPreference = 'hasAdviserLocationPreference',
  zipcodePreferences = 'zipcodePreferences',
  country = 'country',
  isUSCitizen = 'isUsCitizen',
}

export enum OnboardingGetStartedOption {
  FIND_ADVISOR = 'findAdvisor',
  FIND_ADVISOR2 = 'findAdvisor2',
  ADVISOR_SESSION = 'advisorSession',
  EQUITY_TAX = 'equityTax',
  BALANCE_SHEET = 'balanceSheet',
}

export type ProfileOnboardingData = {
  [ProfileFormKey.keyLifeEvents]: Nullable<string[]>; // MILESTONE_EVENTS
  [ProfileFormKey.majorFinancialChanges]: WindfallTypes[];
  [ProfileFormKey.otherMilestones]: string;
  [ProfileFormKey.otherWindfalls]: string;
  [ProfileFormKey.businessAccount]: Nullable<boolean>;
  [ProfileFormKey.expectsAdditionalInvestableAssets]: Nullable<boolean>;
  [ProfileFormKey.windfallAmount]: Nullable<IntegerRangeField>;
  [ProfileFormKey.windfallTiming]: WindfallTiming[];
  [ProfileFormKey.employmentType]: EmploymentType[];
  [ProfileFormKey.otherEmployment]: string;
  [ProfileFormKey.smallBusinessType]: string;
  [ProfileFormKey.smallBusinessRevenue]: Nullable<IntegerRangeField>;
  [ProfileFormKey.smallBusinessFunding]: SmallBusinessFunding[];
  [ProfileFormKey.householdIndustries]: HouseholdIndustries[];
  [ProfileFormKey.subindustries]: Subindustries[];
  [ProfileFormKey.otherHouseholdIndustries]: string;
  [ProfileFormKey.householdIncome]: Nullable<number>;
  [ProfileFormKey.totalInvestableAssets]: Nullable<number>;
  [ProfileFormKey.otherAssets]: Nullable<number>;
  [ProfileFormKey.illiquidAssets]: Nullable<IlliquidAssets[]>;
  [ProfileFormKey.otherIlliquidAssets]: string;
  [ProfileFormKey.statesForTaxFiling]?: RangeValue;
  [ProfileFormKey.k1sForTaxFiling]?: RangeValue;
  [ProfileFormKey.otherPersonalFinancesManagement]: Nullable<number>;
  [ProfileFormKey.personalFinancesManagement]: Nullable<PersonalFinancesManagement[]>;

  [ProfileFormKey.adviserAgePreferences]: number[];
  [ProfileFormKey.hasAdviserLocationPreference]: Nullable<boolean>;
  [ProfileFormKey.zipcodePreferences]: {zipCodePreference: number}[];
  [ProfileFormKey.country]: string;
  [ProfileFormKey.isUSCitizen]: boolean;
}

export type ClientProfileMeta = {
  hasViewedCompleteProfile: boolean;
  hasViewedProfileWelcome: boolean;
  hasClosedNotificationCenter: boolean;
  hasDismissedUpdateBalanceSheetCta?: boolean;
  privateEquityValuationSelection?: string;
  hasDismissedFirstTimeCompanyModal?: boolean;
  hasDismissedPbsGetStartedModal?: boolean;
  hasDismissedPbsConnectAccounts?: boolean;
  hasDismissedFlatNetWorthMessage?: boolean;
  hasDismissedDashboardConnectAccount?: boolean;
  hasDismissedDashboardLiquidityEventMarketplaceCta?: boolean;
  hasViewedFullPageOnboarding?: boolean;
  onboardingGetStartedChoice?: OnboardingGetStartedOption;
  // onboardingSelectedChoice is non-normalized version of onboardingGetStartedChoice currently used for ordering services
  onboardingSelectedChoice?: OnboardingGetStartedOption;
  didBeginShortOnboarding?: boolean;
  didCompleteShortOnboarding?: boolean;
  globalOnboardingCompleteDate?: Date;
  // Triggers on last question on profile onboarding FORM_CITIZEN or FORM_ZIP_CODE
  didCompleteProfile?: boolean;
  // TODO: remove myFirmsCollapsedItems when deprecating services_priorities_v3
  myFirmsCollapsedItems: FirmType[];
  myServicesCollapsedItems: string[];
  hasViewedAdvisorSessionsPage?: boolean;
  hasDismissedPbsFeedback?: boolean;
  hasDismissedPbsInviteFriend?: boolean;
  hasDimissedPbsStartupEquity?: boolean;
  hasDismissedPbsWeeklyUpdate?: boolean;
  hasDismissedPbsWeeklyUpdateModal?: boolean;
  // Assets&Debts
  hasDismissedEquityCta?: boolean;
  hasDismissedInviteFriendInsight?: boolean;
  // scenario
  hasDismissedScenarioInviteFriend?: boolean;
  hasDismissedScenarioConnectAccounts?: boolean;
  hasJoinedBookkeepingWaitlist?: boolean;
  hasCollapsedCompletedEngagements?: boolean;
  hasViewedInvitedClientWelcomeModal?: boolean;
  hasDiscoveredReferralProgram?: boolean;
  twoFactorDontRemind?: boolean;

  hasDismissedTaxDocumentInsight?: boolean;
  hasDismissedPersonalRecordsInsight?: boolean;
}

type TesterHash = {
  personalBalanceSheet?: string;
  primary?: string;
}

export type ClientProfile = ProfileOnboardingData & {
  additionalAdviserExpertise: string[];
  anonymousNetWorthPid?: string;
  anonymousOpportunityPid?: string;
  connectionToHw: string;
  email: string;
  firstName: string;
  hasHomeownership: Nullable<boolean>;
  hasPrivateCompanyEquity: Nullable<boolean>;
  hasWorkedWithAdviser: Nullable<boolean>;
  id: string;
  isEmailVerified: boolean;
  isMarried: Nullable<boolean>;
  isQualifiedClient: boolean;
  lastName: string;
  meta: Partial<ClientProfileMeta>;
  optInMarketing: boolean;
  origin: string;
  percentageOfIncomeInRetirementSavings: Nullable<number>;
  percentageOfIncomeSpent: Nullable<number>;
  percentageOfIncomeToCharity: Nullable<IntegerRangeField>;
  percentageOfInvestableAssetsInCash: Nullable<IntegerRangeField>;
  phoneNumber: string;
  previousAdviserTypes: Nullable<string[]>;
  prefineryCode: string;
  referralCode: string;
  referralCodeTerms: string;
  prefineryTesterHash: TesterHash;
}

export async function saveClientProfile(
  data: Partial<ClientProfile>,
  clientId: string,
): Promise<FetchResponse<ClientProfile>> {
  return HttpClient.post<ClientProfile>(`/v1/client/${clientId}/profile/`, data)
}

export async function getClientProfile(
  clientId: string,
  ignoredStatusCodes: number[] = [],
): Promise<FetchResponse<ClientProfile>> {
  return HttpClient.get<ClientProfile>(`/v1/client/${clientId}/profile/`, {}, {
    captureExceptions: true,
    ignoredStatusCodes,
  })
}

export type ReferralCodeResult = {
  code: string;
  terms: string;
}

type ReferralCodeError = {
  readonly referralCode: string[];
}

type ReferralCodeResponse = {
  result: Nullable<ReferralCodeResult>;
  errorReason: Nullable<string>;
  error: boolean;
}

export async function postReferralCode(referralCode: string, clientId: string): Promise<ReferralCodeResponse> {
  const {data, error} = await HttpClient.post<ClientProfile, ReferralCodeError>(`/v1/client/${clientId}/profile/`,
    {referralCode})

  if (error) {
    return {
      result: null,
      errorReason: error.data?.referralCode[0] ?? null,
      error: true,
    }
  }

  return {
    result: {
      code: data?.referralCode as string,
      terms: data?.referralCodeTerms as string,
    },
    errorReason: null,
    error: false,
  }
}

export enum ClientSalesStatus {
  ACCEPTED = 'ACCEPTED',
  PASS = 'PASS',
  POSTPONED = 'POSTPONED',
  MEET_WITH_ADVISORS = 'MEET_WITH_ADVISORS',
  SCHEDULING_IN_PROGRESS = 'SCHEDULING_IN_PROGRESS',
  EVALUATION_MEETINGS = 'EVALUATION_MEETINGS',
  SEND_AVAILABILITY_TO_CONCIERGE = 'SEND_AVAILABILITY_TO_CONCIERGE',
}

export enum ClientSalesStatusSource {
  ADVISOR_SESSIONS = 'ADVISOR_SESSIONS',
  MARKETPLACE = 'MARKETPLACE',
}

export type ClientAdviserSalesStatus = {
  client: string;
  displayAcceptedFirmMsg: boolean;
  firm: string;
  firmName: string;
  firmType: FirmType;
  matchRequest: string;
  nonResponsive: boolean;
  status: ClientSalesStatus;
  firmServices?: string[];
  firmService?: FirmService;
  pid: string;
  source: ClientSalesStatusSource;
}

type BulkCreateSalesStatusResponse = {
  deals: ClientAdviserSalesStatus[];
  pids: string[];
}

type SetSalesStatusFields = {
  clientId: string;
  status: ClientSalesStatus;
}

type BulkCreateSalesStatusFields = SetSalesStatusFields & {
  selections: {
    firmId: string;
    matchRequestId: string;
    firmServiceId: string;
    source: string;
  }[];
}

export async function bulkCreateClientAdviserSalesStatus({
  clientId,
  selections,
  status,
}: BulkCreateSalesStatusFields): Promise<FetchResponse<BulkCreateSalesStatusResponse>> {
  return HttpClient.post<BulkCreateSalesStatusResponse>(`/v2/client/${clientId}/sales-status/`, {
    status,
    selections,
  })
}

export async function getClientAdviserSalesStatus(
  clientId: string,
): Promise<FetchResponse<ClientAdviserSalesStatus[]>> {
  return HttpClient.get<ClientAdviserSalesStatus[]>(`/v2/client/${clientId}/sales-status/`)
}
