/* eslint-disable max-len */
import {BreakdownCategory} from './types'

import {ScenarioResultType} from 'models'

export default {
  DESKTOP_LABEL: {
    [BreakdownCategory.RSU_INCOME_TAX]: 'RSU Income tax',
    [BreakdownCategory.EXERCISE_COST]: 'Exercise cost',
    [BreakdownCategory.EXERCISE_TAX]: 'Exercise taxes',
    [BreakdownCategory.GROSS_PROCEEDS]: 'Total Proceeds',
    [BreakdownCategory.REMAINING_EQUITY]: 'Remaining Equity',
    [BreakdownCategory.SALE_TAX]: 'Sale taxes',
    [BreakdownCategory.SALE_PROFIT]: 'Net profit',
    [BreakdownCategory.AMT]: 'AMT',
  },
  SUB_LABEL: {
    [BreakdownCategory.GROSS_PROCEEDS]: 'before costs & taxes',
  },
  MOBILE_SUB_LABEL: {
    [BreakdownCategory.GROSS_PROCEEDS]: {
      LINE_1: 'before costs',
      LINE_2: '& taxes',
    },
  },
  MOBILE_LABEL: {
    [BreakdownCategory.RSU_INCOME_TAX]: 'RSU Income tax',
    [BreakdownCategory.EXERCISE_COST]: 'Exercise cost',
    [BreakdownCategory.EXERCISE_TAX]: 'Exercise taxes',
    [BreakdownCategory.GROSS_PROCEEDS]: 'Total Proceeds',
    [BreakdownCategory.REMAINING_EQUITY]: 'Remaining Equity',
    [BreakdownCategory.SALE_TAX]: 'Sale taxes',
    [BreakdownCategory.SALE_PROFIT]: 'Net profit',
    [BreakdownCategory.AMT]: 'AMT',
  },
  MOBILE_PROCEEDS_LABEL: {
    [BreakdownCategory.GROSS_PROCEEDS]: {
      LINE_1: 'Total',
      LINE_2: 'Proceeds',
    },
    [BreakdownCategory.REMAINING_EQUITY]: {
      LINE_1: 'Remaining',
      LINE_2: 'Equity',
    },
  },
  SUBHEADER: 'profits after costs & taxes',
  TOOLTIP_CATEGORY_LABEL: {
    [BreakdownCategory.RSU_INCOME_TAX]: 'Est. RSU income tax',
    [BreakdownCategory.EXERCISE_COST]: 'Exercise cost',
    [BreakdownCategory.EXERCISE_TAX]: 'Est. exercise tax',
    [BreakdownCategory.REMAINING_EQUITY]: 'Remaining Equity',
    [BreakdownCategory.SALE_TAX]: 'Est. taxes from sale',
    [BreakdownCategory.SALE_PROFIT]: 'Est. profits from sale',
    [BreakdownCategory.AMT]: 'Est. alternative minimum tax',
  },
  TOOLTIP_CATEGORY_DESCRIPTION: {
    [BreakdownCategory.RSU_INCOME_TAX]: 'When RSUs vest, the amount gained is counted as income and contributes to ordinary income taxes.',
    [BreakdownCategory.EXERCISE_COST]: 'The upfront cost to exercise options, based on the amount of options exercised and the exercise price of each grant.',
    [BreakdownCategory.EXERCISE_TAX]: 'View breakdown on taxes from exercise below.',
    [BreakdownCategory.REMAINING_EQUITY]: 'The fair market value before any costs or taxes of all remaining equity that is not sold at the time of sale.',
    [BreakdownCategory.SALE_TAX]: 'View breakdown on taxes from sale below.',
    [BreakdownCategory.SALE_PROFIT]: 'Your total take home amount, after costs and taxes have been considered.',
    [BreakdownCategory.AMT]: 'View AMT breakdown on taxes from exercise below',
  },
  SUMMARY_ROWS: {
    EXERCISE_TODAY: 'First, exercise <b>[QUANTITY] options</b> today',
    EXERCISE_AFTER_LOCKUP: 'First, exercise <b>[QUANTITY] options</b> after IPO lockup',
    EXERCISE_ON_DATE: 'First, exercise <b>[QUANTITY] options</b> on [DATE]',
    SELL_IN_MONTH_YEAR: 'Sell [QUANTITY] shares in [MONTH_YEAR]',
    SELL_AFTER_ONE_YEAR: 'Then, sell <b>[QUANTITY] shares</b> after 1 year',
    SELL_IN_YEAR: 'Then, sell <b>[QUANTITY] shares</b> in 1 year',
    SELL_AFTER_LOCKUP: 'Then, sell <b>[QUANTITY] shares</b> after lockup period',
    SELL_AFTER_GOING_PUBLIC: 'Then, sell <b>[QUANTITY] shares</b> after going public',
    SELL_IMMEDIATELY: 'Then, sell <b>[QUANTITY] shares</b> immediately',
    OPTIONS_LEFT: 'You’ll have [#|unexercised option|unexercised options] left over',
    OPTIONS_SHARES_LEFT: 'You’ll have [#|unexercised option|unexercised options] and [#|unsold share|unsold shares] left over',
    SHARES_LEFT: 'You’ll have [#|unsold share|unsold shares] left over',
    NO_EQUITY_LEFT: 'You’ll have no equity left over',
  },
  SUMMARY_MODAL: {
    [ScenarioResultType.HOLD_OVER_A_YEAR]: {
      OVERLINE: 'Minimize Regular Taxes',
      EXERCISE_HEADER: 'Exercise [QUANTITY] options on [DATE]',
      EXERCISE_SUBHEADER: 'The below table is a snapshot of your [COMPANY] equity on [DATE].',
      SALE_HEADER: 'Sell [QUANTITY] shares on [DATE]',
      SALE_SUBHEADER: 'The below table is a snapshot of your [COMPANY] equity on [DATE], 1 year after exercising all options in this scenario.',
      SALE_SUBHEADER_NO_EXERCISE: 'The below table is a snapshot of your [COMPANY] equity on [DATE]. In this scenario, you only sell the shares that have been held for 1+ year.',
    },
    [ScenarioResultType.HOLD_UNDER_A_YEAR]: {
      OVERLINE: 'Minimize Investment Risk',
      EXERCISE_HEADER: 'Exercise [QUANTITY] options on [DATE]',
      EXERCISE_SUBHEADER: 'The below table is a snapshot of your [COMPANY] equity on [DATE].',
      SALE_HEADER: 'Sell [QUANTITY] shares on [DATE]',
      SALE_SUBHEADER: 'The below table is a snapshot of your [COMPANY] equity on [DATE].',
      SALE_SUBHEADER_NO_EXERCISE: 'The below table is a snapshot of your [COMPANY] equity in [DATE]. In this scenario, you sell all vested shares immediately [after IPO|after the lockup period].',
    },
    [ScenarioResultType.AMT_BREAKEVEN]: {
      OVERLINE: 'MINIMIZE AMT',
      EXERCISE_HEADER: 'Exercise [QUANTITY] options on [DATE]',
      SALE_SUBHEADER: 'The below table is a snapshot of your [COMPANY] equity on [DATE], [EVENT]',
      SALE_HEADER: 'Sell [QUANTITY] shares on [DATE]',
      EXERCISE_SUBHEADER: '<b>Why do we recommend this?</b> <br> The below table is a snapshot of your [COMPANY] equity on [DATE]. In this scenario, we recommend exercising the maximum amount of options without triggering Alternative Minimum Tax (AMT). To maximize options exercised, we prioritize grants with the lowest spread (current fair market value - exercise price). Learn more about AMT ',
      SALE_SUBHEADER_NO_EXERCISE: '', // dummy
    },
  },
  SCENARIO_WARNINGS: {
    OPTIONS_NOT_VESTED: {
      LINK_TEXT: 'leave us feedback.',
      BTN_TEXT: 'public offering date',
      INFO: '<b>No options available for exercise.</b> No options have vested by this scenario’s exercise date. To project a scenario, try changing the ',
      INFO_2: ' or if you think there’s been a mistake, ',
    },
    OPTIONS_EXPIRED: {
      LINK_TEXT: 'leave us feedback.',
      BTN_TEXT: 'grant information',
      INFO: '<b>Options expired.</b> All unexercised options have expired by this scenario’s exercise date. If you think there has been a mistake, check your ',
      INFO_2: ' or ',
    },
    OPTIONS_CANCELLED: {
      LINK_TEXT: 'leave us feedback.',
      BTN_TEXT: 'grant information',
      INFO: '<b>Options cancelled.</b> All unexercised options have been cancelled by this scenario’s exercise date. If you think there has been a mistake, check your ',
      INFO_2: ' or ',
    },
  },
  AFTER_LOCKUP: 'after lockup period.',
  AFTER_PUBLIC: 'after going public.',
  AFTER_ONE_YEAR: 'after 1 year.',
  IN_ONE_YEAR: 'in 1 year.',
  AMT_DISCLAIMER: 'AMT support coming soon. Federal and state AMT is not calculated into the estimated taxes above.',
  UNSUPPORTED_ASSET_DISCLAIMER: '<b>[ASSET] support coming soon.</b> [ASSET2] not factored into your sale proceeds or taxes. Upon the above sale date you will have [ASSET_AMOUNT] remaining.',
  AMT_EXERCISE_WARNING: 'No options eligible for exercise in this scenario because the AMT threshold has been met by prior exercises.',
  DOUBLE_TRIGGER_TOOLTIP: `
    Most private companies have a “double-trigger provision” on RSUs they grant.
    Double-trigger RSU grants aren’t taxable until two specific events occur,
    as opposed to most public company RSUs in which shares are taxable immediately upon vesting.
    The two events that are usually considered as part of the double-trigger provision are:
    <ul>
      <li>The RSU grant time-based vesting schedule</li>
      <li>The company has a liquidity event (IPO, acquisition, etc).</li>
    </ul>
  `,
}
