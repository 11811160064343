import React, {useCallback} from 'react'
import styled, {useTheme} from 'styled-components'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import BackButton from 'core/components/button/BackButton'

import {ResultsSection} from '../equity-insights-results/components'
import AdvisorServices from '../../modules/advisor-services/components/Services'

import {LiquidityEventSummaryBar, ScenarioResultsOverview} from './components'
import {EquityGuide} from './components/EquityGuide'
import {TaxPlanning} from './components/TaxPlanning'
import {ScenarioInsights} from './components/Insights'
import {EquityInsightsProvider} from './hooks'

import {showDefaultErrorAlert} from 'store/alert/actions'
import {RoutePaths} from 'core/constants'
import {Header} from 'core/ui'
import {useGetCurrentUserProfileQuery, useUpdateClientProfileMutation} from 'store/profile'
import {useGetFeatureFlagsQuery} from 'store/feature-flag'
import {useGetLiquidityEventQuery} from 'store/scenario'
import {LoadingSpinner} from 'core/components'
import {InviteFriendInsight} from 'modules/insights/InviteFriendInsight'

export const EquityInsightsResults = (): JSX.Element => {
  const {companyId} = useParams<{companyId: string}>()
  const {data: profile, isLoading: isLoadingProfile} = useGetCurrentUserProfileQuery()
  const {data: flags, isLoading: isLoadingFlags} = useGetFeatureFlagsQuery()
  const [updateClientProfile] = useUpdateClientProfileMutation()

  const {data: liquidityEvent, isLoading, isFetching} = useGetLiquidityEventQuery(companyId)

  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()

  const handleNetworkError = useCallback(() => {
    dispatch(showDefaultErrorAlert())
  }, [dispatch])

  if (isLoading || isFetching || isLoadingProfile || isLoadingFlags) {
    return <LoadingSpinner size="large" />
  }

  return (
    <EquityInsightsProvider>
      <PageContainer>
        <Header
          title="Your equity tax scenarios"
          description="The below scenarios are based on your tax profile, but is not fully comprehensive.
          Talk with an equity expert to create a personalized, holistic plan to fit your personal milestones,
          cash flow, and risk tolerance."
          descriptionColor={theme.colors.darkBlue500}
          NavigationComponent={(
            <BackButton
              handleClick={(): void => history.push(RoutePaths.ADD_EQUITY_COMPANY_ENTITY.replace(':companyId', companyId))}
              text={liquidityEvent?.company.name || 'Back'}
            />
          )}
          ActionsComponent={<LiquidityEventSummaryBar handleNetworkError={handleNetworkError} />}
        />
        <StyledContentContainer>
          <ScenarioResultsOverview />
          <EquityGuide />
          {flags?.referralCode ? (
            <>
              {profile && !profile.meta.hasDismissedScenarioInviteFriend && (
                <InviteFriendInsight
                  onDismiss={(): void => {
                    void updateClientProfile({clientId: profile?.id, meta: {hasDismissedScenarioInviteFriend: true}})
                  }}
                />
              )}
            </>
          ) : (
            <>
              {!profile?.meta.hasDismissedScenarioInviteFriend && <ScenarioInsights.InviteFriend />}
            </>
          )}

          <TaxPlanning />
          {profile && !profile.meta.hasDismissedScenarioConnectAccounts && <ScenarioInsights.ScenarioConnectAccount />}
          <ResultsSection
            header="Explore other advisor services"
            subheader="We’ll help you find and evaluate top-tier advisory firms"
            padded
          >
            <AdvisorServices />
          </ResultsSection>
        </StyledContentContainer>
      </PageContainer>
    </EquityInsightsProvider>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding-bottom: 40px;
`
const StyledContentContainer = styled.div(({theme}) => `
  width: 100%;
  max-width: ${theme.maxWidth};
  align-self: center;
  gap: 40px;
  display: flex;
  flex-direction: column;

  ${theme.between(theme.breakpoints.largePhone, '1440px')}{
    padding-left: ${theme.spacing.xl};
    padding-right: ${theme.spacing.xl};
  }
`)
