/* eslint-disable no-underscore-dangle */
import Cookie from 'js-cookie'
import {setUser} from '@sentry/react'

import {StorageKey} from '../constants'

import {AppStorage} from './AppStorage'

export class AppSession {
  _storage: AppStorage

  constructor(storage: AppStorage) {
    this._storage = storage
  }

  setClientId = (clientId: string, expiration: string): void => {
    const expirationDate = new Date(expiration)
    Cookie.set(StorageKey.CLIENT_ID, clientId, {expires: expirationDate})
    Cookie.set(StorageKey.SESSION_EXPIRE, `${expirationDate.getTime()}`, {expires: expirationDate})
    setUser({id: clientId}) // Set the Sentry user id
  }

  getClientId = (): string => Cookie.get(StorageKey.CLIENT_ID) || ''

  endSession = (): void => {
    this._storage.clear()
    Cookie.remove(StorageKey.CLIENT_ID)
    Cookie.remove(StorageKey.SESSION_EXPIRE)
    setUser(null)
  }

  // eslint-disable-next-line class-methods-use-this
  get userIsAuthenticated(): boolean {
    const storedSessionExpiration = Cookie.get(StorageKey.SESSION_EXPIRE)
    if (!storedSessionExpiration) {
      return false
    }

    return parseInt(storedSessionExpiration, 10) > Date.now()
  }
}

export const session = new AppSession(new AppStorage(localStorage))
