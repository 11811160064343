/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {RefObject, useCallback} from 'react'
import cx from 'classnames'

import Icon, {IconName} from '../icon/Icon'
import Tooltip from '../tooltip/Tooltip'

import styles from './_input.module.scss'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Space-separated list of classes passed to the input. */
  className?: string;
  /** Field-specific error message with icon. Displayed beneath the input. */
  error?: string;
  /** Field-level tooltip content positioned relative to an info icon inside the input on the right side. */
  fieldTooltip?: React.ReactChild;
  /** @ignore */
  fieldTooltipAriaLabel?: string;
  /**
   * Optional function to handle onBlur events.
   * @ignore
   * */
  handleBlur?: (name: string, value: string) => void;
  /** @ignore */
  handleBlurWithEvent?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Supporting text beneath the input to aid usability, such as, formatting instructions. */
  helperText?: string;
  /** @ignore */
  inputRef?: Nullable<RefObject<HTMLInputElement>>;
  /** Content associated with the input. */
  label?: React.ReactChild;
  /**
   * Label-level tooltip content - similar to `fieldTooltip`, but positioned inline
   * with the label content rather than within the input field.
   */
  labelTooltip?: React.ReactChild;
  /** @ignore */
  labelTooltipAriaLabel?: string;
  /** Parenthetical text denoting whether the field is optional. */
  optional?: boolean;
  /** Text to display inside the input when value is empty. */
  placeholder?: string;
  /** Text preceding the input's value, e.g., a currency symbol. */
  prefix?: string;
  /** Text succeeding the input's value, e.g., measurement units. */
  suffix?: string;
  /** Space-separated list of classes passed to the input wrapper. */
  wrapperClassName?: string;
}

export default function Input({
  className,
  error,
  fieldTooltip,
  fieldTooltipAriaLabel,
  handleBlur,
  handleBlurWithEvent,
  helperText,
  id,
  inputRef,
  label,
  labelTooltip,
  labelTooltipAriaLabel,
  name,
  optional = false,
  prefix,
  suffix,
  type,
  wrapperClassName,
  ...rest
}: InputProps): JSX.Element {
  const onBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleBlur && name) {
      handleBlur(name, e.target.value)
    }

    // todo(brendan): Consolidate both handleBlur & handleBlurWithEvent
    if (handleBlurWithEvent) {
      handleBlurWithEvent(e)
    }
  }, [name, handleBlur, handleBlurWithEvent])

  return (
    <>
      {label && (
        <div className={styles.labelWrapper}>
          <label htmlFor={id} className={styles.label}>
            {label}
            {optional && <span className={styles.optional}>(optional)</span>}
          </label>
          {labelTooltip && (
            <Tooltip interactive delay={0.5} content={labelTooltip} placement="top">
              <span className={styles.labelTooltip} role="button" tabIndex={0}>
                <Icon name={IconName.Info} size="1rem" />
              </span>
            </Tooltip>
          )}
        </div>
      )}

      <div className={cx(styles.inputWrapper, error && styles.hasError, wrapperClassName)}>
        {prefix && (
        <span
          onClick={(): void | boolean => inputRef?.current?.focus()}
          className={styles.prefix}
        >
          {prefix}
        </span>
        )}
        <input
          className={cx(styles.input, className)}
          id={id}
          data-cy={id}
          name={name}
          onBlur={onBlur}
          ref={inputRef}
          type={type}
          {...rest} // eslint-disable-line react/jsx-props-no-spreading
        />
        {suffix && (
        <span
          onClick={(): void | boolean => inputRef?.current?.focus()}
          className={styles.suffix}
        >
          {suffix}
        </span>
        )}
        {fieldTooltip && (
          <Tooltip interactive delay={0.5} content={fieldTooltip} placement="top">
            <span className={styles.fieldTooltip} role="button" tabIndex={0}>
              <Icon className={styles.fieldTooltipIcon} name={IconName.Info} size="1rem" />
            </span>
          </Tooltip>
        )}
      </div>
      {helperText && <div className={styles.helperText}>{helperText}</div>}
      {error && (
        <div className={styles.error} data-cy={`${name || ''}-input-error`}>
          <Icon name={IconName.Warning} className={styles.errorIcon} size="0.75rem" />
          {error}
        </div>
      )}
    </>
  )
}
