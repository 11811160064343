export enum BreakdownCategory {
  RSU_INCOME_TAX = 'RSU Income Tax',
  EXERCISE_COST = 'Exercise cost',
  EXERCISE_TAX = 'Exercise Tax',
  GROSS_PROCEEDS = 'Gross Proceeds',
  REMAINING_EQUITY = 'Remaining Equity',
  SALE_TAX = 'Sale Tax',
  SALE_PROFIT = 'Sale Profit',
  AMT = 'AMT',
}
