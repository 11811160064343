import React, {ReactNode} from 'react'

export type Props = {
  title: string;
  children: ReactNode;
}

export const Tab = ({children}: Props): JSX.Element => (
  <div>{children}</div>
)
