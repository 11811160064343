import React from 'react'
import Interweave, {Node} from 'interweave'

import Icon, {IconName} from 'core/components/icon/Icon'
import Link from 'core/components/link/Link'

import styles from '../_results.module.scss'

import {RoutePaths} from 'core/constants/RoutePaths'
import {usePageStrings} from 'core/hooks/usePageStrings'
import {DynamicStringsApp} from 'store/dynamic-strings'

const scrollToSection = (sectionHref: string): void => (
  document.querySelector(sectionHref)?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  })
)

type Props = {
  companyId: string;
  companyName: string | undefined;
}

const ResultsHeader = ({
  companyId,
  companyName,
}: Props): JSX.Element => {
  const {strings} = usePageStrings(DynamicStringsApp.SCENARIOS, 'results')

  return (
    <>
      <Link
        className={styles.backLink}
        to={RoutePaths.ADD_EQUITY_COMPANY_ENTITY.replace(':companyId', companyId)}
        data-cy="back-to-company-link"
      >
        <Icon className={styles.arrowBack} name={IconName.MenuBack} size={14} />
        {companyName ?? 'Back'}
      </Link>
      <h1 className={styles.pageTitle}>{strings?.mainTitle}</h1>

      <p className={styles.pageSubTitle}>
        <Interweave
          noWrap
          content={strings?.mainDescription}
          // eslint-disable-next-line consistent-return
          transform={(node: HTMLElement, children: Node[]): React.ReactNode => {
            if (node.tagName.toLowerCase() === 'a') {
              const scroll = (): void => scrollToSection(node.getAttribute('href') ?? '')
              return <button type="button" role="link" tabIndex={0} onClick={scroll}>{children}</button>
            }
          }}
        />
      </p>
    </>
  )
}

export default React.memo(ResultsHeader)
