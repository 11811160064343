import {captureMessage} from '@sentry/browser'
import Cookie from 'js-cookie'

/**
 * @deprecated
 */
export interface FetchResponseError<E = unknown> {
  readonly data: Nullable<E>;
  readonly response: {
    readonly data: Nullable<E>;
    readonly status: number;
    readonly statusText: string;
    readonly url: string;
  };
}

/**
 * @deprecated
 * Having Nullable data is not a correct representation of what the API returns.
 * An example would be any endpoint that lists entities, get all companies.
 * In that case, if there's no companies `[]` is returned, not `null` but because of this
 * type definition we still have to check if the data is not null.
 *
 * Nullable<Company[]> while it really always is just Company[]
 */
export interface FetchResponse<T, E = unknown> {
  readonly data: Nullable<T>;
  readonly error: Nullable<FetchResponseError<E>>;
}

export type AttributeError = {
  code: string;
  message: string;
}

type FetchOptions = {
  // PATCH needs to be all caps due to an upstream issue with the fetch API: https://github.com/github/fetch/issues/254
  method?: 'get' | 'post' | 'put' | 'PATCH' | 'delete';
  body?: BodyInit | null;
  includeHeaders?: Record<string, string>;
}

type ExceptionOptions = {
  captureExceptions: boolean;
  ignoredStatusCodes: number[];
}

/**
 * @deprecated use api/api.ts instead
 */
async function fetchRequest<T, E = unknown>(
  url: string,
  {includeHeaders, ...initOptions}: FetchOptions,
  {captureExceptions, ignoredStatusCodes}: ExceptionOptions,
): Promise<FetchResponse<T, E>> {
  const requestUrl = `/api${url}`

  const requestInit: RequestInit = {
    credentials: 'include',
    headers: {
      'X-CSRFToken': Cookie.get('csrftoken') ?? '',
      ...includeHeaders,
    },
    ...initOptions,
  }

  const response = await fetch(requestUrl, requestInit)
  let responseData = null as Nullable<T> | Nullable<E>

  try {
    responseData = await response.json() as Nullable<T> | Nullable<E>
  } catch (ex) { /* ignore JSON parsing exceptions */ }

  if (!response.ok) {
    if (captureExceptions && ![...ignoredStatusCodes, 0].includes(response.status)) {
      // destructuring the Response object yields an empty vanilla JS object
      // instead we access all the properties of the Response object
      // into a vanilla JS object that satisfies the type requirement for
      // captureException
      // passing in Response to the extra argument will result in no
      // extra data being passed to Sentry
      const reason = {
        ...(responseData || {}),
        status: response.status,
        statusText: response.statusText,
        type: response.type,
        url: response.url,
      }
      captureMessage(`Fetch Request Error ${url}`, {extra: {reason}})
    }

    return {
      data: null,
      error: {
        data: responseData as Nullable<E>,
        response: {
          data: responseData as Nullable<E>,
          status: response.status,
          statusText: response.statusText,
          url: response.url,
        },
      },
    }
  }

  // Some POST calls return 200 without returning body data hence Nullable<T>
  return {data: responseData as Nullable<T>, error: null}
}

/**
 * @deprecated use api.get() instead
 */
async function get<T, E = unknown>(
  url: string,
  opts: FetchOptions = {},
  exceptionOptions: ExceptionOptions = {captureExceptions: true, ignoredStatusCodes: []},
): Promise<FetchResponse<T, E>> {
  return fetchRequest<T, E>(url, {method: 'get', ...opts}, exceptionOptions)
}

/**
 * @deprecated use api.post() instead
 */
async function post<T, E = unknown>(
  url: string,
  body: unknown = {},
  opts: FetchOptions = {},
  exceptionOptions: ExceptionOptions = {captureExceptions: true, ignoredStatusCodes: []},
): Promise<FetchResponse<T, E>> {
  return fetchRequest<T, E>(url, {
    method: 'post',
    body: JSON.stringify(body),
    includeHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...opts,
  }, exceptionOptions)
}

/**
 * @deprecated use api.patch() instead
 */
async function patch<T, E = unknown>(
  url: string,
  body: unknown = {},
  opts: FetchOptions = {},
  exceptionOptions: ExceptionOptions = {captureExceptions: true, ignoredStatusCodes: []},
): Promise<FetchResponse<T, E>> {
  return fetchRequest<T, E>(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    includeHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...opts,

  }, exceptionOptions)
}

/**
 * @deprecated use api.put() instead
 */
async function put<T, E = unknown>(
  url: string,
  body: unknown = {},
  opts: FetchOptions = {},
  exceptionOptions: ExceptionOptions = {captureExceptions: true, ignoredStatusCodes: []},
): Promise<FetchResponse<T, E>> {
  return fetchRequest<T, E>(url, {
    method: 'put',
    body: JSON.stringify(body),
    includeHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...opts,

  }, exceptionOptions)
}

/**
 * @deprecated use api.post() instead
 */
async function del<T, E= unknown>(
  url: string,
  // TODO(Dan): Remove body arg once 'deletePlaidItem' interface is updated to send resource id as part of the URL
  body?: unknown,
  opts: FetchOptions = {},
  exceptionOptions: ExceptionOptions = {captureExceptions: true, ignoredStatusCodes: []},
): Promise<FetchResponse<T, E>> {
  return fetchRequest<T, E>(url, {
    method: 'delete',
    ...(body ? {body: JSON.stringify(body)} : {}),
    includeHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...opts,
  }, exceptionOptions)
}

/**
 * @deprecated use api.delete() instead
 */
export default {
  get, post, patch, put, delete: del,
}
