import React, {useMemo} from 'react'

import GrantTable from '../tables/GrantTable'

import {
  formatNumberWithCommas,
  isoDateFormatter,
  numberFormatter,
} from 'core/formatters'
import {
  IncomeSource,
  VestingEvent,
} from 'models'

type Props = {
  incomeSources: IncomeSource[];
  scenarioNumber: number;
}

export default function IncomeFromVestingRSUs({
  incomeSources,
  scenarioNumber,
}: Props): JSX.Element {
  const title = 'RSU income from vesting'
  const total = incomeSources.reduce((sum, {incomeSource}) => (
    sum + incomeSource.quantity * (incomeSource as VestingEvent).vestedValue), 0)

  const bodyRows = useMemo(() => {
    if (!incomeSources.length) {
      return [{
        id: `${title}-${scenarioNumber}-body`,
        cells: [
          {
            label: 'No RSUs vested',
            colSpan: 7,
          },
        ],
      }]
    }

    return incomeSources.map(({incomeSource, incomeCompensation}: IncomeSource, idx: number) => {
      const source = incomeSource as VestingEvent
      const grantDate = isoDateFormatter(source.grantDate, 'M/d/yy')
      const vestedDate = isoDateFormatter(source.vestedDate, 'M/d/yy')

      return {
        id: `${title}-${scenarioNumber}-${idx}`,
        cells: [
          {label: `RSU ${grantDate}`},
          {label: `${vestedDate}-N/A`},
          {label: 'Ordinary income rate'},
          {label: numberFormatter(source.vestedValue).currency},
          {label: numberFormatter(incomeCompensation).currency},
          {label: formatNumberWithCommas(source.quantity)},
          {label: numberFormatter(source.quantity * source.vestedValue).currency},
        ],
      }
    })
  }, [incomeSources, scenarioNumber])

  return (
    <GrantTable
      id={`${title}-${scenarioNumber}`}
      title={title}
      bodyRows={bodyRows}
      headerRows={[{
        id: `${title}-${scenarioNumber}-header`,
        cells: [
          {label: 'Grant name'},
          {label: 'Held'},
          {label: 'Tax'},
          {label: 'FMV at vest'},
          {label: 'Taxable compensation per RSU'},
          {label: 'Quantity vested'},
          {label: 'Income generated'},
        ],
      }]}
      footerRows={[{
        id: `${title}-${scenarioNumber}-footer`,
        cells: [
          {
            colSpan: 6,
            label: 'RSU income from vesting',
          },
          {label: numberFormatter(total).currency},
        ],
      }]}
    />
  )
}
