import React from 'react'
import styled, {useTheme} from 'styled-components'
import {useSelector} from 'react-redux'

import CaretRight from 'core/components/icon/CaretRight'

import {useScenarioFlyout} from '../../equity-insights-results/ScenarioTaxBreakdown'
import TaxBreakdownFlyout from '../../equity-insights-results/ScenarioTaxBreakdown/components/TaxBreakdownFlyout'

import {numberFormatter} from 'core/formatters'
import {ScenarioResultType} from 'models'
import {useGetFeatureFlagsQuery} from 'store/feature-flag'
import {LiquidityEventSelectors} from 'store/liquidity-event'
import {
  H4, RegularText, Subheader,
} from 'core/theme'
import {InsightIcon} from 'assets/icons'

type InsightRowProps = {
  label: string;
  value: string;
  subtext: string;
  onClick?: () => void;

}
const InsightRow = ({
  label, value, subtext, onClick,
}: InsightRowProps): JSX.Element => {
  const theme = useTheme()

  return (
    <StyledInsightRow isClickable={!!onClick} onClick={onClick}>
      <RegularText color={theme.colors.darkBlue300}>{label}</RegularText>
      <StyledValueSubtextContainer>
        <StyledValueSubtextContent>
          <Subheader color={theme.colors.darkBlue500}>{value}</Subheader>
          <RegularText color={theme.colors.darkBlue300}>{subtext}</RegularText>
        </StyledValueSubtextContent>
        {onClick && (
        <CaretRight />
        )}
      </StyledValueSubtextContainer>
    </StyledInsightRow>
  )
}

type Props = {
  scenarioType: ScenarioResultType;
  scenarioIndex: number;
}

export const ScenarioInsight = ({scenarioType, scenarioIndex}: Props): JSX.Element => {
  const {data: flags} = useGetFeatureFlagsQuery()
  const quickAnalysis = useSelector(
    LiquidityEventSelectors.selectQuickAnalysis(!!flags?.rsuSupport, scenarioType),
  )
  const {handleToggleFlyout, handleSetScenario} = useScenarioFlyout()

  return (
    <StyledContainer>
      <TitleRow>
        <InsightIcon />
        <H4>Scenario insights: Minimize additional taxes </H4>
      </TitleRow>
      <SubtitleRow>
        <RegularText>
          Here’s how your equity tax scenario breaks down:
        </RegularText>
      </SubtitleRow>
      <RowsContainer>
        <InsightRow
          label="Net Profit"
          value={numberFormatter(quickAnalysis.netProfit.value).intCurrency}
          subtext="After costs & taxes"
        />
        {quickAnalysis.exerciseTaxLiability && (
          <InsightRow
            label="Taxes on exercise"
            value={quickAnalysis.exerciseTaxLiability.value}
            subtext={quickAnalysis.exerciseTaxLiability.subText.join('\n')}
            onClick={(): void => {
              handleToggleFlyout()
              handleSetScenario(scenarioIndex, true)
            }}
          />
        )}
        <InsightRow
          label="Taxes on sale"
          value={quickAnalysis.saleTaxLiability.value}
          subtext={quickAnalysis.saleTaxLiability.subText.join('\n')}
          onClick={(): void => {
            handleToggleFlyout()
            handleSetScenario(scenarioIndex, false)
          }}

        />
        <InsightRow
          label="Investment risk"
          value={quickAnalysis.investmentRisk.value}
          subtext={quickAnalysis.investmentRisk.subText}
        />
        {quickAnalysis.cashFlowFromExercise && (
          <InsightRow
            label="Cash Flow from exercise"
            value={numberFormatter(quickAnalysis.cashFlowFromExercise.value).intCurrency}
            subtext={
            quickAnalysis.cashFlowFromExercise.subText.replace(
              '[AMOUNT]',
              numberFormatter(quickAnalysis.cashFlowFromExercise.value).intCurrency,
            )
          }
          />
        )}
      </RowsContainer>
      <TaxBreakdownFlyout />
    </StyledContainer>
  )
}

const StyledContainer = styled.div(({theme}) => `
    background: rgba(232, 242, 251, 0.5);
    padding: ${theme.spacing.xl} ${theme.spacing.l};


    ${theme.above(theme.breakpoints.aboveMobile)} {
       padding: ${theme.spacing.xl} ${theme.spacing['5xl']};
       border-bottom-left-radius: ${theme.spacing.m};
       border-bottom-right-radius: ${theme.spacing.m};
    }
`)

const TitleRow = styled.div(({theme}) => `
    display: flex;
    align-items: center;

    svg {
        margin-right: ${theme.spacing.m}
    }
`)

const SubtitleRow = styled.div(({theme}) => `
    margin-top: ${theme.spacing.m};
    margin-bottom: ${theme.spacing.m};
`)

const RowsContainer = styled.div(({theme}) => `
  margin-top: ${theme.spacing.m};

  > div:not(:first-child) {
      border-top: 1px solid #ced2d9;
  }
`)

const StyledInsightRow = styled.div<{isClickable: boolean}>(({theme, isClickable}) => `
    display: flex;
    justify-content: space-between;
    padding-top: ${theme.spacing.s};
    padding-bottom: ${theme.spacing.s};
    cursor: ${isClickable ? 'pointer' : 'auto'};

    ${theme.above(theme.breakpoints.largePhone)} {
        padding-left: ${theme.spacing.m};
        padding-right: ${theme.spacing.m};
    }

    &:hover {
        background: ${isClickable ? '#E8F2FB' : 'initial'};
    }
`)

const StyledValueSubtextContainer = styled.div`
    display: flex;
`

const StyledValueSubtextContent = styled.div(({theme}) => `
     display: flex;
     flex-direction: column;
     text-align: right;
     margin-right: ${theme.spacing.m}
`)
