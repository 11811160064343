/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, {css} from 'styled-components'

export const ContentContainer = styled.div(({
  theme: {
    breakpoints, above, maxWidth, spacing,
  },
}) => `
  margin: ${spacing.xl} 0px;
  max-width: ${maxWidth};
  align-self: center;

  ${above(breakpoints.tablet)} {
    margin: ${spacing.xl};
  }  
`)

export const RowContainer = styled.div<{
  align?: string;
  justify?: string;
  gap?: string;
  wrap?: string;
  mobile?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({wrap}): string => wrap ?? 'nowrap'};
  align-items: ${({align}): string => align ?? 'stretch'};
  justify-content:  ${({justify}): string => justify ?? 'flex-start'};
  gap: ${({gap}): string => gap ?? '0'};

  ${({theme, mobile}) => mobile && css`${theme.below(theme.breakpoints.desktop)} {
    flex-direction: column;
  }`
}
`

export const ColumnContainer = styled.div<{
  align?: string;
  justify?: string;
  gap?: string;
  wrap?: string;
  style?: any;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: ${({wrap}): string => wrap ?? 'nowrap'};
  align-items: ${({align}): string => align ?? 'stretch'};
  justify-content: ${({justify}): string => justify ?? 'flex-start'};
  gap: ${({gap}): string => gap ?? '0'};
  ${({style}): any => css`
    ${style}
  `}
`

export const CenteredRow = styled(RowContainer).attrs({justify: 'center', align: 'center'})``
export const CenteredColumn = styled(ColumnContainer).attrs({justify: 'center', align: 'center'})``

export const WhiteSpace = styled.span`
  &:after {
    content: " "
  }
`
