import {GrantLifecycleData} from 'api/grant'

export type Company = {
  id: string;
  name: string;
  numberOfGrants: number;
  hasBeenEmployed: boolean;
  employmentEndDate?: string | null;
  postEmploymentOptionExpiration?: number | null;
  closedFromApplication: string | null; // date
  shouldDisplayMarketplaceCta: boolean;
};

export enum PriceType {
  '409A' = '409A',
  PREFERRED = 'PREFERRED_PRICE',
}

export type CompanyPricingInfo = {
  id: string;
  pricingDate: string;
  priceType: PriceType;
  price: number;
}

export type CompanyLifecycleData = GrantLifecycleData & {
  totalDirectValue: number;
  totalDirectQuantity: number;
  totalSaleValueDirect: number;
  totalSaleQuantityDirect: number;
  unvestedOptionQuantity: number;
  unvestedRsaQuantity: number;
  unvestedAwardsQuantity: number;
  unvestedSharesQuantity: number;
  unvestedRsuQuantity: number;
  vestedRsuQuantity: number;
  vestedRsaQuantity: number;
  totalSaleQuantityRsa: number;
  totalSaleQuantityRsu: number;
  totalSaleQuantityExercised: number;
  vestedOptionQuantity: number;
}
