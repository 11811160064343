/* eslint-disable max-len */
import {SpotIconName} from 'core/components/icon/Icon'

import {
  Answer,
  AnswerType,
  ProfilePreferencesLocalData,
  ProfileQuestion,
  ProfileSummaryContent,
  ServiceComboType,
  ServiceNeedsQuestion, SliderInputOptions,
} from './types'

import {FirmType} from 'models/firm'
import {
  ProfileServiceNeedsFormKey,
} from 'models/adviserProposal'
import {ServicesKey} from 'models/service'
import {StateAbbreviations} from 'core/constants/states'
import {countries} from 'core/constants/countries'
import {numberFormatter} from 'core/formatters'
import {
  EmploymentType,
  HouseholdIndustries,
  IlliquidAssets,
  KeyLifeEvents,
  PersonalFinancesManagement,
  ProfileFormKey,
  SmallBusinessFunding,
  Subindustries,
  WindfallTiming,
  WindfallTypes,
} from 'models/clientProfile'
import {RoutePaths} from 'core/constants'
import DigitalAssetIcon from 'assets/spot-icons/digital-asset.svg'
import EquitytIcon from 'assets/spot-icons/equity.svg'
import RealEstateIcon from 'assets/spot-icons/real-estate.svg'
import InternationalIcon from 'assets/spot-icons/international.svg'
import FounderIcon from 'assets/spot-icons/founder.svg'
import SmbOwnerIcon from 'assets/spot-icons/smb-owner.svg'
import EquityPartnerIcon from 'assets/spot-icons/equity-partner.svg'
import TechIcon from 'assets/spot-icons/tech.svg'
import AssetManagementIcon from 'assets/spot-icons/asset-management.svg'
import EquityLiquidityIcon from 'assets/spot-icons/equity-liquidity.svg'
import PromotionIcon from 'assets/spot-icons/promotion.svg'
import StartCompanyIcon from 'assets/spot-icons/starting-company.svg'
import KidsIcon from 'assets/spot-icons/kids.svg'
import ChangingJobsIcon from 'assets/spot-icons/changing-jobs.svg'
import MovingIcon from 'assets/spot-icons/moving.svg'
import MarriageIcon from 'assets/spot-icons/marriage.svg'
import BuyingHouseIcon from 'assets/spot-icons/buying-house.svg'
import DigitalAssetsReturnIcon from 'assets/spot-icons/digital-assets-return.svg'
import PrivateSharesIcon from 'assets/spot-icons/private-shares.svg'
import RealEstateReturnIcon from 'assets/spot-icons/real-estate-return.svg'
import RetirementIcon from 'assets/spot-icons/retirement.svg'

export default {
  ALTERNATIVE_INVESTMENTS_MINIMUM: 1000000,
  INVESTMENT_MANAGEMENT_MINIMUM: 250000,
}

export type ServiceDict = {
  name: string;
  vertical: ProfileServiceNeedsFormKey;
  description: string;
  metadata: string;
  insightText: string;
  type: FirmType;
}

export const SERVICES: Record<ServicesKey, ServiceDict> = {
  [ServicesKey.FINANCIAL_PLANNING]: {
    name: 'Standalone Financial Planning',
    vertical: ProfileServiceNeedsFormKey.financialServiceInterests,
    description: 'Set goals and evaluate the trajectory of your current and future income, investments, and spending with a CFP (Certified Financial Planner). Evaluate scenarios based on upcoming milestones and get coaching on how to adjust your financial behavior and decisions.',
    metadata: 'Financial • Flat fee • One-time',
    insightText: 'A financial advisor can help you budget for the cash needed to exercise options and create a plan for how best to invest your windfall over time.',
    type: FirmType.INVESTMENT,
  },
  [ServicesKey.INVESTMENT_MANAGEMENT]: {
    name: 'Holistic Wealth Management',
    vertical: ProfileServiceNeedsFormKey.financialServiceInterests,
    description: 'Combine financial planning with high-touch, ongoing advising. A team of financial, tax, and legal experts will help source and manage investments that align with your values and goals.',
    metadata: 'Financial • Percentage-based fee • Ongoing • $250K+ investable assets',
    insightText: 'A wealth manager can help you calculate various buy/sell scenarios, manage the taxes on your windfall, diversify away from a concentrated stock holding, and borrow against your shares.',
    type: FirmType.INVESTMENT,
  },
  [ServicesKey.ALTERNATIVE_INVESTMENTS]: {
    name: 'Alternative Investments',
    vertical: ProfileServiceNeedsFormKey.financialServiceInterests,
    description: 'Get access to a custom portfolio managed by a team of investment analysts with broader assets that can obtain higher returns or lower volatility. You’ll have access to illiquid asset classes like real estate, private equity, hedge funds, venture capital, commodities, and more.',
    metadata: 'Financial • Percentage-based fee • Ongoing • $1M+ investable assets',
    insightText: 'Take your investment strategy for your windfall to the next level with a highly customized approach for your overall portfolio.',
    type: FirmType.INVESTMENT,
  },
  [ServicesKey.TAX_PREPARATION]: {
    name: 'Tax Preparation',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Annual tax filing through a CPA (Certified Public Accountant), which includes compiling documents, preparing, and filing all tax forms for your household.',
    metadata: 'Tax • Flat fee • Annual',
    insightText: 'Ensure accurate filing and documentation for an exceptional tax year and make sure you’re managing your Alternative Minimum Tax (AMT), capital gains, and other equity-related considerations correctly.',
    type: FirmType.TAX,
  },
  [ServicesKey.TAX_PLANNING]: {
    name: 'Tax Planning',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Ongoing tax guidance from an accountant on your income, investments, deductions, major purchases, relocations, estate, and gifts throughout the year. Also receive expert insight into the industry and state-specific tax optimizations that affect you.',
    metadata: 'Tax • Hourly fee • Ongoing',
    insightText: 'Immediate advice on optimizing taxes based on various scenario calculations for your exercising and/or selling stock, and planning for credits and additional optimizations in the future.',
    type: FirmType.TAX,
  },
  [ServicesKey.BUSINESS_TAX_RETURNS]: {
    name: 'Business Tax Returns',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Corporate tax strategy for small businesses setup and maintenance, consulting income, or other business-related tax questions.',
    metadata: 'Tax • Flat fee • Annual or ongoing',
    insightText: 'For small business owners, get advice on the sale of your business or the best strategy for reporting gains and losses across your household and other assets.',
    type: FirmType.TAX,
  },
  [ServicesKey.INTERNATIONAL_TAXES]: {
    name: 'International Taxes',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Assistance with strategically managing tax obligations across multiple countries and the required annual filings and documentation.',
    metadata: 'Tax • Flat fee • Ongoing',
    insightText: 'Manage the cross-border tax implications of a liquidity event, taking into consideration where your company itself is legally based, and where you are currently residing or plan to move in the future.',
    type: FirmType.TAX,
  },
  [ServicesKey.ESTATE_PLANNING]: {
    name: 'Estate Planning',
    vertical: ProfileServiceNeedsFormKey.legalServiceInterests,
    description: 'The creation of core estate planning documents, such as a will or a trust to protect assets during your lifetime, reduce tax implications during asset transfer, capture gift tax exclusions, identify beneficiaries, and more.',
    metadata: 'Trust & estate services • Flat fee • One-time',
    insightText: 'Transfer company stock to a trust to reduce gift transfer and estate taxes and ensure your assets are distributed efficiently.',
    type: FirmType.TRUST_ESTATE,
  },
  [ServicesKey.EQUITY_TAX_PLANNING]: {
    name: 'Standalone Equity Tax Planning',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'A personalized one-hour virtual meeting with a CPA experienced in working with equity. Talk through your equity concerns, goals, and next steps.',
    metadata: 'Tax • $500 one-time fee • One hour consultation',
    insightText: 'Get quick answers to questions on how to budget for taxes based on when, how, and how many options you exercise or shares you sell, and plan for credits and optimizations in the future.',
    type: FirmType.TAX,
  },
  [ServicesKey.BOOKKEEPING]: {
    name: 'Bookkeeping',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Get professional help keeping records of financial transactions and preparing financial statements, such as balance sheets, income statements, and crypto transactions. This service is accessible for both businesses and individuals.',
    metadata: 'Tax · Transaction-Based-Fee · Ongoing',
    insightText: '',
    type: FirmType.TAX,
  },
}

export const SERVICES_V2: Record<ServicesKey, ServiceDict> = {
  [ServicesKey.FINANCIAL_PLANNING]: {
    name: 'Standalone Financial Planning',
    vertical: ProfileServiceNeedsFormKey.financialServiceInterests,
    description: 'Set goals and evaluate the trajectory of your current and future income, investments, and spending with a CFP (Certified Financial Planner). Evaluate scenarios based on upcoming milestones and get coaching on how to adjust your financial behavior and decisions.',
    metadata: 'Financial • Flat fee • One-time',
    insightText: 'A financial advisor can help you budget for the cash needed to exercise options and create a plan for how best to invest your windfall over time.',
    type: FirmType.INVESTMENT,
  },
  [ServicesKey.INVESTMENT_MANAGEMENT]: {
    name: 'Holistic Wealth Management',
    vertical: ProfileServiceNeedsFormKey.financialServiceInterests,
    description: 'Combine financial planning with high-touch, ongoing advising. A team of financial, tax, and legal experts will help source and manage investments that align with your values and goals.',
    metadata: 'Financial • Percentage-based fee • Ongoing • $250K+ investable assets',
    insightText: 'A wealth manager can help you calculate various buy/sell scenarios, manage the taxes on your windfall, diversify away from a concentrated stock holding, and borrow against your shares.',
    type: FirmType.INVESTMENT,
  },
  [ServicesKey.ALTERNATIVE_INVESTMENTS]: {
    name: 'Alternative Investments',
    vertical: ProfileServiceNeedsFormKey.financialServiceInterests,
    description: 'Get access to a custom portfolio managed by a team of investment analysts with broader assets that can obtain higher returns or lower volatility. You’ll have access to illiquid asset classes like real estate, private equity, hedge funds, venture capital, commodities, and more.',
    metadata: 'Financial • Percentage-based fee • Ongoing • $1M+ investable assets',
    insightText: 'Take your investment strategy for your windfall to the next level with a highly customized approach for your overall portfolio.',
    type: FirmType.INVESTMENT,
  },
  [ServicesKey.TAX_PREPARATION]: {
    name: 'Annual Tax Filing',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Annual tax filing through a CPA (Certified Public Accountant), which includes compiling documents, preparing, and filing all tax forms for your household.',
    metadata: 'Tax • Flat fee • Annual',
    insightText: 'Ensure accurate filing and documentation for an exceptional tax year and make sure you’re managing your Alternative Minimum Tax (AMT), capital gains, and other equity-related considerations correctly.',
    type: FirmType.TAX,
  },
  [ServicesKey.TAX_PLANNING]: {
    name: 'Comprehensive Tax Planning',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Ongoing tax guidance from an accountant on your income, investments, deductions, major purchases, relocations, estate, and gifts throughout the year. Also receive expert insight into the industry and state-specific tax optimization that affect you. Includes quarterly estimated tax payments.',
    metadata: 'Tax • Quarterly fee • Optional hourly fee • Ongoing',
    insightText: 'Immediate advice on optimizing taxes based on various scenario calculations for your exercising and/or selling stock, and planning for credits and additional optimizations in the future.',
    type: FirmType.TAX,
  },
  [ServicesKey.BUSINESS_TAX_RETURNS]: {
    name: 'Business Tax Returns',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Corporate tax strategy for small businesses setup and maintenance, consulting income, or other business-related tax questions.',
    metadata: 'Tax • Flat fee • Annual or ongoing',
    insightText: 'For small business owners, get advice on the sale of your business or the best strategy for reporting gains and losses across your household and other assets.',
    type: FirmType.TAX,
  },
  [ServicesKey.INTERNATIONAL_TAXES]: {
    name: 'International Taxes',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Assistance with strategically managing tax obligations across multiple countries and the required annual filings and documentation.',
    metadata: 'Tax • Flat fee • Ongoing',
    insightText: 'Manage the cross-border tax implications of a liquidity event, taking into consideration where your company itself is legally based, and where you are currently residing or plan to move in the future.',
    type: FirmType.TAX,
  },
  [ServicesKey.ESTATE_PLANNING]: {
    name: 'Estate Planning',
    vertical: ProfileServiceNeedsFormKey.legalServiceInterests,
    description: 'The creation of core estate planning documents, such as a will or a trust to protect assets during your lifetime, reduce tax implications during asset transfer, capture gift tax exclusions, identify beneficiaries, and more.',
    metadata: 'Trust & estate services • Flat fee • One-time',
    insightText: 'Transfer company stock to a trust to reduce gift transfer and estate taxes and ensure your assets are distributed efficiently.',
    type: FirmType.TRUST_ESTATE,
  },
  [ServicesKey.EQUITY_TAX_PLANNING]: {
    name: '1-Hour Equity Tax Planning Session',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'A personalized one-hour virtual meeting with a CPA experienced in working with equity. Talk through your equity concerns, goals, and next steps.',
    metadata: 'Tax • $500 one-time fee • One hour consultation',
    insightText: 'Get quick answers to questions on how to budget for taxes based on when, how, and how many options you exercise or shares you sell, and plan for credits and optimizations in the future.',
    type: FirmType.TAX,
  },
  [ServicesKey.BOOKKEEPING]: {
    name: 'Crypto Reconciliation & Bookkeeping',
    vertical: ProfileServiceNeedsFormKey.taxServiceInterests,
    description: 'Get professional help keeping records of financial transactions and preparing financial statements, such as balance sheets, income statements, and crypto transactions. This service is accessible for both businesses and individuals.',
    metadata: 'Tax · Transaction-Based-Fee · Ongoing',
    insightText: '',
    type: FirmType.TAX,
  },
}

export const COMBO_LABEL_PREFIX: Record<ServiceComboType, string> = {
  [ServiceComboType.TAX_ONLY]: 'taxOnly',
  [ServiceComboType.TAX_AND_FINANCE]: 'taxAndFinance',
  [ServiceComboType.TAX_FINANCE_AND_LEGAL]: 'taxFinanceAndLegal',
  [ServiceComboType.FINANCE_ONLY]: 'financeOnly',
  [ServiceComboType.FINANCE_AND_LEGAL]: 'financeAndLegal',
  [ServiceComboType.LEGAL_ONLY]: 'legalOnly',
}

export const SERVICE_LABEL_PREFIX: Record<ServicesKey, string> = {
  [ServicesKey.FINANCIAL_PLANNING]: 'financialPlanning',
  [ServicesKey.INVESTMENT_MANAGEMENT]: 'investmentManagement',
  [ServicesKey.TAX_PREPARATION]: 'taxPreparation',
  [ServicesKey.TAX_PLANNING]: 'taxPlanning',
  [ServicesKey.BUSINESS_TAX_RETURNS]: 'businessTaxReturns',
  [ServicesKey.INTERNATIONAL_TAXES]: 'internationalTaxes',
  [ServicesKey.ESTATE_PLANNING]: 'estatePlanning',
  [ServicesKey.EQUITY_TAX_PLANNING]: 'equityTaxPlanning',
  [ServicesKey.ALTERNATIVE_INVESTMENTS]: 'alternativeInvestments',
  [ServicesKey.BOOKKEEPING]: '',
}

export const SERVICES_ORDERING_V2 = {
  // Tax
  [ServicesKey.TAX_PREPARATION]: 1,
  [ServicesKey.TAX_PLANNING]: 2,
  [ServicesKey.INTERNATIONAL_TAXES]: 3,
  [ServicesKey.BUSINESS_TAX_RETURNS]: 4,
  [ServicesKey.EQUITY_TAX_PLANNING]: 5,
  [ServicesKey.BOOKKEEPING]: 6,
  // Financial
  [ServicesKey.FINANCIAL_PLANNING]: 7,
  [ServicesKey.INVESTMENT_MANAGEMENT]: 8,
  [ServicesKey.ALTERNATIVE_INVESTMENTS]: 9,
  // Legal
  [ServicesKey.ESTATE_PLANNING]: 10,
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const generateAnswer = (label: string, value: any = label, subLabel?: string): Answer => ({label, value, subLabel})
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const generateMultiDropdownAnswer = (label: string, value: any = label): Answer => ({label, value, key: label})

/**
 * function to customize scale values such that
 * first 80% of scale spans first 1/2 of domain
 * last 20% of scale spans second 1/2 of domain
 * Ex: domain [0, 10] => [0.625, 1.25, 1.875, 2.5, 3.125, 3.75, 4.375, 5, 7.5, 10]
 * */
export function scaleFormatter(sliderValue: number, maxValue: number): number {
  if (sliderValue <= 0) return sliderValue
  // distribute first 50% of domain into first 80% of scale
  if (sliderValue / maxValue <= 0.8) return Math.ceil(sliderValue * (5 / 8))
  // distribute second 50% of domain over last 20% of scale
  if (sliderValue / maxValue > 0.8) return Math.ceil(maxValue - (5 / 2) * (maxValue - sliderValue))
  return sliderValue
}

/**
 * function to invert custom scale values to get original value from transformed value
 * in order to align value appropriately on slider rail
 * */
export function invertScaleFormatter(sliderValue: number, maxValue: number): number {
  if (sliderValue <= 0) return sliderValue
  if (sliderValue <= maxValue / 2) return Math.ceil(sliderValue * (8 / 5))
  if (sliderValue > maxValue / 2) return Math.ceil(maxValue - (2 / 5) * (maxValue - sliderValue))
  return sliderValue
}

/**
 * function to transform scale values for UI display
 * */
const financialSliderFormatter = (sliderValue: number, maxValue: number): string => {
  const value = scaleFormatter(sliderValue, maxValue)
  if (value <= 50000) return sliderValue === -1 ? '$' : 'Under $50K'
  if (value === maxValue) return `Over ${numberFormatter(value).abbreviatedCurrencyNoSpace}`
  return numberFormatter(value).abbreviatedCurrencyNoSpace
}

export const SCROLL_OFFSET_PADDING = 20

export const DEFAULT_SLIDER_OPTIONS: SliderInputOptions = {
  formatter: () => '',
  scaleFormatter: (value: number) => value,
  invertScaleFormatter: (value: number) => value,
  range: [0, 0],
  step: 0,
  setStep: () => null,
  tickValues: [],
}

export const QUESTION_MOTIVATIONS: ProfileQuestion = {
  mixpanelId: 'profile-milestones',
  key: ProfileFormKey.keyLifeEvents,
  text: 'Hello [NAME], what can we help you with today?',
  subText: 'Based on your needs, we will recommend pre-screened firms that best suit you. If you need help, Concierge is available to help you.',
  answerType: AnswerType.checkbox,
  answers: [
    {label: 'IPO or acquisition planning', value: WindfallTypes.EQUITY_EVENT_IPO},
    {label: 'Founding a company', value: KeyLifeEvents.STARTING_COMPANY},
    {
      label: 'Seeking liquidity for private shares',
      subLabel: '(ex. secondary market, tender offer)',
      value: WindfallTypes.SECONDARY_SALE,
    },
    {label: 'Crypto tax planning', subLabel: '(token sales, NFT sales, staking)', value: WindfallTypes.CRYPTO},
    {label: 'Changing jobs', value: KeyLifeEvents.CHANGING_JOBS},
    {label: 'Planning for children', value: KeyLifeEvents.CHILDREN},
    {label: 'International taxes', value: KeyLifeEvents.MOVING},
    {label: 'Preparing finances for marriage', value: KeyLifeEvents.MARRIED},
    {label: 'Buying a house', value: KeyLifeEvents.BUYING_HOUSE},
  ],
}

export const QUESTION_WINDFALL_TIMING: ProfileQuestion = {
  mixpanelId: 'profile-windfall_timing',
  key: ProfileFormKey.windfallTiming,
  text: 'When are you expecting this windfall?',
  subText: 'The timing of your windfall determines the urgency of the services you need and any tax deadlines you\'ll need to meet.',
  answerType: AnswerType.radio,
  answers: [
    generateAnswer(WindfallTiming.PAST),
    generateAnswer(WindfallTiming.SHORT_TERM),
    generateAnswer(WindfallTiming.LONG_TERM),
    generateAnswer(WindfallTiming.NOT_SURE),
  ],
}

export const MAX_WINDFALL_AMOUNT = 5000000

export const QUESTION_WINDFALL_AMOUNT: ProfileQuestion = {
  mixpanelId: 'profile-windfall_amount',
  key: ProfileFormKey.windfallAmount,
  text: 'And approximately how much are you expecting, in total?',
  subText: 'The size of your windfall helps us find the right firm for your budget and future financial situation.',
  answerType: AnswerType.radio,
  answers: [
    generateAnswer('$0-$100K', {lower: 0, upper: 100000}),
    generateAnswer('$100K-$250K', {lower: 100000, upper: 250000}),
    generateAnswer('$250K-$500K', {lower: 250000, upper: 500000}),
    generateAnswer('$500K-$1M', {lower: 500000, upper: 1000000}),
    generateAnswer('$1M-$5M', {lower: 1000000, upper: 5000000}),
    generateAnswer('$5M+', {lower: MAX_WINDFALL_AMOUNT, upper: null}),
    generateAnswer('I’m not sure', {lower: null, upper: null}),
  ],
}

export const QUESTION_EMPLOYMENT: ProfileQuestion = {
  mixpanelId: 'profile-employment',
  key: ProfileFormKey.employmentType,
  text: 'Tell us about the employment in your household.',
  answerType: AnswerType.checkbox,
  answers: [
    generateAnswer('Employee', EmploymentType.EMPLOYEE, '(Full or Part-Time)'),
    generateAnswer('Founder', EmploymentType.FOUNDER, '(Corporation)'),
    generateAnswer('Small business owner', EmploymentType.SMALL_BUSINESS_OWNER, '(e.g. Independent contractor, Sole proprietor, LLC)'),
    generateAnswer('Equity partner', EmploymentType.EQUITY_PARTNER, '(LLP, LLC)'),
    generateAnswer('Retired', EmploymentType.RETIRED),
  ],
}

export const QUESTION_SMALL_BUSINESS_TYPE: ProfileQuestion = {
  mixpanelId: 'profile-small-business-type',
  key: ProfileFormKey.smallBusinessType,
  text: 'What type of business do you own?',
  answerType: AnswerType.text,
  answers: [],
}

export const MAX_SMALL_BUSINSES_REVENUE = 10000000

export const QUESTION_SMALL_BUSINESS_REVENUE: ProfileQuestion = {
  mixpanelId: 'profile-small-business-revenue',
  key: ProfileFormKey.smallBusinessRevenue,
  text: 'What is the approximate yearly revenue of your business?',
  answerType: AnswerType.radio,
  answers: [
    generateAnswer('Under $1M', {lower: 0, upper: 1000000}),
    generateAnswer('$1M-$10M', {lower: 1000000, upper: 10000000}),
    generateAnswer('$10M+', {lower: MAX_SMALL_BUSINSES_REVENUE, upper: null}),
    generateAnswer('Prefer not to answer', {lower: null, upper: null}),
  ],
}

export const QUESTION_SMALL_BUSINESS_FUNDING: ProfileQuestion = {
  mixpanelId: 'profile-small-business-funding',
  key: ProfileFormKey.smallBusinessFunding,
  text: 'What type of funding has your business received?',
  answerType: AnswerType.checkbox,
  answers: [
    generateAnswer(SmallBusinessFunding.SELF_FUNDED),
    generateAnswer(SmallBusinessFunding.FRIENDS_AND_FAMILY),
    generateAnswer(SmallBusinessFunding.CROWDFUNDED),
    generateAnswer(SmallBusinessFunding.LOAN),
    generateAnswer(SmallBusinessFunding.PROFESSIONAL_INVESTORS),
    generateAnswer(SmallBusinessFunding.OTHER),
  ],
}

export const QUESTION_PROFESSIONS: ProfileQuestion = {
  mixpanelId: 'profile-professions',
  key: ProfileFormKey.householdIndustries,
  text: 'What industry do you work in? If applicable, what industry does your partner work in?',
  answerType: AnswerType.radio,
  answers: [
    generateMultiDropdownAnswer('Technology (Fintech, E-commerce, Crypto, etc.)', HouseholdIndustries.TECHNOLOGY),
    generateMultiDropdownAnswer('Asset Management & Venture Capital', HouseholdIndustries.ASSET_MANAGEMENT),
    generateMultiDropdownAnswer(HouseholdIndustries.BIOTECH_PHARMA),
    generateMultiDropdownAnswer(HouseholdIndustries.HEALTHCARE),
    generateMultiDropdownAnswer(HouseholdIndustries.CONSULTING),
    generateMultiDropdownAnswer(HouseholdIndustries.ENTERTAINMENT_MEDIA),
    generateMultiDropdownAnswer(HouseholdIndustries.REAL_ESTATE),
    generateMultiDropdownAnswer(HouseholdIndustries.LAW),
    generateMultiDropdownAnswer(HouseholdIndustries.SPORTS),
    generateMultiDropdownAnswer(HouseholdIndustries.OTHER),
  ],
}

export const QUESTION_SUBINDUSTRIES: ProfileQuestion = {
  mixpanelId: '',
  key: ProfileFormKey.subindustries,
  text: 'What area of asset management?',
  subText: 'We provide specialized advisory firm recommendations based on your profession.',
  answerType: AnswerType.checkbox,
  answers: [
    generateAnswer(Subindustries.VENTURE_CAPITAL),
    generateAnswer(Subindustries.PRIVATE_EQUITY),
    generateAnswer(Subindustries.HEDGE_FUNDS),
    generateAnswer(Subindustries.MUTUAL_FUNDS),
    generateAnswer(Subindustries.REITS),
    generateAnswer(Subindustries.OTHER),
  ],
}

export const QUESTION_OTHER_PROFESSIONS: ProfileQuestion = {
  mixpanelId: '',
  key: ProfileFormKey.otherHouseholdIndustries,
  text: 'Which industries not listed above are relevant?',
  placeholder: 'Please list any of your other relevant industries here',
  answerType: AnswerType.textarea,
  answers: [],
}

export const QUESTION_HOUSEHOLD_INCOME: ProfileQuestion = {
  mixpanelId: 'profile-income',
  key: ProfileFormKey.householdIncome,
  text: 'What is your total household income?',
  answerType: AnswerType.slider,
  answers: [],
  sliderOptions: {
    formatter: financialSliderFormatter,
    scaleFormatter,
    invertScaleFormatter,
    // increment by $50K when under $1M; increment by $500K when over $1M
    setStep: (val: number): number => (val < 1600000 ? 80000 : 20000),
    range: [-1, 2000000],
    step: 1,
    tickValues: [
      {value: 0},
      {value: 2000000},
    ],
  },
}

export const QUESTION_INVESTABLE_ASSETS: ProfileQuestion = {
  mixpanelId: 'profile-investable',
  key: ProfileFormKey.totalInvestableAssets,
  text: 'What are your total investable assets?',
  answerType: AnswerType.slider,
  answers: [],
  sliderOptions: {
    formatter: financialSliderFormatter,
    scaleFormatter,
    invertScaleFormatter,
    range: [-1, 10000000],
    step: 1,
    setStep: (val: number): number => {
      if (val < 1600000) return 80000
      if (val < 8000000) return 160000
      return 200000
    },
    tickValues: [
      {value: 0},
      {value: 10000000},
    ],
  },
}

export const QUESTION_OTHER_ASSETS: ProfileQuestion = {
  mixpanelId: 'profile-illiquid',
  key: ProfileFormKey.otherAssets,
  text: 'What is the total value of your illiquid assets? Don\'t include the value of any primary residence.',
  subText: 'We will prioritize firms that have expertise in the types of assets you hold or want to invest in.',
  answerType: AnswerType.slider,
  answers: [],
  sliderOptions: {
    formatter: financialSliderFormatter,
    scaleFormatter,
    invertScaleFormatter,
    range: [-1, 10000000],
    step: 1,
    setStep: (val: number): number => {
      if (val < 1600000) return 80000
      if (val < 8000000) return 160000
      return 200000
    },
    tickValues: [
      {value: 0},
      {value: 10000000},
    ],
  },
}

export const QUESTION_ILLIQUID_ASSETS: ProfileQuestion = {
  mixpanelId: '',
  key: ProfileFormKey.illiquidAssets,
  text: 'What type of illiquid assets do you hold, if any?',
  answerType: AnswerType.checkbox,
  answers: [
    {
      label: 'Private shares & employee equity',
      subLabel: '(ISOs, NSOs, RSUs, direct investments, etc)',
      value: IlliquidAssets.PRIVATE_SHARES,
    },
    {
      label: 'Private funds',
      subLabel: '(Venture capital, hedge funds, etc)',
      value: IlliquidAssets.PRIVATE_FUNDS,
    },
    {
      label: 'Digital Assets',
      subLabel: '(Cryptocurrencies, tokens, NFTs, etc)',
      value: IlliquidAssets.CRYPTO,
    },
    {
      label: 'Real estate',
      subLabel: '(Vacation homes, rental properties, etc)',
      value: IlliquidAssets.REAL_ESTATE,
    },
    {
      label: 'Commodities',
      subLabel: '(Gold, silver, etc)',
      value: IlliquidAssets.COMMODITIES,
    },
    {
      label: 'Collectibles',
      subLabel: '(Fine art, jewelry, antiques, etc)',
      value: IlliquidAssets.COLLECTIBLES,
    },
    generateAnswer(IlliquidAssets.NONE),
  ],
}

export const QUESTION_LOCATION_PREFERENCE: ProfileQuestion = {
  mixpanelId: 'profile-location',
  key: ProfileFormKey.hasAdviserLocationPreference,
  text: 'Do you want to have the ability to meet with your advisor in-person?',
  answerType: AnswerType.radio,
  answers: [
    generateAnswer('Yes', true),
    generateAnswer('No', false),
    generateAnswer('No Preference', null),
  ],
}

export const QUESTION_ZIP_CODE: ProfileQuestion = {
  mixpanelId: 'profile-zipcode',
  key: ProfileFormKey.zipcodePreferences,
  text: 'What is your zip code?',
  answerType: AnswerType.zipcode,
  answers: [],
}

export const QUESTION_OTHER_SERVICE_INTERESTS: ServiceNeedsQuestion = {
  mixpanelId: '',
  key: ProfileServiceNeedsFormKey.otherServiceInterests,
  text: 'Do you have any other needs?',
  subText: 'Your Concierge will follow up to assist you and take any additional needs into consideration.',
  placeholder: 'List other needs here...',
  answerType: AnswerType.textarea,
  answers: [],
}

export const QUESTION_STATE_FILINGS: ProfileQuestion = {
  mixpanelId: 'profile-filings',
  key: ProfileFormKey.statesForTaxFiling,
  text: 'How many states do you typically file income taxes in each year?',
  answerType: AnswerType.radio,
  answers: [
    generateAnswer('1', {lower: 0, upper: 1}),
    generateAnswer('2-3', {lower: 2, upper: 3}),
    generateAnswer('4-6', {lower: 4, upper: 6}),
    generateAnswer('7+', {lower: 7, upper: null}),
  ],
}

export const QUESTION_K1_TAXES: ProfileQuestion = {
  mixpanelId: 'profile-k1s',
  key: ProfileFormKey.k1sForTaxFiling,
  text: 'How many separate investments in partnerships, LLCs, or trusts do you have?',
  answerType: AnswerType.radio,
  answers: [
    generateAnswer('0', {lower: 0, upper: 0}),
    generateAnswer('1-5', {lower: 1, upper: 5}),
    generateAnswer('6-10', {lower: 6, upper: 10}),
    generateAnswer('11+', {lower: 11, upper: null}),
  ],
}

export const QUESTION_PERSONAL_FINANCES_MANAGEMENT: ProfileQuestion = {
  mixpanelId: 'profile-current-solutions',
  key: ProfileFormKey.personalFinancesManagement,
  text: 'How do you currently manage your personal finances?',
  answerType: AnswerType.checkbox,
  answers: [
    {
      label: 'Personal finance apps',
      subLabel: '(budgeting, cashflow)',
      value: PersonalFinancesManagement.PERSONAL_FINANCE_APP,
    },
    {
      label: 'Tax advisor',
      subLabel: '(Certified Public Acct., tax attorney)',
      value: PersonalFinancesManagement.TAX_ADVISOR,
    },
    {
      label: 'Self-directed investments',
      subLabel: '(brokerage, roboadvisor)',
      value: PersonalFinancesManagement.SELF_DIRECTED_INVESTMENTS,
    },
    {
      label: 'Financial planner',
      subLabel: '(Certified Fin. Planner, family advisor)',
      value: PersonalFinancesManagement.FINANCIAL_PLANNER,
    },
    {
      label: 'Track in spreadsheets',
      subLabel: '(net worth, equity)',
      value: PersonalFinancesManagement.TRACK_IN_SPREADSHEETS,
    },
    {
      label: 'Wealth manager',
      subLabel: '(RIA, Broker/Dealer, family advisor)',
      value: PersonalFinancesManagement.WEALTH_MANAGER,
    },
    generateAnswer(PersonalFinancesManagement.DIY_TAX_PREP_SOFTWARE),
    generateAnswer(PersonalFinancesManagement.NONE),
  ],
}

export const QUESTION_PRIMARY_STATE_RESIDENCE: ProfileQuestion = {
  mixpanelId: 'profile-residence',
  key: ProfileServiceNeedsFormKey.statesForEstatePlanning,
  text: 'In which state should your trust & estate plan be based?',
  subText: 'For U.S. residents, choose your state of primary residence. For international residents, choose the state where your U.S. based assets are located or where you plan to live in the future.',
  answerType: AnswerType.dropdownSearch,
  answers: Object.keys(StateAbbreviations).map((state: string) => generateMultiDropdownAnswer(state, StateAbbreviations[state])),
}

export const QUESTION_COUNTRY: ProfileQuestion = {
  mixpanelId: 'profile-country',
  key: ProfileFormKey.country,
  text: 'Where are you currently living?',
  answerType: AnswerType.dropdownSearch,
  answers: countries.slice(1).map((country) => generateMultiDropdownAnswer(country)), // slice to remove "US"
}

export const QUESTION_CITIZEN: ProfileQuestion = {
  mixpanelId: 'profile-US-citizen-resident',
  key: ProfileFormKey.isUSCitizen,
  text: 'Are you a U.S. citizen or resident?',
  answerType: AnswerType.radio,
  answers: [
    generateAnswer('Yes', true),
    generateAnswer('No', false),
  ],
}

// TODO(steph): move icons to cdn
export const PROFILE_SUMMARY_CONTENT: KeyedObject<ProfileSummaryContent> = {
  INTERNATIONAL: {
    iconName: SpotIconName.International,
    text: 'International',
  },
  FOUNDER: {
    iconName: SpotIconName.Founder,
    text: 'Founder',
  },
  SMALL_BUSINESS: {
    iconName: SpotIconName.BusinessOwner,
    text: 'Small Business Owner',
  },
  EQUITY_PARTNER: {
    iconName: SpotIconName.EquityPartner,
    text: 'Equity Partner',
  },
  TECHNOLOGY: {
    iconName: SpotIconName.Technology,
    text: 'Technology',
  },
  ASSET_MANAGEMENT: {
    iconName: SpotIconName.AssetManagement,
    text: 'Asset Management',
  },
  EQUITY_LIQUIDITY: {
    iconName: SpotIconName.Equity,
    text: 'Equity Liquidity',
  },
  PROMOTION: {
    iconName: SpotIconName.Promotion,
    text: 'Promotion',
  },
  STARTING_COMPANY: {
    iconName: SpotIconName.BusinessOwner,
    text: 'Starting Company',
  },
  CHILDREN: {
    iconName: SpotIconName.Children,
    text: 'Children',
  },
  CHANGING_JOBS: {
    iconName: SpotIconName.NewJob,
    text: 'Changing Jobs',
  },
  MOVING: {
    iconName: SpotIconName.Moving,
    text: 'Moving',
  },
  MARRIAGE: {
    iconName: SpotIconName.Marriage,
    text: 'Marriage',
  },
  HOUSE: {
    iconName: SpotIconName.House,
    text: 'Buying House',
  },
  DIGITAL_ASSETS: {
    imagePath: DigitalAssetIcon,
    text: 'Digital Assets',
  },
  PRIVATE_SHARES: {
    imagePath: EquitytIcon,
    text: 'Private Shares',
  },
  REAL_ESTATE: {
    imagePath: RealEstateIcon,
    text: 'Real Estate',
  },
  RETIRE: {
    iconName: SpotIconName.Retired,
    text: 'Retiring',
  },
}

export const PROFILE_SUMMARY_ICONS: KeyedObject<ProfileSummaryContent> = {
  INTERNATIONAL: {
    imagePath: InternationalIcon,
    text: 'International',
  },
  FOUNDER: {
    imagePath: FounderIcon,
    text: 'Founder',
  },
  SMALL_BUSINESS: {
    imagePath: SmbOwnerIcon,
    text: 'Small Business Owner',
  },
  EQUITY_PARTNER: {
    imagePath: EquityPartnerIcon,
    text: 'Equity Partner',
  },
  TECHNOLOGY: {
    imagePath: TechIcon,
    text: 'Technology',
  },
  ASSET_MANAGEMENT: {
    imagePath: AssetManagementIcon,
    text: 'Asset Management',
  },
  EQUITY_LIQUIDITY: {
    imagePath: EquityLiquidityIcon,
    text: 'Equity Liquidity',
  },
  PROMOTION: {
    imagePath: PromotionIcon,
    text: 'Promotion',
  },
  STARTING_COMPANY: {
    imagePath: StartCompanyIcon,
    text: 'Starting Company',
  },
  CHILDREN: {
    imagePath: KidsIcon,
    text: 'Children',
  },
  CHANGING_JOBS: {
    imagePath: ChangingJobsIcon,
    text: 'Changing Jobs',
  },
  MOVING: {
    imagePath: MovingIcon,
    text: 'Moving',
  },
  MARRIAGE: {
    imagePath: MarriageIcon,
    text: 'Marriage',
  },
  HOUSE: {
    imagePath: BuyingHouseIcon,
    text: 'Buying House',
  },
  DIGITAL_ASSETS: {
    imagePath: DigitalAssetsReturnIcon,
    text: 'Digital Assets',
  },
  PRIVATE_SHARES: {
    imagePath: PrivateSharesIcon,
    text: 'Private Shares',
  },
  REAL_ESTATE: {
    imagePath: RealEstateReturnIcon,
    text: 'Real Estate',
  },
  RETIRE: {
    imagePath: RetirementIcon,
    text: 'Retiring',
  },
}

export const STEPS = {
  [RoutePaths.PROFILE_V2.FORM_HOUSEHOLD_INCOME]: 1,
  [RoutePaths.PROFILE_V2.FORM_INVESTABLE_ASSETS]: 2,
  [RoutePaths.PROFILE_V2.FORM_ILLIQUID_AMOUNT]: 3,
  [RoutePaths.PROFILE_V2.FORM_FILINGS]: 4,
  [RoutePaths.PROFILE_V2.FORM_K1S]: 5,
  [RoutePaths.PROFILE_V2.FORM_LOCATION_PREFERENCE]: 6,
  [RoutePaths.PROFILE_V2.FORM_ZIP_CODE]: 7,
  [RoutePaths.PROFILE_V2.FORM_COUNTRY]: 8,
  [RoutePaths.PROFILE_V2.FORM_CITIZEN]: 9,
  [RoutePaths.PROFILE_V2.FORM_SERVICE_NEEDS]: 10,
  [RoutePaths.PROFILE_V2.FORM_RESIDENCE]: 11,
}

export const MIXPANEL_IDS = {
  [RoutePaths.ONBOARDING.FORM_EMPLOYMENT]: 'profile-employment',
  [RoutePaths.ONBOARDING.FORM_PROFESSIONS]: 'profile-professions',
  [RoutePaths.ONBOARDING.FORM_SMALL_BUSINESS_TYPE]: 'profile-small-business-type',
  [RoutePaths.ONBOARDING.FORM_SMALL_BUSINESS_REVENUE]: 'profile-small-business-revenue',
  [RoutePaths.PROFILE_V2.FORM_HOUSEHOLD_INCOME]: 'profile-income',
  [RoutePaths.PROFILE_V2.FORM_INVESTABLE_ASSETS]: 'profile-investable',
  [RoutePaths.ONBOARDING.FORM_ILLIQUID_TYPE]: 'profile-illiquid',
  [RoutePaths.PROFILE_V2.FORM_ILLIQUID_AMOUNT]: 'profile-illiquid',
  [RoutePaths.PROFILE_V2.FORM_FILINGS]: 'profile-filings',
  [RoutePaths.PROFILE_V2.FORM_K1S]: 'profile-k1s',
  [RoutePaths.PROFILE_V2.FORM_LOCATION_PREFERENCE]: 'profile-location',
  [RoutePaths.PROFILE_V2.FORM_ZIP_CODE]: 'profile-zipcode',
  [RoutePaths.PROFILE_V2.FORM_COUNTRY]: 'profile-country',
  [RoutePaths.PROFILE_V2.FORM_CITIZEN]: 'profile-US-citizen-resident',
  [RoutePaths.PROFILE_V2.FORM_SERVICE_NEEDS]: 'adviser-services',
  [RoutePaths.PROFILE_V2.FORM_RESIDENCE]: 'profile-residence',
}

const HOUSEHOLD_SLIDER_OPTIONS = QUESTION_HOUSEHOLD_INCOME.sliderOptions ?? DEFAULT_SLIDER_OPTIONS
const INVESTABLE_ASSETS_SLIDER_OPTIONS = QUESTION_INVESTABLE_ASSETS.sliderOptions ?? DEFAULT_SLIDER_OPTIONS
const OTHER_ASSETS_SLIDER_OPTIONS = QUESTION_OTHER_ASSETS.sliderOptions ?? DEFAULT_SLIDER_OPTIONS

export const initialProfilePreferences: ProfilePreferencesLocalData = {
  [ProfileFormKey.keyLifeEvents]: {},
  [ProfileFormKey.majorFinancialChanges]: {},
  [ProfileFormKey.otherMilestones]: '',
  [ProfileFormKey.otherWindfalls]: '',
  [ProfileFormKey.windfallTiming]: {},
  [ProfileFormKey.windfallAmount]: null,
  [ProfileFormKey.expectsAdditionalInvestableAssets]: false,

  [ProfileFormKey.employmentType]: {},
  [ProfileFormKey.otherEmployment]: '',
  [ProfileFormKey.smallBusinessType]: '',
  [ProfileFormKey.smallBusinessFunding]: {},
  [ProfileFormKey.smallBusinessRevenue]: null,
  [ProfileFormKey.householdIndustries]: [],
  [ProfileFormKey.otherHouseholdIndustries]: '',
  [ProfileFormKey.subindustries]: {},
  [ProfileFormKey.householdIncome]: HOUSEHOLD_SLIDER_OPTIONS.range[0],
  [ProfileFormKey.totalInvestableAssets]: INVESTABLE_ASSETS_SLIDER_OPTIONS.range[0],
  [ProfileFormKey.otherAssets]: OTHER_ASSETS_SLIDER_OPTIONS.range[0],
  [ProfileFormKey.illiquidAssets]: {},
  [ProfileFormKey.otherIlliquidAssets]: '',
  [ProfileFormKey.statesForTaxFiling]: undefined,
  [ProfileFormKey.k1sForTaxFiling]: undefined,
  [ProfileFormKey.personalFinancesManagement]: {},
  [ProfileFormKey.otherPersonalFinancesManagement]: '',

  [ProfileFormKey.hasAdviserLocationPreference]: null,
  [ProfileFormKey.zipcodePreferences]: '',
  [ProfileFormKey.country]: 'United States',
  [ProfileFormKey.isUSCitizen]: true,
}

export const equityEvents: Set<string> = new Set(
  [
    WindfallTypes.EQUITY_LIQUIDITY_EVENT,
    WindfallTypes.SECONDARY_SALE,
    WindfallTypes.EQUITY_EVENT_IPO,
    WindfallTypes.EXERCISE_OPTIONS,
  ],
)
