import {createApi} from '@reduxjs/toolkit/query/react'

import baseQuery from 'store/main/baseQuery'
import {
  NetWorthSeriesResponse,
  NetWorthTimeseriesRouteId,
} from 'models/timeSeries'

type NetWorthQueryParams = {
  clientId: string;
  routeId: NetWorthTimeseriesRouteId;
  start: string; // YYYY-MM-DD
  end: string; // YYYY-MM-DD
}

export const timeSeriesApi = createApi({
  reducerPath: 'timeSeriesApi',
  baseQuery,
  endpoints: (builder) => ({
    getNetWorthSeries: builder.query<NetWorthSeriesResponse, NetWorthQueryParams>({
      query: ({
        clientId,
        routeId,
        start,
        end,
      }: NetWorthQueryParams) => ({
        url: `/v1/timeseries/client:${clientId}:${routeId}`,
        params: {start, end},
      }),
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {useGetNetWorthSeriesQuery} = timeSeriesApi
