export enum Environment {
  LOCAL = 'local',
  GCLOUD_STAGE = 'gcloud_stage',
  DEMO = 'demo',
  GCLOUD_PROD = 'gcloud_prod',
  TEST = 'test',
  GCLOUD_FEATURE = 'gcloud_feature',
}

export type Base = {
  MARKETING_SITE_URL: string;
  URL_TERMS_OF_SERVICE: string;
  URL_PRIVACY_POLICY: string;
  URL_IN_THE_NEWS: string;
  URL_HARNESS_FOR_EMPLOYERS: string;
  URL_CRS_FORM: string;
  URL_CRS_DISCLOSURES: string;
  URL_DISCLOSURES: string;
  URL_PROMOTERS_DISCLOSURE: string;
  URL_ADV_PART_2A: string;
  URL_CRS: string;
  URL_LINKEDIN: string;
  URL_TWITTER: string;
  URL_INSTAGRAM: string;
  URL_ROADMAP: string;
  PLAID_ENVIRONMENT: string;
  PLAID_PUBLIC_KEY: string;
  GOOGLE_SSO_CLIENT_ID: string;
  URL_ABOUT_US: string;
  URL_ARTICLES: string;
  URL_GLB_PRIVACY_NOTICE: string;
  URL_ADVISOR_AGREEMENT: string;
}

export type EnvConfig = {
  ENV: Environment;

  HELLO_SIGN_CLIENT_ID: string;
  LINKEDIN_CLIENTID: string;
  FB_PIXEL_ID?: string;
  OPEN_HUBSPOT_CHAT?: boolean;
  VEZGO_CLIENT_ID?: string;
  RELEASE_ID?: string;
  SENTRY_HOST?: string;
  SENTRY_TRACING_RATE?: number;
  GA_TOKEN?: string;
  USE_GTM?: boolean;
  CANNY_APP_ID?: string;
  MARKETING_SITE_URL?: string;
  MARKETING_SITE_OUR_ADVISERS_URL?: string;
  MARKETING_SITE_IMPACT_PLANNING_URL?: string;
  MARKETING_SITE_ABOUT_US_URL?: string;
  MIXPANEL_ACTIVE?: boolean;
  MIXPANEL_TOKEN?: string;
  MIXPANEL_PROXY_DOMAIN?: string;
  URL_TERMS_OF_SERVICE?: string;
  URL_PRIVACY_POLICY?: string;
  URL_IN_THE_NEWS?: string;
  URL_LINKEDIN?: string;
  URL_TWITTER?: string;
  HELLO_SIGN_SKIP_DOMAIN_VERIFY?: boolean;
  HUBSPOT_QUESTIONNAIRE_FORM_ID: string;
}
