import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {useMediaQuery} from 'react-responsive'
import cx from 'classnames'

import HWLogoStacked from 'core/components/icon/HWLogoStacked'
import Link from 'core/components/link/Link'

import styles from './_styles.module.scss'

import colors from 'core/styles/_colors.module.scss'
import {AppSession, MixpanelAgent} from 'core/utils'
import {MediaQuery} from 'core/legacy-components/responsive/breakpoints'
import {logOut} from 'models'
import {RoutePaths} from 'core/constants/RoutePaths'

const AuthenticatedNavMenu = lazy(() => import(/* webpackChunkName: "AuthenticatedNavMenu" */ './AuthenticatedNavMenu'))
const MobileNavigation = lazy(() => import(/* webpackChunkName: "MobileNavigation" */ './MobileNavigation'))
const UnauthenticatedNavMenu = lazy(() => import(/* webpackChunkName: "UnauthenticatedNavMenu" */ './UnauthenticatedNavMenu')) // eslint-disable-line max-len

type Props = {
  onInternetExplorer: boolean;
  session: AppSession;
  isAuthenticatedRoute: boolean;
}

/*
 AppNavigation encapsulates everything to do with navigating (via links) to different routes
 within the app. Two things to say up front:
  1. The unauthenticated navigation experience is meant to mimic the marketing site as much as possible
  (to give the sensation of seamlessness when transitioning from the marketing to product site)
  2. The authenticated product navigation experience incorporates a sidebar navigation style.

 AppNavigation is broken down into three sections:
   1. MobileNavigation - contains the unauthenticated/authenticated mobile navigation components
   2. Authenticated Side Navigation menu (>= Landscape-Tablet breakpoint)
   3. Unauthenticated Top Navigation (>= Landscape-Tablet breakpoint)
*/
export default function AppNavigation({
  onInternetExplorer,
  session,
  isAuthenticatedRoute,
}: Props): JSX.Element {
  const isBeigeTheme = !session.userIsAuthenticated
  const isAboveMobileNav = useMediaQuery({query: MediaQuery.ABOVE_MOBILE_NAV})
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false)
  const [signingOut, setSigningOut] = useState<boolean>(false)
  const [top, setTop] = useState(true)

  useEffect(() => {
    function handleScroll(): void {
      setTop((document.documentElement.scrollTop || document.body.scrollTop) <= 0)
    }

    if (!session.userIsAuthenticated) {
      window.addEventListener('scroll', handleScroll)
      handleScroll()
    }

    return (): void => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [session.userIsAuthenticated])

  const openMobileMenu = useCallback(() => {
    setHamburgerOpen(true)
    document.body.classList.add(styles.noScroll)
  }, [])

  const closeMobileMenu = useCallback(() => {
    setHamburgerOpen(false)
    document.body.classList.remove(styles.noScroll)
  }, [])

  useEffect(() => {
    // Prevent no scroll if viewport resizes from mobile (w/ menu open) to above mobile
    if (isAboveMobileNav) closeMobileMenu()
  }, [closeMobileMenu, isAboveMobileNav])

  const onSignOutClick = useCallback(async () => {
    setSigningOut(true)

    const {error} = await logOut()

    if (error) {
      setSigningOut(false)
      return
    }

    MixpanelAgent.track('signout')
    session.endSession()
    window.location.href = window.location.origin
  }, [session])

  return (
    <div
      data-cy="app-navigation"
      className={cx(styles.appNavigation, {
        [styles.internetExplorer]: onInternetExplorer,
        [styles.authenticatedSidebar]: isAboveMobileNav && session.userIsAuthenticated,
        [styles.top]: top,
        [styles.beigeTheme]: isBeigeTheme,
      })}
    >
      <Suspense fallback={null}>
        <>
          {!isAboveMobileNav && (
            <MobileNavigation
              closeMobileMenu={closeMobileMenu}
              hamburgerOpen={hamburgerOpen}
              onSignOut={onSignOutClick}
              openMobileMenu={openMobileMenu}
              signOutInProgress={signingOut}
              session={session}
            />
          )}
          {(isAboveMobileNav && session.userIsAuthenticated && isAuthenticatedRoute) && (
            <div className={styles.authenticatedSidebarContentContainer}>
              <div className={styles.logoLinkContainer}>
                <Link
                  aria-label="home dashboard"
                  className={styles.logoLink}
                  to={RoutePaths.ADVISER_SEARCH}
                >
                  <HWLogoStacked fill={colors.darkBlue500} size={60} />
                </Link>
              </div>
              <AuthenticatedNavMenu
                session={session}
                onSignOut={onSignOutClick}
                signOutInProgress={signingOut}
              />
            </div>
          )}
          {(isAboveMobileNav && !session.userIsAuthenticated) && <UnauthenticatedNavMenu />}
        </>
      </Suspense>
    </div>
  )
}
