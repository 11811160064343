import React, {lazy} from 'react'

import {OptionsMenu, OptionsMenuProps} from './OptionsMenu'

const KebabIcon = lazy(() => import('../icon/Kebab'))

export const KebabMenu = ({
  className = '',
  disabled = false,
  direction = 'left',
  options,
  'data-cy': dataCy,
}: OptionsMenuProps): JSX.Element => {
  const kebabOptions = options.map((option) => ({...option, 'data-cy': 'kebab-menu'}))

  return (
    <OptionsMenu
      className={className}
      disabled={disabled}
      direction={direction}
      menuIcon={<KebabIcon fill={disabled ? '#6A7A8E' : '#082042'} />}
      data-cy={dataCy}
      options={kebabOptions}
    />
  )
}
