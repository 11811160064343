import {useEffect, useState} from 'react'

import {MixpanelAgent} from 'core/utils'

type PrefinerySubmitArgs = {
  origin: string;
  data: string;
  isTrusted: boolean;
}

const trackPrefinerySubmit = ({origin, data, isTrusted}: PrefinerySubmitArgs): void => {
  if (
    isTrusted
    && data === 'invitation_submit'
    && origin === 'https://i.prefinery.com'
  ) {
    MixpanelAgent.track('tools-invite-a-friend-click')
  }
}

const usePrefinery = (): boolean => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://widget.prefinery.com/widget/v2/agshowvm.js'
    scriptTag.onload = (): void => setScriptLoaded(true)

    document.body.appendChild(scriptTag)

    window.addEventListener('message', trackPrefinerySubmit)

    return (): void => {
      // eslint-disable-next-line no-underscore-dangle
      window._pfy = undefined
      window.removeEventListener('message', trackPrefinerySubmit)
      document.body.removeChild(scriptTag)
    }
  }, [])

  return scriptLoaded
}

export default usePrefinery
