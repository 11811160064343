import React, {Children, ReactElement, useState} from 'react'
import styled from 'styled-components'

import Overline from '../components/display/Overline'

import {Props as TabProps} from './Tab'

type Props = {
  children: ReactElement<TabProps>[];
  onTabChanged?: (index: number) => void;
}

export const Tabs = ({children, onTabChanged}: Props): JSX.Element => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <>
      <ul>
        {Children.map(children, (child, index) => (
          <StyledListItem
            key={child.props.title}
            onClick={(): void => {
              setActiveTabIndex(index)

              if (onTabChanged) {
                onTabChanged(index)
              }
            }}
            role="presentation"
            isActive={index === activeTabIndex}
            tabsCount={children.length}
          >
            <Overline text={child.props.title} />
          </StyledListItem>
        ))}
      </ul>
      <StyledTab>
        {children[activeTabIndex]}
      </StyledTab>
    </>
  )
}
const StyledTab = styled.div`
  width: 100%;
`

const StyledListItem = styled.li<{isActive: boolean; tabsCount: number}>(({theme, isActive, tabsCount}) => `
  display: inline-block;
  padding: ${theme.spacing.l};
  border-bottom: 2px solid ${isActive ? theme.colors.darkBlue500 : theme.colors.darkBlue100};
  transition: all 0.5s;
  cursor: pointer;
  color: ${isActive ? theme.colors.darkBlue500 : theme.colors.darkBlue300};
  width: calc(${100 / tabsCount}%);
  text-align: center;
`)
