import React from 'react'
import {Markup} from 'interweave'

import TextTooltip from 'core/components/tooltip/TextTooltip'

import l from '../locale'

import styles from './_taxBreakdown.module.scss'

export enum TaxColumnType {
  fromExercise = 'fromExercise',
  fromSale = 'fromSale',
  totalTaxes = 'totalTaxes',
}

const copy = {
  [TaxColumnType.fromExercise]: l.TAX_OVERVIEW.COLUMNS.FROM_EXERCISE,
  [TaxColumnType.fromSale]: l.TAX_OVERVIEW.COLUMNS.FROM_SALE,
  [TaxColumnType.totalTaxes]: l.TAX_OVERVIEW.COLUMNS.TOTAL_TAXES,
}

export type Props = {
  taxColumnType: TaxColumnType;
  taxYear: number;
}

function TaxColumnLabel({taxColumnType, taxYear}: Props): JSX.Element {
  return (
    <TextTooltip
      appendTo={document.body} // prevents tooltip being cut off
      popperOptions={{positionFixed: true}}
      content={(
        <div className={styles.taxColumnTooltip}>
          <p className={styles.description}>
            {copy[taxColumnType].TOOLTIP_TEXT.replace('[YEAR]', taxYear.toString())}
          </p>
          <Markup tagName="p" content={copy[taxColumnType].TOOLTIP_LIST_CAPTION} />
          <ul className={styles.taxList}>
            {copy[taxColumnType].TOOLTIP_LIST_ITEMS.map((item) => (
              <li key={item}>{item.replace('[YEAR]', taxYear.toString())}</li>
            ))}
          </ul>
        </div>
      )}
      text={copy[taxColumnType].LABEL}
    />
  )
}

export default React.memo(TaxColumnLabel)
