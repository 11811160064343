import {EnhancedStore, configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {combineReducers} from 'redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  Persistor,
  REGISTER,
  REHYDRATE,
  persistStore,
} from 'redux-persist'

import {apiSlice} from './apiSlice'

import {alertReducer} from 'store/alert'
import {companyReducer} from 'store/company'
import {cryptoAccountsReducer} from 'store/crypto'
import {dynamicStringsApi} from 'store/dynamic-strings'
import {liquidityEventReducer} from 'store/liquidity-event'
import {notificationCenterApi} from 'store/notification-center'
import {profileOnboardingReducer} from 'store/profile'
import {workflowApi} from 'store/workflow'
import {userReducer} from 'store/user'
import {timeSeriesApi} from 'store/timeseries'

const rootReducer = combineReducers({
  user: userReducer,
  cryptoAccounts: cryptoAccountsReducer,
  alert: alertReducer,
  company: companyReducer,
  liquidityEvent: liquidityEventReducer,
  profileOnboarding: profileOnboardingReducer,
  [dynamicStringsApi.reducerPath]: dynamicStringsApi.reducer,
  [notificationCenterApi.reducerPath]: notificationCenterApi.reducer,
  [workflowApi.reducerPath]: workflowApi.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [timeSeriesApi.reducerPath]: timeSeriesApi.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default (): {
  persistor: Persistor;
  store: EnhancedStore<RootState>;
} => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(
        dynamicStringsApi.middleware,
        notificationCenterApi.middleware,
        workflowApi.middleware,
        apiSlice.middleware,
        timeSeriesApi.middleware,
      ),
  })

  // required for refetchOnFocus/refetchOnReconnect behaviors
  setupListeners(store.dispatch)
  const persistor = persistStore(store)
  return {store, persistor}
}
