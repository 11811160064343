import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import cx from 'classnames'

import Button from 'core/components/button/Button'

import styles from './_styles.module.scss'
import {FORM as locale} from './locale'

type Props = {
  loading?: boolean;
  routeOnCancel?: string;
  cancelText?: string;
  proceedText?: string;
  onCancel?: () => void;
  disableCancel?: boolean;
  disableConfirm?: boolean;
  // Signifies whether this component is inside a form in a Modal component
  isInModal?: boolean;
  // Indicates whether the primary action and cancel action should switch places on mobile,
  // such as when stacking
  flipMobileActions?: boolean;
  /* Whether the Confirm and Cancel buttons should swap order */
  flipButtons?: boolean;
  /** Intent of the confirm CTA. Defaults to `primary`. */
  confirmIntent?: 'primary' | 'secondary' | 'tertiary';
  /** Intent of the cancel CTA. Defaults to `tertiary`. */
  cancelIntent?: 'primary' | 'secondary' | 'tertiary';
  /** Next step text to display if Form has steps specified */
  nextStep?: string;
}

export default function FormSubmit({
  loading = false,
  routeOnCancel,
  cancelText,
  proceedText,
  onCancel,
  disableCancel = false,
  disableConfirm = false,
  isInModal = false,
  flipMobileActions = false,
  flipButtons = false,
  confirmIntent = 'primary',
  cancelIntent = 'tertiary',
  nextStep,
}: Props): JSX.Element {
  const history = useHistory()
  const onClick = useCallback(() => {
    if (onCancel) {
      onCancel()
      return
    }

    if (routeOnCancel) {
      history.push(routeOnCancel)
    }
  }, [onCancel, routeOnCancel, history])

  return (
    <div>
      {nextStep && (
      <div className={styles.nextStepWrapper}>
        <p>
          <span className={styles.nextStep}>
            {locale.NEXT_STEP}
            :
          </span>
          {' '}
          {nextStep}
        </p>
      </div>
      )}

      <div className={cx(styles.buttonsWrapper, {
        [styles.inModal]: isInModal,
        [styles.flip]: flipButtons,
        [styles.flipMobile]: flipMobileActions,
        [styles.withNextStep]: nextStep !== undefined,
      })}
      >
        {
        (onCancel || routeOnCancel) && (
        <Button
          type="button"
          intent={cancelIntent}
          onClick={onClick}
          disabled={disableCancel || loading}
          className={cx(styles.addEquityButton, {[styles.cancelBtn]: isInModal})}
          data-cy="form-submit-cancel-button"
        >
          {cancelText || locale.CANCEL}
        </Button>
        )
      }

        <Button
          type="submit"
          intent={confirmIntent}
          loading={loading}
          disabled={disableConfirm}
          className={cx(styles.addEquityButton, {[styles.confirmBtn]: isInModal})}
          data-cy="form-submit-confirm-button"
        >
          {proceedText || locale.SUBMIT}
        </Button>
      </div>
    </div>
  )
}
