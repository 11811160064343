import {createApi} from '@reduxjs/toolkit/query/react'

import {WorkflowState} from './types'

import baseQuery from 'store/main/baseQuery'

export const workflowApi = createApi({
  reducerPath: 'workflowApi',
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getWorkflowState: builder.query<WorkflowState, void>({
      query: () => 'v1/workflow/',
    }),
  }),
})

export const {
  useGetWorkflowStateQuery,
} = workflowApi
