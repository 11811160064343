/* eslint-disable max-len */
import React from 'react'

type Props = {
  fill?: string;
  size?: string | number;
  stroke?: string;
}

export default function CaretRight(
  {
    fill = '#082042',
    size = 20,
    stroke = '',
    ...rest
  }: Props,
): JSX.Element {
  return (
    <svg height={size} width={size} viewBox="0 0 20 20" {...rest}>
      <path fill={fill} fillRule="evenodd" stroke={stroke} d="M6.379 4.293a1 1 0 000 1.414L10.172 9.5l-3.793 3.793a1 1 0 101.414 1.414L13 9.5 7.793 4.293a1 1 0 00-1.414 0z" clipRule="evenodd" />
    </svg>
  )
}
