import * as Events from './events'

export const Analytics = {
  ...Events.commonAnalyticsEvents,
  ...Events.marketplaceAnalyticsEvents,
  ...Events.toolsAnalyticsEvents,
  ...Events.advisorSessionsAnalyticsEvents,
  ...Events.uploadEvents,
  ...Events.engagementsAnalyticsEvents,
  ...Events.twoFactorEvents,
}
