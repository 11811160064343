// eslint-disable-next-line import/no-unresolved
import {OverridedMixpanel} from 'mixpanel-browser' // in dev dependencies
import noop from 'lodash/noop'

import config, {Environment} from '../config'

declare global {
  interface Window {
    mixpanel: OverridedMixpanel;
  }
}

export const MixpanelAgent = {
  track: (event: string, properties?: KeyedObject): void => {
    // eslint-disable-next-line no-console
    console.log('Tracking', event, properties)
  },

  register: noop,
  identify: noop,
  setUserData: noop,
  logout: noop,

  config(mixPanelToken: string): void {
    if (!mixPanelToken) return

    const proxyDomain: string | undefined = config.MIXPANEL_PROXY_DOMAIN
    const mpConfig = proxyDomain ? {api_host: `https://${proxyDomain}`} : {}
    window.mixpanel.init(mixPanelToken, mpConfig)

    this.track = (event: string, properties?: KeyedObject): void => {
      window.mixpanel.track(event, properties)
      if (config.ENV !== Environment.GCLOUD_PROD) {
        console.log('Tracking', event, properties) // eslint-disable-line no-console
      }
    }

    this.register = (superProperties: KeyedObject): void => {
      // set super properties https://developer.mixpanel.com/docs/javascript#super-properties
      // and reserved properties for
      // events https://help.mixpanel.com/hc/en-us/articles/360001355266#reserved-properties-for-events
      window.mixpanel.register(superProperties)
    }

    this.identify = (clientId: string): void => {
      window.mixpanel.identify(clientId)
    }

    this.setUserData = (properties: KeyedObject): void => {
      // set profile super properties
      // and reserved properties for profiles https://help.mixpanel.com/hc/en-us/articles/360001355266
      window.mixpanel.people.set(properties)
    }
  },
}
