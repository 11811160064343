import track from '../track'

import {ClientProfile} from 'models'
import {Engagement, Task} from 'store/advisor-sessions'

type TaskEventProperties = {
  engagement?: Engagement;
  task: Task;
  pathUrl: string;
  profile?: ClientProfile;

  // The following properties pertain only to questionnaire tasks.
  totalQuestionsAnswered?: number;
  totalQuestionsAvailable?: number;
  percentQuestionsCompleted?: number;
  returnClientQuestionnaire?: boolean;

  // The following properties pertain only to file upload tasks.
  hasSuggestedUploads?: boolean;
  hasNoLongerRelevant?: boolean;

  // The following properties pertain only to awaiting documents tasks.
  isAwaitingK1Task?: boolean;
  isWaitingForK1?: boolean;
  awaitingK1ModalClickSource?: string;
  awaitingK1Date?: string;
}

export const engagementsAnalyticsEvents = {
  taskViewed: ({
    engagement,
    task,
    pathUrl,
    profile,
    returnClientQuestionnaire,
    hasSuggestedUploads,
  }: TaskEventProperties): void => track.mixpanel('portal_engagement-dashboard_task-viewed', {
    engagementPid: engagement?.pid ?? '',
    engagementType: engagement?.engagementType ?? '',
    engagementDueDate: engagement?.dueDate ?? '',
    taskPid: task.pid ?? '',
    taskType: task.taskType ?? '',
    taskVariation: task.variation ?? '',
    taskLabel: `${task.taskType ?? ''}${task.variation ? `-${task.variation}` : ''}`,
    taskDueDate: task.dueDate ?? '',
    isAdhocTask: task.isAdhocTask ?? false,
    isAwaitingK1Task: task.isAwaitingK1Task ?? false,
    waitingForDocuments: task.waitingForDocumentTypes,
    waitingForDocumentsMessage: task.waitingForDocumentsMessage,
    kbaEnabled: task.pandadocDocuments?.some((document) => document.kbaEnabled),
    taxpayerPid: engagement?.aeTaxpayerPid ?? '',
    advisorName: `${engagement?.meta?.advisor ?? ''}`,
    advisorTeam: `${engagement?.meta?.advisorTeamName ?? ''}`,
    taxpayerType: `${engagement?.meta?.taxpayerType ?? ''}`,
    userName: profile ? `${profile.firstName} ${profile.lastName}` : '',
    email: profile?.email ?? '',
    pathUrl,
    returnClientQuestionnaire,
    hasSuggestedUploads,
  }),
  taskInProgress: ({
    engagement,
    task,
    pathUrl,
    profile,
    returnClientQuestionnaire,
    hasSuggestedUploads,
    hasNoLongerRelevant,
  }: TaskEventProperties): void => track.mixpanel('portal_engagement-dashboard_task-in-progress', {
    engagementPid: engagement?.pid ?? '',
    engagementType: engagement?.engagementType ?? '',
    engagementDueDate: engagement?.dueDate ?? '',
    taskPid: task.pid ?? '',
    taskType: task.taskType ?? '',
    taskVariation: task.variation ?? '',
    taskLabel: `${task.taskType ?? ''}${task.variation ? `-${task.variation}` : ''}`,
    taskDueDate: task.dueDate ?? '',
    isAdhocTask: task.isAdhocTask ?? false,
    isAwaitingK1Task: task.isAwaitingK1Task ?? false,
    waitingForDocuments: task.waitingForDocumentTypes,
    waitingForDocumentsMessage: task.waitingForDocumentsMessage,
    kbaEnabled: task.pandadocDocuments?.some((document) => document.kbaEnabled),
    taxpayerPid: engagement?.aeTaxpayerPid ?? '',
    advisorName: `${engagement?.meta?.advisor ?? ''}`,
    advisorTeam: `${engagement?.meta?.advisorTeamName ?? ''}`,
    taxpayerType: `${engagement?.meta?.taxpayerType ?? ''}`,
    userName: profile ? `${profile.firstName} ${profile.lastName}` : '',
    email: profile?.email ?? '',
    pathUrl,
    returnClientQuestionnaire,
    hasSuggestedUploads,
    hasNoLongerRelevant,
  }),
  taskCompleted: ({
    engagement,
    task,
    pathUrl,
    profile,
    totalQuestionsAnswered,
    totalQuestionsAvailable,
    percentQuestionsCompleted,
    returnClientQuestionnaire,
    hasSuggestedUploads,
    hasNoLongerRelevant,
  }: TaskEventProperties): void => track.mixpanel('portal_engagement-dashboard_task-completed', {
    engagementPid: engagement?.pid ?? '',
    engagementType: engagement?.engagementType ?? '',
    engagementDueDate: engagement?.dueDate ?? '',
    taskPid: task.pid ?? '',
    taskType: task.taskType ?? '',
    taskVariation: task.variation ?? '',
    taskLabel: `${task.taskType ?? ''}${task.variation ? `-${task.variation}` : ''}`,
    taskDueDate: task.dueDate ?? '',
    isAdhocTask: task.isAdhocTask ?? false,
    isAwaitingK1Task: task.isAwaitingK1Task ?? false,
    waitingForDocuments: task.waitingForDocumentTypes,
    waitingForDocumentsMessage: task.waitingForDocumentsMessage,
    kbaEnabled: task.pandadocDocuments?.some((document) => document.kbaEnabled),
    taxpayerPid: engagement?.aeTaxpayerPid ?? '',
    advisorName: `${engagement?.meta?.advisor ?? ''}`,
    advisorTeam: `${engagement?.meta?.advisorTeamName ?? ''}`,
    taxpayerType: `${engagement?.meta?.taxpayerType ?? ''}`,
    userName: profile ? `${profile.firstName} ${profile.lastName}` : '',
    email: profile?.email ?? '',
    pathUrl,
    totalQuestionsAnswered,
    totalQuestionsAvailable,
    percentQuestionsCompleted,
    returnClientQuestionnaire,
    hasSuggestedUploads,
    hasNoLongerRelevant,
  }),
  taskDismissed: ({
    engagement,
    task,
    pathUrl,
    profile,
    returnClientQuestionnaire,
    hasSuggestedUploads,
  }: TaskEventProperties): void => track.mixpanel('portal_engagement-dashboard_task-dismissed', {
    engagementPid: engagement?.pid ?? '',
    engagementType: engagement?.engagementType ?? '',
    engagementDueDate: engagement?.dueDate ?? '',
    taskPid: task.pid ?? '',
    taskType: task.taskType ?? '',
    taskVariation: task.variation ?? '',
    taskLabel: `${task.taskType ?? ''}${task.variation ? `-${task.variation}` : ''}`,
    taskDueDate: task.dueDate ?? '',
    isAdhocTask: task.isAdhocTask ?? false,
    isAwaitingK1Task: task.isAwaitingK1Task ?? false,
    waitingForDocuments: task.waitingForDocumentTypes,
    waitingForDocumentsMessage: task.waitingForDocumentsMessage,
    taxpayerPid: engagement?.aeTaxpayerPid ?? '',
    advisorName: `${engagement?.meta?.advisor ?? ''}`,
    advisorTeam: `${engagement?.meta?.advisorTeamName ?? ''}`,
    taxpayerType: `${engagement?.meta?.taxpayerType ?? ''}`,
    userName: profile ? `${profile.firstName} ${profile.lastName}` : '',
    email: profile?.email ?? '',
    pathUrl,
    returnClientQuestionnaire,
    hasSuggestedUploads,
  }),
  taskNoLongerRelevant: ({
    engagement,
    categoryName,
    formType,
    reasons,
    dismissed,
    dismissedRatio,
  }: {
    engagement: Engagement;
    categoryName: string;
    formType: string;
    reasons: string[];
    dismissed: boolean;
    dismissedRatio: string;
  }): void => track.mixpanel('portal_engagement-task_no-longer-relevant', {
    engagementPid: engagement?.pid ?? '',
    engagementType: engagement?.engagementType ?? '',
    taxpayerType: `${engagement?.meta?.taxpayerType ?? ''}`,
    category: categoryName,
    formType,
    reasons,
    action: dismissed ? 'Mark no longer relevant' : 'Mark relevant',
    dismissedRatio,
  }),
  taskMissingDocs: ({
    engagement,
    task,
    pathUrl,
    profile,
    isWaitingForK1,
    awaitingK1ModalClickSource,
    awaitingK1Date,
  }: TaskEventProperties): void => track.mixpanel('portal_engagement-task_missing-docs', {
    engagementPid: engagement?.pid ?? '',
    engagementType: engagement?.engagementType ?? '',
    engagementDueDate: engagement?.dueDate ?? '',
    taskPid: task.pid ?? '',
    taskType: task.taskType ?? '',
    taskVariation: task.variation ?? '',
    taskLabel: `${task.taskType ?? ''}${task.variation ? `-${task.variation}` : ''}`,
    taskDueDate: task.dueDate ?? '',
    isAdhocTask: task.isAdhocTask ?? false,
    isAwaitingK1Task: task.isAwaitingK1Task ?? false,
    taxpayerPid: engagement?.aeTaxpayerPid ?? '',
    advisorName: `${engagement?.meta?.advisor ?? ''}`,
    advisorTeam: `${engagement?.meta?.advisorTeamName ?? ''}`,
    taxpayerType: `${engagement?.meta?.taxpayerType ?? ''}`,
    userName: profile ? `${profile.firstName} ${profile.lastName}` : '',
    email: profile?.email ?? '',
    pathUrl,
    isWaitingForK1,
    awaitingK1ModalClickSource,
    awaitingK1Date,
  }),
}
