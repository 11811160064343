import React from 'react'
import cx from 'classnames'

import styles from './_link.module.scss'

export enum MailToAddresses {
  CONCIERGE = 'concierge@harnesswealth.com',
  PR = 'pr@harnesswealth.com',
  SECURITY = 'security@harnesswealth.com',
  HARNESS_TAX = 'hello@harnesstax.com',
}

type Props = {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  emailSubject?: string;
  intent?: 'inline' | 'underlined' | 'nav' | 'primary' | 'secondary' | 'tertiary';
  mailTo: MailToAddresses;
  onClick?(): void;
  small?: boolean;
}

export default function MailtoLink({
  className,
  children,
  emailSubject,
  intent,
  mailTo,
  onClick,
  small,
}: Props): JSX.Element {
  let href = `mailto:${mailTo}?`

  if (emailSubject) {
    href += `subject=${emailSubject}`
  }

  return (
    <a
      data-theme="default"
      className={cx(styles.link, intent && styles[intent], small && styles.small, className)}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  )
}
