import api from '../api'

import {CreateCryptoAccountResponse, CryptoAccount} from './types'

/**
 * https://plutus.harnesswealth.com/api/v2/docs/#operation/crypto_account_list
 */
export const fetchCryptoAccounts = (): Promise<CryptoAccount[]> => api.get('v2/crypto/account/')
export const createCryptoAccount = (accountId: string): Promise<CreateCryptoAccountResponse> => api.post(
  'v2/crypto/account/',
  {accountId},
)
export const updateCryptoAccount = (id: string, data: Partial<CryptoAccount>): Promise<CryptoAccount> => api.patch(
  `v2/crypto/account/${id}/`,
  data,
)
export const deleteCryptoAccount = (id: string): Promise<void> => api.delete(`v2/crypto/account/${id}/`)
