/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Series,
  SeriesPoint,
  axisLeft,
  max,
  scaleBand,
  scaleLinear,
  select,
  stack,
  stackOffsetNone,
  stackOrderReverse,
} from 'd3'
import {useMediaQuery} from 'react-responsive'
import {useParams} from 'react-router-dom'
import {createPortal} from 'react-dom'
import styled from 'styled-components'

import {BreakdownCategory} from '../types'

import locale from './locale'

import {formatNumberWithCommas, numberFormatter} from 'core/formatters/numberFormatter'
import {ScenarioGrantMixType, ScenarioType, useGetLiquidityEventQuery} from 'store/scenario'

type Data = {
  key: string;
  value: number;
}[]
type ChartData = {[key: string]: number}
type OptionalString = string | undefined

const DESKTOP_MARGIN = {
  left: 150,
  right: 150,
  top: 64,
  bottom: 0,
}
const MOBILE_MARGIN = {
  left: 105,
  right: 105,
  top: 64,
  bottom: 0,
}
const BAR_WIDTH = 123
const BAR_HEIGHT = 450
const BAR_HEIGHT_SHORT = 310
const COLORS = [
  {
    costAndTax: '#AFB9AE',
    profit: '#364F35',
  }, {
    costAndTax: '#9CA6B4',
    profit: '#082042',
  },
]

const REMAINING_EQUITY_COLORS = [
  {
    dark: '#CAD7CA',
    light: '#F5F5F5',
  }, {
    dark: '#CED2D9',
    light: '#F5F5F5',
  },
]

const LABEL_FONT_SIZE_MOBILE = 14
const LABEL_FONT_SIZE_DESKTOP = 16
const SUB_LABEL_FONT_SIZE_MOBILE = 12
const SUB_LABEL_FONT_SIZE_DESKTOP = 14
const MIN_BAR_SECTION_HEIGHT = 19
const MIN_ZERO_VALUE_BAR_SECTION_HEIGHT = 13
const PROCEEDS_LABEL_MARGIN = 16
const TOOLTIP_HIDDEN_STYLE = {display: 'none'}
const TOOLTIP_OFFSET = 20

/**
 * calculate percentage of each bar section
 * normalize small and zero sections to fit text labels
 * recompute non normalized section values based on original percentages to maintain stacked bar ratio
 */
function normalize(data: Data, barHeight: number): Data {
  const total = data.reduce((sum, {value}) => sum + value, 0) || MIN_ZERO_VALUE_BAR_SECTION_HEIGHT * 5
  const normalizedZeroValue = (MIN_ZERO_VALUE_BAR_SECTION_HEIGHT / barHeight) * total
  const normalizedNonZeroValue = (MIN_BAR_SECTION_HEIGHT / barHeight) * total
  let nonNormalizedTotal = total
  data.forEach(({key, value}) => {
    if (key === BreakdownCategory.REMAINING_EQUITY || value < normalizedNonZeroValue) {
      if (value === 0) nonNormalizedTotal -= normalizedZeroValue
      nonNormalizedTotal -= normalizedNonZeroValue
    }
  })
  return data.reduce((res, {key, value}) => {
    if (value === 0) return [...res, {key, value: normalizedZeroValue}]
    if (value < normalizedNonZeroValue) return [...res, {key, value: normalizedNonZeroValue}]
    return [...res, {key, value: (value / total) * nonNormalizedTotal || 0}]
  }, [] as Data)
}

/**
 * @param x left bound of rect
 * @param y upper bound of rect
 * @param height height of rect
 * @param index data index to add white border between middle rects
 * @param radius pixel number to round top rect corners
 * @param hasWhiteBorder whether to add white border beneath bar section
 * */
function drawTopRoundedRect(
  x: number,
  y: number,
  height: number,
  index: number,
  radius: number,
  hasWhiteBorder: boolean,
): string {
  // add white border between sections excluding remaining equity and last rect
  const h = hasWhiteBorder ? height : Math.max(0, height - 2)
  // const h = index === 0 || key === BreakdownCategory.REMAINING_EQUITY ? height : Math.max(0, height - 2)
  // change radius to 0 if height is 0
  const r = h <= 0 ? 0 : radius
  return `M${x + r},${y}h${BAR_WIDTH - r * 2}a${r},${r} 0 0 1 ${r},${r}v${h - r}h${-BAR_WIDTH}v${-(h - r)}a${r},${r} 0 0 1 ${r},${-r}z` /* eslint-disable-line max-len */
}

type Props = {
  scenarioType: ScenarioType;
  isShortGraph: boolean;
}

export const BreakdownChart = ({scenarioType, isShortGraph}: Props): JSX.Element => {
  const id = 'breakdown-chart-1'
  const {companyId} = useParams<{companyId: string}>()
  const {data: scenario} = useGetLiquidityEventQuery(companyId, {
    selectFromResult: ({data}) => ({
      data: data?.scenarios?.find(({type}) => type === scenarioType),
    }),
  })

  const isRsuOnly = scenario?.grantType === ScenarioGrantMixType.ONLY_RSU
  const barHeight: number = isShortGraph ? BAR_HEIGHT_SHORT : BAR_HEIGHT
  const exerciseSummaryData = scenario?.exercise.summary
  const data = useMemo(() => ([
    ...(scenario?.remainingEquity ? [{key: BreakdownCategory.REMAINING_EQUITY, value: scenario.remainingEquity}] : []),
    ...(!isRsuOnly && scenario ? [{key: BreakdownCategory.EXERCISE_COST, value: scenario.exercise.cost}] : []),
    ...(!isRsuOnly && scenario ? [{key: BreakdownCategory.EXERCISE_TAX, value: scenario.exercise.taxTotals?.total || 0}] : []),
    ...(isRsuOnly ? [{key: BreakdownCategory.RSU_INCOME_TAX, value: scenario.rsu.incomeTaxes.total || 0}] : []),
    {
      key: BreakdownCategory.SALE_TAX,
      value: scenario?.sale.taxTotals.total || 0,
    },
    {
      key: BreakdownCategory.SALE_PROFIT,
      value: scenario?.netProfit || 0,
    },
    {
      key: BreakdownCategory.GROSS_PROCEEDS,
      value: scenario?.sale.grossProceeds || 0,
    },
  ]), [isRsuOnly, scenario])

  // Set to 1 just to keep same colors, will be updated on new version
  const scenarioIndex = 1

  const chartRef = useRef<SVGSVGElement>(null)
  const isMobile: boolean = useMediaQuery({maxWidth: 1250})

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tooltipContent, setTooltipContent] = useState<{
    category?: BreakdownCategory;
    categoryColor?: string;
    categoryLabel?: string;
    categoryDescription?: string;
    categoryPercent?: string;
    categoryValue?: string;
    style: CSSProperties;
  }>({style: TOOLTIP_HIDDEN_STYLE})

  const chartData: ChartData = useMemo(() => (
    data.reduce((res, {key, value}) => ({...res, [key]: value}), {})
  ), [data])

  const costAndTaxes = useMemo(() => [
    BreakdownCategory.AMT,
    BreakdownCategory.RSU_INCOME_TAX,
    BreakdownCategory.EXERCISE_TAX,
    BreakdownCategory.EXERCISE_COST,
    BreakdownCategory.SALE_TAX,
  ], [])

  const handleMouseMove = useCallback((
    {
      clientX,
      clientY,
    }: MouseEvent,
    d: Series<ChartData, string>,
  ) => {
    let tooltipX = {}
    if (isMobile) {
      tooltipX = clientX > window.innerWidth / 2
        ? {right: `${TOOLTIP_OFFSET}px`}
        : {left: `${TOOLTIP_OFFSET}px`}
    } else {
      tooltipX = clientX > window.innerWidth / 2
        ? {right: `${window.innerWidth - clientX + TOOLTIP_OFFSET}px`}
        : {left: `${clientX + TOOLTIP_OFFSET}px`}
    }

    const tooltipY = clientY > window.innerHeight / 2
      ? {bottom: `${window.innerHeight - clientY + TOOLTIP_OFFSET}px`}
      : {top: `${clientY + TOOLTIP_OFFSET}px`}

    let categoryColor = REMAINING_EQUITY_COLORS[scenarioIndex].dark
    if (d.key !== BreakdownCategory.REMAINING_EQUITY) {
      categoryColor = costAndTaxes.includes(d.key as BreakdownCategory)
        ? COLORS[scenarioIndex].costAndTax
        : COLORS[scenarioIndex].profit
    }

    setTooltipContent({
      category: d.key as BreakdownCategory,
      categoryColor,
      categoryDescription: locale.TOOLTIP_CATEGORY_DESCRIPTION[d.key as BreakdownCategory] as OptionalString,
      categoryLabel: locale.TOOLTIP_CATEGORY_LABEL[d.key as BreakdownCategory] as OptionalString,
      categoryPercent: d.key === BreakdownCategory.REMAINING_EQUITY
        ? ''
        : `${((chartData[d.key] / chartData[BreakdownCategory.GROSS_PROCEEDS]) * 100).toFixed(1)}%`,
      categoryValue: numberFormatter(chartData[d.key]).intCurrency,
      style: {
        display: 'block',
        ...tooltipX,
        ...tooltipY,
      },
    })
  }, [chartData, costAndTaxes, isMobile, scenarioIndex])

  useEffect(() => {
    const generateScenarioChart = (ref: SVGSVGElement): void => {
      const margin = isMobile ? MOBILE_MARGIN : DESKTOP_MARGIN
      const chartHeight = barHeight + margin.top + margin.bottom
      const chartWidth = BAR_WIDTH + margin.left + margin.right
      const labelFontSize = isMobile ? LABEL_FONT_SIZE_MOBILE : LABEL_FONT_SIZE_DESKTOP
      const subLabelFontSize = isMobile ? SUB_LABEL_FONT_SIZE_MOBILE : SUB_LABEL_FONT_SIZE_DESKTOP

      const barData = data
        .filter(({key}) => key !== BreakdownCategory.GROSS_PROCEEDS)

      const normalizedBarData = normalize(barData, barHeight)

      const normalizedBarSeries = stack<Record<string, number>>()
        .keys(normalizedBarData.map(({key}) => key))
        // series stacked in reverse order as specified in .keys(), i.e. reverse order provided by consumer's data prop
        .order(stackOrderReverse)
        .offset(stackOffsetNone)([normalizedBarData.reduce((res, {key, value}) => ({...res, [key]: value}), {})])

      // Since this is charting a single scenario, we can set the domain
      // as a single arbitrary 'category', zero in this case
      const x = scaleBand<number>()
        .range([margin.left, BAR_WIDTH + margin.left])
        .domain([0])

      // sets y-axis to chart height and defines the domain between zero
      // and the largest y1 value in the dataset
      const y = scaleLinear()
        .range([margin.top, margin.top + barHeight])
        .domain([
          max(
            normalizedBarSeries,
            (d: Series<ChartData, string>) => max(d, (_d: SeriesPoint<ChartData>) => _d[1]),
          ) || 0,
          0,
        ])

      const svg = select<SVGSVGElement, ChartData[]>(ref)

      // draw svg bounds
      svg
        .attr('width', `${chartWidth}px`)
        .attr('height', `${chartHeight}px`)
        .attr('viewbox', `0 0 ${chartWidth}px ${chartHeight}px`)

      // append linear gradient for remaining equity rect fill color
      svg.select('defs').remove()
      const defs = svg.append('defs')

      const gradient = defs
        .append('linearGradient')
        .attr('id', `${id}-gradient`)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', 1)

      // append first color in linear gradient
      gradient.append('stop')
        .attr('offset', 0)
        .attr('stop-color', REMAINING_EQUITY_COLORS[scenarioIndex].dark)

      // append second color in linear gradient
      gradient.append('stop')
        .attr('offset', '100%')
        .attr('stop-color', REMAINING_EQUITY_COLORS[scenarioIndex].light)

      svg.selectAll('.header').remove()
      const header = svg.append('text')
        .attr('class', 'header')
        .attr('text-anchor', 'middle')
        .attr('fill', '#082042')

      header
        .append('tspan')
        .attr('x', margin.left + (BAR_WIDTH / 2))
        .attr('dy', 20)
        .attr('font-size', 20)
        .text(numberFormatter(chartData[BreakdownCategory.SALE_PROFIT]).intCurrency)

      header
        .append('tspan')
        .attr('x', margin.left + (BAR_WIDTH / 2))
        .attr('dy', 20)
        .attr('font-size', 14)
        .text(locale.SUBHEADER)

      // round corners of 2 rects if remaining equity exists; otherwise, only round first rect
      let roundedRectsNum = 1
      if (chartData[BreakdownCategory.REMAINING_EQUITY] + 1 > 0) roundedRectsNum += 1

      const lastSectionIdx = normalizedBarData.length - 1

      // draw stacked bar
      svg.selectAll('.bar').remove()
      svg.append('g')
        .attr('class', 'bar')
        .selectAll('path')
        .data(normalizedBarSeries)
        .join('path')
        .attr('d', (d, i) => {
          const datum = d[0]
          return drawTopRoundedRect(
            x(0) || 0,
            y(datum[1]),
            y(datum[0]) - y(datum[1]),
            i,
            i < roundedRectsNum ? 4 : 0,
            // don't add white border to last bar section or remaining equity
            lastSectionIdx === i || d.key === BreakdownCategory.REMAINING_EQUITY,
          )
        })
        .attr('fill', (d) => {
          // fill remaining equity with gradient
          if (d.key === BreakdownCategory.REMAINING_EQUITY) {
            const gradientId = gradient.node()?.id
            if (gradientId) return `url("#${gradientId}")`
            return REMAINING_EQUITY_COLORS[scenarioIndex].dark
          }

          return costAndTaxes.includes(d.key as BreakdownCategory)
            ? COLORS[scenarioIndex].costAndTax
            : COLORS[scenarioIndex].profit
        })

      // filter out remaining equity before drawing labels
      const labelData = normalizedBarSeries.filter((d) => d.key !== BreakdownCategory.REMAINING_EQUITY)

      // draw bar section value label
      svg.selectAll('.bar-labels').remove()
      svg.append('g')
        .attr('class', 'bar-labels')
        .selectAll('text')
        .data(labelData)
        .join('text')
        .attr('x', margin.left + (BAR_WIDTH / 2))
        // center label vertically within corresponding rect
        .attr('y', ([d]) => y(d[1] + (d[0] - d[1]) / 2))
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('fill', 'white')
        .attr('font-size', 12)
        .text((d) => numberFormatter(chartData[d.key]).intCurrency)

      const labelLocale = isMobile ? locale.MOBILE_LABEL : locale.DESKTOP_LABEL
      // draw category labels to the left of bar
      svg.selectAll('.y-axis').remove()
      svg.append('g')
        .attr('class', 'y-axis')
        .call(
          axisLeft(y)
            .tickFormat((d, i) => labelLocale[labelData[i].key as BreakdownCategory])
            // center ticks vertically along corresponding rect
            .tickValues(labelData.map(([d]) => (d[1] + (d[0] - d[1]) / 2)))
            .tickSize(12)
            .tickPadding(4),
        )
        .call((g) => g.select('.domain').remove())
        .attr('font-size', labelFontSize)
        .attr('font-family', 'Theinhardt')
        .attr('transform', `translate(${margin.left}, 0)`)
        .selectAll('line')
        .attr('stroke', '#6A7A8E')

      const normalizedProceedsData = normalizedBarData.reduce((res, {key, value}) => (
        key === BreakdownCategory.REMAINING_EQUITY
          ? {...res, [BreakdownCategory.REMAINING_EQUITY]: res[BreakdownCategory.REMAINING_EQUITY] + value}
          : {...res, [BreakdownCategory.GROSS_PROCEEDS]: res[BreakdownCategory.GROSS_PROCEEDS] + value}
      ), {
        [BreakdownCategory.REMAINING_EQUITY]: 0,
        [BreakdownCategory.GROSS_PROCEEDS]: 0,
      })

      const normalizedProceedsSeries = stack<Record<string, number>>()
        .keys(
          [BreakdownCategory.REMAINING_EQUITY, BreakdownCategory.GROSS_PROCEEDS]
            .filter((key) => normalizedProceedsData[key] > 0),
        )
        .order(stackOrderReverse)
        .offset(stackOffsetNone)([normalizedProceedsData])

      svg.selectAll('.proceeds').remove()
      const proceedsGroup = svg.append('g').attr('class', 'proceeds')

      proceedsGroup
        .selectAll('rect')
        .data(normalizedProceedsSeries)
        .join('rect')
        .attr('fill', (d) => {
          if (d.key === BreakdownCategory.REMAINING_EQUITY) {
            const gradientId = gradient.node()?.id
            if (gradientId) return `url("#${gradientId}")`
            return '#CED2D9'
          }
          return '#082042'
        })
        .attr('x', () => margin.left + BAR_WIDTH + 8)
        .attr('y', ([d]) => y(d[1]))
        // modify height to add 4px gap beneath each rect except last in series
        .attr('height', ([d], i) => (y(d[0]) - y(d[1]) - (i !== normalizedProceedsSeries.length - 1 ? 4 : 0)))
        .attr('width', 2)
        .attr('rx', 1)

      const proceedLabel = proceedsGroup
        .selectAll('text')
        .data(normalizedProceedsSeries)
        .join('text')
        .attr('y', ([d], i) => {
          const h = (y(d[0]) - y(d[1]) - (i !== normalizedProceedsSeries.length - 1 ? 4 : 0))
          return Math.max(12, y(d[1]) + h / 2)
        })
        .attr('fill', (d) => (d.key === BreakdownCategory.REMAINING_EQUITY ? '#6A7A8E' : '#082042'))
      // append quantity
      proceedLabel.append('tspan')
        .attr('dy', 0)
        .attr('x', margin.left + BAR_WIDTH + PROCEEDS_LABEL_MARGIN)
        .text((d) => numberFormatter(chartData[d.key]).intCurrency)
        .attr('font-size', labelFontSize)
      // append category
      if (isMobile) {
        proceedLabel.append('tspan')
          .attr('dy', 16)
          .attr('x', margin.left + BAR_WIDTH + PROCEEDS_LABEL_MARGIN)
          .text((d) => (locale.MOBILE_PROCEEDS_LABEL[d.key] as KeyedObject<string>).LINE_1)
          .attr('font-size', labelFontSize)
        proceedLabel.append('tspan')
          .attr('dy', 16)
          .attr('x', margin.left + BAR_WIDTH + PROCEEDS_LABEL_MARGIN)
          .text((d) => (locale.MOBILE_PROCEEDS_LABEL[d.key] as KeyedObject<string>).LINE_2)
          .attr('font-size', labelFontSize)
        proceedLabel.append('tspan')
          .attr('dy', 16)
          .attr('x', margin.left + BAR_WIDTH + PROCEEDS_LABEL_MARGIN)
          /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
          .text((d) => ((locale.MOBILE_SUB_LABEL[d.key] as KeyedObject<string>)?.LINE_1 ?? ''))
          .attr('font-size', subLabelFontSize)
        proceedLabel.append('tspan')
          .attr('dy', 16)
          .attr('x', margin.left + BAR_WIDTH + PROCEEDS_LABEL_MARGIN)
          /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
          .text((d) => ((locale.MOBILE_SUB_LABEL[d.key] as KeyedObject<string>)?.LINE_2 ?? ''))
          .attr('font-size', subLabelFontSize)
      } else {
        proceedLabel.append('tspan')
          .attr('dy', 18)
          .attr('x', margin.left + BAR_WIDTH + PROCEEDS_LABEL_MARGIN)
          .text((d) => (locale.DESKTOP_LABEL[d.key] as string))
          .attr('font-size', labelFontSize)
        proceedLabel.append('tspan')
          .attr('dy', 14)
          .attr('x', margin.left + BAR_WIDTH + PROCEEDS_LABEL_MARGIN)
          .text((d) => (locale.SUB_LABEL[d.key] as string || ''))
          .attr('font-size', subLabelFontSize)
      }

      // overlay rect to capture mouse events
      svg.selectAll('.focus-overlay').remove()
      svg.append('g')
        .attr('class', 'focus-overlay')
        .selectAll('rect')
        .data(normalizedBarSeries)
        .join('rect')
        .attr('x', x(0) || 0)
        .attr('y', ([d]) => y(d[1]))
        .attr('opacity', 0)
        .attr('width', BAR_WIDTH)
        .attr('height', ([d]) => y(d[0]) - y(d[1]))
        .on('mousemove', handleMouseMove)
        .on('mouseleave', () => setTooltipContent({style: TOOLTIP_HIDDEN_STYLE}))
    }

    if (chartRef.current && data.length) {
      void generateScenarioChart(chartRef.current)
    }
  }, [chartData, costAndTaxes, data, id, isMobile, scenarioType, scenarioIndex, isRsuOnly, handleMouseMove, barHeight])

  return (
    <ChartContainer>
      <svg ref={chartRef} />
      {createPortal((
        <ChartTooltip style={tooltipContent.style}>
          <TooltipHeader>
            <CategoryPercent
              noPercent={tooltipContent.category === BreakdownCategory.REMAINING_EQUITY}
              style={{backgroundColor: tooltipContent.categoryColor}}
            >
              {tooltipContent.categoryPercent}
            </CategoryPercent>
            <Bold>{tooltipContent.categoryLabel}</Bold>
            <Bold>{tooltipContent.categoryValue}</Bold>
          </TooltipHeader>
          <TooltipContent>
            {tooltipContent.category === BreakdownCategory.EXERCISE_COST && (
            <>
              {exerciseSummaryData?.map(({
                optionsExercisedForScenario,
                exercisePrice,
                grantDate,
                grantType,
              }) => (
                <div key={`${grantDate}:${grantType}`}>
                  {`${formatNumberWithCommas(optionsExercisedForScenario)} options x
                    ${numberFormatter(exercisePrice || 0).currency} exercise price`}
                </div>
              ))}
            </>
            )}
            {tooltipContent.category === BreakdownCategory.SALE_PROFIT && (
              <>
                <div>
                  <Bold>total proceeds</Bold>
                  {` ${numberFormatter(chartData[BreakdownCategory.GROSS_PROCEEDS]).intCurrency} - `}
                  <Bold>cost to exercise</Bold>
                  {` ${numberFormatter(chartData[BreakdownCategory.EXERCISE_COST]).intCurrency} - `}
                </div>
                <div>
                  <Bold>taxes from exercise</Bold>
                  {` ${numberFormatter(chartData[BreakdownCategory.EXERCISE_TAX]).intCurrency} - `}
                  <Bold>taxes from sale</Bold>
                  {` ${numberFormatter(chartData[BreakdownCategory.SALE_TAX]).intCurrency}`}
                </div>
              </>
            )}
            {scenario && tooltipContent.category === BreakdownCategory.REMAINING_EQUITY && (
              <>
                {scenario.unvestedAwards > 0 && (
                  <div>
                    {`${formatNumberWithCommas(scenario.unvestedAwards)} unvested options x
                    ${numberFormatter(scenario?.sale.salePrice).currency} fair market value at sale`}
                  </div>
                )}
                {scenario.unvestedSharesSum > 0 && (
                  <div>
                    {`${formatNumberWithCommas(scenario.unvestedSharesSum)} unvested shares x
                    ${numberFormatter(scenario?.sale.salePrice).currency} fair market value at sale`}
                  </div>
                )}
                {scenario.vestedOptions > 0 && (
                  <div>
                    {`${formatNumberWithCommas(scenario.vestedOptions)} vested options x
                    ${numberFormatter(scenario?.sale.salePrice).currency} fair market value at sale`}
                  </div>
                )}
              </>
            )}
          </TooltipContent>
          <CategoryDescription>
            {tooltipContent.categoryDescription}
          </CategoryDescription>
        </ChartTooltip>
      ), document.body)}
    </ChartContainer>
  )
}

const ChartContainer = styled.div(({theme}) => `
  align-items: center;
  justify-content: center;
  display: flex;
  background: #F9F4EF; //TODO(zare): check this color name with Caro
  padding: ${theme.spacing.xl} 0px;

  ${theme.above(theme.breakpoints.aboveMobile)} {
    padding: 0px;
  }
`)

const TooltipContent = styled.div``

const ChartTooltip = styled.div`
  display: none;
  position: fixed;
  z-index: 15;
  width: 315px;
  padding: ${({theme}): string => theme.spacing.xs};
  border-radius: 4px;
  background-color: ${({theme}): string => theme.colors.white100};
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  font-size: ${({theme}): string => theme.fontSize.desktop.note};
`
const TooltipHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-bottom: ${({theme}): string => theme.spacing.xs};;
`

const CategoryPercent = styled.span<{noPercent: boolean}>`
  margin-right: ${({theme}): string => theme.spacing.xs};;
  padding: ${({noPercent}): string => (noPercent ? '10px 2px' : '2px 8px')};
  border-radius: 4px;
  color: ${({theme}): string => theme.colors.white100};
  font-weight: bold;
`

const CategoryDescription = styled.div`
    margin-top: ${({theme}): string => theme.spacing.xs};;
    padding: ${({theme}): string => theme.spacing.xs} 0 0;
    border-top: 1px solid ${({theme}): string => theme.colors.darkBlue100};
`

const Bold = styled.span`
  font-weight: bold;
`
