import React from 'react'
import {Markup} from 'interweave'

import styles from './_taxBreakdownEducation.module.scss'
import {ExerciseAndSaleFederalOrdinaryIncomeTax as locale} from './locale'

type ListItem = {
  CONTENT: string;
  SUB_LIST_ITEMS?: null | string[];
}

type Props = {
  isBracket: boolean;
}

const ExerciseAndSaleFederalOrdinaryIncomeTaxEdu = ({isBracket}: Props): JSX.Element => {
  if (isBracket) {
    return (
      <div className={styles.bracketContainer}>
        <p className={styles.educationText}>{locale.BRACKET}</p>
      </div>
    )
  }

  return (
    <div className={styles.educationContainer}>
      <p className={styles.educationText}>
        <Markup noWrap content={locale.TABLE.INTRO} />
      </p>
      <span>
        <Markup noWrap content={locale.TABLE.LIST_TITLE} />
      </span>
      <ul className={styles.taxableList}>
        {
          locale.TABLE.LIST_ITEMS.map((item: ListItem) => (
            <>
              <li className={styles.taxableItem} key={item.CONTENT}>
                <span>{item.CONTENT}</span>
              </li>
              {item.SUB_LIST_ITEMS && (
              <ul className={styles.taxableList}>
                {item.SUB_LIST_ITEMS.map((subItem) => (
                  <li className={styles.taxableItem} key={subItem}>
                    <span>{subItem}</span>
                  </li>
                ))}
              </ul>
              )}
            </>
          ))
        }
      </ul>
    </div>
  )
}

export default ExerciseAndSaleFederalOrdinaryIncomeTaxEdu
