import {createSelector} from '@reduxjs/toolkit'

import {CryptoAccount} from 'api'
import type {RootState} from 'store/main'

export const cryptoAccountsSelector = (state: RootState): RootState['cryptoAccounts'] => state.cryptoAccounts

export const getCryptoAccounts = createSelector(
  [cryptoAccountsSelector],
  (cryptoAccounts): CryptoAccount[] => cryptoAccounts,
)
