/* eslint-disable max-len */
import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'

import ExternalLink from 'core/components/link/ExternalLink'

import EquitySessionSquareAd from 'assets/images/equity-sessions-square-ad.png'
import {MarketingWidget} from 'modules/marketing-widget'
import {ExternalRoutePaths, RoutePaths} from 'core/constants'
import {ColumnContainer, RowContainer, WhiteSpace} from 'core/styled/blocks'
import {H3, RegularText} from 'core/theme'
import stripeLogo from 'assets/images/scenario-equity-guide/stripe-logo.png'
import snowflakeLogo from 'assets/images/scenario-equity-guide/snowflake-logo.png'
import plaidLogo from 'assets/images/scenario-equity-guide/plaid-logo.png'
import palantirLogo from 'assets/images/scenario-equity-guide/palantir-logo.png'
import doorDashLogo from 'assets/images/scenario-equity-guide/doordash-logo.png'
import airbnbLogo from 'assets/images/scenario-equity-guide/airbnb-logo.png'
import coinbaseLogo from 'assets/images/scenario-equity-guide/coinbase-logo.png'
import affirmLogo from 'assets/images/scenario-equity-guide/affirm-logo.png'
import robinhoodLogo from 'assets/images/scenario-equity-guide/robinhood-logo.png'
import {MixpanelAgent} from 'core/utils'
import {ServicesKey} from 'models/service'

const ColoredExternalLink = styled(ExternalLink)(({theme}) => `
  color: ${theme.colors.orange500};
`)

const ExternalRouteHarnessTax = (
  <span>
    with
    <WhiteSpace />
    <ColoredExternalLink to={window.origin + ExternalRoutePaths.HARNESS_TAX} underlined>
      Harness Tax
    </ColoredExternalLink>
  </span>
)

export const TaxPlanning = (): JSX.Element => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    MixpanelAgent.track('global_advisor-sessions-CTA_click', {
      serviceName: ServicesKey.EQUITY_TAX_PLANNING,
    })

    history.push(RoutePaths.EQUITY_TAX_PLANNING_LANDING_PAGE)
  }, [history])

  return (
    <Container>
      <WidgetHeader>
        <H3>Get answers to your equity questions</H3>
      </WidgetHeader>
      <StyledWidget
        image={EquitySessionSquareAd}
        title="1-hour Equity Session"
        subTitle={ExternalRouteHarnessTax}
        cta={handleClick}
        ctaText="Learn more"
        description="We’ve vetted and hand-selected equity-experienced Tax Advisors to help answer your key equity questions. In this 1-hour tax planning session, your advisor will help build various tax projections for strategies that are customized to your specific financial position, life goals, and milestones."
        time="1 hour"
        price="500"
      />
      <WidgetFooter>
        <RegularText>Trusted by employees and founders at top tech companies:</RegularText>
        <LogoRelativeContainer>
          <LogoContainer>
            <ScrollContainer>
              <img draggable="false" src={stripeLogo} alt="stripe" />
              <img draggable="false" src={snowflakeLogo} alt="snowflake" />
              <img draggable="false" src={plaidLogo} alt="plaid" />
              <img draggable="false" src={palantirLogo} alt="palantir" />
              <img draggable="false" src={doorDashLogo} alt="door dash" />
              <img draggable="false" src={airbnbLogo} alt="airbnb" />
              <img draggable="false" src={coinbaseLogo} alt="coinbase" />
              <img draggable="false" src={affirmLogo} alt="affirm" />
              <img draggable="false" src={robinhoodLogo} alt="robinhood" />
            </ScrollContainer>
          </LogoContainer>
        </LogoRelativeContainer>
      </WidgetFooter>
    </Container>
  )
}

const StyledWidget = styled(MarketingWidget)(({theme}) => `
  padding: 0 ${theme.spacing.xs};

  >div:first-child {
      ${theme.below(theme.breakpoints.largePhone)} {
        padding: ${theme.spacing.xs};
      }
  }

  ${theme.above(theme.breakpoints.aboveMobile)} {
    padding: ${theme.spacing.l} ${theme.spacing.xs} 0px ${theme.spacing.l};
  }
`)

const Container = styled(ColumnContainer)(({theme}) => `
  gap: ${theme.spacing.l};
  background: ${theme.colors.white100};
  ${theme.above(theme.breakpoints.largePhone)} {
    border-radius: 12px 12px 4px 4px;
  }
`)
const WidgetHeader = styled.div(({theme}) => `
  padding: ${theme.spacing.l};
  border-bottom: 0.5px solid ${theme.colors.darkBlue100};
`)

const WidgetFooter = styled(ColumnContainer)(({theme}) => `
  padding: ${theme.spacing.l};
  gap: 20px;
  position: relative;
`)

const LogoRelativeContainer = styled.div`
  position: relative;
  padding-bottom: ${({theme}): string => theme.spacing.l};
`

const LogoContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: absolute;
  opacity: 0.5;
`

const ScrollContainer = styled(RowContainer)`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  img {
    height: 18px;
    object-fit: contain;
    user-drag: none;
    pointer-events: none;
  }
`
