import HttpClient, {FetchResponse} from '../helpers/httpClient'

export enum AccountCategory {
  DEPOSITORY = 'Depository',
  INVESTMENT = 'Investment',
  LOANS = 'Loans',
  OTHER = 'Other',
  REVOLVING = 'Revolving',
}

export enum AccountCategoryLabel {
  CASH = 'Cash',
  INVESTABLE_ASSETS = 'Investable Assets',
  LOANS = 'Loans',
  OTHER = 'Other',
  CREDIT_CARD = 'Credit Card',
}

export const ACCOUNT_CATEGORY_LABEL = {
  [AccountCategory.DEPOSITORY]: AccountCategoryLabel.CASH,
  [AccountCategory.INVESTMENT]: AccountCategoryLabel.INVESTABLE_ASSETS,
  [AccountCategory.LOANS]: AccountCategoryLabel.LOANS,
  [AccountCategory.OTHER]: AccountCategoryLabel.OTHER,
  [AccountCategory.REVOLVING]: AccountCategoryLabel.CREDIT_CARD,
}

export enum ItemConnectionStatus {
  GOOD = 'good',
  USER_ACTION_REQUIRED = 'user_action_required',
  USER_ACTION_NOT_REQUIRED = 'user_action_not_required',
  NO_ACCOUNTS = 'no_accounts',
}

export enum InstitutionConnectionStatus {
  HEALTHY = 'HEALTHY',
  DEGRADED = 'DEGRADED',
  DOWN = 'DOWN',
}

export type PlaidAccount = {
  accountType: string;
  accountSubtype: string;
  harnessCategory: AccountCategory;
  id: string;
  name: string;
  officialName: string;
  customName: string;
  customCategory: Nullable<AccountCategory>;
  customAccountSubtype: string;
  closedFromApplication: string | null; // date
}

export type PlaidInstitution = {
  name: string;
  institutionId: string;
  statuses: {
    transactionsUpdates?: {
      status: InstitutionConnectionStatus;
      lastStatusChange: string; // date
    };
  };
}

export type PlaidItem = {
  accounts: PlaidAccount[];
  id: string;
  institution: PlaidInstitution;
  lastFailedUpdateTime: string;
  lastSuccessfulUpdateTime: string;
  lastSync: string;
  lastSyncFromNow: string;
  status: ItemConnectionStatus;
}

export type PlaidAccountBalance = {
  account: PlaidAccount & {
    item: PlaidItem & {
      itemId: string;
    };
  };
  balanceDate: string;
  currentBalanceLocal: number;
  currentBalanceUsd: number;
  availableBalance: number;
  currencyCode: string;
}

export async function findAccountBalancesFromPlaid(): Promise<FetchResponse<PlaidAccountBalance[]>> {
  return HttpClient.get<PlaidAccountBalance[]>('/v1/financials/plaid_balance/')
}

export async function exchangePlaidPublicTokenForAccessToken(
  publicToken: string,
  accountIds: string[],
): Promise<FetchResponse<unknown>> {
  return HttpClient.post<unknown>('/v1/financials/exchange_token/', {publicToken, accountIds})
}

export async function deletePlaidItem(itemId: string | number): Promise<FetchResponse<void>> {
  // TODO: Update DELETE /v1/financials/remove_item/ endpoint to make 'itemId' as part of URL
  return HttpClient.delete<void>('/v1/financials/remove_item/', {itemId})
}

export async function updatePlaidItem(itemId: string | number): Promise<FetchResponse<unknown>> {
  return HttpClient.post<unknown>('/v1/financials/sync_item/', {itemId})
}

export async function updatePlaidAccount(
  accountId: string,
  account: Partial<PlaidAccount>,
): Promise<FetchResponse<PlaidAccount>> {
  return HttpClient.patch(`/v1/financials/account/${accountId}/`, {...account})
}

export async function deletePlaidAccount(accountId: string): Promise<FetchResponse<unknown>> {
  return HttpClient.delete<void>(`/v1/financials/account/${accountId}/`)
}

export type PlaidLinkToken = {
  linkToken: string;
}

export async function createPlaidLinkToken(
  itemId?: string,
  redirectUri?: string,
): Promise<FetchResponse<PlaidLinkToken>> {
  return HttpClient.post<PlaidLinkToken>('/v1/financials/plaid_token/', {itemId, redirectUri})
}
