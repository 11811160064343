import React, {useLayoutEffect, useState} from 'react'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {styled as muiStyled} from '@mui/material/styles'
import styled from 'styled-components'

import Button from 'core/components/button/Button'
import ExternalLink from 'core/components/link/ExternalLink'
import Modal from 'core/components/modal/Modal'

import usePrefinery from './hooks/usePrefinery'

import {defaultTheme} from 'core/theme'
import {
  useGetCurrentUserProfileQuery,
  useUpdateClientProfileMutation,
} from 'store/profile'
import IconReferAFriend from 'assets/icons/refer-a-friend.svg?react'
import IconClose from 'assets/icons/close.svg?react'

type Props = {
  onClose: () => void;
  open: boolean;
  prefineryTesterHash?: string;
}

export default function InviteFriendModalV2({
  onClose,
  open,
  prefineryTesterHash,
}: Props): JSX.Element {
  const {data: profile} = useGetCurrentUserProfileQuery()
  const isLoaded = usePrefinery()

  const [updateClientProfile] = useUpdateClientProfileMutation()

  const personalCode = profile?.prefineryCode ?? ''

  useLayoutEffect(() => {
    if (open && profile) {
      void updateClientProfile({clientId: profile.id, meta: {hasDiscoveredReferralProgram: true}})
    }
  }, [open, profile, updateClientProfile])

  return (
    <StyledModal id="invite-a-friend-modal" unpadded open={isLoaded && open}>
      <ModalHeader>
        <IconReferAFriend />
        Give $250, Get $250

        <CloseButton
          fill={false}
          onClick={onClose}
        >
          <IconClose />
        </CloseButton>
      </ModalHeader>
      <ModalBody>

        <h3>Get $250 when you refer friends!</h3>

        <p>
          Once both you and your friend become paying clients of Harness, you’ll both receive $250 in account credit.
          Share the wealth!
          {' '}
          <ExternalLink
            underlined
            to="https://www.harnesswealth.com/refer-a-friend-details/"
          >
            Details & FAQ.
          </ExternalLink>
        </p>
        <p>
          <b>Your unique referral code is: </b>

          <CopyButton copyContent={personalCode}>
            <b>{personalCode}</b>
          </CopyButton>

        </p>
        <div
          className="prefinery-invite-a-friend-embed"
          data-prefinery-tester-hash={prefineryTesterHash}
        />
      </ModalBody>
    </StyledModal>
  )
}

const ModalHeader = styled.div`
  display: flex;
  position: relative;
  gap: ${defaultTheme.spacing.xs};
  padding: ${defaultTheme.spacing.xl};
  background: #D1F6C7;
  color: #2F5B3A;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  letter-spacing: 2px;
  text-transform: uppercase;

  svg {
    color: ${defaultTheme.colors.success}
  }
`

const ModalBody = styled.div`
  padding: ${defaultTheme.spacing.xl};
  text-align: center;

  h3 {
    margin-bottom: ${defaultTheme.spacing.xs};
  }

  p {
    margin-bottom: ${defaultTheme.spacing.m};
  }
`

const CloseButton = styled(Button)`
  svg { color: ${defaultTheme.colors.darkBlue500}; }
  position: absolute;
  z-index: 1;
  top: ${defaultTheme.spacing.xl};
  right: ${defaultTheme.spacing.xl};
`

const StyledModal = styled(Modal)`
  && { border-radius: 8px; }
`

const StyledTooltip = muiStyled(({className, ...props}: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: `${defaultTheme.spacing.xs} ${defaultTheme.spacing.s}`,
  },
}))

const CopyButton = ({children, copyContent}: {children: React.ReactNode; copyContent: string}): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const copyToClipboard = (textToCopy: string): () => void => ((): void => {
    void navigator.clipboard.writeText(textToCopy)
    setTooltipOpen(true)
  })

  // Hide the clipboard tooltip after a short while
  useLayoutEffect(() => {
    if (tooltipOpen) setTimeout(() => setTooltipOpen(false), 1000)
  }, [tooltipOpen])

  return (
    <StyledTooltip
      title="Code copied to clipboard"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={tooltipOpen}
      placement="top"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      <span>
        <Button underlined bold onClick={copyToClipboard(copyContent)}>
          {children}
        </Button>
      </span>
    </StyledTooltip>
  )
}
