/* eslint-disable max-len */

export const ExerciseFederalAlternativeMinimumTax = {
  PARAGRAPH_1: '<b>When does the Alternative Minimum Tax apply?</b> <br/> The alternative minimum tax (AMT) exists to make sure wealthy individuals with high investment income pay at least a minimum tax rate of 26%. It’s calculated in parallel to your regular taxes using a different set of rules and applies if your AMT liability is higher than your regular tax liability. When exercising ISOs (but not NSOs), the difference between the fair market value (FMV) at the time of exercise and the exercise price is added to your AMT income.',
  PARAGRAPH_2: '<b>How we calculate AMT:</b> We’ve simplified the IRS’s AMT calculation for readability by calculating it as a separate tax category on top of your regular taxes. This only applies if your overall AMT liability is greater than your regular tax liability.',
  PARAGRAPH_3: 'When exercising ISOs, you can avoid triggering AMT if you exercise enough ISOs to keep your AMT liability at or below your regular tax liability.',
  COMING_SOON: '<b>Alternative minimum tax support coming soon!</b>',
}

export const ExerciseStateAlternativeMinimumTax = {
  PARAGRAPH_1: 'The state alternative minimum tax varies depending on which state you live in. Some states do not calculate any AMT while others have their own set of rules to calculate state and local AMT.',
  COMING_SOON: '<b>Alternative minimum tax support coming soon!</b>',
}

export const SaleFederalLongTermCapitalGainsTax = {
  PARAGRAPH_1: "<b>What's the long-term capital gains tax?</b><br> When you make money, it is categorized as either “income” or as a “capital gain” for tax purposes. Capital gains tax is paid on monetary gains from the sale of an asset (like equity). It is calculated on the spread between the sale price and the acquisition price of the asset. The gains from shares held for less than a year between exercise and sale are “short-term capital gains” and are added to your ordinary income tax liability.",
  PARAGRAPH_2: 'The gains from shares held for longer than a year are “long-term capital gains” and are taxed at the more favorable long-term capital gains rate (see below). Holding your shares to qualify for long-term capital gains is one of the primary ways to save taxes on equity sales.',
  PARAGRAPH_3: '<b>Exercising options:</b> When exercising options, you are taxed on the spread between the FMV at exercise and the exercise price of the option (AKA the “compensation element”). When you sell those assets, the taxable capital gain is adjusted to deduct the compensation element that has been previously taxed from exercising.',
  BRACKET: '*Your long-term capital gains tax bracket is determined first by your ordinary income. Because exercising NSOs, ISOs sold in a disqualified disposition, and short-term capital gains all count towards your ordinary income, these actions may push your long-term capital gains tax into a higher bracket.',
}

export const SaleFederalNetInvestmentIncomeTax = {
  PARAGRAPH_1: '<b>What is the net investment income tax (NIIT)?</b> NIIT is a 3.8% tax on some or all of your net investment income (NII) introduced by the Affordable Care Act. Your net investment income is the income you receive from investment assets, like capital gains from selling shares, minus fees and state/local income taxes.',
  PARAGRAPH_2: 'You are only subject to the NIIT if your modified adjusted gross income (AKA “MAGI”) exceeds a certain threshold, set by your filing status. The net investment income tax is then applied to your (a) MAGI - threshold or (b) your total Net Investment Income, whichever is lower.',
  PARAGRAPH_3: 'NIIT and exercising options: Exercising options does not contribute to your Net Investment Income.',
}

export const SaleStateLongTermCapitalGainsTax = {
  PARAGRAPH_1: 'The long-term capital gains <b>state tax</b> rate varies depending on which state you live in. Some states have lower capital gains rates than others and some states have no capital gains taxes at all.',
}

export const ExerciseAndSaleStateOrdinaryIncomeTax = {
  PARAGRAPH_1: 'The amount of ordinary income <b>state tax</b> you owe varies depending on which state you live in. Some states have lower income rates than others and some states have no income taxes at all.',
}

export const ExerciseAndSaleFederalMedicareTax = {
  PARAGRAPH_1: "<b>What's the medicare tax?</b> All citizens pay the [NORMAL_RATE] Medicare Tax on their earned income up to a threshold that is determined by their filing status. Any earned income dollars above that threshold are taxed an additional 0.9%. Similarly to the social security tax, employers often withhold the medicare tax directly from their employees’ paychecks. Self-employed individuals are required to pay the medicare tax as part of their self-employment tax.",
  PARAGRAPH_2: 'Earned income includes your regular wages, income generated from exercising NSOs or selling ISOs as disqualified dispositions, and income from vesting RSUs.',
}

export const ExerciseAndSaleFederalSocialSecurityTax = {
  PARAGRAPH_1: "<b>What's social security tax?</b> All citizens pay the [CURRENT_TAX_RATE] Social Security Tax on their earned income up to [SOCIAL_SECURITY_THRESHOLD]. For employees, employers often withhold the social security tax directly from the employee’s paycheck. Self-employed individuals are required to pay the social security tax as part of their self-employment tax.",
  PARAGRAPH_2: 'Earned income includes your regular wages, income generated from exercising NSOs or selling ISOs as disqualified dispositions, and income from vesting RSUs.',
}

export const ExerciseAndSaleFederalOrdinaryIncomeTax = {
  TABLE: {
    INTRO: "<b>What's ordinary income tax?</b> Your income tax rate is based on your specific tax bracket, which is determined by how much income you make in the calendar year minus any deductions.",
    LIST_TITLE: 'Your taxable ordinary income may include:',
    LIST_ITEMS: [
      {
        CONTENT: 'Regular income',
        SUB_LIST_ITEMS: [
          'Pre-tax income (ex wages) entered in your tax profile',
          'Income generated from prior exercises or sales in this tax year',
        ],
      },
      {
        CONTENT: 'Income from exercising NSOs',
        SUB_LIST_ITEMS: null,
      },
      {
        CONTENT: 'Income from selling ISOs as disqualified dispositions',
        SUB_LIST_ITEMS: null,
      },
      {
        CONTENT: 'Income from vesting RSUs or RSAs',
        SUB_LIST_ITEMS: null,
      },
      {
        CONTENT: 'Short-Term capital gains from selling shares',
        SUB_LIST_ITEMS: null,
      },
    ],
  },
  BRACKET: 'The IRS defines 7 tax brackets that vary by tax filing status; the first dollars you make fall within the first bracket and are taxed at a lower rate. Your tax bracket is the highest rate that applies to your ordinary income.',
}
