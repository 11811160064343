export enum AlertMessageType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  BACKUP_CODE_USED = 'backup_code_used',
}

export type AlertMessage = {
  content: string;
  type: AlertMessageType;
  hasRefreshBtn?: boolean;
  refreshMsg?: string;
  preserve?: boolean; // should it preserve the alert banner across route changes
  data?: KeyedObject;
}
