/* eslint-disable max-len */
import {differenceInYears, parseISO} from 'date-fns'

import {
  EducationContentItems,
  OptionsStatus,
  OptionsStatusType,
  Scenario,
  ScenarioBreakdownData,
  ScenarioGrantMixType,
  ScenarioGrantSummary,
  ScenarioHeaderData,
  ScenarioNames,
  ScenarioNamesShort,
  ScenarioOrdering,
  ScenarioType,
  TaxGrantExerciseSummary,
  TaxGrantSaleSummary,
  TaxScenarioGrantType,
  Visibility,
} from './types'

import {
  LiquidityEventWithResults, OptionType, TaxBracketOrdering, TaxScenarioResults, getTaxTotals, getTaxTypeTotals,
} from 'models'
import {numberFormatter} from 'core/formatters'

export const getGrantType = ({sale, purchase = []}: ScenarioGrantSummary): ScenarioGrantMixType => {
  const hasRSU = sale.find(({grantType}) => grantType === TaxScenarioGrantType.RSU)

  const hasOption = [...purchase, ...sale].find(({grantType}) => (
    [TaxScenarioGrantType.INCENTIVE_STOCK, TaxScenarioGrantType.NON_QUALIFIED].includes(grantType)
  ))

  if (hasRSU && hasOption) return ScenarioGrantMixType.MIXED
  if (hasRSU) return ScenarioGrantMixType.ONLY_RSU
  return ScenarioGrantMixType.ONLY_OPTIONS
}

export const getOptionsStatus = (purchase: TaxGrantExerciseSummary[]): OptionsStatus => purchase
  .reduce((acc, currentGrantSummary) => {
    // TODO(Raylen): handle case for RSUs/RSAs
    if (![TaxScenarioGrantType.INCENTIVE_STOCK, TaxScenarioGrantType.NON_QUALIFIED].includes(currentGrantSummary.grantType)) {
      return {optionsVested: false, optionsNotExpired: false, optionsCancelled: false}
    }

    if (currentGrantSummary.vestedShares || currentGrantSummary.optionsExercisedForScenario) {
      return {
        optionsVested: true,
        optionsNotExpired: true,
        optionsCancelled: !!currentGrantSummary.cancelledOptions,
      }
    }

    if (currentGrantSummary.cancelledOptions) {
      acc.optionsCancelled = true
    }

    if (!currentGrantSummary.cancelledOptions) {
      acc.optionsNotExpired = true
    }

    if (currentGrantSummary.vestedOptions) {
      acc.optionsVested = true
    }

    return acc
  }, {
    optionsVested: false,
    optionsNotExpired: false,
    optionsCancelled: false,
  } as OptionsStatus)

export const generateScenarioHeaderContent = (scenario: Scenario | undefined): ScenarioHeaderData | undefined => {
  // Is RSUs only
  if (scenario?.grantType === ScenarioGrantMixType.ONLY_RSU) {
    return scenario.type === ScenarioType.HOLD_OVER_A_YEAR ? {
      title: 'Sell shares that you’ve held for 1+ year',
      subtitle: `to take home ${scenario.netProfitFormatted} in the ${scenario.saleDate.year} tax year`,
      description: 'Hold shares for 1+ year after they’ve vested to take advantage of long-term capital gains (less than short-term capital gains rate).',
    } : {
      title: 'Sell all vested shares immediately upon liquidity',
      subtitle: `to take home ${scenario.netProfitFormatted} in the ${scenario.saleDate.year} tax year`,
      description: 'Sell all vested shares after going public to avoid concentrating assets in a single illiquid stock and to cover any additional taxes.',
    }
  }

  // Options
  if (scenario?.type === ScenarioType.HOLD_OVER_A_YEAR) {
    return {
      title: 'Exercise early & hold for 1+ year',
      subtitle: `to take home ${scenario.netProfitFormatted} in the ${scenario.saleDate.year} tax year`,
      description: 'Exercise before liquidity and then hold shares for 1+ year to take advantage of long-term capital gains (less than short-term capital gains rate). You’ll need cash to cover the exercise and tax costs.',
    }
  }

  if (scenario?.type === ScenarioType.HOLD_UNDER_A_YEAR) {
    return {
      title: 'Wait to exercise & sell immediately upon liquidity',
      subtitle: `to take home ${scenario.netProfitFormatted} in the ${scenario.saleDate.year} tax year`,
      description: 'Exercise post-IPO to avoid concentrating assets in a single illiquid stock. Sell immediately to cover exercise costs & taxes. Profits from exercising will be taxed as ordinary income.',
    }
  }

  if (scenario?.type === ScenarioType.AMT_BREAKEVEN) {
    return {
      title: 'Exercise without triggering AMT, then sell after 1 year',
      subtitle: `to take home ${scenario.netProfitFormatted} in the ${scenario.saleDate.year} tax year`,
      description: "Exercise ISO's tax-free by exercising the max number of ISOs without exceeding the AMT threshold for the tax year. Hold for a year to take advantage of favorable capital gains tax rate.",
    }
  }

  return undefined
}

export const generateScenarioBreakdownContent = (scenario: Scenario | undefined): ScenarioBreakdownData | null => {
  if (!scenario) {
    return null
  }

  const saleYear = scenario.saleDate.year
  const {optionsExercised, optionsExercisable, optionsExercisedFormatted} = scenario.exercise
  const {isGrantRSUOnly} = scenario
  const purchaseYear = scenario.purchaseDate.year

  const getExerciseLabel = (): string => {
    const exercisedQuantityLabel = ((): string => {
      if (optionsExercised < optionsExercisable) {
        return ' partial'
      }

      if (optionsExercised > optionsExercisable) {
        return ' early'
      }

      return '' // user fully exercised
    })()

    const timeLabel = scenario.isLockupPeriodLongerThanYear ? 'One year before liquidity' : 'Today'

    switch (scenario.type) {
      case ScenarioType.AMT_BREAKEVEN:
        return `${timeLabel},${exercisedQuantityLabel} exercise ${optionsExercisedFormatted} options to avoid AMT in the ${purchaseYear} tax year`
      case ScenarioType.HOLD_OVER_A_YEAR:
        return `${timeLabel},${exercisedQuantityLabel} exercise ${optionsExercisedFormatted} vested options in the ${purchaseYear} tax year`
      case ScenarioType.HOLD_UNDER_A_YEAR:
        return `Exercise ${optionsExercisedFormatted} options that are vested in the ${purchaseYear} tax year`

      default: return ''
    }
  }

  const getSaleLabel = (): string => {
    const timeLabel = ((): string => {
      switch (scenario.type) {
        case ScenarioType.AMT_BREAKEVEN:
          return 'After one year'

        case ScenarioType.HOLD_OVER_A_YEAR:
          return isGrantRSUOnly ? 'Upon liquidity' : 'After one year'

        case ScenarioType.HOLD_UNDER_A_YEAR:
          return isGrantRSUOnly ? 'Upon liquidity' : 'Immediately'

        default: return ''
      }
    })()

    if (scenario.type === ScenarioType.HOLD_UNDER_A_YEAR) {
      return `${timeLabel}, sell ${!scenario.sale.sharesLeft ? 'all ' : ''}${scenario.sale.sharesSoldFormatted} ${!scenario.sale.sharesLeft ? 'vested shares'
        : 'shares'} to reduce investment risk in the ${saleYear} tax year`
    }

    return `${timeLabel}, sell ${scenario.sale.sharesSoldFormatted} shares that qualify for long-term capital gains in the ${saleYear} tax year`
  }

  const getRemainingLabel = (): string => `Afterwards, you'll hold ${scenario.remainingAwards} ${isGrantRSUOnly ? 'shares left to sell' : 'equity awards to exercise/sell'} in future tax years`

  return {
    exerciseLabel: scenario.grantType !== ScenarioGrantMixType.ONLY_RSU ? getExerciseLabel() : '',
    saleLabel: getSaleLabel(),
    remainingLabel: getRemainingLabel(),
  }
}

export const generateEquityGuideContent = (scenarios: Scenario[]): EducationContentItems[] => {
  const {optionTypes, isGrantRSUOnly, grantType} = scenarios[0]
  const hasISO = !!optionTypes?.some((type) => type === OptionType.ISO)
  const hasNSO = !!optionTypes?.some((type) => type === OptionType.NON_QUALIFIED)
  const hasRSUs = isGrantRSUOnly || grantType === ScenarioGrantMixType.MIXED

  return [
    {
      categoryLabel: 'isoCategoryTitle',
      titleLabel: 'isoTitle',
      contentLabel: 'isoContent',
      visible: Visibility.ISO_ONLY,
    },
    {
      categoryLabel: 'nsoCategoryTitle',
      titleLabel: 'nsoTitle',
      contentLabel: 'nsoContent',
      visible: Visibility.NSO_ONLY,
    },
    {
      categoryLabel: 'amtCategoryTitle',
      titleLabel: 'amtTitle',
      contentLabel: 'amtContent',
      visible: Visibility.ISO_ONLY,
    },
    {
      categoryLabel: 'rsuCategoryTitle',
      titleLabel: 'rsuTitle',
      contentLabel: 'rsuContent',
      visible: Visibility.RSU_ONLY,
    },
    {
      categoryLabel: 'fmvCategoryTitle',
      titleLabel: 'fmvTitle',
      contentLabel: 'fmvContent',
      visible: Visibility.ALWAYS,
    },
    {
      categoryLabel: 'lcgCategoryTitle',
      titleLabel: 'lcgTitle',
      contentLabel: 'lcgContent',
      visible: Visibility.ALWAYS,
    },
    {
      categoryLabel: 'iirCategoryTitle',
      titleLabel: 'iirTitle',
      contentLabel: 'iirContent',
      visible: Visibility.ALWAYS,
    },
    {
      categoryLabel: 'cfCategoryTitle',
      titleLabel: 'cfTitle',
      contentLabel: 'cfContent',
      visible: Visibility.ALWAYS,
    },
    {
      categoryLabel: 'niiCategoryTitle',
      titleLabel: 'niiTitle',
      contentLabel: 'niiContent',
      visible: Visibility.ALWAYS,
    },
  ].filter(({visible}) => visible === Visibility.ALWAYS
    || (visible === Visibility.ISO_ONLY && hasISO)
    || (visible === Visibility.NSO_ONLY && hasNSO)
    || (visible === Visibility.RSU_ONLY && hasRSUs))
}

const getOptionsStatusType = (optionsInfo: OptionsStatus): OptionsStatusType | undefined => {
  if (!optionsInfo.optionsVested) return OptionsStatusType.OPTIONS_NOT_VESTED
  if (!optionsInfo.optionsNotExpired) return OptionsStatusType.OPTIONS_EXPIRED
  if (optionsInfo.optionsCancelled) return OptionsStatusType.OPTIONS_CANCELLED
  return undefined
}

const getRSUsSharesSold = (results: TaxScenarioResults): {
  rsuSharesSoldInMinimizeCapitalGainsScenario: number;
  rsuSharesSoldInMinimizeInvestmentRisk: number;
} => {
  const rsuSharesSoldInMinimizeCapitalGainsScenario = results[ScenarioType.HOLD_OVER_A_YEAR].grantSummary.sale
    .filter((grant) => !grant.isDoubleTrigger && grant.grantType !== TaxScenarioGrantType.RSA)
    .filter((grant) => grant.grantType === TaxScenarioGrantType.RSU)
    .reduce((sum, sale) => sum + sale.sharesSoldForScenario, 0)

  const rsuSharesSoldInMinimizeInvestmentRisk = results[ScenarioType.HOLD_UNDER_A_YEAR].grantSummary.sale
    .filter((grant) => !grant.isDoubleTrigger && grant.grantType !== TaxScenarioGrantType.RSA)
    .filter((grant) => grant.grantType === TaxScenarioGrantType.RSU)
    .reduce((sum, sale) => sum + sale.sharesSoldForScenario, 0)

  return {
    rsuSharesSoldInMinimizeCapitalGainsScenario,
    rsuSharesSoldInMinimizeInvestmentRisk,
  }
}

export const generateScenarioData = (
  {results, ...rest}: LiquidityEventWithResults,
): Scenario[] => Object
  .entries(results)
  .map(([type, scenario]) => {
    const purchaseDate = parseISO(scenario.purchaseDate)
    const purchaseYear = purchaseDate.getFullYear()
    const saleDate = parseISO(scenario.saleDate)
    const saleYear = saleDate.getFullYear()

    const {
      rsuSharesSoldInMinimizeCapitalGainsScenario,
      rsuSharesSoldInMinimizeInvestmentRisk,
    } = getRSUsSharesSold(results)

    const unsupportedAssets = scenario.grantSummary.sale
      .reduce((acc, curr) => ({
        RSA: acc.RSA + (curr.grantType === TaxScenarioGrantType.RSA ? curr.vestedShares : 0),
        RSU: acc.RSU + (curr.grantType === TaxScenarioGrantType.RSU ? curr.vestedShares : 0),
      }), {RSA: 0, RSU: 0})

    const doubleTriggerRSUsSupport = scenario.grantSummary.sale
      .filter((grant) => grant.grantType === TaxScenarioGrantType.RSU)
      .filter((grant) => !!grant.isDoubleTrigger)
      .reduce((sum, sale) => sum + sale.vestedShares, 0)

    // NOTE: Remove double triggered and RSA from summary and all calculations until we add support
    const saleGrantSummary = scenario.grantSummary.sale
      .filter((grant) => !grant.isDoubleTrigger && grant.grantType !== TaxScenarioGrantType.RSA)

    const purchaseInfo = scenario[purchaseYear]
    const saleInfo = scenario[saleYear]

    const taxTotalsFromExercise = !scenario.purchaseDate ? null : getTaxTotals(scenario[purchaseYear], true)
    const taxTotalsFromSale = getTaxTotals(saleInfo, false)

    const rsuIncomeTaxes = getTaxTypeTotals(saleInfo, TaxBracketOrdering.RSU_VESTING, false)

    const exerciseCost = scenario.grantSummary.purchase.reduce((sum, {exerciseCostForScenario}) => (sum + exerciseCostForScenario), 0)
    const grossProceeds = saleGrantSummary.reduce((sum, {saleProceedsForScenario}) => (sum + saleProceedsForScenario), 0)
    const netProfit = grossProceeds - exerciseCost - (taxTotalsFromExercise?.total ?? 0) - taxTotalsFromSale.total - rsuIncomeTaxes.total
    const netProfitNormalized = netProfit >= 0 ? netProfit : 0

    const optionsInfo = getOptionsStatus(scenario.grantSummary.purchase)

    const hasOptionGrants: boolean = saleGrantSummary.some(({grantType}: TaxGrantSaleSummary) => (
      grantType === TaxScenarioGrantType.INCENTIVE_STOCK || grantType === TaxScenarioGrantType.NON_QUALIFIED
    ))

    const optionsStatusType = hasOptionGrants ? getOptionsStatusType(optionsInfo) : undefined

    const {
      unvestedAwards,
      vestedOptions,
      unvestedSharesSum,
      vestedSharesSum,
      sharesSoldForScenarioSum,
    } = saleGrantSummary.reduce((sum, {
      vestedOptions: vestedAwards,
      unvestedOptions,
      unvestedShares,
      vestedShares,
      sharesSoldForScenario,
    }) => ({
      unvestedAwards: unvestedOptions + sum.unvestedAwards,
      vestedOptions: vestedAwards + sum.vestedOptions,
      unvestedSharesSum: unvestedShares + sum.unvestedSharesSum,
      vestedSharesSum: vestedShares + sum.vestedSharesSum,
      sharesSoldForScenarioSum: sharesSoldForScenario + sum.sharesSoldForScenarioSum,
    }), {
      unvestedAwards: 0,
      vestedOptions: 0,
      unvestedSharesSum: 0,
      vestedSharesSum: 0,
      sharesSoldForScenarioSum: 0,
    })
    // This is event data
    const {salePrice, eventYear} = {...rest}

    const remainingAwards = (
      vestedOptions
        + unvestedAwards
        + unvestedSharesSum
        + vestedSharesSum
        - sharesSoldForScenarioSum
    )

    const remainingEquity = remainingAwards * (salePrice || 0)

    const exerciseTaxTotals = !scenario.purchaseDate ? null : getTaxTotals(purchaseInfo, true)
    const grossProceedsRounded = Math.round(grossProceeds)
    const saleTaxTotals = getTaxTotals(saleInfo, false)
    const netProfitRounded = Math.round(netProfitNormalized)
    const grantType = getGrantType(scenario.grantSummary)

    const isRSUOnly = grantType === ScenarioGrantMixType.ONLY_RSU
    const optionTypes = isRSUOnly ? undefined : purchaseInfo.incomeSources.optionGrant.map(({optionType}) => optionType)
    const sharesSold = saleGrantSummary.reduce((sum, d) => sum + d.sharesSoldForScenario, 0)
    const sharesLeft = saleGrantSummary.reduce((sum, d) => sum + d.unvestedShares, 0)

    const optionsExercised = scenario.grantSummary.purchase.reduce((sum, d) => sum + d.optionsExercisedForScenario, 0)

    const hasRSUGrants = saleGrantSummary.some((item: TaxGrantSaleSummary) => item.grantType === TaxScenarioGrantType.RSU)
    const noAvailableRSUsShares = type === ScenarioType.HOLD_UNDER_A_YEAR
      && rsuSharesSoldInMinimizeInvestmentRisk === 0 && hasRSUGrants

    const noLongTermCapitalGainsRSUs = type === ScenarioType.HOLD_OVER_A_YEAR
      && rsuSharesSoldInMinimizeCapitalGainsScenario === 0
      && rsuSharesSoldInMinimizeInvestmentRisk > 0

    return {
      type: type as ScenarioType,
      scenario,
      name: ScenarioNames[type as ScenarioType],
      nameShort: ScenarioNamesShort[type as ScenarioType],
      order: ScenarioOrdering[type as ScenarioType],
      grantType,
      isGrantRSUOnly: isRSUOnly,
      eventYear,
      netProfit: netProfitRounded,
      netProfitFormatted: numberFormatter(netProfitRounded).intCurrency,
      isEmpty: exerciseCost <= 0 && netProfit <= 0,
      optionTypes,
      optionsAllExpired: !optionsInfo.optionsNotExpired,
      optionsNotVested: !optionsInfo.optionsVested,
      optionsCancelled: optionsInfo.optionsCancelled,
      unvestedAwards,
      vestedOptions,
      unvestedSharesSum,
      vestedSharesSum,
      sharesSoldForScenarioSum,
      remainingEquity,
      remainingAwards,
      remainingEquityFormatted: numberFormatter(remainingEquity).intCurrency,
      purchaseDate: {
        date: scenario.purchaseDate,
        year: purchaseYear,
      },
      saleDate: {
        date: scenario.saleDate,
        year: saleYear,
      },
      isLockupPeriodLongerThanYear: differenceInYears(new Date(scenario.saleDate), new Date()) > 0,
      exercise: {
        cost: Math.round(exerciseCost),
        costFormatted: numberFormatter(exerciseCost).intCurrency,
        taxTotals: exerciseTaxTotals,
        taxTotalsFormatted: numberFormatter(exerciseTaxTotals?.total || 0).intCurrency,
        optionsExercised,
        optionsExercisedFormatted: numberFormatter(optionsExercised).decimal,
        optionsExercisable: scenario.grantSummary.purchase.reduce((sum, d) => sum + d.exercisableOptions, 0),
        optionsLeft: scenario.grantSummary.purchase.reduce((sum, d) => (sum + (d.vestedOptions + d.unvestedOptions - d.optionsExercisedForScenario)), 0),
        summary: scenario.grantSummary.purchase,
      },
      sale: {
        salePrice: salePrice || 0,
        grossProceeds: grossProceedsRounded,
        grossProceedsFormatted: numberFormatter(grossProceedsRounded).intCurrency,
        taxTotals: saleTaxTotals,
        taxTotalsFormatted: numberFormatter(saleTaxTotals.total).intCurrency,
        sharesSold,
        sharesSoldFormatted: numberFormatter(sharesSold).decimal,
        sharesLeft,
        sharesLeftFormatted: numberFormatter(sharesLeft).decimal,
        summary: saleGrantSummary,
      },
      rsu: {
        incomeTaxes: rsuIncomeTaxes,
      },
      warnings: {
        doubleTriggerRSUsSupport,
        unsupportedAssets,
        optionsStatusType,
        noAvailableRSUsShares,
        noLongTermCapitalGainsRSUs,
      },
      unsupportedAssets,
      doubleTriggerRSUsSupport,
    }
  })
  .sort((a, b) => a.order - b.order)
