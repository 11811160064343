import HttpClient, {FetchResponse} from 'models/helpers/httpClient'

export enum ServicesKey {
  FINANCIAL_PLANNING = 'Financial Planning',
  INVESTMENT_MANAGEMENT = 'Investment Management',
  ALTERNATIVE_INVESTMENTS = 'Alternative Investments',
  TAX_PREPARATION = 'Tax Preparation',
  TAX_PLANNING = 'Tax Planning',
  BUSINESS_TAX_RETURNS = 'Business Tax Returns',
  INTERNATIONAL_TAXES = 'International Taxes',
  ESTATE_PLANNING = 'Estate Planning',
  EQUITY_TAX_PLANNING = 'Standalone Equity Tax Planning',
  BOOKKEEPING = 'Crypto Reconciliation & Bookkeeping',
}

export enum ServiceTypeKey {
  additionalServices = 'additionalServices',
  recommendedServices = 'recommendedServices',
  selectedServices = 'selectedServices',
  otherServiceNeeds = 'otherServiceNeeds',
}

export type ServicesResponse = {
  [ServiceTypeKey.additionalServices]: string[];
  [ServiceTypeKey.recommendedServices]: string[];
  [ServiceTypeKey.selectedServices]: string[];
  [ServiceTypeKey.otherServiceNeeds]: string;
}

export type ServicesUpdateArgs = {
  selectedServices?: string[];
  otherServiceNeeds?: string;
  finalSelection?: boolean;
}

export async function generateServiceRecommendations(): Promise<FetchResponse<ServicesResponse>> {
  return HttpClient.post('/v1/service/')
}

export async function getPersonalizedServices(): Promise<FetchResponse<ServicesResponse>> {
  return HttpClient.get('/v1/service/')
}

export async function getAllServices(finalSelection: string = 'true'): Promise<FetchResponse<ServicesResponse[]>> {
  return HttpClient.get(`/v1/service/all/?final_selection=${finalSelection}`)
}

export async function updateServiceSelection(data: ServicesUpdateArgs): Promise<FetchResponse<ServicesResponse>> {
  return HttpClient.post('/v1/service/selection/', data)
}
