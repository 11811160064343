import React from 'react'

import Modal from 'core/components/modal/Modal'

import {useScenarioFlyout} from '../hooks/ScenarioFlyoutProvider'
import TaxOverview from '../../ScenarioResults/TaxBreakdown/TaxOverview'
import styles from '../_scenarioTaxBreakdown.module.scss'

const MODAL_LAYOUT = 'rightHalfScreen'
const MODAL_ID = 'scenario-result-right-rail-modal'

const TaxBreakdownFlyout = (): JSX.Element => {
  const {
    isOpen,
    handleToggleFlyout,
    scenario,
  } = useScenarioFlyout()

  return (
    <Modal
      id={MODAL_ID}
      data-cy={MODAL_ID}
      intent={MODAL_LAYOUT}
      onBackdropClick={handleToggleFlyout}
      open={isOpen}
      className={styles.taxBreakdownFlyout}
    >
      {isOpen && (
        <TaxOverview
          noSharesSold={scenario.noSharesSold}
          result={scenario.result}
          purchaseDate={scenario.purchaseDate}
          taxYear={scenario.taxYear}
          taxTotalsFromEvent={scenario.taxTotalsFromEvent}
          title={scenario.title}
          taxEventDescription={scenario.taxEventDescription}
          isExercise={scenario.isExercise}
          exerciseSummaryData={scenario.exerciseSummaryData}
          scenarioNumber={scenario.scenarioNumber}
          overrideScenarioResult={scenario.overrideScenarioResult}
          scenarioType={scenario.scenarioType}
        />
      )}
    </Modal>
  )
}

export default React.memo(TaxBreakdownFlyout)
