import Cookie from 'js-cookie'

export const UTM_FIELDS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
const UTM_COOKIE_EXPIRATION_DAYS = 30

export interface UtmParameters {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

/**
 *
 * Gets Utm parameters
 * @returns Record<string, string>
 */
export const getUtmParameters = (): Record<string, string> => UTM_FIELDS
  .reduce((acc, utmField) => (Cookie.get(utmField) ? {...acc, [utmField]: Cookie.get(utmField)} : acc), {})

/**
 * Parse UTM parameters from QS
 * @param queryString query string to parse
 */
export const setUtmParametersFromQueryString = (queryString: string): void => {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + UTM_COOKIE_EXPIRATION_DAYS)

  UTM_FIELDS.forEach((utmField) => {
    const utmFieldValue = new URLSearchParams(queryString).get(utmField)
    if (utmFieldValue) {
      Cookie.set(utmField, utmFieldValue, {expires: expirationDate})
    }
  })
}

/**
 * Remove UTM parameters from Cookies.
 */
export const removeUtmParameters = (
  utmParams: Record<string, string>,
): void => Object.keys(utmParams).forEach((key) => Cookie.remove(key))
