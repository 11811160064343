import React, {useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div(({theme}) => `
  margin: 0 0 ${theme.spacing.m};
`)

export const CollapsibleSpace = ({
  open = false,
  children,
}: {
  open?: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  const ref = useRef(null)

  // collapse toast on exit
  useLayoutEffect(() => {
    const node: HTMLElement = ref.current!

    if (!open) {
      requestAnimationFrame(() => {
        node.style.minHeight = 'initial'
        node.style.height = `${node.scrollHeight}px`
        node.style.transition = 'all 200ms ease-in 150ms'

        requestAnimationFrame(() => {
          node.style.margin = '0'
          node.style.height = '0'
        })
      })
    }
  }, [open])

  return (
    <StyledDiv ref={ref}>
      {children}
    </StyledDiv>
  )
}
