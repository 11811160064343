import React from 'react'
import {useMediaQuery} from 'react-responsive'

import Slider from './Slider'

type Props = {
  children: JSX.Element | JSX.Element[];
}

const SwipeContainer = ({children}: Props): JSX.Element => {
  const isMobile = useMediaQuery({maxWidth: 720})

  return isMobile ? <Slider>{children}</Slider> : <>{children}</>
}

export default React.memo(SwipeContainer)
