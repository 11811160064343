import React, {useCallback} from 'react'
import styled, {useTheme} from 'styled-components'
import {Link} from 'react-router-dom'

import {Button, Insight} from 'core/components'
import {NetWorthIcon, ReferIcon} from 'assets/icons'
import {useGetCurrentUserProfileQuery, useUpdateClientProfileMutation} from 'store/profile'
import {useBoolean} from 'core/hooks'
import {InviteFriendModal} from 'modules/invite-friend-modal'
import {RoutePaths} from 'core/constants'

const InviteFriend = (): JSX.Element => {
  const {
    prefineryTesterHash, clientId,
  } = useGetCurrentUserProfileQuery(
    undefined, {
      selectFromResult: ({data}) => ({
        prefineryTesterHash: data ? data.prefineryTesterHash.primary : '',
        hasDismissedScenarioInviteFriend: !!data?.meta.hasDismissedScenarioInviteFriend,
        hasDismissedScenarioConnectAccounts: !!data?.meta.hasDismissedScenarioConnectAccounts,
        clientId: data?.id || '',
      }),
    },
  )

  const [isInviteFriendModalOpen, toggleIsInviteFriendModalOpen] = useBoolean(false)
  const [updateClientProfile] = useUpdateClientProfileMutation()

  const handleClick = useCallback(() => {
    toggleIsInviteFriendModalOpen()
  }, [toggleIsInviteFriendModalOpen])

  const theme = useTheme()
  return (
    <>
      <StyledInsight
        customIcon={<ReferIcon />}
        title="Refer a coworker or friend"
        description="Share Harness with coworkers or friends
    that need help analyzing their equity or planning for an upcoming IPO or acquisition."
        backgroundColor={theme.colors.white100}
        onDismiss={(): void => void updateClientProfile({clientId, meta: {hasDismissedScenarioInviteFriend: true}})}
        action={(
          <Button onClick={handleClick}>Invite a friend</Button>
      )}
      />
      {isInviteFriendModalOpen && (
        <InviteFriendModal
          onClose={handleClick}
          open
          prefineryTesterHash={prefineryTesterHash}
        />
      )}
    </>
  )
}

const ScenarioConnectAccount = (): JSX.Element => {
  const theme = useTheme()
  const {clientId} = useGetCurrentUserProfileQuery(
    undefined, {
      selectFromResult: ({data}) => ({
        clientId: data?.id || '',
      }),
    },
  )

  const [updateClientProfile] = useUpdateClientProfileMutation()

  return (
    <StyledInsight
      customIcon={<NetWorthIcon />}
      title="Get insights on your full net worth"
      description="Link your online accounts and wallets or manually add assets like
     startup equity and real estate to track your complete net worth."
      backgroundColor={theme.colors.white100}
      onDismiss={(): void => void updateClientProfile({clientId, meta: {hasDismissedScenarioConnectAccounts: true}})}
      action={(
        <Link
          to={RoutePaths.ASSETS_AND_DEBTS}
          data-cy="connect-account"
        >
          Connect accounts
        </Link>
    )}
    />
  )
}

const StyledInsight = styled(Insight)`
  width: 100%;
`

export const ScenarioInsights = {
  InviteFriend,
  ScenarioConnectAccount,
}
