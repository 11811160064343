import React from 'react'
import cx from 'classnames'

import styles from './_status.module.scss'

export type Props = {
  content: string | JSX.Element;
  icon: JSX.Element;
  containerClassName?: string;
  contentClassName?: string;
  contentIconName?: string;
}

export const Status = React.memo(({
  content,
  icon,
  containerClassName = '',
  contentClassName = '',
  contentIconName = '',
}: Props) => (
  <div className={cx(styles.notificationContainer, containerClassName)}>
    <span className={cx(styles.iconContainer, contentIconName)}>{icon}</span>
    <span className={cx(styles.statusContent, contentClassName)}>{content}</span>
  </div>
))
