import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {addHours} from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import {timeParse} from 'd3'
import {useDispatch} from 'react-redux'

import {TimeRange, getStartDateEndDate} from 'core/helpers'
import {
  NetWorth,
  ValuationSelection,
  ValuationSelectionToRouteId,
  getNetWorthTimeSeries,
} from 'models'
import {showErrorAlert} from 'store/alert/actions'

export type NetWorthAndDate = NetWorth & {
  date: Date;
}

export type NetWorthData = {
  data: NetWorthAndDate[];
  lastSync?: string;
}

type NetWorthState = {
  data: Partial<NetWorthData>;
  loading: boolean;
  requestInProgress?: boolean;
  sevenDayChange: number;
  isEmpty: boolean;
}

type CurrentNetWorth = NetWorth & {sevenDayChange?: number}

type NetWorthCache = {
  [valuationSelection: string]: {
    [timeRangeSelection: string]: NetWorth[];
  };
}

const parseDate = timeParse('%Y-%m-%d')

const initialCurrentNetWorth = {
  assetValue: 0,
  cryptoValue: 0,
  depositoryValue: 0,
  investmentValue: 0,
  liabilityValue: 0,
  loansValue: 0,
  networthValue: 0,
  otherValue: 0,
  revolvingValue: 0,
  timestamp: '',
  sevenDayChange: 0,
  realEstateValue: 0,
}

type Props = {
  clientId: string;
  initialTimeRange: TimeRange;
  valuation?: ValuationSelection;
  hasAccounts: boolean;
}

type ReturnType = {
  netWorth: NetWorthState;
  getNetWorth: () => Promise<boolean>;
  currentNetWorth: CurrentNetWorth;
  setCurrentNetWorth: (netWorth: CurrentNetWorth) => void;
  netWorthDifferential: number;
  netWorthTimeRange: TimeRange;
  setNetWorthTimeRange: (timeRange: TimeRange) => void;
  valuationSelection: Nullable<ValuationSelection>;
  setValuationSelection: (selection: ValuationSelection) => void;
}

// TODO(davorin): new version in src/pages/balance-sheet/utils.ts, we should consolidate
export function useNetWorth({
  clientId,
  initialTimeRange,
  valuation,
  hasAccounts,
}: Props): ReturnType {
  const dispatch = useDispatch()
  const [netWorth, setNetWorth] = useState<NetWorthState>({
    data: {data: [], lastSync: ''},
    sevenDayChange: 0,
    loading: true,
    isEmpty: false,
  })
  const [netWorthTimeRange, setNetWorthTimeRange] = useState<TimeRange>(initialTimeRange)
  const [netWorthDifferential, setNetWorthDifferential] = useState<number>(0)
  const [netWorthCache, setNetWorthCache] = useState<NetWorthCache>({})
  const [currentNetWorth, setCurrentNetWorth] = useState<CurrentNetWorth>(initialCurrentNetWorth)
  const [valuationSelection, setValuationSelection] = useState<Nullable<ValuationSelection>>(valuation || null)
  const previousValuationSelection = useRef<Nullable<ValuationSelection>>(null)

  const getNetWorth = useCallback(async () => {
    if (!valuationSelection) {
      return false
    }

    const netWorthPreference = ValuationSelectionToRouteId[valuationSelection].NET_WORTH
    const [startDate, endDate] = getStartDateEndDate(netWorthTimeRange)
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    let timeseries = netWorthCache[netWorthPreference]?.[netWorthTimeRange]
    let lastSync = ''

    if (!timeseries) {
      setNetWorth((prev) => ({...prev, loading: false}))

      // we use a timer technique to defer showing
      // the loader to prevent flashing
      // https://medium.com/trabe/delayed-render-of-react-components-3482f8ad48ad
      const timer = setTimeout(() => setNetWorth((prev) => ({...prev, loading: true})), 2000)

      const {data, error} = await getNetWorthTimeSeries(
        clientId,
        netWorthPreference,
        startDate,
        endDate,
      )

      clearTimeout(timer)

      if (error && error.response.status !== 404) {
        dispatch(showErrorAlert('Failed to fetch your net worth. Please try reloading the page.'))
        timeseries = []
      } else {
        // in the event that the user selects current year or month and
        // the date is the 1st we are able to display data
        // we want to keep the behavior of showing only data for yesterday
        // so we ignore the request to show an empty timeseries
        const isFirstDayEdgeCase = (
          (netWorthTimeRange === TimeRange.CURRENT_MONTH && new Date().getDate() === 1)
          || (netWorthTimeRange === TimeRange.CURRENT_YEAR
              && new Date().getDate() === 1 && new Date().getMonth() === 0)
        )

        timeseries = isFirstDayEdgeCase ? [] : (data?.data || [])
        lastSync = data?.lastSync || ''
      }
    }

    let sevenDayChange = 0
    let newData: NetWorthAndDate[]
    let emptyChart = false

    if (!hasAccounts) {
      // Create empty 90-day timeseries for chart only when user doesn't have any accounts.
      // This should display in tandem with the 'Connect accounts' note in the chart.
      const defaultTimeInDays = 90

      newData = Array.from(Array(defaultTimeInDays)).map((fakeNetWorthObj, i) => {
        const initialDate = new Date(startDate)
        const date = new Date(initialDate.setDate(initialDate.getDate() + i))

        return {
          assetValue: 0,
          cryptoValue: 0,
          depositoryValue: 0,
          investmentValue: 0,
          liabilityValue: 0,
          loansValue: 0,
          networthValue: 0,
          otherValue: 0,
          revolvingValue: 0,
          timestamp: '',
          realEstateValue: 0,
          date,
        }
      })

      emptyChart = true
    } else {
      // If we receive an empty timeseries then there are no datapoints we can use to create
      // a familiar y-axis on the Net Worth chart.

      // This is a problem because when a user selects a time range that doesn't have any data points
      // (ex. a range like Last Month, where start and end dates don't intersect with existing datapoints),
      // we still want to show them a normal-looking chart with the correct date range
      // and without the default Y-axis numbers or the 'Connect accounts' note.

      // To do that we can create two datapoints with the start and end dates.
      // When we chart this data we can use the user's `currentNetWorth` value as the max or
      // min value of the y-axis so that the chart retains familiarity with their net
      // worth, despite having no data for the current selected range.
      if (timeseries.length === 0) {
        timeseries.push({
          assetValue: 0,
          cryptoValue: 0,
          depositoryValue: 0,
          investmentValue: 0,
          liabilityValue: 0,
          loansValue: 0,
          networthValue: 0,
          otherValue: 0,
          revolvingValue: 0,
          timestamp: new Date(startDate).toISOString().replace(/T.+$/, ''),
          realEstateValue: 0,
        })

        timeseries.push({
          assetValue: 0,
          cryptoValue: 0,
          depositoryValue: 0,
          investmentValue: 0,
          liabilityValue: 0,
          loansValue: 0,
          networthValue: 0,
          otherValue: 0,
          revolvingValue: 0,
          timestamp: new Date(endDate).toISOString().replace(/T.+$/, ''),
          realEstateValue: 0,
        })
      }

      // if there is only 1 data point netWorthTRange is 0
      // else netWorthTRange is latest - earliest data point
      const netWorthTRange = (
        timeseries.length === 1
          ? 0
          : timeseries[timeseries.length - 1].networthValue - timeseries[0].networthValue
      )

      setNetWorthDifferential(netWorthTRange)

      const processedData: NetWorthAndDate[] = timeseries
        .map((datum: NetWorth) => (
          {...datum, date: parseDate(datum.timestamp) as Date}
        ))

      if (processedData.length === 1) {
        processedData.unshift({
          ...processedData[0],
          date: addHours(processedData[0].date, 1),
        })
      }

      const currentWorth: NetWorth = processedData[processedData.length - 1]

      sevenDayChange = (
        currentWorth.networthValue
        - (processedData[processedData.length - 8]?.networthValue || 0)
      )

      newData = processedData

      setNetWorthCache((prev) => ({
        ...prev,
        [valuationSelection]: {
          ...(prev[valuationSelection] || {}),
          [netWorthTimeRange]: timeseries,
        },
      }))

      // Set new currentNetWorth only if running for first time or if valuation has changed
      const firstUpdate = previousValuationSelection.current === null

      if (firstUpdate || previousValuationSelection.current !== valuationSelection) {
        setCurrentNetWorth({...currentWorth, sevenDayChange})
      }

      // Set previous valuation to the current value, otherwise a change in netWorthTimeRange
      // will cause currentNetWorth to change (since the previousValuationSelection and
      // valuationSelection will still have their previous values).
      previousValuationSelection.current = valuationSelection
    }

    setNetWorth((prevData) => ({
      data: {...prevData.data, data: newData, lastSync},
      loading: false,
      sevenDayChange,
      isEmpty: emptyChart,
    }))

    return !isEmpty(timeseries)
    // we ignore netWorthCache in deps because we don't want this to fire
    // when it changes - will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, valuationSelection, netWorthTimeRange, hasAccounts])

  useEffect(() => {
    if (valuationSelection) {
      void getNetWorth()
    }
  }, [valuationSelection, getNetWorth])

  const onValuationSelectionChange = useCallback((selection: ValuationSelection) => {
    if (selection !== valuationSelection) {
      previousValuationSelection.current = valuationSelection
      setValuationSelection(selection)
    }
  }, [valuationSelection])

  return {
    netWorth,
    getNetWorth,
    currentNetWorth,
    setCurrentNetWorth,
    netWorthDifferential,
    netWorthTimeRange,
    valuationSelection,
    setNetWorthTimeRange,
    setValuationSelection: onValuationSelectionChange,
  }
}
