import React from 'react'
import cx from 'classnames'

import Link from 'core/components/link/Link'

import styles from './_results.module.scss'

type Props = {
  children: React.ReactNode;
  header?: string;
  subheader?: string;
  twoColumn?: boolean;
  cta?: {
    ctaRoute: string;
    ctaCopy: string;
  };
  onCtaClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  padded?: boolean;
  noMargin?: boolean;
  className?: string;
  sectionId?: string;
}

export default function ResultsSection(
  {
    children,
    header,
    subheader,
    cta,
    onCtaClick,
    padded,
    twoColumn = false,
    noMargin,
    className,
    sectionId = '',
  }: Props,
): JSX.Element {
  return (
    <div className={cx(styles.resultsSection, {[styles.noMargin]: noMargin}, className)} id={sectionId}>
      {header && (
        <div className={styles.resultsSectionHeaderContainer}>
          <div className={styles.headerCTAContainer}>
            <h4 className={styles.resultsSectionHeader}>{header}</h4>
            {cta && (
              <Link to={cta.ctaRoute} className={styles.cta} onClick={onCtaClick}>
                {cta.ctaCopy}
              </Link>
            )}
          </div>
          {subheader && <div className={styles.resultsSectionSubheader}>{subheader}</div>}

        </div>
      )}
      <div className={cx(styles.resultsSectionContent, {
        [styles.resultsSectionGrid]: twoColumn,
        [styles.padded]: padded,
      })}
      >
        {children}
      </div>
    </div>
  )
}
