import React, {useMemo} from 'react'
import {parseISO} from 'date-fns'

import l from '../locale'
import NIITable from '../tables/NIITable'
import NIITThresholdTable from '../tables/NIITThresholdTable'
import IncomeDisplayTable from '../tables/IncomeDisplayTable'
import PayableNIITTable from '../tables/PayableNIITTable'

import CollapsibleTaxRow from './CollapsibleTaxRow'
import IncomeFromExercises from './IncomeFromExercises'
import IncomeFromVestingRSUs from './IncomeFromVestingRSUs'
import TaxColumnLabel, {TaxColumnType} from './TaxColumnLabel'
import CapitalGainsIncome from './CapitalGainsIncome'
import TaxBracketTable from './TaxBracketTable'
import styles from './_taxBreakdown.module.scss'
import {EducationTypes, TaxBreakdownEducationContainer} from './TaxBreakdownEducation'
import AMTTaxBreakdown from './AMTTaxBreakdown'

import {useGetFeatureFlagsQuery} from 'store/feature-flag'
import {STATE_ABBREV_TO_NAME} from 'core/constants/states'
import {numberFormatter} from 'core/formatters'
import {
  EquitySale,
  IncomeSource,
  OptionDisposition,
  OptionType,
  ScenarioResultType,
  TaxBracketOrdering,
  TaxBracketType,
  TaxGrantExerciseSummary,
  TaxScenarioGrantType,
  TaxScenarioResult,
  TaxTypeTotals,
  getIncomeSourcesFromTaxCategory,
} from 'models'

type Props = {
  isExercise: boolean;
  noSharesSold: boolean;
  result: TaxScenarioResult;
  title: string;
  taxEventDescription: string;
  purchaseDate: string;
  taxTotalsFromEvent: TaxTypeTotals;
  taxYear: number;
  scenarioNumber: number;
  exerciseSummaryData?: TaxGrantExerciseSummary[];
  overrideScenarioResult?: Nullable<TaxScenarioResult>;
  scenarioType?: ScenarioResultType;
}

function getExerciseOnlyFromTaxYear(exercises: IncomeSource[], taxYear: number): IncomeSource[] {
  return exercises.filter(({incomeSource}) => (
    parseISO((incomeSource as OptionDisposition).exerciseDate).getFullYear() === taxYear))
}

function getGrossNumberFromOptions(exercises: IncomeSource[]): number {
  return exercises.reduce((accu, record) => {
    const incomeSource = record.incomeSource as OptionDisposition
    const {company409a, exercisePrice, quantity} = incomeSource
    return (Math.max((company409a - exercisePrice), 0) * quantity) + accu
  }, 0)
}

export default function TaxOverview(
  {
    isExercise,
    noSharesSold,
    result,
    purchaseDate,
    title,
    taxEventDescription,
    taxTotalsFromEvent,
    taxYear,
    scenarioNumber,
    exerciseSummaryData,
    overrideScenarioResult = null,
    scenarioType,
  }: Props,
): JSX.Element {
  const {data: flags, isLoading: isLoadingFlags} = useGetFeatureFlagsQuery()

  const {taxBreakdown, taxProfile, incomeSources: {optionGrant}} = result
  const federalTaxBreakdown = taxBreakdown.federal
  const stateTaxBreakdown = taxBreakdown.state[taxProfile.states[0]]
  const {
    isoPriorExerciseIncomeSources: tmpFederalISOPriorExerciseIncomeSources,
    isoExerciseIncomeSources: tmpFederalISOExerciseIncomeSources,
    nsoPriorExerciseIncomeSources: tmpFederalNSOPriorExerciseIncomeSources,
    nsoExerciseIncomeSources: tmpFederalNSOExerciseIncomeSources,
    shortTermCapitalGainsIncomeSources: federalSTCGIncomeSources,
    longTermCapitalGainsIncomeSources: federalLTCGIncomeSources,
  } = useMemo(
    () => getIncomeSourcesFromTaxCategory(purchaseDate, federalTaxBreakdown),
    [federalTaxBreakdown, purchaseDate],
  )

  const {
    isoPriorExerciseIncomeSources: tmpStateISOPriorExerciseIncomeSources,
    isoExerciseIncomeSources: tmpStateISOExerciseIncomeSources,
    nsoPriorExerciseIncomeSources: tmpStateNSOPriorExerciseIncomeSources,
    nsoExerciseIncomeSources: tmpStateNSOExerciseIncomeSources,
    shortTermCapitalGainsIncomeSources: stateSTCGIncomeSources,
    longTermCapitalGainsIncomeSources: stateLTCGIncomeSources,
  } = useMemo(
    () => getIncomeSourcesFromTaxCategory(purchaseDate, stateTaxBreakdown),
    [stateTaxBreakdown, purchaseDate],
  )

  let federalISOPriorExerciseIncomeSources = tmpFederalISOPriorExerciseIncomeSources
  let federalISOExerciseIncomeSources = tmpFederalISOExerciseIncomeSources
  let stateISOPriorExerciseIncomeSources = tmpStateISOPriorExerciseIncomeSources
  let stateISOExerciseIncomeSources = tmpStateISOExerciseIncomeSources
  let federalNSOPriorExerciseIncomeSources = tmpFederalNSOPriorExerciseIncomeSources
  let federalNSOExerciseIncomeSources = tmpFederalNSOExerciseIncomeSources
  let stateNSOPriorExerciseIncomeSources = tmpStateNSOPriorExerciseIncomeSources
  let stateNSOExerciseIncomeSources = tmpStateNSOExerciseIncomeSources

  // IMPORTANT!
  // sale data is not known at the time of purchase
  // to cover the case of categorizing unsold and sold ISO options for
  // the purchase year we can just copy over the option data from the sale
  // year since it is known then
  if (overrideScenarioResult) {
    const {taxBreakdown: isoOverride} = overrideScenarioResult

    const fedIsoOverride = getIncomeSourcesFromTaxCategory(purchaseDate, isoOverride.federal)

    federalISOPriorExerciseIncomeSources = fedIsoOverride.isoPriorExerciseIncomeSources
    federalISOExerciseIncomeSources = fedIsoOverride.isoExerciseIncomeSources
    federalNSOPriorExerciseIncomeSources = fedIsoOverride.nsoPriorExerciseIncomeSources
    federalNSOExerciseIncomeSources = fedIsoOverride.nsoExerciseIncomeSources

    const isoOverrideStateTaxBreakdown = isoOverride.state[taxProfile.states[0]]
    const stateIsoOverride = getIncomeSourcesFromTaxCategory(purchaseDate, isoOverrideStateTaxBreakdown)

    stateISOPriorExerciseIncomeSources = stateIsoOverride.isoPriorExerciseIncomeSources
    stateISOExerciseIncomeSources = stateIsoOverride.isoExerciseIncomeSources
    stateNSOExerciseIncomeSources = stateIsoOverride.nsoExerciseIncomeSources
    stateNSOPriorExerciseIncomeSources = stateIsoOverride.nsoPriorExerciseIncomeSources
  }

  const federalPriorISOExerciseOnlyTaxYear = useMemo(() => (
    getExerciseOnlyFromTaxYear(federalISOPriorExerciseIncomeSources, taxYear)
  ), [federalISOPriorExerciseIncomeSources, taxYear])

  const statePriorISOExerciseOnlyTaxYear = useMemo(() => (
    getExerciseOnlyFromTaxYear(stateISOPriorExerciseIncomeSources, taxYear)
  ), [stateISOPriorExerciseIncomeSources, taxYear])

  const federalPriorNSOExerciseOnlyTaxYear = useMemo(() => (
    getExerciseOnlyFromTaxYear(federalNSOPriorExerciseIncomeSources, taxYear)
  ), [federalNSOPriorExerciseIncomeSources, taxYear])

  const statePriorNSOExerciseOnlyTaxYear = useMemo(() => (
    getExerciseOnlyFromTaxYear(stateNSOPriorExerciseIncomeSources, taxYear)
  ), [stateNSOPriorExerciseIncomeSources, taxYear])

  const isOptionGrant = ({incomeSource}: IncomeSource): boolean => (
    [
      TaxScenarioGrantType.INCENTIVE_STOCK,
      TaxScenarioGrantType.NON_QUALIFIED,
    ].includes((incomeSource as EquitySale).grantType)
  )

  const hasPriorExerciseFedLTCG = useMemo(() => (
    federalLTCGIncomeSources
      .filter(isOptionGrant)
      .some(({incomeSource}) => (incomeSource as EquitySale).purchaseDate !== purchaseDate)),
  [federalLTCGIncomeSources, purchaseDate])

  const hasPriorExerciseFedSTCG = useMemo(() => (
    federalSTCGIncomeSources
      .filter(isOptionGrant)
      .some(({incomeSource}) => (incomeSource as EquitySale).purchaseDate !== purchaseDate)),
  [federalSTCGIncomeSources, purchaseDate])

  const hasPriorExerciseStateLTCG = useMemo(() => (
    stateLTCGIncomeSources
      .filter(isOptionGrant)
      .some(({incomeSource}) => (incomeSource as EquitySale).purchaseDate !== purchaseDate)),
  [stateLTCGIncomeSources, purchaseDate])

  const hasPriorExerciseStateSTCG = useMemo(() => (
    stateSTCGIncomeSources
      .filter(isOptionGrant)
      .some(({incomeSource}) => (incomeSource as EquitySale).purchaseDate !== purchaseDate)),
  [stateSTCGIncomeSources, purchaseDate])

  const hasISOs = exerciseSummaryData?.some(({grantType}) => grantType === TaxScenarioGrantType.INCENTIVE_STOCK)

  const onlyHoldsNSOs = optionGrant.every(({optionType}) => optionType === OptionType.NON_QUALIFIED)
  const onlyHoldsISOs = !onlyHoldsNSOs && optionGrant.every(({optionType}) => optionType === OptionType.ISO)
  const stateName = STATE_ABBREV_TO_NAME[taxProfile.states[0]] as string

  let federalTotalTaxes = result.taxSummary.totalFederalTax
  let {totalStateTax} = result.taxSummary

  if (isExercise) {
    federalTotalTaxes += result.taxSummary.amtTax
    federalTotalTaxes -= result.taxSummary.netInvestmentIncomeTax + result.taxBreakdown.federal.capitalGains.taxesOwed
    federalTotalTaxes = Math.max(0, federalTotalTaxes)

    totalStateTax -= result.taxBreakdown.state[taxProfile.states[0]].capitalGains.taxesOwed
    totalStateTax = Math.max(0, totalStateTax)
  }

  const federalIncomePriorExerciseNSO = useMemo(() => (
    getGrossNumberFromOptions(federalPriorNSOExerciseOnlyTaxYear)),
  [federalPriorNSOExerciseOnlyTaxYear])

  const stateIncomePriorExerciseNSO = useMemo(() => (
    getGrossNumberFromOptions(statePriorNSOExerciseOnlyTaxYear)),
  [statePriorNSOExerciseOnlyTaxYear])

  const overline = scenarioType
    ? `SCENARIO ${scenarioNumber}: ${l.SCENARIO_TITLE[scenarioType]}`
    : `SCENARIO ${scenarioNumber}`

  const federalRSUVestingIncomeSources = federalTaxBreakdown.ordinaryIncome.incomeSources
    .filter(({orderingLabel}) => orderingLabel === TaxBracketOrdering.RSU_VESTING)

  const stateRSUVestingIncomeSources = stateTaxBreakdown.ordinaryIncome.incomeSources
    .filter(({orderingLabel}) => orderingLabel === TaxBracketOrdering.RSU_VESTING)

  return (
    <div className={styles.wrapper}>
      <div className={styles.scenarioIntro}>
        <span className={styles.scenarioNumber}>{overline}</span>
        <div className={styles.header}>
          <h2 className={styles.scenarioTitle}>{title}</h2>
          <h2>{numberFormatter(taxTotalsFromEvent.total).intCurrency}</h2>
        </div>
        <p className={styles.scenarioDescription}>{taxEventDescription}</p>
      </div>
      <div className={styles.subheader}>
        <span className={styles.totalTaxes}>
          <TaxColumnLabel taxColumnType={TaxColumnType.totalTaxes} taxYear={taxYear} />
        </span>
        <span>
          <TaxColumnLabel
            taxColumnType={isExercise ? TaxColumnType.fromExercise : TaxColumnType.fromSale}
            taxYear={taxYear}
          />
        </span>
      </div>
      <div className={styles.sectionTitle}>
        <span>{l.TAX_OVERVIEW[TaxBracketType.FEDERAL].TITLE}</span>
        <span className={styles.totalTaxes}>
          {numberFormatter(federalTotalTaxes).intCurrency}
        </span>
        <span>{numberFormatter(taxTotalsFromEvent.federal).intCurrency}</span>
      </div>
      <div className={styles.sectionBody}>
        <CollapsibleTaxRow
          label={l.TAX_OVERVIEW.ORDINARY_INCOME_TAX}
          totalOwed={federalTaxBreakdown.ordinaryIncome.taxesOwed}
          totalOwedFromEvent={taxTotalsFromEvent.federalOrdinary}
        >
          <TaxBreakdownEducationContainer
            educationType={EducationTypes.EXERCISE_AND_SALE_FEDERAL_ORDINARY_INCOME_TAX}
          />
          {
            !onlyHoldsISOs && isExercise && !!federalPriorNSOExerciseOnlyTaxYear.length && (
              <IncomeFromExercises
                isPriorExercise
                hasPriorExercise
                exercises={federalPriorNSOExerciseOnlyTaxYear}
                isExercise={isExercise}
                purchaseDate={purchaseDate}
                scenarioNumber={scenarioNumber}
                taxYear={taxYear}
                isNSO
              />
            )
          }
          {!onlyHoldsISOs && isExercise && (
            <IncomeFromExercises
              exercises={federalNSOExerciseIncomeSources}
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              hasPriorExercise={!isExercise && !!federalNSOExerciseIncomeSources.length}
              isExercise={isExercise}
              purchaseDate={purchaseDate}
              scenarioNumber={scenarioNumber}
              taxYear={taxYear}
              isNSO
            />
          )}
          {
            !onlyHoldsNSOs && isExercise && !!federalPriorISOExerciseOnlyTaxYear.length && (
              <IncomeFromExercises
                isPriorExercise
                hasPriorExercise
                exercises={federalPriorISOExerciseOnlyTaxYear}
                isExercise={isExercise}
                purchaseDate={purchaseDate}
                scenarioNumber={scenarioNumber}
                taxYear={taxYear}
              />
            )
          }
          {!onlyHoldsNSOs
            && (
            <IncomeFromExercises
              exercises={federalISOExerciseIncomeSources
                .concat(!isExercise ? federalISOPriorExerciseIncomeSources : [])}
              hasPriorExercise={!isExercise && !!federalISOExerciseIncomeSources.length}
              isExercise={isExercise}
              purchaseDate={purchaseDate}
              scenarioNumber={scenarioNumber}
              taxYear={taxYear}
            />
            )}
          {flags?.rsuSupport && (
            <IncomeFromVestingRSUs
              incomeSources={federalRSUVestingIncomeSources}
              scenarioNumber={scenarioNumber}
            />
          )}
          {!isExercise && (
            <CapitalGainsIncome
              noSharesSold={noSharesSold}
              sales={federalSTCGIncomeSources}
              purchaseDate={purchaseDate}
              hasPriorExercise={hasPriorExerciseFedSTCG}
              id={`fed-stcg-${scenarioNumber}`}
              isShortTerm
            />
          )}
          <IncomeDisplayTable
            result={result}
            title={`${taxYear} Ordinary income`}
            isFederal
            scenarioNumber={scenarioNumber}
            purchaseDate={purchaseDate}
            hideLongTermGains
            hideISOs={onlyHoldsNSOs}
            hideNSOs={onlyHoldsISOs}
            showRSUs={flags?.rsuSupport}
          />
          <TaxBracketTable
            deductions={result.taxBreakdown.federal.ordinaryIncome.deductions[0]?.amount || 0}
            breakdown={result.taxBreakdown.federal.ordinaryIncome}
            result={result}
            taxType={TaxBracketType.FEDERAL_ORDINARY}
            scenarioNumber={scenarioNumber}
            incomePriorExerciseNSO={federalIncomePriorExerciseNSO}
          />
        </CollapsibleTaxRow>
        {!isExercise && (
          <>
            <CollapsibleTaxRow
              label="Long-term capital gains tax"
              totalOwed={federalTaxBreakdown.capitalGains.taxesOwed}
              totalOwedFromEvent={taxTotalsFromEvent.federalLongTermCapGains}
            >
              <TaxBreakdownEducationContainer
                educationType={EducationTypes.SALE_FEDERAL_LONG_TERM_CAPITAL_GAINS_TAX}
              />
              <CapitalGainsIncome
                noSharesSold={noSharesSold}
                sales={federalLTCGIncomeSources}
                purchaseDate={purchaseDate}
                hasPriorExercise={hasPriorExerciseFedLTCG}
                id={`fed-ltcg-${scenarioNumber}`}
              />
              <IncomeDisplayTable
                result={result}
                isFederal
                title={`${taxYear} Ordinary income to calculate LTCG bracket`}
                scenarioNumber={scenarioNumber}
                purchaseDate={purchaseDate}
                hideLongTermGains
                hasEducation
                showRSUs={flags?.rsuSupport}
              />
              <TaxBracketTable
                breakdown={result.taxBreakdown.federal.capitalGains}
                result={result}
                taxType={TaxBracketType.FEDERAL_LTCG}
                deductions={result.taxBreakdown.federal.ordinaryIncome.deductions[0].amount}
                scenarioNumber={scenarioNumber}
                incomePriorExerciseNSO={federalIncomePriorExerciseNSO}
              />
            </CollapsibleTaxRow>
            <CollapsibleTaxRow
              label={l.TAX_OVERVIEW.NIIT}
              totalOwed={result.taxBreakdown.netInvestmentIncome.taxesOwed}
              totalOwedFromEvent={taxTotalsFromEvent.netInvestmentIncome}
            >
              <TaxBreakdownEducationContainer
                educationType={EducationTypes.SALE_FEDERAL_NET_INVESTMENT_INCOME_TAX}
              />
              <IncomeDisplayTable
                title={`${taxYear} Modified Adjusted Gross Income (MAGI)`}
                totalLabel="Modified Adjusted Gross Income (MAGI)"
                result={result}
                isFederal
                scenarioNumber={scenarioNumber}
                purchaseDate={purchaseDate}
                incomeFromSaleLabel={l.INCOME_FROM_SCENARIO_SALE_LABEL_MAGI}
                showRSUs={flags?.rsuSupport}
              />
              <NIITable result={result} />
              <NIITThresholdTable result={result} />
              <PayableNIITTable result={result} scenarioNumber={scenarioNumber} />
            </CollapsibleTaxRow>
          </>
        )}
        <CollapsibleTaxRow
          label={l.TAX_OVERVIEW.SOCIAL_SECURITY_TAX}
          totalOwed={result.taxBreakdown.socialSecurity.taxesOwed}
          totalOwedFromEvent={taxTotalsFromEvent.socialSecurity}
        >
          <TaxBreakdownEducationContainer
            socialSecurityThreshold={result.taxBreakdown.socialSecurity.taxRates[0].amountInBracket}
            currentTaxRate={result.taxBreakdown.socialSecurity.taxRates[0].rate}
            educationType={EducationTypes.EXERCISE_AND_SALE_FEDERAL_SOCIAL_SECURITY_TAX}
          />
          <IncomeDisplayTable
            result={result}
            title={`${taxYear} Earned Income`}
            totalLabel="Total Taxable Earned Income"
            isFederal
            hideShortTermGains
            hideLongTermGains
            removeDeductions
            scenarioNumber={scenarioNumber}
            purchaseDate={purchaseDate}
            hideISOs={onlyHoldsNSOs}
            hideNSOs={onlyHoldsISOs}
            showRSUs={flags?.rsuSupport}
          />
          <TaxBracketTable
            deductions={result.taxBreakdown.socialSecurity.deductions[0]?.amount || 0}
            bracketThreshold={result.taxBreakdown.socialSecurity.taxRates[1]?.bracket?.[0] || 0}
            breakdown={result.taxBreakdown.socialSecurity}
            result={result}
            scenarioNumber={scenarioNumber}
            taxType={TaxBracketType.SOCIAL_SECURITY}
            incomePriorExerciseNSO={federalIncomePriorExerciseNSO}
          />
        </CollapsibleTaxRow>
        <CollapsibleTaxRow
          label={l.TAX_OVERVIEW.MEDICARE}
          totalOwed={result.taxBreakdown.medicare.taxesOwed}
          totalOwedFromEvent={taxTotalsFromEvent.medicare}
        >
          <TaxBreakdownEducationContainer
            normalTaxRate={result.taxBreakdown.medicare.taxRates[0].rate}
            educationType={EducationTypes.EXERCISE_AND_SALE_FEDERAL_MEDICARE_TAX}
          />
          <IncomeDisplayTable
            result={result}
            title={`${taxYear} Earned Income`}
            totalLabel="Total Taxable Earned Income"
            isFederal
            hideShortTermGains
            hideLongTermGains
            removeDeductions
            scenarioNumber={scenarioNumber}
            purchaseDate={purchaseDate}
            hideISOs={onlyHoldsNSOs}
            hideNSOs={onlyHoldsISOs}
            showRSUs={flags?.rsuSupport}
          />
          <TaxBracketTable
            deductions={result.taxBreakdown.medicare.deductions[0]?.amount || 0}
            breakdown={result.taxBreakdown.medicare}
            result={result}
            scenarioNumber={scenarioNumber}
            taxType={TaxBracketType.MEDICARE}
            incomePriorExerciseNSO={federalIncomePriorExerciseNSO}
          />
        </CollapsibleTaxRow>
        {(!isLoadingFlags || (isExercise && hasISOs)) && (
          <AMTTaxBreakdown
            result={result}
            purchaseDate={purchaseDate}
            taxYear={taxYear}
            scenarioNumber={scenarioNumber}
            overrideScenarioResult={overrideScenarioResult}
            incomePriorExerciseNSO={federalIncomePriorExerciseNSO}
            isExercise={isExercise}
            noSharesSold={noSharesSold}
            hasPriorExerciseFedSTCG={hasPriorExerciseFedSTCG}
            hasPriorExerciseFedLTCG={hasPriorExerciseFedLTCG}
            federalSTCGIncomeSources={federalSTCGIncomeSources}
            federalLTCGIncomeSources={federalLTCGIncomeSources}
          />
        )}
      </div>
      <div className={styles.sectionTitle}>
        <span>{`${stateName} ${l.TAX_OVERVIEW[TaxBracketType.STATE].TITLE}`}</span>
        <span className={styles.totalTaxes}>{numberFormatter(totalStateTax).intCurrency}</span>
        <span>{numberFormatter(taxTotalsFromEvent.state).intCurrency}</span>
      </div>
      <div className={styles.sectionBody}>
        <CollapsibleTaxRow
          label={l.TAX_OVERVIEW.ORDINARY_INCOME_TAX}
          totalOwed={stateTaxBreakdown.ordinaryIncome.taxesOwed}
          totalOwedFromEvent={taxTotalsFromEvent.stateOrdinary}
        >
          <TaxBreakdownEducationContainer
            educationType={EducationTypes.EXERCISE_AND_SALE_STATE_ORDINARY_INCOME_TAX}
          />
          {
            isExercise && !!statePriorNSOExerciseOnlyTaxYear.length && (
              <IncomeFromExercises
                isPriorExercise
                hasPriorExercise
                exercises={statePriorNSOExerciseOnlyTaxYear}
                isExercise={isExercise}
                purchaseDate={purchaseDate}
                scenarioNumber={scenarioNumber}
                taxYear={taxYear}
                isNSO
              />
            )
          }
          {!onlyHoldsISOs && isExercise && (
            <IncomeFromExercises
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              hasPriorExercise={!isExercise && !!stateNSOPriorExerciseIncomeSources.length}
              exercises={stateNSOExerciseIncomeSources}
              isExercise={isExercise}
              purchaseDate={purchaseDate}
              scenarioNumber={scenarioNumber}
              taxYear={taxYear}
              isNSO
            />
          )}
          {
            !onlyHoldsNSOs && isExercise && !!statePriorISOExerciseOnlyTaxYear.length && (
              <IncomeFromExercises
                isPriorExercise
                hasPriorExercise
                exercises={statePriorISOExerciseOnlyTaxYear}
                isExercise={isExercise}
                purchaseDate={purchaseDate}
                scenarioNumber={scenarioNumber}
                taxYear={taxYear}
              />
            )
          }
          {!onlyHoldsNSOs
            && (
            <IncomeFromExercises
              hasPriorExercise={!isExercise && !!stateISOPriorExerciseIncomeSources.length}
              exercises={stateISOExerciseIncomeSources.concat(!isExercise ? stateISOPriorExerciseIncomeSources : [])}
              isExercise={isExercise}
              purchaseDate={purchaseDate}
              scenarioNumber={scenarioNumber}
              taxYear={taxYear}
            />
            )}
          {flags?.rsuSupport && (
            <IncomeFromVestingRSUs
              incomeSources={stateRSUVestingIncomeSources}
              scenarioNumber={scenarioNumber}
            />
          )}
          {!isExercise && (
            <CapitalGainsIncome
              noSharesSold={noSharesSold}
              sales={stateSTCGIncomeSources}
              purchaseDate={purchaseDate}
              hasPriorExercise={hasPriorExerciseStateSTCG}
              id={`state-stcg-${scenarioNumber}`}
              isShortTerm
            />
          )}
          <IncomeDisplayTable
            result={result}
            title={`${taxYear} Ordinary income`}
            scenarioNumber={scenarioNumber}
            purchaseDate={purchaseDate}
            hideLongTermGains
            hideISOs={onlyHoldsNSOs}
            hideNSOs={onlyHoldsISOs}
            showRSUs={flags?.rsuSupport}
          />
          <TaxBracketTable
            deductions={stateTaxBreakdown.ordinaryIncome.deductions[0]?.amount || 0}
            breakdown={stateTaxBreakdown.ordinaryIncome}
            result={result}
            scenarioNumber={scenarioNumber}
            taxType={TaxBracketType.STATE_ORDINARY}
            incomePriorExerciseNSO={stateIncomePriorExerciseNSO}
          />
        </CollapsibleTaxRow>
        {!isExercise && (
          <CollapsibleTaxRow
            label="Long-term capital gains tax"
            totalOwed={stateTaxBreakdown.capitalGains.taxesOwed}
            totalOwedFromEvent={taxTotalsFromEvent.stateLongTermCapGains}
          >
            <TaxBreakdownEducationContainer
              educationType={EducationTypes.SALE_STATE_LONG_TERM_CAPITAL_GAINS_TAX}
            />
            <CapitalGainsIncome
              sales={stateLTCGIncomeSources}
              noSharesSold={noSharesSold}
              purchaseDate={purchaseDate}
              hasPriorExercise={hasPriorExerciseStateLTCG}
              id={`state-ltcg-${scenarioNumber}`}
            />
            <IncomeDisplayTable
              result={result}
              title={`${taxYear} Ordinary income to calculate LTCG bracket`}
              scenarioNumber={scenarioNumber}
              purchaseDate={purchaseDate}
              hideLongTermGains
              showRSUs={flags?.rsuSupport}
            />
            <TaxBracketTable
              breakdown={stateTaxBreakdown.capitalGains}
              result={result}
              scenarioNumber={scenarioNumber}
              taxType={TaxBracketType.STATE_LTCG}
              deductions={stateTaxBreakdown.ordinaryIncome.deductions[0].amount}
              incomePriorExerciseNSO={stateIncomePriorExerciseNSO}
            />
          </CollapsibleTaxRow>
        )}

        {(isExercise && hasISOs) && (
          <CollapsibleTaxRow
            label={`${l.TAX_OVERVIEW.ALTERNATIVE_MINIMUM_TAX.TITLE}*`}
            totalOwed="N/A*"
            totalOwedFromEvent="N/A*"
          >
            <div className={styles.taxDescription}>
              <TaxBreakdownEducationContainer
                educationType={EducationTypes.EXERCISE_STATE_ALTERNATIVE_MINIMUM_TAX}
              />
            </div>
          </CollapsibleTaxRow>
        )}
      </div>
    </div>
  )
}
