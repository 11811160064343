import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Link from 'core/components/link/Link'
import {Banner} from 'core/components/banner'

import {Analytics} from 'modules/analytics'
import {AlertAction, AlertSelector} from 'store/alert'
import {RoutePaths} from 'core/constants'

const ACCOUNT_SETTINGS = 'account settings'
const REGENERATE = 'regenerate'

export const BackupCodeBanner = (): JSX.Element => {
  const message = useSelector(AlertSelector.getAlert)
  const dispatch = useDispatch()

  const handleLinkClick = (cta: string) => (): void => {
    Analytics.global2FABackupCodesBannerCTAClick({cta})
    if (cta === REGENERATE) {
      Analytics.global2FABackupCodesRegenerateCodes({from: 'banner'})
    }
    dispatch(AlertAction.removeAlert())
  }

  const handleDismiss = (): void => {
    dispatch(AlertAction.removeAlert())
  }

  return (
    <Banner
      id="backup-code-banner"
      intent="warning"
      sticky
      onDismiss={handleDismiss}
    >
      {message?.data?.backupCodesLeft > 0 ? (
        <>
          You used a one-time use backup code. Go to
          {' '}
          <Link bold underlined to={RoutePaths.SETTINGS} onClick={handleLinkClick(ACCOUNT_SETTINGS)}>
            Account Settings
          </Link>
          {' '}
          to manage or regenerate your codes.
        </>
      ) : (
        <>
          You’ve used your single-use backup codes.
          {' '}
          <Link
            bold
            underlined
            to={{
              pathname: RoutePaths.SETTINGS,
              state: {regenerateBackupCodes: true},
            }}
            onClick={handleLinkClick(REGENERATE)}
          >
            Regenerate
          </Link>
          {' '}
          and save new backup codes.
        </>
      )}
    </Banner>
  )
}
