import React from 'react'
import cx from 'classnames'

import Icon, {IconName} from 'core/components/icon/Icon'

import {SwipeSideText} from '../types'
import styles from '../_results.module.scss'
import {useScenarioSlider} from '../hooks/ScenarioSliderProvider'

const LOWER_RISK_SLIDE_INDEX = 1

const SwipeInfo = (): JSX.Element => {
  const {currentSlideIndex} = useScenarioSlider()

  const isLowerRisk = currentSlideIndex === LOWER_RISK_SLIDE_INDEX
  const text = isLowerRisk ? SwipeSideText.LOWER_RISK_TEXT : SwipeSideText.EFFICIENT_TEXT

  return (
    <div className={cx(styles.swipeInfo, {
      [styles.reverse]: isLowerRisk,
    })}
    >
      <span>{text}</span>
      <Icon
        className={cx(styles.arrowIcon, {
          [styles.arrowLeft]: isLowerRisk,
        })}
        name={IconName.ArrowPointer}
      />
    </div>
  )
}

export default React.memo(SwipeInfo)
