import {
  NumberedTaxRate,
  TaxRate,
} from 'models'

// Assigns bracket numbers to tax rates
// - an ordinal number is determined by matching the lower limit
// - assumes tax rates are in a non-decreasing order, starting with the lowest bracket
export function numberedTaxRates(taxRates: TaxRate[]): NumberedTaxRate[] {
  const bracketSet = new Set(taxRates.map((tr) => tr.bracket[0]))
  const bracketMap: Record<number, number> = Array.from(bracketSet)
    .reduce((res, lower, idx) => ({...res, [lower]: idx + 1}), {})
  return taxRates.map((taxRate) => ({
    ...taxRate,
    bracketNumber: bracketMap[taxRate.bracket[0]],
  }))
}
