export const strings = {
  HEADER: 'Edit Liquidity Event',
  EVENT_TYPE: {
    LABEL: 'Liquidity Event',
    TOOLTIP: '',
  },
  EVENT_TIMING: {
    LABEL: 'Estimated [EVENT_TYPE] date',
    TOOLTIP: '',
  },
  LOCKUP_PERIOD: {
    LABEL: 'Estimated lockup period',
    TOOLTIP: '',
  },
  SALE_PRICE: {
    LABEL: 'Estimated sale price',
    TOOLTIP: '',
  },
}
