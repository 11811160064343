import React from 'react'
import {useMediaQuery} from 'react-responsive'
import cx from 'classnames'

import Button from '../button/Button'
import Icon, {IconName, SpotIconName} from '../icon/Icon'
import Link from '../link/Link'

import styles from './_insight.module.scss'

import colors from 'core/styles/_colors.module.scss'

export type Props = {
  /**
   * Element to render an action corresponding to the insight. Styles are applied to the element within the insight,
   * but may be be overridden by passing a class to the element.
   * */
  action?: React.ReactComponentElement<typeof Link | typeof Button>;
  /** An additional `action` */
  additionalAction?: React.ReactComponentElement<typeof Link | typeof Button>;
  /**
   * Whether to blend the insight into its background. Basic insights typically will not be dismissible or contain an
   * action.
   * */
  basic?: boolean;
  /** Background color for the component */
  backgroundColor?: string;
  /** Border color for the component */
  borderColor?: string;
  /** Space-separated list of classes passed to the underlying element. */
  className?: string;
  /** Detailed explanation of the insight. */
  description: React.ReactNode;
  /** React element in place of the icon position. */
  customIcon?: React.ReactNode;
  /** Name of the icon to display in the insight. */
  icon?: IconName | SpotIconName;
  /** Color (HTML color names, rgb, rgba, hex values) to fill the backdrop behind the icon. */
  // TODO(steph): narrow background colors to make component consumption easier and styling cleaner
  iconBackground?: string;
  /** Color (HTML color names, rgb, rgba, hex values) to paint the inside of the icon object. */
  iconFill?: string;
  /** Value to set the icon size, altering both `height` and `width`. Explicit units may be set (px, em, rem, etc.). */
  iconSize?: number | string;
  /** Whether to render the insight description inline with the title. */
  inline?: boolean;
  /** Whether to render the insight container with a standard 2.25rem height and width. */
  fixedLogoDimensions?: boolean;
  /** Whether to render the insight icon inline with the text on mobile. */
  inlineIcon?: boolean;
  /** Callback to invoke when an insight's dismiss button is pressed. */
  onDismiss?: Nullable<(e: React.MouseEvent<HTMLButtonElement>) => void>;
  /** Primary headline for the insight. */
  title?: React.ReactNode;
  'data-cy'?: string;
}

/**
 * **Highlights brief, visually important content to the user.**
 * <br>
 * An insight may contain a title, an icon and an action. An insight may also be dismissible.
 *
 *
 * See [InsightGroup](/docs/components-insightgroup--insight-group-preview) to group together multiple insights.
 */

export function InsightComponent(
  {
    action,
    additionalAction,
    basic,
    backgroundColor,
    borderColor,
    className,
    description,
    icon,
    iconBackground = 'transparent',
    iconFill = '#fff',
    customIcon,
    iconSize,
    inline,
    inlineIcon = false,
    fixedLogoDimensions = true,
    onDismiss,
    title,
    'data-cy': dataCy,
  }:
  Props,
): JSX.Element {
  const isAboveMobile = useMediaQuery({minWidth: 720})
  let insightIconBackground = iconBackground
  // On mobile, in order for insight icons to display properly inline with text, the background
  // must be transparent; Above mobile, insight icons should have the iconBackground filled back in
  if (!isAboveMobile && inlineIcon) insightIconBackground = 'transparent'

  return (
    <div
      className={cx(
        styles.insight,
        basic && styles.basic,
        !onDismiss && styles.undismissable,
        (icon || customIcon) && !inline && styles.insightWithIcon,
        className,
      )}
      data-cy={dataCy ?? ''}
      style={{background: backgroundColor, border: borderColor ? `1px solid ${borderColor}` : 'none'}}
    >
      <div className={cx(
        styles.insightContentContainer,
        inlineIcon && styles.inlineIcon,
      )}
      >
        {(icon || customIcon) && (
          <div
            className={cx(
              styles.iconBackground,
              inlineIcon && styles.inlineIcon,
              fixedLogoDimensions && styles.fixedDimensions,
            )}
            style={{background: insightIconBackground}}
          >
            {
              customIcon
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ?? <Icon name={icon} fill={iconFill} size={iconSize ?? (isAboveMobile ? 16 : 14)} />
            }
          </div>
        )}
        <div className={styles.insightContent}>
          <div className={cx(styles.insightText, inline && styles.inline)}>
            {title && <span className={styles.title}>{title}</span>}
            <span className={styles.description}>{description}</span>
          </div>
          {action && React.cloneElement(action, {className: cx(styles.action, action.props.className)})}

          {
            additionalAction && React.cloneElement(
              additionalAction,
              {className: cx(styles.action, styles.additional, additionalAction.props.className)},
            )
          }
        </div>
      </div>
      {onDismiss && (
        <Button className={styles.dismissBtn} onClick={onDismiss} aria-label="click to dismiss insight">
          <Icon name={IconName.MenuCancel} fill={colors.darkBlue200} size={isAboveMobile ? 10 : 12} />
        </Button>
      )}
    </div>
  )
}

export default React.memo(InsightComponent)
