import {Base, Environment} from './types'

// Use staging marketing site url to prevent 404 errors on images and articles from marketing site
const MARKETING_SITE_URL = import.meta.env.REACT_APP_ENV === Environment.LOCAL
  ? 'https://www-staging.harnesswealth.com'
  : window.location.origin

const baseConfig: Base = {
  MARKETING_SITE_URL,
  URL_TERMS_OF_SERVICE: `${MARKETING_SITE_URL}/terms-of-service/`,
  URL_PRIVACY_POLICY: `${MARKETING_SITE_URL}/privacy-policy/`,
  URL_IN_THE_NEWS: `${MARKETING_SITE_URL}/in-the-news/`,
  URL_HARNESS_FOR_EMPLOYERS: `${MARKETING_SITE_URL}/harness-for-employers/`,
  URL_CRS_FORM: `${MARKETING_SITE_URL}/form-crs-client-relationship-summary/`,
  URL_CRS_DISCLOSURES: `${MARKETING_SITE_URL}/client-relationship-disclosures/`,
  URL_DISCLOSURES: `${MARKETING_SITE_URL}/disclosures/`,
  URL_PROMOTERS_DISCLOSURE: `${MARKETING_SITE_URL}/promoters-disclosure/`,
  URL_ADV_PART_2A: 'https://adviserinfo.sec.gov/firm/brochure/299795',
  URL_CRS: `${MARKETING_SITE_URL}/form-crs-client-relationship-summary/`,
  URL_ABOUT_US: `${MARKETING_SITE_URL}/about-us/`,
  URL_ARTICLES: `${MARKETING_SITE_URL}/articles/`,
  URL_LINKEDIN: 'https://linkedin.com/company/harnesswealth',
  URL_TWITTER: 'https://twitter.com/HarnessWealth',
  URL_INSTAGRAM: 'https://www.instagram.com/harnesswealth',
  URL_ROADMAP: 'https://harness.canny.io/',
  URL_GLB_PRIVACY_NOTICE: `${MARKETING_SITE_URL}/gramm-leach-bliley-privacy-notice/`,
  PLAID_ENVIRONMENT: 'sandbox',
  PLAID_PUBLIC_KEY: 'ebe61b109afec22e8b18a7108a2d78',
  GOOGLE_SSO_CLIENT_ID: '750398498729-llhcm9553633mjinq8okjcrm9ci2kd3b.apps.googleusercontent.com',
  URL_ADVISOR_AGREEMENT: `${MARKETING_SITE_URL}/advisor-agreement/`,
}

export default baseConfig
