import {createReducer, unwrapResult} from '@reduxjs/toolkit'

import * as CryptoActions from './actions'

import {CryptoAccount} from 'api'

const cryptoAccounts = createReducer([] as CryptoAccount[], (builder) => {
  builder
    .addCase(CryptoActions.getCryptoAccounts.fulfilled, (_, action) => unwrapResult(action))
})

export default cryptoAccounts
