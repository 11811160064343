import React from 'react'
import cx from 'classnames'

import Button from 'core/components/button/Button'
import Modal from 'core/components/modal/Modal'

import styles from './_styles.module.scss'

type Props = {
  /** Cancel CTA (left footer button; tertiary intent). */
  cancelText?: string;
  /** Intent of the cancel CTA. Defaults to `tertiary`. */
  cancelIntent?: 'primary' | 'secondary' | 'tertiary';
  /** Confirm CTA (right footer button; defaults to `primary` intent). */
  confirmText?: string;
  /** Intent of the confirm CTA. Defaults to `primary`. */
  confirmIntent?: 'primary' | 'secondary' | 'tertiary';
  /** Body contents of the modal. Can also be used in place of cancelText/confirmText to customize CTA. */
  children?: React.ReactNode;
  /** Whether to switch the confirm/cancel button positions. */
  flipButtons?: boolean;
  /** Primary header text. */
  header?: string;
  /** Unique identifier for the modal. */
  id: string;
  /** Callback to invoke when cancel CTA is clicked. */
  onCancel(): void;
  /** Callback to invoke when backdrop or close button is clicked. Will default to onCancel if unspecified. */
  onClose?(): void;
  /** Callback to invoke when confirm CTA is clicked. */
  onConfirm(): void;
  /** Whether to display the modal. */
  open: boolean;
  /** Whether to disable the modal's buttons. */
  disabled?: boolean;
  /** Whether an associated resource is being loaded from/updated in the server. */
  loading?: boolean;
  /** Additional css class for styling */
  className?: string;
}

export default function TextModal({
  cancelText,
  cancelIntent = 'tertiary',
  confirmText,
  confirmIntent = 'primary',
  children,
  flipButtons = false,
  header,
  id,
  onCancel,
  onClose,
  onConfirm,
  open,
  disabled = false,
  loading = false,
  className = '',
}: Props): JSX.Element {
  return (
    <Modal
      className={cx(styles.textModal, className)}
      id={id}
      data-cy={id}
      onBackdropClick={onClose || onCancel}
      open={open}
    >
      {header && (
        <h4 className={styles.textModalHeader}>
          {header}
        </h4>
      )}
      {children}
      {cancelText && confirmText && (
        <div className={cx(styles.textModalBtns, {[styles.flip]: flipButtons})}>
          <Button
            className={styles.textModalCancelBtn}
            intent={cancelIntent}
            onClick={onCancel}
            disabled={disabled || loading}
            data-cy="text-modal-cancel-button"
          >
            {cancelText}
          </Button>
          <Button
            className={styles.textModalConfirmBtn}
            intent={confirmIntent}
            onClick={onConfirm}
            disabled={disabled || loading}
            loading={loading}
            data-cy="text-modal-confirm-button"
          >
            {confirmText}
          </Button>
        </div>
      )}
    </Modal>
  )
}
