import {
  format,
  getDate,
  isEqual,
  sub,
} from 'date-fns'

import {ErrorCapture} from 'core/utils'

export enum TimeRange {
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  DAYS_7 = 'DAYS_7',
  DAYS_30 = 'DAYS_30',
  DAYS_90 = 'DAYS_90',
  YEAR_1 = 'YEAR_1',
  CURRENT_YEAR = 'CURRENT_YEAR',
  LAST_YEAR = 'LAST_YEAR',
}

export function getStartDateEndDate(
  timeRangeSelected: string,
  timeNow = new Date(),
): string[] {
  const rangeLimit = sub(timeNow, {days: 1})
  const rangeLimitFormatted = format(rangeLimit, 'yyyy-MM-dd')
  const currentYear = timeNow.getFullYear()
  switch (timeRangeSelected) {
    case TimeRange.CURRENT_MONTH: {
      const month = format(timeNow, 'MM')
      const formatted = `${currentYear}-${month}-01`
      const limit = getDate(timeNow) === 1 ? formatted : rangeLimitFormatted
      return [`${currentYear}-${month}-01`, limit]
    }
    case TimeRange.LAST_MONTH: {
      const lastMonthTime = sub(timeNow, {months: 1})
      const lastMonth = format(lastMonthTime, 'MM')
      const lastMonthYear = lastMonthTime.getFullYear()
      const endOfLastMonth = (
        format(
          sub(
            new Date(lastMonthYear, Number(lastMonth), 1),
            {days: 1},
          ),
          'yyyy-MM-dd',
        )
      )

      return [`${lastMonthYear}-${lastMonth}-01`, endOfLastMonth]
    }
    case TimeRange.DAYS_7: {
      const pastTime = sub(timeNow, {days: 7})
      const formatted = format(pastTime, 'yyyy-MM-dd')
      return [formatted, rangeLimitFormatted]
    }
    case TimeRange.DAYS_30: {
      const pastTime = sub(timeNow, {days: 30})
      const formatted = format(pastTime, 'yyyy-MM-dd')
      return [formatted, rangeLimitFormatted]
    }
    case TimeRange.DAYS_90: {
      const pastTime = sub(timeNow, {days: 90})
      const formatted = format(pastTime, 'yyyy-MM-dd')
      return [formatted, rangeLimitFormatted]
    }
    case TimeRange.YEAR_1: {
      const pastTime = sub(timeNow, {years: 1})
      const formatted = format(pastTime, 'yyyy-MM-dd')
      return [formatted, rangeLimitFormatted]
    }
    case TimeRange.CURRENT_YEAR: {
      const formatted = `${currentYear}-01-01`
      const limit = isEqual(new Date(currentYear, 0, 1), timeNow) ? formatted : rangeLimitFormatted
      return [formatted, limit]
    }
    case TimeRange.LAST_YEAR: {
      const lastYear = sub(timeNow, {years: 1}).getFullYear()
      const lastYearM = new Date(lastYear, 0, 1)
      const lastDayForLastYear = (
        format(
          sub(
            new Date(currentYear, 0, 1),
            {days: 1},
          ),
          'yyyy-MM-dd',
        )
      )
      return [format(lastYearM, 'yyyy-MM-dd'), lastDayForLastYear]
    }
    default: {
      ErrorCapture('Unknown selection for PBS date range', `${timeRangeSelected} is an unknown value`)
      // default to the 90 day option
      const pastTime = sub(timeNow, {days: 90})
      const formatted = format(pastTime, 'yyyy-MM-dd')
      return [formatted, rangeLimitFormatted]
    }
  }
}
