import React from 'react'
import styled, {useTheme} from 'styled-components'
import {useParams} from 'react-router-dom'

import {LightBulbIcon} from 'assets/icons'
import {H3, H4} from 'core/theme'
import {
  ScenarioType, selectScenarioHeaderData, useGetLiquidityEventQuery,
} from 'store/scenario'
import {Insight} from 'core/components'

type Props = {
  scenarioType: ScenarioType;
}

export const ScenarioHeader = ({scenarioType}: Props): JSX.Element => {
  const theme = useTheme()
  const {companyId} = useParams<{companyId: string}>()
  const {data} = useGetLiquidityEventQuery(companyId, {
    selectFromResult: (result) => ({
      ...result,
      data: selectScenarioHeaderData(result.data?.scenarios, scenarioType),
    }),
  })

  return (
    <StyledContainer>
      <H3>{data?.title}</H3>
      <H4>{data?.subtitle}</H4>
      <Insight
        title="The bottom line"
        description={data?.description}
        backgroundColor={theme.colors.lightBlue075}
        customIcon={<LightBulbIcon />}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div(({theme}) => `
    margin: 0 ${theme.spacing.l};
    ${theme.above(theme.breakpoints.largePhone)}{
         margin: 0 ${theme.spacing['5xl']};
     }

     > h3 {
        margin-top: ${theme.spacing.l};
     }

     > h4 {
        margin-top: ${theme.spacing.s};
     }

     > div {
        margin-top: ${theme.spacing.m};
        margin-bottom: ${theme.spacing.l};
     }
`)
