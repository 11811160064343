export enum GrantType {
  RSU = 'RSU',
  RSA = 'RSA',
  EQUITY = 'EQUITY',
}

export enum OptionType {
  ISO = 'INCENTIVE_STOCK',
  NON_QUALIFIED = 'NON_QUALIFIED',
  NA = '', // todo: confirm why this is needed
}

export enum ShareClass {
  COMMON = 'COMMON',
  PREFERRED = 'PREFERRED',
}

export enum VestingCliff {
  NONE = '',
  ONE_YEAR = 'YEARS_1',
}

export enum VestingCadence {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}

export type Grant = {
  id: string;
  company: string;
  grantType: GrantType;
  grantDate: string;
  isOption: boolean;
  optionType?: OptionType;
  perShareCostBasis?: number;
  quantity: number;
  shareClass: ShareClass;
  vestingCliff?: VestingCliff;
  vestingStartDate: string;
  vestingCadence: VestingCadence;
  vestingPeriod: number;
  isEarlyExercise?: boolean;
  expirationDate?: Nullable<string>;
  isDoubleTrigger?: boolean;
};

export type GrantLifecycleData = {
  vestedAwardsValue: number;
  vestedAwardsQuantity: number;
  unvestedAwardsValue: number;
  unvestedAwardsQuantity: number;
  '409Valuation': number;
  totalEquityQuantity: number;
  totalEquityValue: number;
  totalExercisableQuantity: number;
  totalExercisableValue: number;
  totalVestedExercisableQuantity: number;
  totalVestedExercisableValue: number;
  totalExercisedQuantity: number;
  totalExercisedValue: number;
  totalValueObtained: number;
  canceledOptions: number;
  canceledShares: number;
  date: string;
}
