import {Dispatch, SetStateAction} from 'react'

import {
  GrantLifecycleData,
  ScenarioResultType, TaxProfile, TaxProfileResidency,
  TaxScenario,
  TaxTypeTotals,
} from 'models'
import {CombinedGrantLifecycleData} from 'routes/add-equity/types'

export type ScenarioData = {
  grossProceeds: number;
  exerciseCost: number;
  scenario: TaxScenario;
  scenarioType: ScenarioResultType;
  taxTotalsFromExercise: Nullable<TaxTypeTotals>;
  taxTotalsFromSale: TaxTypeTotals;
}

export enum SwipeSideText {
  EFFICIENT_TEXT = 'Swipe to view more scenarios',
  LOWER_RISK_TEXT = 'Swipe to view more scenarios',
}

export type GrantWithCurrentLifecycle = CombinedGrantLifecycleData & {
  currentLifecycle: Nullable<GrantLifecycleData>;
}

export enum ErrorCode {
  UNSUPPORTED_STATES = 'UNSUPPORTED_STATES',
}

export type UseTaxProfileReturnType = {
  taxProfile: TaxProfile;
  taxProfileResidency: Nullable<TaxProfileResidency>;
  setTaxProfile: Dispatch<SetStateAction<TaxProfile>>;
  setTaxProfileResidency: Dispatch<SetStateAction<Nullable<TaxProfileResidency>>>;
}

export type UseTaxProfileV2ReturnType = {
  taxProfile: Nullable<TaxProfile>;
  taxProfileResidency: Nullable<TaxProfileResidency>;
  setTaxProfile: Dispatch<SetStateAction<Nullable<TaxProfile>>>;
  setTaxProfileResidency: Dispatch<SetStateAction<Nullable<TaxProfileResidency>>>;
}
