import {CheckboxGroupState, CheckboxOptionData} from 'core/components/input/checkbox/CheckboxGroup'
import {SpotIconName} from 'core/components/icon/Icon'

import {ServiceTypeKey} from 'models/service'
import {FirmType} from 'models/firm'
import {ProfileFormKey} from 'models/clientProfile'
import {ProfileServiceNeedsFormKey, RangeValue} from 'models/adviserProposal'

type SliderTick = {
  value: number;
  suffix?: string;
}

export type SelectState = {
  [key: string]: CheckboxOptionData;
}

export type ServicesState = {
  [ServiceTypeKey.additionalServices]: string[];
  [ServiceTypeKey.recommendedServices]: string[];
  [ServiceTypeKey.selectedServices]: SelectState;
  [ServiceTypeKey.otherServiceNeeds]: string;
}

export type SliderInputOptions = {
  formatter?: (value: number, maxValue: number) => string;
  scaleFormatter: (value: number, maxValue: number) => number;
  invertScaleFormatter: (value: number, maxValue: number) => number;
  range: [number, number]; // [lower, upper]
  step: number;
  setStep?: (value: number) => Nullable<number>;
  tickValues: SliderTick[];
}

export type Answer = {
  key?: string;
  label: string;
  value: any;
  subLabel?: string;
}

export type ServiceOption = Answer & {
  description: string;
  metadata: string;
  insightText: string;
  banner?: string;
  badge?: string;
  type: string;
}

export type ServiceOptionGroup = {
  answers: ServiceOption[];
  answerType: AnswerType;
  key: string;
  mixpanelId?: string;
  placeholder?: string;
  subText?: string;
  text?: string;
}

export type ProfileQuestion = {
  answers: Answer[];
  answerType: AnswerType;
  key: ProfileFormKey | ProfileServiceNeedsFormKey;
  mixpanelId: string;
  placeholder?: string;
  sliderOptions?: SliderInputOptions;
  subText?: string;
  text: string;
}

export type ProfilePreferencesLocalData = {
  [ProfileFormKey.keyLifeEvents]: CheckboxGroupState;
  [ProfileFormKey.majorFinancialChanges]: CheckboxGroupState;
  [ProfileFormKey.otherMilestones]: string;
  [ProfileFormKey.otherWindfalls]: string;
  [ProfileFormKey.expectsAdditionalInvestableAssets]: boolean;
  [ProfileFormKey.windfallTiming]: CheckboxGroupState;
  [ProfileFormKey.windfallAmount]: Nullable<RangeValue>;

  [ProfileFormKey.employmentType]: CheckboxGroupState;
  [ProfileFormKey.otherEmployment]: string;
  [ProfileFormKey.smallBusinessFunding]: CheckboxGroupState;
  [ProfileFormKey.smallBusinessRevenue]: Nullable<RangeValue>;
  [ProfileFormKey.smallBusinessType]: string;
  [ProfileFormKey.householdIndustries]: Answer[];
  [ProfileFormKey.otherHouseholdIndustries]: string;
  [ProfileFormKey.subindustries]: CheckboxGroupState;
  [ProfileFormKey.householdIncome]: number;
  [ProfileFormKey.totalInvestableAssets]: number;
  [ProfileFormKey.otherAssets]: number;
  [ProfileFormKey.illiquidAssets]: CheckboxGroupState;
  [ProfileFormKey.otherIlliquidAssets]: string;
  [ProfileFormKey.statesForTaxFiling]?: Optional<RangeValue>;
  [ProfileFormKey.k1sForTaxFiling]?: Optional<RangeValue>;
  [ProfileFormKey.personalFinancesManagement]?: Optional<CheckboxGroupState>;
  [ProfileFormKey.otherPersonalFinancesManagement]?: Optional<string>;

  [ProfileFormKey.adviserAgePreferences]?: CheckboxGroupState;
  [ProfileFormKey.hasAdviserLocationPreference]: Nullable<boolean>;
  [ProfileFormKey.zipcodePreferences]: string;
  [ProfileFormKey.country]: string;
  [ProfileFormKey.isUSCitizen]: boolean;
}

export enum AnswerType {
  checkbox = 'checkbox',
  radio = 'radio',
  text = 'text',
  textarea = 'textarea',
  dropdownSearch = 'dropdownSearch',
  slider = 'slider',
  zipcode = 'zipcode',
}

export type ServiceNeedsQuestion = {
  answers: Answer[];
  answerType: AnswerType;
  key: ProfileServiceNeedsFormKey;
  mixpanelId: string;
  placeholder?: string;
  subText?: string;
  text: string;
}

export type ReactSelectValue = {
  label: string;
  value: string;
}

export type ProfileSummaryContent = {
  iconName?: SpotIconName; // TODO(steph): deprecate
  imagePath?: string;
  text: string;
}

export type ProfileSummary = {
  content: ProfileSummaryContent[];
  overflow: ProfileSummaryContent[] | [];
}

export type ProfileServiceNeedsLocalFormData = {
  [ProfileServiceNeedsFormKey.advisersSeeking]?: FirmType[];
  [ProfileServiceNeedsFormKey.financialServiceInterests]: CheckboxGroupState;
  [ProfileServiceNeedsFormKey.taxServiceInterests]: CheckboxGroupState;
  [ProfileServiceNeedsFormKey.legalServiceInterests]: CheckboxGroupState;
  [ProfileServiceNeedsFormKey.otherServiceInterests]: string;

  // Legal Services Interest Follow Up Keys
  [ProfileServiceNeedsFormKey.statesForEstatePlanning]?: ReactSelectValue[];
}

export enum ServiceComboType {
  TAX_AND_FINANCE = 'Tax and Finance',
  TAX_FINANCE_AND_LEGAL = 'Tax, Finance, and Legal',
  FINANCE_AND_LEGAL = 'Finance and Legal',
  TAX_ONLY = 'Tax Only',
  FINANCE_ONLY = 'Finance Only',
  LEGAL_ONLY = 'Legal Only',
}

export enum ComboLabel {
  DESCRIPTION = 'description',
  EXPAND_CTA = 'expandCta',
  EXPANDED_TEXT = 'expandedText',
  FOLLOWUP_DESCRIPTION = 'followupDescription',
  FOLLOWUP_EXPAND_CTA = 'followupExpandCta',
  FOLLOWUP_EXPANDED_TEXT = 'followupExpandedText',
}

export type ServiceCombo = {
  firstGroup: ServiceOptionGroup;
  secondGroup?: ServiceOptionGroup;
  labels: {
    [key in ComboLabel]: string
  };
}

export type ChildRefType = {
  submitForm: () => void;
}
