import React, {Suspense, lazy} from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom'
import {withProfiler, withSentryRouting} from '@sentry/react'
import {ThemeProvider} from '@mui/material/styles'

import AppLayout from './AppLayout'
import {EquityInsightsResults} from './pages/equity-insights-results-v2'

import {AppProps} from 'core/utils/types'
import usePageViewTracking from 'core/hooks/usePageViewTracking'
import {RoutePaths} from 'core/constants'
import {
  AuthenticatedRoutes, EmailVerifyGuard, OnboardingGuard, UnauthenticatedRoutes,
} from 'modules/auth'
import {VerifyEmail} from 'modules/verify-email'
import theme from 'core/muiTheme'
import {LoadingSpinner} from 'core/components'

const SentryRoute = withSentryRouting(Route)

interface CompanyIdRouterProp {
  companyId?: string;
}

/* eslint-disable max-len */
const GlobalOnboarding = lazy(() => import(/* webpackChunkName: "GlobalOnboarding" */ 'pages/global-onboarding/GlobalOnboarding'))
const CompanyOnboarding = lazy(() => import(/* webpackChunkName: "CompanyOnboarding" */ 'pages/company-onboarding/CompanyOnboarding'))
const AssetsDebts = lazy(() => import(/* webpackChunkName: "AssetsDebts" */ 'pages/assets-debts/AssetsDebts'))
const PersonalizedDashboard = lazy(() => import(/* webpackChunkName: "PersonalizedDashboard" */ 'pages/dashboard/PersonalizedDashboard'))
const ClientProfile = lazy(() => import(/* webpackChunkName: "ClientProfile" */ 'pages/client-profile/ClientProfile'))
const ProfileOnboarding = lazy(() => import(/* webpackChunkName: "ProfileOnboarding" */ 'pages/profile-onboarding/ProfileOnboarding'))
const ProfileStart = lazy(() => import(/* webpackChunkName: "ProfileStart" */ 'pages/profile-start/ProfileStart'))
const EquityInsightsPlanning = lazy(() => import(/* webpackChunkName: "EquityInsightsPlanning" */ 'pages/equity-insights-planning/EquityInsightsPlanning'))
const Company = lazy(() => import(/* webpackChunkName: "Company" */ 'pages/company/Company'))
const Register = lazy(() => import(/* webpackChunkName: "Register" */ 'pages/register/Register'))
const AcceptInvite = lazy(() => import(/* webpackChunkName: "Register" */ 'pages/accept-invite/AcceptInvite'))
const WelcomeInvitedClient = lazy(() => import(/* webpackChunkName: "Register" */ 'pages/accept-invite/WelcomeInvitedClient'))
const LandingProxy = lazy(() => import(/* webpackChunkName: "LandingProxy" */ 'routes/landing/LandingProxy'))
const AddPriorExercise = lazy(() => import(/* webpackChunkName: "AddPriorExercise" */ 'routes/add-equity/AddPriorExercise'))
const AddPriorSale = lazy(() => import(/* webpackChunkName: "AddPriorSale" */ 'routes/add-equity/AddPriorSale'))
const AddPriorSaleDirectInvestment = lazy(() => import(/* webpackChunkName: "AddPriorSaleDirectInvestment" */ 'routes/add-equity/AddPriorSaleDirectInvestment'))
const AdviserPairing = lazy(() => import(/* webpackChunkName: "AdviserPairing" */ 'routes/match/AdviserPairingView'))
const AdvisorSessionsDashboard = lazy(() => import(/* webpackChunkName: "AdvisorSessionsDashboard" */ 'pages/advisor-sessions/Dashboard'))
const TaxPreparation = lazy(() => import(/* webpackChunkName: "TaxPreparation" */ 'modules/advisor-services/pages/TaxPreparation'))
const AdvisorSessionsLandingPage = lazy(() => import(/* webpackChunkName: "AdvisorSessionsLandingPage" */ 'modules/advisor-services/pages/EquityTaxPlanning'))
const EngagementDashboard = lazy(() => import(/* webpackChunkName: "EngagementDashboard" */ 'pages/advisor-sessions/EngagementDashboard'))
const AdvisorSessionsSignAgreement = lazy(() => import(/* webpackChunkName: "AdvisorSessionsSignAgreement" */ 'pages/advisor-sessions/SignAgreement'))
const AdvisorSessionsDocuments = lazy(() => import(/* webpackChunkName: "AdvisorSessionsDocuments" */ 'modules/documents/pages/MyDocuments'))
const Questionnaire = lazy(() => import(/* webpackChunkName: "Questionnaire" */ 'modules/questionnaire/pages/Questionnaire'))
const FilesUpload = lazy(() => import(/* webpackChunkName: "FilesUpload" */ 'modules/file-upload/pages/FilesUpload'))
const Agreement = lazy(() => import(/* webpackChunkName: "Agreement" */ 'pages/advisor-sessions/Agreement'))
const CannyFeedbackView = lazy(() => import(/* webpackChunkName:"CannyFeedbackView" */ 'routes/feedback/CannyFeedbackView'))
const DirectInvestmentForm = lazy(() => import(/* webpackChunkName: "DirectInvestmentForm" */ 'routes/add-equity/DirectInvestmentForm'))
const FirmProfile = lazy(() => import(/* webpackChunkName: "FirmProfile" */ 'routes/match/firm/FirmProfile'))
const LoginView = lazy(() => import(/* webpackChunkName: "Login" */ 'routes/access/LoginView'))
const MarketplaceDashboard = lazy(() => import(/* webpackChunkName: "MarketplaceDashboard" */ 'pages/marketplace-dashboard/MarketplaceDashboard'))
const PasswordResetView = lazy(() => import(/* webpackChunkName: "PasswordReset" */ 'routes/access/password_reset/PasswordResetView'))
const BalanceSheet = lazy(() => import(/* webpackChunkName: "PersonalBalance" */ 'pages/balance-sheet/BalanceSheetProxy'))
const RegisterViewProxyPage = lazy(() => import(/* webpackChunkName: "RegisterProxy" */ 'routes/access/RegisterViewProxyPage'))
const ReviewNextSteps = lazy(() => import(/* webpackChunkName: "ReviewNexSteps" */ 'routes/match/ReviewNextSteps'))
const RsuRsaForm = lazy(() => import(/* webpackChunkName: "RsuRsaForm" */ 'routes/add-equity/RsuRsaForm'))
const StockOptions = lazy(() => import(/* webpackChunkName: "StockOptions" */ 'routes/add-equity/StockOptions'))
const PropertyDetail = lazy(() => import(/* webpackChunkName: "PropertyDetail" */ 'routes/real-estate/PropertyDetail'))
const PropertyForm = lazy(() => import(/* webpackChunkName: "PropertyForm" */ 'routes/real-estate/PropertyForm'))
const ValuationsForm = lazy(() => import(/* webpackChunkName: "ValuationsForm" */ 'routes/real-estate/ValuationsForm'))
const PropertySaleForm = lazy(() => import(/* webpackChunkName: "PropertySaleForm" */ 'routes/real-estate/PropertySaleForm'))
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ 'modules/settings/Settings'))
const NotFoundView = lazy(() => import(/* webpackChunkName: "NotFound" */ 'routes/error/404'))
const WelcomeBack = lazy(() => import(/* webpackChunkName: "WelcomeBack" */ 'pages/register/WelcomeBack'))
const EmailVerification = lazy(() => import(/* webpackChunkName: "EmailVerification" */ 'pages/email-verification/EmailVerification'))
const ToastDemo = lazy(() => import(/* webpackChunkName: "ToastDemo" */ 'modules/toast/pages/DemoPage'))
const TwoFactorVerification = lazy(() => import(/* webpackChunkName: "TwoFactorVerification" */ 'modules/auth/components/TwoFactorVerification'))
/* eslint-enable max-len */

/*
* PLEASE DO NOT ADD FEATURE FLAGS HERE THAT ARE FOR AUTH EXPERIENCE
* THIS WILL NOT TURN ON THE FLAG FOR AUTH USERS BECAUSE IT WILL BE CALLED
* WHEN THEY ARE UNAUTHENTICATED
* */

/**
 * NOTE: App needs to ALWAYS be a stateless component. Introducing state will cause double mounting/rendering issues
 * for all child <Route> components in here which we want to avoid.
 */

/**
 * NOTE: usePageViewTracking triggers the entire tree to update
 * As a side effect, the updated session prop is passed down and app navigation components render appropriately
 * If usePageViewTracking is ever deprecated, another method must be put in place to ensure the app session is
 * updated in the navigation components
 */
function AppContainer({session, storage}: AppProps): JSX.Element {
  usePageViewTracking()

  return (
    <ThemeProvider theme={theme}>
      <AppLayout session={session}>
        {/* eslint-disable max-len */}
        <Suspense fallback={<LoadingSpinner />}>
          <UnauthenticatedRoutes session={session}>
            <Switch>
              <SentryRoute exact path={RoutePaths.ACCEPT_INVITE}><AcceptInvite session={session} /></SentryRoute>
              <SentryRoute exact path={RoutePaths.REGISTER}><Register session={session} /></SentryRoute>
              <SentryRoute exact path={RoutePaths.WELCOME_BACK}><WelcomeBack session={session} /></SentryRoute>
              <SentryRoute exact path={RoutePaths.PASSWORD_RESET}><PasswordResetView /></SentryRoute>
              <SentryRoute exact path={RoutePaths.LOGIN_VERIFY}><TwoFactorVerification session={session} /></SentryRoute>
              <SentryRoute path={RoutePaths.LOGIN}><LoginView session={session} /></SentryRoute>
              <SentryRoute exact path={RoutePaths.EMAIL_VERIFY}><EmailVerification storage={storage} /></SentryRoute>
              <SentryRoute component={NotFoundView} />
            </Switch>
          </UnauthenticatedRoutes>
          <AuthenticatedRoutes session={session} storage={storage}>
            <Switch>
              <SentryRoute exact path="/"><LandingProxy /></SentryRoute>
              <SentryRoute exact path={RoutePaths.WELCOME_INVITED_CLIENT} component={WelcomeInvitedClient} />
              <SentryRoute path={RoutePaths.ONBOARDING_ROOT}>
                <GlobalOnboarding session={session} />
              </SentryRoute>
              <SentryRoute path={RoutePaths.FEEDBACK}><CannyFeedbackView /></SentryRoute>
              <SentryRoute path={RoutePaths.SETTINGS}><Settings /></SentryRoute>
              <SentryRoute exact path={RoutePaths.EMAIL_VERIFY}><EmailVerification storage={storage} /></SentryRoute>
              <SentryRoute exact path={RoutePaths.TOAST_DEMO}><ToastDemo /></SentryRoute>
              <OnboardingGuard>
                <Switch>
                  <SentryRoute exact path={RoutePaths.ADVISER_SEARCH}><PersonalizedDashboard session={session} storage={storage} /></SentryRoute>
                  <SentryRoute path={RoutePaths.ADD_NEW_COMPANY} component={CompanyOnboarding} />
                  <SentryRoute exact path={RoutePaths.ADD_EQUITY_STOCK_OPTIONS}><StockOptions session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.ASSETS_AND_DEBTS} component={AssetsDebts} />
                  <SentryRoute exact path={RoutePaths.EQUITY_TAX_PLANNING_LANDING_PAGE}>
                    <AdvisorSessionsLandingPage session={session} />
                  </SentryRoute>
                  <SentryRoute exact path={RoutePaths.TAX_PREP_PAGE}>
                    <TaxPreparation session={session} />
                  </SentryRoute>
                  <SentryRoute exact path={[RoutePaths.ADVISOR_SESSIONS_DASHBOARD, RoutePaths.ADVISOR_SESSIONS]} component={AdvisorSessionsDashboard} />
                  <SentryRoute exact path={RoutePaths.VERIFY_EMAIL} component={VerifyEmail} />
                  <SentryRoute
                    path={[
                      RoutePaths.ADVISOR_SESSIONS_SIGN_AGREEMENT,
                      RoutePaths.ADVISOR_SESSIONS_SIGN_AGREEMENT_V2,
                      RoutePaths.ADVISOR_SESSIONS_SIGN_AGREEMENT_V3,
                      RoutePaths.ADVISOR_SESSIONS_DOCUMENTS,
                      RoutePaths.ADVISOR_SESSIONS_QUESTIONNAIRE,
                      RoutePaths.ADVISOR_SESSIONS_FILES_UPLOAD,
                      RoutePaths.ENGAGEMENT_DASHBOARD,
                    ]}
                  >
                    <EmailVerifyGuard>
                      <Switch>
                        <SentryRoute exact path={RoutePaths.ADVISOR_SESSIONS_SIGN_AGREEMENT} component={AdvisorSessionsSignAgreement} />
                        <SentryRoute exact path={RoutePaths.ADVISOR_SESSIONS_SIGN_AGREEMENT_V2} component={AdvisorSessionsSignAgreement} />
                        <SentryRoute exact path={RoutePaths.ADVISOR_SESSIONS_SIGN_AGREEMENT_V3} component={AdvisorSessionsSignAgreement} />
                        <SentryRoute exact path={`${RoutePaths.ADVISOR_SESSIONS_DOCUMENTS}/:tab?`} component={AdvisorSessionsDocuments} />
                        <SentryRoute path={RoutePaths.ADVISOR_SESSIONS_QUESTIONNAIRE} component={Questionnaire} />
                        <SentryRoute path={RoutePaths.ADVISOR_SESSIONS_FILES_UPLOAD} component={FilesUpload} />
                        <SentryRoute exact path={RoutePaths.ENGAGEMENT_DASHBOARD}>
                          <EngagementDashboard />
                        </SentryRoute>
                      </Switch>
                    </EmailVerifyGuard>
                  </SentryRoute>
                  <SentryRoute exact path={RoutePaths.AGREEMENT_SIGNING}>
                    <Agreement />
                  </SentryRoute>
                  <SentryRoute exact path={RoutePaths.MARKETPLACE_DASHBOARD} component={MarketplaceDashboard} />
                  <SentryRoute exact path={RoutePaths.MATCH_CONFIRMATION}><ReviewNextSteps session={session} stepThree /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.MATCH_PROFILE_COMPLETE}><ReviewNextSteps session={session} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.MATCH_REVIEW}><AdviserPairing session={session} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.NEW_MATCH_REVIEW}><AdviserPairing session={session} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.PERSONAL_BALANCE}><BalanceSheet session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.PROFILE_START}><ProfileStart session={session} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.PROFILE_SUMMARY} component={ClientProfile} />
                  <SentryRoute exact path={RoutePaths.REGISTER_PROXY}><RegisterViewProxyPage /></SentryRoute>
                  <SentryRoute path={RoutePaths.PROFILE_FORM_ROOT}><ProfileOnboarding session={session} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.FIRM_PROFILE}><FirmProfile /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.FIRM_PROFILE_PAIRING}><FirmProfile firmFitRequired /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.ADD_EQUITY_RSU_RSA}><RsuRsaForm session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.ADD_EQUITY_RSU_RSA_PRIOR_SALE}><AddPriorSale session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.ADD_EQUITY_DIRECT_INVESTMENTS}><DirectInvestmentForm session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.ADD_EQUITY_PRIOR_EXERCISE}><AddPriorExercise session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.ADD_EQUITY_PRIOR_SALE}><AddPriorSale session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.ADD_EQUITY_DIRECT_INVESTMENTS_PRIOR_SALE}><AddPriorSaleDirectInvestment session={session} storage={storage} /></SentryRoute>
                  {/* Pass companyId as a key to the Company page to allow it to re-render when navigating between companies without leaving the company page */}
                  <SentryRoute
                    exact
                    path={RoutePaths.ADD_EQUITY_COMPANY_ENTITY}
                    render={({match: {params}}: RouteComponentProps<CompanyIdRouterProp>): React.ReactNode => <Company session={session} storage={storage} key={params.companyId} />}
                  />
                  <SentryRoute exact path={RoutePaths.REAL_ESTATE_PROPERTY_DETAIL}><PropertyDetail session={session} storage={storage} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.REAL_ESTATE_PROPERTY_FORM}><PropertyForm session={session} /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.REAL_ESTATE_VALUATIONS_FORM}><ValuationsForm /></SentryRoute>
                  <SentryRoute exact path={RoutePaths.REAL_ESTATE_SALE_FORM}><PropertySaleForm /></SentryRoute>
                  <SentryRoute path={RoutePaths.LIQUIDITY_EVENT_ROOT}><EquityInsightsPlanning session={session} storage={storage} /></SentryRoute>
                  <SentryRoute path={RoutePaths.EQUITY_INSIGHTS_RESULTS}>
                    <EquityInsightsResults />
                  </SentryRoute>
                  {/* This is to ensure that links in emails redirect users to new route */}
                  <Redirect from="/adviser-search" to={RoutePaths.ADVISER_SEARCH} />
                  <SentryRoute>
                    <NotFoundView />
                  </SentryRoute>
                </Switch>
              </OnboardingGuard>
            </Switch>
          </AuthenticatedRoutes>
        </Suspense>
        {/* eslint-enable max-len */}
      </AppLayout>
    </ThemeProvider>
  )
}

export default withProfiler(AppContainer)
