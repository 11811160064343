import React, {useCallback, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import {H3, RegularText} from 'core/theme'
import {useGetUserQuery, useLazyGetUserQuery} from 'store/user/userSlice'
import {Button} from 'core/components'
import {RoutePaths} from 'core/constants'
import {sendVerifyEmail} from 'api/user'
import {CheckYourEmailImage} from 'assets/images'
import {showDefaultErrorAlert, showErrorAlert, showSuccessAlert} from 'store/alert/actions'

const VerifyEmail = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {data: user} = useGetUserQuery()
  const [trigger, {
    data: userResponse, isSuccess, isFetching,
  }] = useLazyGetUserQuery(undefined)

  const {search} = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const handleSendVerifyEmail = useCallback(async (showSuccessBanner: boolean) => {
    // TODO: move to RTK
    const route = searchParams.get('path') ?? RoutePaths.EQUITY_TAX_PLANNING_LANDING_PAGE
    try {
      await sendVerifyEmail(`${route}?v=1`)
      if (showSuccessBanner) {
        dispatch(showSuccessAlert(`A verification link has been sent to ${user?.email}. Check your inbox to verify your email.`))
      }
    } catch (e) {
      dispatch(showDefaultErrorAlert())
    }
  }, [dispatch, searchParams, user])

  useEffect(() => {
    if (user && !user.isEmailVerified) {
      void handleSendVerifyEmail(false)
    }
  }, [handleSendVerifyEmail, user])

  useEffect(() => {
    if (isSuccess && !isFetching && userResponse) {
      if (!userResponse?.isEmailVerified) {
        dispatch(showErrorAlert(`Your email was not verified. Please click the link in the email we sent to ${user?.email}.`))
      } else {
        const route = searchParams.get('path') ?? RoutePaths.EQUITY_TAX_PLANNING_LANDING_PAGE
        history.push(`${route}?v=1`)
      }
    }
  }, [userResponse, isSuccess, isFetching, history, searchParams, user, dispatch])

  return (
    <MainContainer>
      <Container>
        <CheckYourEmailImage />
        <H3>Check your email</H3>
        <MainText>
          Please check your inbox and click the link in the email we sent to verify your email address:
          {' '}
          <br />
          <UserEmail>{user?.email}</UserEmail>
          {' '}
          <br />
          After you have verified, click “continue” below.
        </MainText>
        <ContinueButton
          fill
          intent="primary"
          label="continue"
          onClick={(): void => void trigger()}
        />
        <ResendText>
          Didn’t get the email?
          {' '}
          <Button
            label="Resend verification email"
            underlined
            onClick={(): Promise<void> => handleSendVerifyEmail(true)}
          />
        </ResendText>
      </Container>
    </MainContainer>
  )
}

export default VerifyEmail

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  justify-content: flex-start;
  flex-grow: 0!important;
`

const ResendText = styled(RegularText)(({theme}) => `
  color: ${theme.colors.darkBlue300};
`)

const MainText = styled(RegularText)(({theme: {colors, spacing}}) => `
  color: ${colors.darkBlue300};
  margin: ${spacing.m} 0px ${spacing.m} 0px;
  width: 404px;
`)

const UserEmail = styled.span(({theme}) => `
  display: block;
  color: ${theme.colors.darkBlue500};
  font-weight: bold;
  margin-bottom: ${theme.spacing.xl};
`)

const ContinueButton = styled(Button)`
  max-width: 286px;
  margin-bottom: 48px;
`
const Container = styled.div`
  margin: 0 auto;
  max-width: 404px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
`
