import React from 'react'
import cx from 'classnames'

import styles from './_link.module.scss'

type Props = {
  /** Whether to bold the link text. */
  bold?: boolean;
  className?: string;
  children: React.ReactChild | React.ReactChild[];
  // TODO(steph): deprecate inline and underlined intent
  intent?: 'inline' | 'underlined' | 'nav' | 'primary' | 'secondary' | 'tertiary';
  onClick?(e: React.MouseEvent): any;
  sameTab?: boolean;
  /** Whether to shrink the size of the button. */
  small?: boolean;
  to: string;
  /** Whether to underline the link text. */
  underlined?: boolean;
}

export default function ExternalLink(
  {
    bold,
    className,
    children,
    intent,
    to = '',
    sameTab = false,
    small,
    underlined,
    ...rest
  }: Props,
): JSX.Element {
  // NOTE: Using target="_blank" without rel="noopener noreferrer" is a security risk
  // See https://mathiasbynens.github.io/rel-noopener for more details
  const configProps = sameTab ? {target: '_self'} : {target: '_blank', rel: 'noopener noreferrer'}

  return (
    <a
      data-theme="default"
      className={cx(styles.link, intent && styles[intent], {
        [styles.bold]: bold,
        [styles.small]: small,
        [styles.underlined]: underlined,
      },
      className)}
      href={to}
      {...configProps} // eslint-disable-line react/jsx-props-no-spreading
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </a>
  )
}
