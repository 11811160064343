export interface FeatureFlagResponse {
  created: string;
  modified: string;
  name: FeatureFlagName;
  project: string;
  toggleActive: boolean;
}

export enum FeatureFlagName {
  CONCIERGE_CLIENT_PROFILE = 'concierge-client-profile',
  REGISTRATION_REFERRAL_CODE = 'registration-referral-code',
  RSU_SUPPORT = 'rsu_support',
  PBS_REDESIGN = 'pbs_redesign',
  REFERRAL_CODE = 'referral_code',
  TWO_FACTOR = '2fa',
  FILE_AUTO_CATEGORIZATION = 'file-auto-categorization',
  SUGGESTED_UPLOADS = 'suggested-uploads',
  PREFILL_FOR_SELF_EMPLOYMENT = 'prefill-for-self-employment',
}

export interface FeatureFlags {
  conciergeClientProfile: boolean;
  registrationReferralCode: boolean;
  rsuSupport: boolean;
  pbsRedesign: boolean;
  referralCode: boolean;
  twoFactor: boolean;
  fileAutoCategorization: boolean;
  suggestedUploads: boolean;
  prefillForSelfEmployment: boolean;
}
