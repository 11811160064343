import React, {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {captureException} from '@sentry/browser'

import Banner from 'core/components/banner/Banner'

import styles from './_app.module.scss'

type Props = {
  error: Error;
}

const ErrorBoundaryBanner = ({error}: Props): JSX.Element => {
  const [showBanner, setShowBanner] = useState<boolean>(true)
  useEffect(() => {
    captureException(error)
  }, [error])

  const dismissBanner = useCallback(() => {
    setShowBanner(false)
  }, [])

  return (
    <>
      {showBanner && (
        <Banner
          id="error-boundary-banner"
          className={styles.errorBoundaryBanner}
          intent="error"
          sticky
          onDismiss={dismissBanner}
        >
          An error has occurred. We are working hard to get it
          fixed as soon as possible.
        </Banner>
      )}
    </>
  )
}

export default memo(ErrorBoundaryBanner)
