import React from 'react'
import cx from 'classnames'

import styles from './_loaders.module.scss'

const SIZE_MAP = {
  small: 20,
  large: 50,
}

type Props = {
  className?: string;
  color?: 'blue' | 'white';
  size?: 'small' | 'large';
  strokeWidth?: number;
}

export default function LoadingSpinner({
  className,
  color,
  size = 'large',
  strokeWidth = 10,
}: Props): JSX.Element {
  return (
    <svg
      aria-labelledby="loadingSpinner"
      className={cx(styles.loadingSpinner, color && styles[color], className)}
      width={40}
      height={SIZE_MAP[size]}
      role="img"
      viewBox="0 0 100 100"
    >
      <title id="loadingSpinner">Loading Spinner</title>
      <circle
        className={styles.loadingCircle}
        cx="50"
        cy="50"
        r="45"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
