/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import React, {CSSProperties, useMemo} from 'react'
import cx from 'classnames'
import uniqueId from 'lodash/uniqueId'

import InfoIcon from 'core/components/icon/Info'
import Tooltip from 'core/components/tooltip/TooltipV2'

import {EducationTypes, TaxBreakdownEducationContainer} from '../TaxBreakdown/TaxBreakdownEducation'

import styles from './_table.module.scss'

export type TableRowProps = {
  cells: {
    className?: string;
    colSpan?: number;
    label: string | number;
    style?: CSSProperties;
    labelClass?: string;
    Element?: React.ElementType;
    info?: string;
  }[];
  className?: string;
  cellTag?: 'td' | 'th';
  id: string;
}

const AmtTooltip = ({content}: {content: string}): JSX.Element => (
  <Tooltip
    popperOptions={{
      positionFixed: true,
    }}
    content={(
      <span className={styles.tooltipContent}>
        {content}
      </span>
    )}
  >
    <span className={styles.info} role="button">
      <InfoIcon size="1rem" />
    </span>
  </Tooltip>
)

function TableRow(
  {
    cells,
    className,
    cellTag: Tag = 'td',
    id,
  }: TableRowProps,
): JSX.Element {
  return (
    <tr className={cx(styles.row, className)}>
      {cells.map(({
        label, Element, info, ...rest
      }, idx) => (
        <Tag className={styles.cell} key={`${id}-cell-${idx}`} {...rest}>
          { Element ? (<Element>{label}</Element>) : label }
          { info ? <AmtTooltip content={info} /> : null}
        </Tag>
      ))}
    </tr>
  )
}

type Props = {
  className?: string;
  bodyRows?: TableRowProps[];
  headerRows?: TableRowProps[];
  footerRows?: TableRowProps[];
  footNote?: string;
  id: string;
  title: string;
  wrapperClassName?: string;
  isOrdinary?: boolean;
}

export default function GrantTable(
  {
    bodyRows,
    className,
    footNote,
    footerRows,
    headerRows,
    id,
    title,
    wrapperClassName,
    isOrdinary = false,
  }: Props,
): JSX.Element {
  const tableId = useMemo(() => uniqueId(`${id} `), [id])
  const titleId = `${tableId} title`
  return (
    <div className={cx(styles.wrapper)}>
      <div className={styles.title} id={titleId}>{title}</div>
      {isOrdinary && (
        <TaxBreakdownEducationContainer
          isBracket
          educationType={EducationTypes.EXERCISE_AND_SALE_FEDERAL_ORDINARY_INCOME_TAX}
        />
      )}
      <div className={styles.scrollWrapper}>
        <div className={cx(styles.tableWrapper, wrapperClassName)}>
          <table className={cx(styles.table, className)} id={tableId} aria-labelledby={titleId}>
            <thead className={styles.tableHeader}>
              {headerRows?.map((row, rowIdx) => (
                <TableRow
                  key={`${id}-header-row-${rowIdx}`}
                  cellTag="th"
                  {...row}
                />
              ))}
            </thead>
            <tbody className={styles.tableBody}>
              {bodyRows?.map((row, rowIdx) => (
                <TableRow
                  key={`${id}-body-row-${rowIdx}`}
                  cellTag="td"
                  {...row}
                />
              ))}
            </tbody>
            <tfoot className={styles.tableFooter}>
              {footerRows?.map((row, rowIdx) => (
                <TableRow
                  key={`${id}-footer-row-${rowIdx}`}
                  {...row}
                />
              ))}
            </tfoot>
          </table>
        </div>
      </div>
      {footNote && <div className={styles.footNote}>{footNote}</div>}
    </div>
  )
}
