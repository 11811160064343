import {createReducer, unwrapResult} from '@reduxjs/toolkit'

import {AlertMessage} from './types'
import * as AlertActions from './actions'

const alert = createReducer<Nullable<AlertMessage>>(null, (builder) => {
  builder
    .addCase(AlertActions.addAlert, (_, action) => unwrapResult(action))
    .addCase(AlertActions.removeAlert, () => null)
})

export default alert
