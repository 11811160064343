export const validateLockupPeriod = (name: string, value: string): string | null => {
  const days = Number(value.replace(/,/g, ''))

  if (days < 0) {
    return 'Lock-up period must be a minimum of 0 days'
  }

  if (days > 1000) {
    return 'Lock up period must be less than or equal to 1,000 days'
  }

  return null
}
