import React, {FC, ReactNode, memo} from 'react'
import styled from 'styled-components'

import {H2, RegularText} from '../theme'

type Props = {
  title: string;
  description?: string;
  descriptionColor?: string;
  ActionsComponent?: ReactNode;
  NavigationComponent?: ReactNode;
}

export const Header: FC<Props> = memo(({
  title, description, descriptionColor, ActionsComponent, NavigationComponent,
}) => (
  <Container>
    <StyledContent>
      {!!NavigationComponent && (
      <NavigationContainer>{NavigationComponent}</NavigationContainer>
      )}
      <H2>{title}</H2>
      {!!description && (
      <DescriptionContainer>
        <RegularText color={descriptionColor}>
          {description}
        </RegularText>
      </DescriptionContainer>
      )}
      {!!ActionsComponent && (
      <ActionsContainer>{ActionsComponent}</ActionsContainer>
      )}
    </StyledContent>
  </Container>
))

const Container = styled.div(({theme}) => `
  width: 100%;
  padding: ${theme.spacing.xl};
   ${theme.below(theme.breakpoints.largePhone)} {
        padding: ${theme.spacing.l};
   }
  background-color: ${theme.colors.white100};
`)

const DescriptionContainer = styled.div(({theme}) => `
  margin-top: ${theme.spacing.xs};
`)

const ActionsContainer = styled.div(({theme}) => `
  margin-top: ${theme.spacing.m};
`)

const NavigationContainer = styled.div(({theme}) => `
  margin-bottom: ${theme.spacing.s};
`)

const StyledContent = styled.div(({theme}) => `
  max-width: ${theme.maxWidth};
  margin: 0 auto;
`)
