/* eslint-disable no-restricted-globals */
import numeral from 'numeral'

const OneThousand = 1000
const OneMillion = 1000000

type Conditions = {
  onNan?: string;
  onZero?: string;
}

type NumberFormatterReturn = {
  abbreviatedCurrency: string;
  abbreviatedCurrencyNoSpace: string;
  intCurrency: string;
  currency: string;
  decimal: string;
  percent: string;
  roundedPercent: string;
  rangedCurrency: string;
}

const abbreviatedCurrencyFormatter = (
  value: string | number,
  {onNan = '--', onZero = '0'}: Conditions = {onNan: '--', onZero: '0'},
  noSpace = false,
): string => {
  const numberVal = typeof value === 'string' ? Number(value) : value

  if (isNaN(numberVal)) return onNan
  if (numberVal === 0) return onZero
  if (numberVal < OneThousand) return numeral(numberVal).format('$0').toUpperCase()

  const millionLhsValue = Math.floor(numberVal / OneMillion)

  // Refer to the test spec as documentation to understand what is happening here
  if (numberVal < OneMillion
    || (millionLhsValue > 9 && millionLhsValue < 1000)) { // 10-999 B
    return numeral(numberVal).format(noSpace ? '$0a' : '$0 a').toUpperCase() // no trailing decimals
  }

  return numeral(numberVal).format(noSpace ? '$0.[00]a' : '$0.[00] a').toUpperCase()
}

export const numberFormatter = (
  value: string | number,
  {onNan = '--', onZero = '0'}: Conditions = {onNan: '--', onZero: '0'},
): NumberFormatterReturn => ({
  get abbreviatedCurrency(): string {
    return abbreviatedCurrencyFormatter(value, {onNan, onZero})
  },
  get abbreviatedCurrencyNoSpace(): string {
    return abbreviatedCurrencyFormatter(value, {onNan, onZero}, true)
  },
  intCurrency: numeral(value).format('$0,0'),
  currency: numeral(value).format('$0,0.00'),
  get decimal(): string {
    const numberVal = typeof value === 'string' ? Number(value) : value

    if (isNaN(numberVal)) return onNan
    if (numberVal === 0) return onZero

    return numeral(value).format('0,0')
  },
  percent: numeral(value).format('0.00%'),
  roundedPercent: numeral(value).format('0%'),

  get rangedCurrency(): string {
    if (typeof value !== 'number' && value.includes('-')) {
      const [firstRange, secondRange] = value.split('-')
      return `$${firstRange}-$${secondRange}`
    }
    return `$${value}`
  },
})

const numberFormat = new Intl.NumberFormat('en-US')

export function formatNumberWithCommas(value: number): string {
  return numberFormat.format(value)
}

export function formatPriceWithCommas(value: number): string {
  if (value === 0) return `$${0}`
  if (value < 0) return `-$${numberFormat.format(value * -1)}`
  return `$${numberFormat.format(value)}`
}

/**
 *
 * @param value
 *
 * Convert a string number to a number so it can be passed correctly
 * to server
 */
export function convertFormattedStringToNumber(value: string): number {
  return Number(value.replace(/,/g, ''))
}
