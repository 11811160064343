import HttpClient, {FetchResponse} from '../helpers/httpClient'

export type NetWorth = {
  assetValue: number;
  depositoryValue: number;
  investmentValue: number;
  liabilityValue: number;
  loansValue: number;
  networthValue: number;
  otherValue: number;
  realEstateValue: number;
  revolvingValue: number;
  cryptoValue: number;
  timestamp: string;
}

export type AccountValue = {
  accountValue: number;
  timestamp: string;
}

export type TimeseriesResponse = {
  lastSync: string;
  routeKey: string;
  data: any[];
}

export type NetWorthSeriesResponse = TimeseriesResponse & {
  data: NetWorth[];
}

export type AccountSeriesResponse = TimeseriesResponse & {
  data: AccountValue[];
}

export enum NetWorthTimeseriesRouteId {
  NETWORTH_FAIR_MARKET = 'NETWORTH_TS',
  NETWORTH_PRIVATE_EQUITY_ZERO = 'NETWORTH_TS_MARK_ZERO',
  NETWORTH_PRIVATE_EQUITY_PREFERRED = 'NETWORTH_TS_PREFERRED_PRICE',
}

export enum AccountTimeseriesRouteId {
  ACCOUNT_FAIR_MARKET = 'ACCOUNT_TS',
  ACCOUNT_PRIVATE_EQUITY_ZERO = 'ACCOUNT_TS_MARK_ZERO',
  ACCOUNT_PRIVATE_EQUITY_PREFERRED = 'ACCOUNT_TS_PREF_PRICE',
}

export enum ValuationSelection {
  FAIR_MARKET = 'FAIR_MARKET',
  MARK_ZERO = 'MARK_ZERO',
  PREFERRED_PRICE = 'PREFERRED_PRICE',
}

export const ValuationSelectionToRouteId = {
  [ValuationSelection.FAIR_MARKET]: {
    ACCOUNT: AccountTimeseriesRouteId.ACCOUNT_FAIR_MARKET,
    NET_WORTH: NetWorthTimeseriesRouteId.NETWORTH_FAIR_MARKET,
  },
  [ValuationSelection.MARK_ZERO]: {
    ACCOUNT: AccountTimeseriesRouteId.ACCOUNT_PRIVATE_EQUITY_ZERO,
    NET_WORTH: NetWorthTimeseriesRouteId.NETWORTH_PRIVATE_EQUITY_ZERO,
  },
  [ValuationSelection.PREFERRED_PRICE]: {
    ACCOUNT: AccountTimeseriesRouteId.ACCOUNT_PRIVATE_EQUITY_PREFERRED,
    NET_WORTH: NetWorthTimeseriesRouteId.NETWORTH_PRIVATE_EQUITY_PREFERRED,
  },
}

async function getTimeSeries(
  endpoint: string,
  startDate: string, // YYYY-MM-DD
  endDate: string, // YYYY-MM-DD
): Promise<FetchResponse<TimeseriesResponse>> {
  const params = {...(startDate && {start: startDate}), ...(endDate && {end: endDate})}
  const qsParams = new URLSearchParams(params).toString()
  let qs = ''
  if (qsParams) {
    qs = `?${qsParams}`
  }
  const response = await HttpClient.get<TimeseriesResponse>(
    `${endpoint}/${qs}`,
    {},
    {
      captureExceptions: true,
      ignoredStatusCodes: [404],
    },
  )

  return {
    data: response.data,
    error: response.error,
  }
}

export async function getNetWorthTimeSeries(
  clientId: string,
  routeId: NetWorthTimeseriesRouteId = NetWorthTimeseriesRouteId.NETWORTH_FAIR_MARKET,
  startDate = '', // YYYY-MM-DD
  endDate = '', // YYYY-MM-DD
): Promise<FetchResponse<NetWorthSeriesResponse>> {
  return getTimeSeries(`/v1/timeseries/client:${clientId}:${routeId}`, startDate, endDate)
}

export async function getAccountTimeSeries(
  accountId: string,
  routeId: AccountTimeseriesRouteId = AccountTimeseriesRouteId.ACCOUNT_FAIR_MARKET,
  startDate = '', // YYYY-MM-DD
  endDate = '', // YYYY-MM-DD
): Promise<FetchResponse<AccountSeriesResponse>> {
  return getTimeSeries(`/v1/timeseries/account:${accountId}:${routeId}`, startDate, endDate)
}
