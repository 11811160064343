import React from 'react'
import Tippy, {TippyProps} from '@tippy.js/react'

import 'tippy.js/themes/light.css' // eslint-disable-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css' // eslint-disable-line import/no-extraneous-dependencies

export default function Tooltip(
  {
    children,
    content,
    interactive = false,
    theme = 'light',
    zIndex = 9999, // default z-index (https://atomiks.github.io/tippyjs/v5/all-props/)
    ...rest
  }: TippyProps,
): JSX.Element {
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Tippy theme={theme} content={content} interactive={interactive} zIndex={zIndex} {...rest}>
      {children}
    </Tippy>
  )
}
