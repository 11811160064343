import React, {useContext} from 'react'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'

import {ToastContext} from './context'
import {ToastNotification} from './ToastNotification'
import {CollapsibleSpace} from './CollapsibleSpace'

import {RoutePaths} from 'core/constants/RoutePaths'

const StyledWrapper = styled.div<{isFullPage: boolean; isDropZone: boolean}>(
  ({theme, isFullPage, isDropZone}) => `
  position: fixed;
  width: calc(100vw - ${theme.spacing.xl});
  left: 0;
  bottom: 0;
  margin: 0 ${theme.spacing.m};
  z-index: 1400;

  ${theme.above(theme.breakpoints.aboveMobile)} {
    width: unset;
    left: ${isDropZone ? '50%' : '0'};
    ${isDropZone && 'transform: translateX(-50%)'};
  }

  ${theme.above(theme.breakpoints.aboveMobileNav)} {
    ${!isFullPage && 'left: var(--sidebar-navigation-width)'};
    margin: 0 0 ${theme.spacing.xs} ${theme.spacing.l};
    left: ${isDropZone ? '50%' : '0'};
    ${isDropZone && 'transform: translateX(-50%)'};
  }
`,
)

export const ToastContainer = (): JSX.Element => {
  const toasts = useContext(ToastContext)
  const isDropZone = toasts.some((obj) => obj.isDropZone === true)

  const FULL_PAGE_ROUTES = [
    RoutePaths.ADVISOR_SESSIONS_QUESTIONNAIRE,
    RoutePaths.ADVISOR_SESSIONS_FILES_UPLOAD,
  ]

  const match = useRouteMatch({
    path: FULL_PAGE_ROUTES,
    exact: true,
  })
  const fullPage = Boolean(match)

  return (
    <StyledWrapper isFullPage={fullPage} isDropZone={isDropZone}>
      {toasts.map((obj) => (
        <CollapsibleSpace key={obj.id} open={obj.open}>
          <ToastNotification
            id={obj.id}
            intent={obj.intent}
            title={obj.title}
            onDismiss={obj.onDismiss}
            description={obj.description}
            ctaLabel={obj.ctaLabel}
            onCtaClick={obj.onCtaClick}
            ctaLabelSecondary={obj.ctaLabelSecondary}
            onSecondaryCtaClick={obj.onSecondaryCtaClick}
            open={obj.open}
            animate
            isDropZone={isDropZone}
          />
        </CollapsibleSpace>
      ))}
    </StyledWrapper>
  )
}
