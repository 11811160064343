import track from '../track'

type PairingProfile = {
  firmId: string;
  firmName: string;
  vertical: string;
}

export const marketplaceAnalyticsEvents = {
  profileCompleted: (): void => track.all('marketplace_profile_profile-completed'),
  pairingProfileSave: (properties: PairingProfile): void => track.mixpanel('pairing_profile-save', properties),
  pairingProfileUnsave: (properties: PairingProfile): void => track.mixpanel('pairing_profile-unsave', properties),
  editProfileClicked: (): void => track.mixpanel('marketplace_edit-profile_clicked'),
  getStartedClicked: (): void => track.mixpanel('marketplace_dashboard_get-started_clicked'),
  completeProfileClicked: (): void => track.mixpanel('marketplace_dashboard_complete-profile_clicked'),
  // eslint-disable-next-line max-len
  confirmationShortlistClicked: (properties: Omit<PairingProfile, 'firmId'>): void => track.all('marketplace_confirm-shortlist_click', properties),
}
