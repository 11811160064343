import {DefaultTheme} from 'styled-components'

// TODO(zare)
/**
 * 1. Add fonts
 * 2. Add font sizes
 * 3. Add borderRadius
 * 4. Add devices and breakpoints for mobile
 */

export const defaultTheme: DefaultTheme = {
  // eslint-disable-next-line max-len
  // https://medium.flatstack.com/how-to-make-media-queries-strings-in-styled-components-shorter-and-more-reliable-c78bcce13fc8
  above: (breakpoint) => `@media (min-width: ${breakpoint})`,
  below: (breakpoint) => `@media (max-width: ${breakpoint})`,
  between: (min, max, vertical = false) => `@media (max-${vertical ? 'height' : 'width'}:
    ${max}) and (min-${vertical ? 'height' : 'width'}: calc(${min} + 0.02px))`,
  breakpoints: {
    smallPhone: '320px',
    largePhone: '640px',
    aboveMobile: '720px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1440px',
    aboveMobileNav: '960px',
  },
  spacing: {
    '3xs': '2px',
    xxs: '4px',
    xs: '8px',
    s: '12px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xxl: '48px',
    '3xl': '56px',
    '4xl': '64px',
    '5xl': '80px',
    '6xl': '96px',
    '7xl': '112px',
    '8xl': '120px',
  },
  colors: {
    darkBlue600: '#031732',
    darkBlue500: '#082042',
    darkBlue400: '#384e68',
    darkBlue300: '#6a7a8e',
    darkBlue200: '#9ca6b4',
    darkBlue100: '#ced2d9',

    /* light blue */
    lightBlue600: '#5a779a',
    lightBlue500: '#7390b0',
    lightBlue400: '#8ea7c0',
    lightBlue300: '#aabdd0',
    lightBlue350: '#348dd0',
    lightBlue200: '#c7d3e0',
    lightBlue100: '#e3e9ef',
    lightBlue075: '#EBF4FA',
    lightBlue050: '#EEF4FB',

    /* grey */
    grey600: '#2b2b2b',
    grey500: '#626262',
    grey400: '#808080',
    grey300: '#aaa',
    grey200: '#d5d5d5',
    grey150: '#e7e7e7',
    grey100: '#f5f5f5',
    grey50: '#fafafa',

    /* green */
    green600: '#253924',
    green500: '#364f35',
    green400: '#5f715d',
    green300: '#869586',
    green200: '#afb9ae',
    green100: '#d7dcd7',

    /* orange */
    orange600: '#db8a21',
    orange500: '#e49b3d',
    orange400: '#eaad61',
    orange300: '#efc289',
    orange200: '#f5d6b1',
    orange100: '#f9ead8',
    orange50: '#febc45',
    orange25: '#FFE0A5',

    /* beige */
    beige600: '#a8a18f',
    beige500: '#bfb7a6',
    beige400: '#cec7b7',
    beige300: '#ddd7c9',
    beige200: '#eae7df',
    beige100: '#f6f5f1',
    beige50: '#fffbf6',

    /* rust */
    rust600: '#ab3d06',
    rust500: '#be530a',
    rust400: '#cc7438',
    rust300: '#d8976a',
    rust200: '#e6b99c',
    rust100: '#f3ddce',

    brown300: '#8F5300',

    /* admin colors */
    warning: '#ffc149',
    warning100: '#FFF3DB',
    warningLight: '#fff9ed',
    warningLightMain: '#ff9215',
    info: '#4d9aff',
    infoLight: '#edf5ff',
    infoLightMain: '#276fff',
    success100: '#D1F6C7',
    success200: '#bfe0c7',
    success500: '#348C46',
    success: '#5eb273',
    error: '#cd5437',
    error50: '#faeeeb',
    error100: '#f5ddd7',
    error200: '#EBBBAF',
    error300: '#e19887',
    error500: '#cd5437',
    error600: '#a51c0c',

    /* white */
    white100: '#fff',
  },
  fontSize: {
    mobile: {
      h1: '2.125rem',
      h2: '1.75rem',
      h3: '1.5rem',
      h4: '1.25rem',
      h5: '1.125rem', // 18px
      body: '1rem',
      nav: '0.875rem',
      note: '0.75rem',
      footNote: '0.625rem',
      tag: '0.6rem',
    },
    desktop: {
      h1: '3rem',
      h2: '2.25rem',
      h3: '1.875rem',
      h4: '1.5rem',
      h5: '1.25rem',
      caption: '1.125rem',
      body: '1rem',
      nav: '0.875rem',
      note: '0.75rem',
      footNote: '0.625rem',
      tag: '0.6rem',
    },
  },
  fontFamily: {
    primary: 'Theinhardt, sans-serif',
    secondary: 'Media77, serif',
  },
  maxWidth: '1200px',
  navBarHeaderHeight: '64px',
}
