import React from 'react'
import cx from 'classnames'

import styles from './_progressBar.module.scss'

export type Props = {
  /** Progress className */
  className?: string;
  /** Wrapper className */
  wrapperClassName?: string;
  /** Current progress as number */
  currentProgress: number;
  /** Total progress as number */
  totalProgress: number;
  /** Cypress tag */
  'data-cy'?: string;
  /** Progress bar id */
  id: string;
}

export default function ProgressBar(
  {
    className,
    wrapperClassName,
    currentProgress,
    totalProgress,
    'data-cy': dataCy = '',
    id,
  }: Props,
): JSX.Element {
  return (
    <>
      <div
        className={cx(styles.progressBarWrapper, wrapperClassName)}
        data-cy={dataCy || `progressBar:${id}`}
      >
        <progress
          className={cx(styles.progressBar, className)}
          value={currentProgress}
          max={totalProgress}
        />
        <div>
          {`${currentProgress} of ${totalProgress}`}
        </div>
      </div>
    </>
  )
}
