import {CSSProperties} from 'react'

export type SelectOptions<T> = {
  label: string;
  value: T;
}[]

export enum FormStatus {
  INITIAL = 'INITIAL',
  SUBMITTING = 'SUBMITTING',
  SUBMITTED = 'SUBMITTED',
}

export const selectStyles: KeyedObject = {
  control: (provided: CSSProperties, {isFocused, selectProps: {error}}) => ({
    ...provided,
    borderColor: (error && !isFocused) ? '#CD5437' : provided.borderColor,
    boxShadow: 'none',
    height: '3rem',
    ':hover': {
      borderColor: (error && !isFocused) ? '#CD5437' : provided.borderColor,
    },
  }),
  indicatorsContainer: (provided: CSSProperties, {selectProps: {error}}) => ({
    ...provided,
    color: error ? '#CD5437' : provided.backgroundColor,
  }),
  indicatorSeparator: (provided: CSSProperties, {isFocused, selectProps: {error}}) => ({
    ...provided,
    backgroundColor: (error && !isFocused) ? '#CD5437' : provided.backgroundColor,
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    fontWeight: 'normal',
  }),
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
    borderRadius: '4px',
    paddingLeft: '0.5rem',
    fontWeight: 'normal',
  }),
}
