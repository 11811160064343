import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import cx from 'classnames'

import Button from '../button/Button'
import CaretDown from '../icon/CaretDown'

import styles from './_menu.module.scss'

export type Option = {
  key?: string; // If the `name` is JSX.ReactNode use key to set button key
  name: JSX.Element | string;
  disabled?: boolean;
  onClick(e?: React.MouseEvent): any | void;
  /** @ignore */
  'data-cy'?: string;
}

export type OptionsMenuProps = {
  className?: string;
  direction?: 'left' | 'right';
  disabled?: boolean;
  options: Option[];
  menuIcon?: React.ReactNode;
  /** @ignore */
  'data-cy'?: string;
}

const KEY_MAP = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ENTER: 'Enter',
}

export const OptionsMenu = (
  {
    className = '',
    disabled = false,
    direction = 'left',
    options,
    menuIcon,
    'data-cy': dataCy,
  }: OptionsMenuProps,
): JSX.Element => {
  const ref = useRef<HTMLHeadingElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [focusedIndex, setFocusedIndex] = useState<number>(-1)

  const toggleMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen: boolean) => {
      const button = e.target as HTMLButtonElement
      if (prevOpen && button) button.blur() // remove focus from button on menu close
      return !prevOpen
    })
  }, [])

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (open) {
      window.addEventListener('click', handleClickOutside)
    }

    return (): void => {
      window.removeEventListener('click', handleClickOutside)
      setFocusedIndex(-1)
    }
  }, [handleClickOutside, open])

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    const {key} = e
    if (open) {
      switch (key) {
        case KEY_MAP.ARROW_DOWN:
          e.preventDefault()
          setFocusedIndex((prevIndex) => (prevIndex === options.length - 1 ? 0 : prevIndex + 1))
          break
        case KEY_MAP.ARROW_UP:
          e.preventDefault()
          setFocusedIndex((prevIndex) => (prevIndex <= 0 ? options.length - 1 : prevIndex - 1))
          break
        case KEY_MAP.ENTER:
          if (focusedIndex > -1) options[focusedIndex].onClick()
          break
        default:
          setFocusedIndex(-1)
          setOpen(false)
      }
    }
  }, [focusedIndex, open, options])

  const handleOptionClick = useCallback(
    (optionOnClick: (e?: React.MouseEvent) => any) => (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation()
      optionOnClick()
      setOpen(false)
    }, [],
  )

  return (
    <div className={cx(styles.optionsMenuContainer, className)} ref={ref} data-cy={dataCy || ''}>
      <Button
        aria-haspopup="true"
        aria-label="options menu"
        className={styles.toggleOptionsMenuBtn}
        disabled={disabled}
        onClick={toggleMenu}
        onKeyDown={handleKeyDown}
      >
        {menuIcon ?? <CaretDown fill={disabled ? '#6A7A8E' : '#082042'} />}
      </Button>
      <div
        aria-hidden={!open}
        className={cx(styles.optionsMenu, open && styles.open, styles[direction])}
        role="menu"
      >
        {options.map(({
          name, disabled: optionDisabled = false, onClick: optionOnClick, key, 'data-cy': optionDataCy = 'options-menu',
        }: Option, index: number) => (
          <Button
            key={key ?? name.toString()}
            className={cx(
              styles.optionsMenuOption,
              {
                [styles.focused]: focusedIndex === index,
                [styles.disabled]: optionDisabled,
              },
            )}
            onClick={handleOptionClick(optionOnClick)}
            disabled={optionDisabled}
            data-cy={`${optionDataCy}-${key ?? name.toString().split(' ').join('-')}`}
          >
            {name}
          </Button>
        ))}
      </div>
    </div>
  )
}
