import track from '../track'

export const advisorSessionsAnalyticsEvents = {
  advisorSessionSendAvailability: (): void => track.mixpanel('advisor-sessions_equity-session_send-availability'),
  advisorSessionPayAndSign: (): void => track.mixpanel('advisor-sessions_equity-session_pay-and-sign'),
  advisorSessionDocsAndQuestionnaire: (): void => track.mixpanel('advisor-sessions_equity-session_docs-and-questionnaire'),
  advisorSessionDocumentDownload: (): void => track.mixpanel('advisor-sessions_equity-session_document-download'),
  advisorSessionNextStepsUploadDocumentsClick: (
    {firmService, taskState}: {firmService: string; taskState: string},
  ): void => track.mixpanel('advisor-sessions-next-steps-upload-documents_click', {
    firmService, taskState,
  }),
  advisorSessionNextStepsDownloadPlanClick: (): void => track.mixpanel(' advisor-sessions-next-steps-download-plan_click'),
  advisorSessionsConfirmShortlist: ({serviceName, location}: {serviceName: string; location: string}): void => {
    track.all('advisor-sessions_confirm-shortlist_click', {
      serviceName,
      location,
    })
  },
  advisorSessionsDashboardPageView: (): void => track.mixpanel('advisor-sessions_next-steps_page-view'),
  advisorSessionsDashboardScheduleIntro: (): void => track.mixpanel('advisor-sessions_next-steps-schedule-intro_click'),
  advisorSessionsDashboardQuestionnaireClick: (): void => track.mixpanel('advisor-sessions_next-steps-questionnaire_click'),
  advisorSessionsDashboardInvoiceClick: (): void => track.mixpanel('advisor-sessions_next-steps-pay-invoice_click'),
  advisorSessionsDashboardLetterClick: (): void => track.mixpanel('advisor-sessions_next-steps-sign-letter_click'),
  advisorSessionsConnectAccountClick: (): void => track.mixpanel('advisor-sessions-connect-account_click'),
  advisorSessionsDashboardUploadDocs: (): void => track.mixpanel('advisor-sessions_next-steps-upload-docs_click'),
  advisorSessionsDashboardClientPortalClick: (): void => track.mixpanel('advisor-sessions_next-steps-client-portal-login_click'),
  advisorSessionsDashboardSendAvailabilityMarketingWidget:
    ({firmService}: {firmService: string}): void => track.mixpanel('advisor-sessions_send-availability_marketing-widget', {
      firmService,
    }),
}
