import {UserSettings} from 'api/user'
import {apiSlice} from 'store/main/apiSlice'

const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserSettings, void>({
      query: () => '/v1/authentication/user/',
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<UserSettings, Partial<UserSettings>>({
      query: (settings) => ({
        url: '/v1/authentication/user/',
        method: 'PATCH',
        body: settings,
      }),
      invalidatesTags: ['User'],
    }),
  }),
})

export const {
  useLazyGetUserQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} = userSlice
