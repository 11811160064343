import {EnvConfig, Environment} from './types'

const result: EnvConfig = {
  ENV: Environment.DEMO,
  HELLO_SIGN_CLIENT_ID: '73889e8ee509ff3e0d393b267a451738',
  SENTRY_HOST: 'https://1532267158b2437bac4ab3c8ac98ea28@sentry.io/1378213',
  LINKEDIN_CLIENTID: '77rx2ipbqqepeu',
  OPEN_HUBSPOT_CHAT: false,
  SENTRY_TRACING_RATE: 0.5,
  VEZGO_CLIENT_ID: '1gse42uph5u09he2sujges929q',
  HUBSPOT_QUESTIONNAIRE_FORM_ID: '7293a247-5bda-4ced-884e-72440339f19d',
}

export default result
