import {createSelector} from '@reduxjs/toolkit'

import {equityEvents} from '../../pages/profile-onboarding/constants'

import {apiSlice} from 'store/main/apiSlice'
// TODO(zare): move from models to types here
import {
  ClientProfile, OnboardingGetStartedOption,
} from 'models/clientProfile'
import {session} from 'core/utils/AppSession'

export const clientProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientProfile: builder.query<ClientProfile, string>({
      query: (clientId) => `/v1/client/${clientId}/profile/`,
      providesTags: ['ClientProfile'],
    }),
    getCurrentUserProfile: builder.query<ClientProfile, void>({
      query: () => {
        const clientId = session.getClientId() ?? ''
        return {
          url: `/v1/client/${clientId}/profile/`,
          skip: Boolean(clientId),
        }
      },
      providesTags: ['ClientProfile'],
    }),
    saveClientProfile: builder.mutation<void, Partial<ClientProfile> & {clientId: string}>({
      query: ({clientId, ...rest}) => ({
        url: `/v1/client/${clientId}/profile/`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: ['ClientProfile'],
    }),
    updateClientProfile: builder.mutation<void, Partial<ClientProfile> & {clientId: string}>({
      query: ({clientId, ...rest}) => ({
        url: `/v1/client/${clientId}/profile/`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: ['ClientProfile'],
    }),
  }),
})

export const {
  useGetCurrentUserProfileQuery,
  useLazyGetCurrentUserProfileQuery,
  useGetClientProfileQuery,
  useLazyGetClientProfileQuery,
  useSaveClientProfileMutation,
  useUpdateClientProfileMutation,
} = clientProfileApiSlice

export const selectCurrentUserProfile = clientProfileApiSlice.endpoints.getCurrentUserProfile.select()

export const selectIsFinanicalPlanningSelected = createSelector(selectCurrentUserProfile,
  (currentUserProfile): boolean => currentUserProfile?.data?.meta.onboardingSelectedChoice === OnboardingGetStartedOption.FIND_ADVISOR2)

export const selectShouldShowEquityInsights = createSelector(selectCurrentUserProfile, (currentUserProfile):
boolean => (currentUserProfile?.data ?
  currentUserProfile.data.majorFinancialChanges.some((majorFinancialChange) => equityEvents.has(majorFinancialChange)) : false))
