import React, {useMemo} from 'react'

import GrantTable from '../tables/GrantTable'

import l, {INCOME_FROM_EXERCISES} from './locale'

import {EquitySale, IncomeSource, TaxScenarioGrantType} from 'models'
import {formatNumberWithCommas, isoDateFormatter, numberFormatter} from 'core/formatters'

const GRANT_NAME_PREFIX = {
  [TaxScenarioGrantType.INCENTIVE_STOCK]: 'ISO',
  [TaxScenarioGrantType.NON_QUALIFIED]: 'NSO',
  [TaxScenarioGrantType.RSA]: 'RSA',
  [TaxScenarioGrantType.RSU]: 'RSU',
}

const OPTION_GRANT = [
  TaxScenarioGrantType.INCENTIVE_STOCK,
  TaxScenarioGrantType.NON_QUALIFIED,
]

type Props = {
  noSharesSold: boolean;
  sales: IncomeSource[];
  purchaseDate: string;
  hasPriorExercise: boolean;
  id: string;
  isShortTerm?: boolean;
  isAMT?: boolean;
}

export default function CapitalGainsIncome({
  noSharesSold,
  sales,
  purchaseDate,
  hasPriorExercise,
  id,
  isShortTerm,
  isAMT = false,
}: Props): JSX.Element {
  const locale = isShortTerm ? l.SHORT_TERM_CAPITAL_GAINS : l.LONG_TERM_CAPITAL_GAINS

  const validSales = useMemo(() => sales.filter(({incomeSource: {quantity}}) => quantity), [sales])

  const headerRows = [
    {
      id: `${id}-header`,
      cells: [
        {label: locale.HEADER.GRANT_NAME},
        {label: locale.HEADER.HOLDING_PERIOD},
        {label: locale.HEADER.TAX_TREATMENT},
        {label: locale.HEADER.PRICE_AT_SALE},
        {label: locale.HEADER.COST_BASIS},
        {label: locale.HEADER.COMPENSATION_ADJUSTMENT},
        {label: locale.HEADER.CAPITAL_GAIN_PER_SHARE},
        {label: locale.HEADER.QUANTITY},
        {label: locale.HEADER.TAX_LIABILITY},
      ],
    },
  ]

  const bodyRows = useMemo(() => {
    let emptyCopy = ''
    if (noSharesSold) emptyCopy = locale.NO_SHARES
    else if (!validSales.length) emptyCopy = locale.NO_GAINS
    if (emptyCopy) {
      return [{
        id: `${id}-body`,
        cells: [
          {label: emptyCopy, colSpan: 9},
        ],
      }]
    }
    return validSales.map((incomeSource) => {
      const sale = incomeSource.incomeSource as EquitySale
      const grantNamePrefix = GRANT_NAME_PREFIX[sale.grantType] || ''
      const displayGrantDate = isoDateFormatter(sale.grantDate, 'M/d/yy')
      const isPriorExerciseLabel = (
        OPTION_GRANT.includes(sale.grantType) && sale.purchaseDate !== purchaseDate ? '*' : ''
      )
      const displayPurchaseDate = isoDateFormatter(sale.purchaseDate, 'M/d/yy')
      const displaySaleDate = isoDateFormatter(sale.saleDate, 'M/d/yy')
      const incomeCompensation = (incomeSource.incomeCompensation * -1) / sale.quantity
      const capitalGainsPerShare = sale.salePrice - sale.purchasePrice + incomeCompensation
      return {
        id: `${id}-body`,
        cells: [
          {label: `${grantNamePrefix} ${displayGrantDate}`},
          {label: `${isPriorExerciseLabel}${displayPurchaseDate} to ${displaySaleDate}`},
          {label: isAMT ? locale.TAX_TREATMENT_AMT : locale.TAX_TREATMENT},
          {label: numberFormatter(sale.salePrice).currency},
          {label: numberFormatter(sale.purchasePrice).currency},
          {label: numberFormatter(incomeCompensation).currency},
          {label: numberFormatter(capitalGainsPerShare).currency},
          {label: formatNumberWithCommas(sale.quantity)},
          {label: numberFormatter(incomeSource.grossIncome).currency},
        ],
      }
    })
  }, [
    id,
    locale,
    noSharesSold,
    purchaseDate,
    validSales,
    isAMT,
  ])

  const footerRows = useMemo(() => {
    const totalIncome = validSales.reduce((sum, sale) => sum + sale.grossIncome, 0)
    return [{
      id: `${id}-footer`,
      cells: [
        {label: locale.TOTAL_INCOME, colSpan: 8},
        {label: numberFormatter(totalIncome).currency},
      ],
    }]
  }, [id, locale, validSales])

  return (
    <GrantTable
      id={id}
      title={locale.TITLE}
      footNote={hasPriorExercise ? INCOME_FROM_EXERCISES.PRIOR_EXERCISE_INDICATOR : ''}
      headerRows={headerRows}
      bodyRows={bodyRows}
      footerRows={footerRows}
    />
  )
}
