import React from 'react'
import cx from 'classnames'

import InfoIcon from 'core/components/icon/Info'
import Tooltip from 'core/components/tooltip/Tooltip'

import styles from './_styles.module.scss'

// TODO (kpollich): Determine how we can deprecate these props and replace them for `RadioGroup`
// form fields, etc
type Props = {
  className?: string;
  children: React.ReactNode;
  label?: string;
  labelFor?: string;
  tooltip?: string | JSX.Element;
  tooltipLabel?: string;
  // Don't include .formField's automatic min-height
  noMinHeight?: boolean;
}

function FormField({
  className = '',
  children,
  label,
  labelFor,
  tooltip,
  tooltipLabel,
  noMinHeight = false,
}: Props): JSX.Element {
  return (
    <div className={cx(styles.formField, {[styles.noMinHeight]: noMinHeight}, className)}>
      <div className={styles.labelWrapper}>
        {label && (
        <label className={styles.formLabel} htmlFor={labelFor}>
          {label}
        </label>
        )}

        {tooltip && (
        <Tooltip interactive delay={0.5} content={tooltip}>
          <span
            className={styles.formTooltip}
            aria-label={tooltipLabel}
            role="button"
            tabIndex={0}
          >
            <InfoIcon size="1rem" />
          </span>
        </Tooltip>
        )}
      </div>

      {children}
    </div>
  )
}

export default FormField
