import React from 'react'
import {useTheme} from 'styled-components'

import Button from 'core/components/button/Button'

import {ActionsContainer, Container, ContentContainer} from './styled'

import {Overline, RegularText, Subheader} from 'core/theme'

type Prop = {
  onClick: () => void;
  title: string;
  content: string;
  label: string;
  imageUrl: string;
  imageAlt: string;
  estimateInMinutes?: number;
  maxWidth?: string;
}

const EmptyState = ({
  onClick,
  title,
  content,
  label,
  imageUrl,
  imageAlt,
  estimateInMinutes,
  maxWidth,
}: Prop): JSX.Element => {
  const theme = useTheme()
  return (
    <Container>
      <ContentContainer maxWidth={maxWidth}>
        <img alt={imageAlt} src={imageUrl} />
        <div>
          <Subheader>{title}</Subheader>
          <RegularText color={theme.colors.darkBlue300}>{content}</RegularText>
        </div>
        <ActionsContainer>
          {estimateInMinutes && <Overline color={theme.colors.darkBlue300}>{`Est. ${estimateInMinutes} minutes`}</Overline>}
          <Button
            label={label}
            intent="primary"
            onClick={onClick}
          />
        </ActionsContainer>
      </ContentContainer>
    </Container>
  )
}

export default React.memo(EmptyState)
