import styled from 'styled-components'

const StyledSubHeader = styled.p(({theme}) => `
  font-family: ${theme.fontFamily.primary};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${theme.colors.darkBlue500};
`)

export default StyledSubHeader
