import {createSelector} from '@reduxjs/toolkit'

import {AlertMessage} from './types'

import type {RootState} from 'store/main'

export const alertSelector = (state: RootState): RootState['alert'] => state.alert

export const getAlert = createSelector(
  [alertSelector],
  (alert): Nullable<AlertMessage> => alert,
)
