import React, {CSSProperties, useMemo} from 'react'

import {numberedTaxRates} from '../../helpers'
import GrantTable, {TableRowProps} from '../tables/GrantTable'

import {TAX_BRACKET as l} from './locale'

import {numberFormatter} from 'core/formatters'
import {
  FILING_STATUS_LABELS,
  NumberedTaxRate,
  TaxBracketOrdering,
  TaxBracketType,
  TaxBreakdown,
  TaxRate,
  TaxScenarioResult,
} from 'models'

const lineItemStyle: CSSProperties = {borderColor: '#6A7A8E', color: '#9CA6B4'}
const leftAlignStyle: CSSProperties = {textAlign: 'left'}

export enum IncomeType {
  ORDINARY = 'ORDINARY',
  SALE = 'SALE',
  VESTING = 'VESTING',
  UNCATEGORIZED = 'UNCATEGORIZED',
  NSO_EXERCISE = 'NSO_EXERCISE',
}

export const INCOME_TYPE_MAP = {
  [TaxBracketOrdering.REGULAR_INCOME]: IncomeType.ORDINARY,
  [TaxBracketOrdering.SCENARIO_EXERCISE]: IncomeType.NSO_EXERCISE,
  [TaxBracketOrdering.SCENARIO_SALE]: IncomeType.SALE,
  [TaxBracketOrdering.RSU_VESTING]: IncomeType.VESTING,
  '': IncomeType.UNCATEGORIZED,
}

export const SUBCATEGORY_DISPLAY = {
  [IncomeType.ORDINARY]: 'regular',
  [IncomeType.NSO_EXERCISE]: 'exercise',
  [IncomeType.VESTING]: 'RSU',
  [IncomeType.SALE]: 'sale',
}

type Props = {
  /** Maximum income taxable for tax event. */
  bracketThreshold?: number;
  breakdown: TaxBreakdown;
  deductions: number;
  result: TaxScenarioResult;
  scenarioNumber: number;
  taxType: TaxBracketType;
  incomePriorExerciseNSO: number;
  isAMT?: boolean;
}

export function reduceTaxBracketsOfTheSameNumberInType(
  rowsByIncomeType: Record<IncomeType, NumberedTaxRate[]>,
): Record<IncomeType, NumberedTaxRate[]> {
  /**
   * Some subcategories are grouped together as regular income/ sale
   *
   * The API returns back rows that are broken up in the most granular level
   * (they keys in INCOME_TYPE_MAP)
   *
   * So in order to accomplish grouping we need to reduce by bracket number
   * within a group
   *
   * */
  return Object.entries(rowsByIncomeType).reduce((accu, [incomeType, taxRates]) => {
    // eslint-disable-next-line no-param-reassign
    accu[incomeType] = taxRates.reduce((resultAccu, taxRate, idx) => {
      if (taxRates[idx - 1] && taxRates[idx - 1].bracketNumber === taxRate.bracketNumber) {
        // eslint-disable-next-line no-param-reassign
        taxRates[idx - 1].taxesOwed += taxRate.taxesOwed
        // eslint-disable-next-line no-param-reassign
        taxRates[idx - 1].amountInBracket += taxRate.amountInBracket
      } else {
        resultAccu.push(taxRate)
      }
      return resultAccu
    }, [] as NumberedTaxRate[])

    return accu
  }, {} as Record<IncomeType, NumberedTaxRate[]>)
}

export function buildIncomeCategoryRows(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  brackets: NumberedTaxRate[] = [],
  incomeType: IncomeType,
  scenarioNumber: number,
  taxType: TaxBracketType,
  taxesOwedNotApplicable: boolean,
  totalIncome: number,
  userThreshold: number,
  isAMT?: boolean,
): TableRowProps[] {
  const [underThreshold, overThreshold, totalPayable] = (brackets || []).reduce((res, taxRate) => {
    if (!totalIncome || taxRate.bracket[0] >= userThreshold) {
      return [res[0], res[1].concat(taxRate), res[2]]
    }
    return [res[0].concat(taxRate), res[1], res[2] + taxRate.taxesOwed]
  }, [[], [], 0] as [NumberedTaxRate[], NumberedTaxRate[], number])

  const isLTCG = [TaxBracketType.FEDERAL_LTCG, TaxBracketType.STATE_LTCG].includes(taxType)

  let subcategoryCopy = isAMT && incomeType === IncomeType.SALE ? l.AMT_SCENARIO_COPY : l.AMT_SUBCATEGORY_COPY

  if (!isAMT) {
    subcategoryCopy = l.SUBCATEGORY_COPY
    if (incomeType === IncomeType.SALE && isLTCG) {
      subcategoryCopy = l.SUBCATEGORY_LTCG_COPY
    } else if (incomeType === IncomeType.ORDINARY && isLTCG) {
      subcategoryCopy = l.SUBCATEGORY_LTCG_ORDINARY_INCOME_COPY
    }
  }

  const rowsUnderThreshold = underThreshold.map(({
    bracketNumber,
    bracket,
    amountInBracket,
    rate,
    taxesOwed,
  }) => ({
    id: `${taxType}-${incomeType}-${scenarioNumber}-over-threshold`,
    cells: [
      {label: `${bracketNumber}.` || ''},
      {
        label: bracket[1]
          ? `${numberFormatter(bracket[0]).intCurrency} - ${numberFormatter(bracket[1]).intCurrency}`
          : `Over ${numberFormatter(bracket[0]).intCurrency}`,
        style: leftAlignStyle,
      },
      {
        label: taxesOwedNotApplicable ? '$0' : numberFormatter(amountInBracket).intCurrency,
        style: leftAlignStyle,
      },
      {label: `${(rate * 100 || 0).toFixed(2)}%`, style: leftAlignStyle},
      {label: numberFormatter(taxesOwed).intCurrency},
    ],
  }))

  const incomeTotalRows = brackets.length > 0 && incomeType !== IncomeType.UNCATEGORIZED
    ? [{
      id: `${taxType}-${incomeType}-${scenarioNumber}-total`,
      cells: [
        {
          label: subcategoryCopy
            .replace('[INCOME_TYPE]', SUBCATEGORY_DISPLAY[incomeType])
            .replace('[TOTAL_INCOME]', numberFormatter(totalIncome).intCurrency),
          colSpan: 4,
          style: lineItemStyle,
        },
        {
          label: numberFormatter(totalPayable || 0).intCurrency,
          style: lineItemStyle,
        },
      ],
    }]
    : []

  const rowsOverThreshold = overThreshold.map(({
    bracketNumber,
    bracket,
    amountInBracket,
    rate,
    taxesOwed,
  }) => ({
    id: `${taxType}-${incomeType}-${scenarioNumber}-under-threshold`,
    cells: [
      {label: `${bracketNumber}.` || ''},
      {
        label: bracket[1]
          ? `${numberFormatter(bracket[0]).intCurrency} - ${numberFormatter(bracket[1]).intCurrency}`
          : `Over ${numberFormatter(bracket[0]).intCurrency}`,
        style: leftAlignStyle,
      },
      {
        label: taxesOwedNotApplicable ? '$0' : numberFormatter(amountInBracket).intCurrency,
        style: leftAlignStyle,
      },
      {label: `${(rate * 100 || 0).toFixed(2)}%`, style: leftAlignStyle},
      {label: numberFormatter(taxesOwed).intCurrency},
    ],
  }))

  return [
    ...rowsUnderThreshold,
    ...incomeTotalRows,
    ...rowsOverThreshold,
  ]
}

export default function TaxBracketTable({
  bracketThreshold = 0,
  breakdown,
  // todo (Damjan) delete once rsu bugs are resolved and if it it turns out these params are unnecessary
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deductions = 0,
  result,
  scenarioNumber,
  taxType,
  // todo (Damjan) delete once rsu bugs are resolved and if it it turns out these params are unnecessary
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  incomePriorExerciseNSO,
  isAMT = false,
}: Props): JSX.Element {
  const isLongTermCap = [TaxBracketType.FEDERAL_LTCG, TaxBracketType.STATE_LTCG].includes(taxType)

  const regularIncome = result.taxBreakdown.federal.ordinaryIncome.adjustedGrossIncome

  const rowsByIncomeType = useMemo(() => numberedTaxRates(breakdown.taxRates).reduce((res, taxRate) => {
    let incomeType = INCOME_TYPE_MAP[taxRate.subCategory] as string
    // for long term cap gains displaying the ordinary income label
    // find the regular income brackets and label it as ordinary income
    if (
      isLongTermCap
      && taxRate.bracket[0] < regularIncome
      && breakdown.effectiveTaxRate
    ) {
      // if there is no taxes owed by LTCG in this bracket
      // then it is safe to label as ordinary income
      if (!taxRate.amountInBracket) {
        incomeType = IncomeType.ORDINARY
        // if there is LTCG owed in this bracket and ordinary income
        // fits into this bracket then we need to duplicate this row
        // label it as ordinary income
        // and insert it back into the bracket
      } else {
        res[IncomeType.ORDINARY] = [
          ...(res[IncomeType.ORDINARY] || []),
          {...taxRate, amountInBracket: 0, taxesOwed: 0},
        ]
      }
    }

    return {
      ...res,
      [incomeType]: [...(res[incomeType] as TaxRate[] || []), taxRate],
    }
  }, {} as Record<IncomeType, NumberedTaxRate[]>), [breakdown, isLongTermCap, regularIncome])

  const numberedRowsByIncome = reduceTaxBracketsOfTheSameNumberInType(rowsByIncomeType)
  const totalsByIncome = Object.entries(numberedRowsByIncome).reduce((accu, incomeRowPair) => ({
    ...accu,
    [incomeRowPair[0]]: incomeRowPair[1].reduce((subAccu, record) => subAccu + record.amountInBracket, 0),
  }), {} as {string: number})

  const totalIncome = Object.values(totalsByIncome).reduce((accu, value) => value + accu, 0)

  const bodyRows = useMemo(() => (
    [
      ...buildIncomeCategoryRows(
        numberedRowsByIncome.ORDINARY,
        IncomeType.ORDINARY,
        scenarioNumber,
        taxType,
        isLongTermCap,
        isLongTermCap ? regularIncome : totalsByIncome[IncomeType.ORDINARY],
        isLongTermCap ? regularIncome : totalsByIncome[IncomeType.ORDINARY],
        isAMT,
      ),
      ...buildIncomeCategoryRows(
        numberedRowsByIncome.VESTING,
        IncomeType.VESTING,
        scenarioNumber,
        taxType,
        isLongTermCap,
        totalsByIncome[IncomeType.VESTING],
        totalsByIncome[IncomeType.VESTING],
        isAMT,
      ),
      ...buildIncomeCategoryRows(
        numberedRowsByIncome.NSO_EXERCISE,
        IncomeType.NSO_EXERCISE,
        scenarioNumber,
        taxType,
        false,
        totalsByIncome[IncomeType.NSO_EXERCISE],
        (
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          (totalsByIncome[IncomeType.ORDINARY] || 0)
          + (totalsByIncome[IncomeType.NSO_EXERCISE] || 0)
        ),
        isAMT,
      ),
      ...buildIncomeCategoryRows(
        numberedRowsByIncome.SALE,
        IncomeType.SALE,
        scenarioNumber,
        taxType,
        false,
        totalsByIncome[IncomeType.SALE],
        totalIncome,
        isAMT,
      ),
      ...buildIncomeCategoryRows(
        numberedRowsByIncome.UNCATEGORIZED,
        IncomeType.UNCATEGORIZED,
        scenarioNumber,
        taxType,
        false,
        0,
        0,
        isAMT,
      ),
    ]
  ), [
    regularIncome,
    totalsByIncome,
    totalIncome,
    numberedRowsByIncome,
    isLongTermCap,
    scenarioNumber,
    taxType,
    isAMT,
  ])

  const headerRows = [{
    id: `${taxType}${scenarioNumber}-header`,
    cells: [
      {label: ''}, // empty space for number cell
      {label: l.INCOME_BETWEEN[taxType] as string, style: leftAlignStyle},
      {label: l.TAXES_FILLED[taxType] as string, style: leftAlignStyle},
      {label: l.TAX_RATE, style: leftAlignStyle},
      {label: l.TAXES_OWED},
    ],
  }]

  const footerRows = [
    {
      id: `${taxType}${scenarioNumber}-effective-rate`,
      cells: [
        {
          colSpan: 3,
          label: l.TOTAL_TAX[taxType] as string,
        },
        {
          label: `${(breakdown.effectiveTaxRate * 100 || 0).toFixed(2)}%`,
          style: leftAlignStyle,
        },
        {
          label: numberFormatter(breakdown.taxesOwed).intCurrency,
        },
      ],
    },
  ]

  const titlePrefix = [TaxBracketType.STATE_ORDINARY, TaxBracketType.STATE_LTCG].includes(taxType)
    ? `${result.taxProfile.states[0]} `
    : ''
  const title = l.BRACKET_TITLE[taxType] as string
  const titleSuffix = [TaxBracketType.FEDERAL_ORDINARY, TaxBracketType.STATE_ORDINARY].includes(taxType)
    ? l.FILING_STATUS.replace('[FILING_STATUS]', FILING_STATUS_LABELS[result.taxProfile.filingStatus])
    : ''
  const disclaimer = (bracketThreshold > 0) && (totalIncome >= bracketThreshold)
    ? (l.THRESHOLD_DISCLAIMER[taxType] as string)
      .replace('[THRESHOLD]', numberFormatter(bracketThreshold).intCurrency)
    : ''
  return (
    <GrantTable
      isOrdinary={taxType === TaxBracketType.FEDERAL_ORDINARY}
      id={`${taxType}${scenarioNumber}-table`}
      bodyRows={bodyRows}
      footerRows={footerRows}
      footNote={disclaimer}
      headerRows={headerRows}
      title={`${titlePrefix}${title || ''}${titleSuffix}`}
    />
  )
}
