import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type Props = {
  children: React.ReactNode;
}

type EquityInsightsType = {
  setShowEditLiquidityEvent: Dispatch<SetStateAction<boolean>>;
  showEditLiquidityEvent: boolean;
}

const EquityInsightsContext = createContext<EquityInsightsType>({} as EquityInsightsType)
const useEquityInsights = (): EquityInsightsType => useContext(EquityInsightsContext)

const EquityInsightsProvider = ({children}: Props): JSX.Element => {
  const [showEditLiquidityEvent, setShowEditLiquidityEvent] = useState(false)

  const value = {
    setShowEditLiquidityEvent,
    showEditLiquidityEvent,
  }
  return (
    <EquityInsightsContext.Provider value={value}>
      {children}
    </EquityInsightsContext.Provider>
  )
}

export {
  EquityInsightsProvider,
  useEquityInsights,
}
