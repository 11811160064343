/* eslint-disable max-len */
import ky, {Options} from 'ky'
import Cookie from 'js-cookie'

const api = ky.extend({
  prefixUrl: '/api',
  hooks: {
    beforeRequest: [
      (request): void => request.headers.set('X-CSRFToken', Cookie.get('csrftoken') ?? ''),
    ],
  },
})

export default {
  get: <T>(url: string, options: Options = {}): Promise<T> => api.get(url, options).json(),
  post: <T, P>(url: string, body?: P, options: Options = {}): Promise<T> => api.post(url, {...options, json: body ?? {}}).json(),
  put: <T, P>(url: string, body?: P, options: Options = {}): Promise<T> => api.put(url, {...options, json: body ?? {}}).json(),
  patch: <T, P>(url: string, body?: P, options: Options = {}): Promise<T> => api.patch(url, {...options, json: body ?? {}}).json(),
  delete: <T>(url: string, options: Options = {}): Promise<T> => api.delete(url, options).json(),
}
