import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import l from '../../ScenarioResults/locale'
import {taxBreakdownCardLocale as locale} from '../locale'

import {
  ScenarioResultType,
  TaxGrantExerciseSummary,
  TaxScenario,
  TaxScenarioResult,
  TaxTypeTotals,
} from 'models'
import {ScenarioOrdering} from 'store/scenario'

export type ScenarioData = {
  grossProceeds: number;
  exerciseCost: number;
  scenario: TaxScenario;
  scenarioType: ScenarioResultType;
  taxTotalsFromExercise: Nullable<TaxTypeTotals>;
  taxTotalsFromSale: TaxTypeTotals;
}

type Props = {
  children: React.ReactNode;
  scenarioResultsData: ScenarioData[];
}

type ScenarioContextType = {
  scenario: TaxOverviewScenarioData;
  isOpen: boolean;
  setScenarioData: (scenario: TaxOverviewScenarioData) => void;
  setIsOpen: (f: boolean) => void;
  handleSetScenario: (i: number, exercise: boolean) => void;
  handleToggleFlyout: () => void;
}

type TaxOverviewScenarioData = {
  isExercise: boolean;
  noSharesSold: boolean;
  result: TaxScenarioResult;
  purchaseDate: string;
  saleDate: string;
  taxYear: number;
  taxTotalsFromEvent: TaxTypeTotals;
  title: string;
  taxEventDescription: string;
  exerciseSummaryData: TaxGrantExerciseSummary[] | undefined;
  scenarioNumber: number;
  overrideScenarioResult: Nullable<TaxScenarioResult>;
  scenarioType: ScenarioResultType;
}

const ScenarioContext = createContext<ScenarioContextType>({} as ScenarioContextType)

const ScenarioFlyoutProvider = ({children, scenarioResultsData}: Props): JSX.Element => {
  const [scenario, setScenarioData] = useState({} as TaxOverviewScenarioData)
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleFlyout = useCallback(() => {
    setIsOpen((open) => !open)
  }, [])

  const handleSetScenario = useCallback((order: number, exercise: boolean) => {
    const scenariosArray = scenarioResultsData
      .map(({scenarioType, ...rest}) => ({
        ...rest,
        scenarioType,
        order: ScenarioOrdering[scenarioType],
      }))

    const scenarioData = scenariosArray.find((sc) => sc.order === order)

    if (scenarioData) {
      const purchaseDate = new Date(scenarioData.scenario.purchaseDate)
      const saleDate = new Date(scenarioData?.scenario.saleDate)
      const noSharesSold = scenarioData?.scenario.grantSummary.sale
        .every(({sharesSoldForScenario}) => sharesSoldForScenario === 0)

      setScenarioData({
        isExercise: exercise,
        noSharesSold,
        result: exercise ? scenarioData.scenario[purchaseDate.getFullYear()]
          : scenarioData.scenario[saleDate.getFullYear()],
        overrideScenarioResult: exercise ? scenarioData.scenario[saleDate.getFullYear()] : null,
        purchaseDate: scenarioData.scenario.purchaseDate,
        saleDate: scenarioData.scenario.saleDate,
        taxYear: exercise ? purchaseDate.getFullYear() : saleDate.getFullYear(),
        taxTotalsFromEvent: exercise
          ? scenarioData.taxTotalsFromExercise ?? {} as TaxTypeTotals
          : scenarioData.taxTotalsFromSale,
        title: exercise ? locale.TAXES_FROM_EXERCISE : locale.TAXES_FROM_SALE,
        taxEventDescription: exercise ? l.TAX_OVERVIEW.FROM_EXERCISE_DESCRIPTION : l.TAX_OVERVIEW.FROM_SALE_DESCRIPTION,
        exerciseSummaryData: exercise ? scenarioData.scenario.grantSummary.purchase : undefined,
        scenarioNumber: order,
        scenarioType: scenarioData.scenarioType,
      })
    }
  }, [scenarioResultsData])

  const value = {
    scenario,
    isOpen,
    setScenarioData,
    setIsOpen,
    handleToggleFlyout,
    handleSetScenario,
  }
  return (
    <ScenarioContext.Provider value={value}>
      {children}
    </ScenarioContext.Provider>
  )
}

const useScenarioFlyout = (): ScenarioContextType => useContext(ScenarioContext)

export {
  ScenarioFlyoutProvider,
  useScenarioFlyout,
}
