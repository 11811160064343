import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query'
import Cookie from 'js-cookie'

import {showDefaultErrorAlert} from '../alert/actions'

const baseQuery = retry(fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers) => {
    headers.set('X-CSRFToken', Cookie.get('csrftoken') ?? '')
    return headers
  },
}), {maxRetries: 1})

// A custom query wrapper that dispatches a global error action
// when the extraOptions.triggerGlobalError flag is set to true.
const customQuery: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError,
{triggerGlobalError?: boolean; maxRetries?: number}
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)

  if (result.error && extraOptions?.triggerGlobalError) {
    api.dispatch(showDefaultErrorAlert())
  }

  return result
}

export default customQuery
