/* eslint-disable max-len */
import React from 'react'

type Props = {
  fill?: string;
  size?: string | number;
}

export default function CaretDown(
  {
    fill = '#082042',
    size = 20,
    ...rest
  }: Props,
): JSX.Element {
  return (
    <svg height={size} width={size} viewBox="0 0 20 20" {...rest}>
      <path fill={fill} fillRule="evenodd" d="M4.293 7.293a1 1 0 011.414 0L9.5 11.086l3.793-3.793a1 1 0 111.414 1.414L9.5 13.914 4.293 8.707a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>
  )
}
