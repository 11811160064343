/* eslint-disable max-len */
import {createSelector} from '@reduxjs/toolkit'
import {
  formatDistanceToNowStrict,
  parseISO,
} from 'date-fns'
import {utcToZonedTime} from 'date-fns-tz'

import {
  AccountCategory, ItemConnectionStatus, PlaidAccount, PlaidAccountBalance, PlaidItem, PlaidLinkToken,
} from './types'

import {apiSlice} from 'store/main/apiSlice'
import {showDefaultErrorAlert, showDefaultSuccessAlert} from 'store/alert/actions'

const assetCategories = [AccountCategory.INVESTMENT, AccountCategory.DEPOSITORY, AccountCategory.OTHER]

export const financeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBalances: builder.query<PlaidItem[], void>({
      query: () => '/v1/financials/plaid_balance/',
      transformResponse: (balances: PlaidAccountBalance[]): PlaidItem[] => Object.values(
        balances.reduce((acc, {account: {item, ...rest}}) => {
          if (acc[item.itemId]) {
            acc[item.itemId].accounts.push({...rest})
          } else {
            acc[item.itemId] = {
              ...item,
              lastSyncFromNow: formatDistanceToNowStrict(
                utcToZonedTime(
                  parseISO(item.lastSuccessfulUpdateTime),
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ),
              ),
              id: item.itemId,
              accounts: [{...rest}],
            }
          }
          return acc
        }, {} as {[itemId: string]: PlaidItem}),
      ) || [],
      providesTags: ['PlaidItem'],
    }),
    updatePlaidItem: builder.mutation<void, {itemId: string | number}>({
      query: ({itemId}) => ({
        url: '/v1/financials/sync_item/',
        method: 'POST',
        body: {itemId},
      }),
      invalidatesTags: ['PlaidItem', 'Entity'],
    }),
    deletePlaidItem: builder.mutation<void, string>({
      query: (itemId) => ({
        url: '/v1/financials/remove_item/',
        method: 'DELETE',
        body: {itemId},
      }),
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled
          dispatch(showDefaultSuccessAlert('institution', 'removed'))
        } catch (e) {
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['PlaidItem', 'Entity'],
    }),
    updateAccount: builder.mutation<PlaidAccount, Pick<PlaidAccount, 'id'> & Partial<PlaidAccount>>({
      query: ({id, ...rest}) => ({
        url: `/v1/financials/account/${id}/`,
        method: 'PATCH',
        body: {...rest},
      }),
      onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled
          // TODO(zare): Move category update
          const keys = Object.keys(arg)
          if (keys.includes('customName')) {
            dispatch(showDefaultSuccessAlert('account', 'renamed'))
          }

          if (keys.includes('closedFromApplication')) {
            dispatch(showDefaultSuccessAlert('account', `${arg.closedFromApplication ? 'closed' : 're-opened'}`))
          }
        } catch (e) {
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['PlaidItem', 'Entity'],
    }),
    deleteAccount: builder.mutation<void, string>({
      query: (accountId) => ({
        url: `/v1/financials/account/${accountId}/`,
        method: 'DELETE',
      }),
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled
          dispatch(showDefaultSuccessAlert('account', 'removed'))
        } catch (e) {
          dispatch(showDefaultErrorAlert())
        }
      },
      invalidatesTags: ['PlaidItem', 'Entity'],
    }),
    createPlaidLink: builder.mutation<PlaidLinkToken, {id: string; redirectUri?: string}>({
      query: ({id, redirectUri}) => ({
        url: '/v1/financials/plaid_token/',
        method: 'POST',
        body: {itemId: id, redirectUri},
      }),
    }),
    exchangeToken: builder.mutation<void, {publicToken: string; accountIds: string[]}>({
      query: ({publicToken, accountIds}) => ({
        url: '/v1/financials/exchange_token/',
        method: 'POST',
        body: {publicToken, accountIds},
      }),
      invalidatesTags: ['PlaidItem', 'Entity'],
    }),
  }),
})

export const {
  useGetAllBalancesQuery,
  useLazyGetAllBalancesQuery,
  useUpdateAccountMutation,
  useUpdatePlaidItemMutation,
  useDeletePlaidItemMutation,
  useDeleteAccountMutation,
  useCreatePlaidLinkMutation,
  useExchangeTokenMutation,
} = financeApiSlice

// TODO(zare): Move selectors to selectors.ts
export const plaidItemsSelector = financeApiSlice.endpoints.getAllBalances.select()
export const selectAllPlaidItems = createSelector(plaidItemsSelector, ({data: plaidItems}) => plaidItems || [])

export const selectBankAndBrokeragesItemsFormPlaidList = createSelector(
  plaidItemsSelector,
  ({data: plaidItems}) => plaidItems?.map(({accounts, ...rest}) => ({
    ...rest,
    accounts: accounts.filter(({
      customCategory, harnessCategory,
    }) => (customCategory ? assetCategories.includes(customCategory) : assetCategories.includes(harnessCategory)))
      .sort((a, b) => a.customName.localeCompare(b.customName)),
  })),
)

export const selectNumberOfBankAndBrokerageAccounts = createSelector(
  selectBankAndBrokeragesItemsFormPlaidList,
  (plaidItems): number => plaidItems?.reduce((acc, curr) => (acc + curr.accounts.length), 0) || 0,
)

export const selectCreditAndLoansItemsFormPlaidList = createSelector(
  plaidItemsSelector,
  ({data: plaidItems}) => plaidItems?.map(({accounts, ...rest}) => ({
    ...rest,
    accounts: accounts.filter(({
      customCategory, harnessCategory,
    }) => (customCategory ? !assetCategories.includes(customCategory) : harnessCategory && !assetCategories.includes(harnessCategory)))
      .sort((a, b) => a.customName.localeCompare(b.customName)),
  })),
)

export const selectNumberOfCreditAndLoanAccounts = createSelector(
  selectCreditAndLoansItemsFormPlaidList,
  (plaidItems): number => plaidItems?.reduce((acc, curr) => (acc + curr.accounts.length), 0) || 0,
)

export const selectNumberOfDisconnectedPlaidItems = createSelector(
  selectAllPlaidItems,
  (plaidItems): number => plaidItems.reduce((acc, curr) => {
    if (curr.status === ItemConnectionStatus.USER_ACTION_REQUIRED) {
      // eslint-disable-next-line no-param-reassign
      acc += curr.accounts.length
    }
    return acc
  }, 0),
)
