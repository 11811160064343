import HttpClient, {FetchResponse} from '../helpers/httpClient'

export type PartialCompanyWithId = Partial<Company> & {id: string}

export type Company = {
  id: string;
  name: string;
  numberOfGrants: number;
  hasBeenEmployed: boolean;
  employmentEndDate?: string | null;
  postEmploymentOptionExpiration?: number | null;
  closedFromApplication: string | null; // date
  shouldDisplayMarketplaceCta: boolean;
};

export type CompanyPricingInfo = {
  id: string;
  pricingDate: string;
  priceType: PriceType;
  price: number;
}

export type CompanyPricingInfoCreateUpdate = Omit<CompanyPricingInfo, 'id'>

export type GrantLifecycleData = {
  vestedAwardsValue: number;
  vestedAwardsQuantity: number;
  unvestedAwardsValue: number;
  unvestedAwardsQuantity: number;
  '409Valuation': number;
  totalEquityQuantity: number;
  totalEquityValue: number;
  totalExercisableQuantity: number;
  totalExercisableValue: number;
  totalVestedExercisableQuantity: number;
  totalVestedExercisableValue: number;
  totalExercisedQuantity: number;
  totalExercisedValue: number;
  totalValueObtained: number;
  canceledOptions: number;
  canceledShares: number;
  date: string;
}

export type CompanyLifecycleData = GrantLifecycleData & {
  totalDirectValue: number;
  totalDirectQuantity: number;
  totalSaleValueDirect: number;
  totalSaleQuantityDirect: number;
  unvestedOptionQuantity: number;
  unvestedRsaQuantity: number;
  unvestedAwardsQuantity: number;
  unvestedSharesQuantity: number;
  unvestedRsuQuantity: number;
  vestedRsuQuantity: number;
  vestedRsaQuantity: number;
  totalSaleQuantityRsa: number;
  totalSaleQuantityRsu: number;
  totalSaleQuantityExercised: number;
  vestedOptionQuantity: number;
}

export enum GrantType {
  RSU = 'RSU',
  RSA = 'RSA',
  EQUITY = 'EQUITY',
}

export enum OptionType {
  ISO = 'INCENTIVE_STOCK',
  NON_QUALIFIED = 'NON_QUALIFIED',
  NA = '', // todo: confirm why this is needed
}

export enum OptionsStatus {
  OPTIONS_CANCELLED = 'OPTIONS_CANCELLED',
  OPTIONS_NOT_VESTED = 'OPTIONS_NOT_VESTED',
  OPTIONS_EXPIRED = 'OPTIONS_EXPIRED',
}

export const OptionTypeLabels: Readonly<Record<OptionType, string>> = {
  INCENTIVE_STOCK: 'ISO',
  NON_QUALIFIED: 'NSO',
  [OptionType.NA]: 'N/A', // todo: confirm why this is needed
}

export enum ScenarioType {
  ISO_EXERCISE = 'ISO_EXERCISE',
  SHARE_SALE = 'SHARE_SALE',
  DIRECT = 'DIRECT',
}

export type Grant = {
  id: string;
  company: string;
  grantType: GrantType;
  grantDate: string;
  isOption: boolean;
  optionType?: OptionType;
  perShareCostBasis?: number;
  quantity: number;
  shareClass: ShareClass;
  vestingCliff?: VestingCliff;
  vestingStartDate: string;
  vestingCadence: VestingCadence;
  vestingPeriod: number;
  isEarlyExercise?: boolean;
  expirationDate?: Nullable<string>;
  isDoubleTrigger?: boolean;
};

export type Scenario = {
  id: string;
  company: string;
  grant: Nullable<string>;
  parent?: Nullable<string>;
  scenarioDate: string;
  scenarioType: ScenarioType;
  quantity: number;
  price: number;
  perShareCostBasis: number;
  isHypothetical: boolean;
  isQsbsEligible: boolean;
  shareClass: ShareClass;
  childScenarios?: Scenario[];
}

export type CreateScenarioBody = Omit<Scenario, 'id'>
export type CreateGrantBody = Omit<Grant, 'id'>

export enum PriceType {
  '409A' = '409A',
  PREFERRED = 'PREFERRED_PRICE',
}

export enum ShareClass {
  COMMON = 'COMMON',
  PREFERRED = 'PREFERRED',
}

export const ShareClassLabels = new Map<ShareClass, string>([
  [ShareClass.COMMON, 'Common'],
  [ShareClass.PREFERRED, 'Preferred'],
])

export enum VestingCliff {
  NONE = '',
  ONE_YEAR = 'YEARS_1',
}

export const VestingCliffLabels = new Map<VestingCliff, string>([
  [VestingCliff.NONE, 'None'],
  [VestingCliff.ONE_YEAR, '1 year'],
])

export enum VestingCadence {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}

export enum HoldingType {
  OPTIONS = 'OPTIONS',
  RSU_RSA = 'RSU_RSA',
  DIRECT_INVESTMENTS = 'DIRECT_INVESTMENTS',
}

export async function findCompany(id: string): Promise<FetchResponse<Company>> {
  return HttpClient.get<Company>(`/v1/equity_liquidity/company/${id}/`)
}

/**
 * @deprecated use api.fetchCompanies instead
 */
export async function findCompanies(): Promise<FetchResponse<Company[]>> {
  return HttpClient.get<Company[]>('/v1/equity_liquidity/company/')
}

export async function findCompanyGrants(
  companyId: string,
): Promise<FetchResponse<Grant[]>> {
  return HttpClient.get<Grant[]>(
    `/v1/equity_liquidity/grant/?company_id=${companyId}`,
  )
}

export async function findGrant(companyId: string, grantId: string): Promise<FetchResponse<Grant>> {
  return HttpClient.get<Grant>(`/v1/equity_liquidity/grant/${grantId}/?company_id=${companyId}`)
}

export type FindScenariosOptions = {
  companyId?: string;
  grantId?: string;
  scenarioType?: ScenarioType;
}

export async function findScenarios(options?: FindScenariosOptions): Promise<FetchResponse<Scenario[]>> {
  const baseUrl = '/v1/equity_liquidity/scenario/'
  const queryParams: string[] = []

  if (options?.companyId) {
    queryParams.push(`company_id=${options.companyId}`)
  }

  if (options?.grantId) {
    queryParams.push(`grant_id=${options.grantId}`)
  }

  if (options?.scenarioType) {
    queryParams.push(`scenario_type=${options.scenarioType}`)
  }

  return HttpClient.get<Scenario[]>(`${baseUrl}?${queryParams.join('&')}`)
}

export async function findScenario(scenarioId: string): Promise<FetchResponse<Scenario>> {
  return HttpClient.get<Scenario>(`/v1/equity_liquidity/scenario/${scenarioId}/`)
}

export async function findCompanyPricingInfo(
  companyId: string,
): Promise<FetchResponse<CompanyPricingInfo[]>> {
  return HttpClient.get<CompanyPricingInfo[]>(
    `/v1/equity_liquidity/company/${companyId}/pricing/`,
  )
}

export async function findCompanyLifecycle(
  companyId: string,
): Promise<FetchResponse<CompanyLifecycleData[]>> {
  return HttpClient.get<CompanyLifecycleData[]>(`/v1/equity_liquidity/company/${companyId}/lifecycle/`)
}

export async function createCompany(
  company: Omit<Partial<Company>, 'id'>,
): Promise<FetchResponse<Company>> {
  return HttpClient.post('/v1/equity_liquidity/company/', company)
}

export async function deleteCompany(
  companyId: string,
): Promise<FetchResponse<void>> {
  return HttpClient.delete(`/v1/equity_liquidity/company/${companyId}/`)
}

export async function updateCompany(
  company: PartialCompanyWithId,
): Promise<FetchResponse<Company>> {
  return HttpClient.patch(
    `/v1/equity_liquidity/company/${company.id}/`,
    company,
  )
}

export async function createCompanyPricingInfo(
  companyId: string,
  pricingInfo: CompanyPricingInfoCreateUpdate,
): Promise<FetchResponse<CompanyPricingInfo>> {
  return HttpClient.post(`/v1/equity_liquidity/company/${companyId}/pricing/`, pricingInfo)
}

export async function updateCompanyPricingInfo(
  companyId: string,
  pricingInfoId: string,
  pricingInfo: CompanyPricingInfoCreateUpdate,
): Promise<FetchResponse<CompanyPricingInfo>> {
  return HttpClient.put(`/v1/equity_liquidity/company/${companyId}/pricing/${pricingInfoId}/`, pricingInfo)
}

export async function createGrant(
  grant: CreateGrantBody,
): Promise<FetchResponse<Grant>> {
  return HttpClient.post('/v1/equity_liquidity/grant/', grant)
}

export async function updateGrant(
  grant: Grant,
): Promise<FetchResponse<Grant>> {
  return HttpClient.patch(`/v1/equity_liquidity/grant/${grant.id}/`, grant)
}

export async function createScenario(
  scenario: CreateScenarioBody,
): Promise<FetchResponse<Scenario>> {
  return HttpClient.post('/v1/equity_liquidity/scenario/', scenario)
}

export async function updateScenario(
  scenario: Scenario,
): Promise<FetchResponse<Scenario>> {
  return HttpClient.patch(`/v1/equity_liquidity/scenario/${scenario.id}/`, scenario)
}

export async function deleteScenario(
  scenarioId: string,
): Promise<FetchResponse<void>> {
  return HttpClient.delete(`/v1/equity_liquidity/scenario/${scenarioId}/`)
}

export async function findGrantLifecycles(
  grantId: string,
): Promise<FetchResponse<GrantLifecycleData[]>> {
  return HttpClient.get(
    `/v1/equity_liquidity/grant/${grantId}/lifecycle/`,
  )
}

export async function deleteGrant(
  grantId: string,
): Promise<FetchResponse<void>> {
  return HttpClient.delete(`/v1/equity_liquidity/grant/${grantId}/`)
}
