import {PayloadAction, createAction} from '@reduxjs/toolkit'

import {DEFAULT_ERROR_MESSAGE} from './const'
import {AlertMessage, AlertMessageType} from './types'

export const addAlert = createAction<AlertMessage>('ADD_ALERT')
export const removeAlert = createAction('REMOVE_ALERT')

export const showSuccessAlert = (content: string): PayloadAction<AlertMessage> => addAlert({
  content,
  type: AlertMessageType.SUCCESS,
})

export const showErrorAlert = (
  content: string,
  hasRefreshBtn?: boolean,
  refreshMsg?: string,
): PayloadAction<AlertMessage> => addAlert({
  content,
  type: AlertMessageType.ERROR,
  hasRefreshBtn,
  refreshMsg,
})

/**
 * This function returns an action that shows a success alert with the standardized message.
 * Usage:
 *  - dispatch(showDefaultSuccessAlert('company', 'closed') => "Success! Your company has been closed."
 *  - dispatch(showDefaultSuccessAlert('account', 'connected') => "Success! Your account has been connected."
 *
 * If there's a need for a custom success message, there's a `showSuccessAlert` function that takes any string.
 */
export const showDefaultSuccessAlert = (
  entityType: string,
  action: string,
): PayloadAction<AlertMessage> => showSuccessAlert(`Success! Your ${entityType} has been ${action}.`)

export const showDefaultErrorAlert = (): PayloadAction<AlertMessage> => addAlert({
  content: DEFAULT_ERROR_MESSAGE,
  type: AlertMessageType.ERROR,
  hasRefreshBtn: true,
})

export const showWarningAlert = (content: string, preserve?: boolean): PayloadAction<AlertMessage> => addAlert({
  content,
  type: AlertMessageType.WARNING,
  preserve,
})

export const showBackupCodesAlert = (backupCodesLeft?: number): PayloadAction<AlertMessage> => addAlert({
  content: '',
  type: AlertMessageType.BACKUP_CODE_USED,
  preserve: true,
  data: {backupCodesLeft},
})
