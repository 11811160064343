import {HoldingType} from 'models'

export type NameFormValueType = {
  companyName: string;
}

export type PostEmploymentForm = {
  postEmploymentOptionExpiration: number | null;
}

export type EmploymentFormType = {
  companyName: string;
  hasBeenEmployed: string;
  stillEmployed: string;
  employmentEndDate: string;
}

export type CompanyValuationFormType = {
  price: number | undefined;
}

export type GrantTypeForm = {
  grantType: HoldingType | string;
}

export type AddCompanyFormType = {
  companyName: string;
  hasBeenEmployed: boolean;
  stillEmployed: boolean;
  employmentEndDate: string;
  postEmploymentOptionExpiration: number | null;
  price: number | undefined;
  priceType: string;
  pricingDate: string;
  grantType: HoldingType | string;
}

export enum ProgressStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'INPROGRESS',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export interface Tasks {
  equityTask: ProgressStatus;
  scenarioTask: ProgressStatus;
  accountsTask: ProgressStatus;
}

export type Company = {
  id: string;
  name: string;
  numberOfGrants: number;
  hasBeenEmployed: boolean;
  employmentEndDate?: string | null;
  postEmploymentOptionExpiration?: number | null;
  closedFromApplication: string | null; // date
  shouldDisplayMarketplaceCta: boolean;
  numberOfShareholdings: number;
  client: string; // uid
};
