import React, {ReactChild, forwardRef} from 'react'
import cx from 'classnames'

import Overline from '../display/Overline'

import styles from './_questionLayout.module.scss'

export type Props = {
  /** Overline content */
  overline?: string;

  instruction?: string;
  /** Question title */
  question: ReactChild;
  /** Question description text */
  description?: ReactChild;
  /** Question form instructions text */
  formInstructions?: string;
  /** Error text */
  error?: string;
  /** Cypress error tag */
  cyError?: string;
  /** Cypress header tag */
  cyHeader?: string;
  /** Content */
  children: JSX.Element;
  /** Widen content container */
  isWide?: boolean;
  /** Remove bottom margin */
  removeBottomMargin?: boolean;
}

const QuestionLayout = forwardRef(({
  overline = undefined,
  instruction,
  question,
  description = undefined,
  formInstructions = undefined,
  error = undefined,
  cyHeader = '',
  cyError = '',
  children,
  isWide = false,
  removeBottomMargin = false,
}: Props, ref: React.ForwardedRef<HTMLHeadingElement | null>): JSX.Element => (
  <div className={cx(
    styles.questionContainer,
    isWide && styles.wide,
    removeBottomMargin && styles.removeBottomMargin,
  )}
  >
    <div className={styles.questionHeader}>
      {overline && <Overline className={styles.overline} text={overline} />}
      {!!instruction && <p className={styles.instruction}>{instruction}</p>}
      <h2 ref={ref} className={styles.question} data-cy={cyHeader}>{question}</h2>
      {description && <span className={styles.description}>{description}</span>}
      {formInstructions && <span className={styles.formInstructions}>{formInstructions}</span>}
      {error && <span className={styles.error} data-cy={cyError}>{error}</span>}
    </div>
    <div className={styles.formContainer}>
      {children}
    </div>
  </div>
))

export default React.memo(QuestionLayout)
