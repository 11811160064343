import api from '../api'

import {UserSettings} from './types'

export const fetchUserSettings = (): Promise<UserSettings> => api.get('v1/authentication/user/')

export const saveUserSettings = (
  settings: Partial<UserSettings>,
): Promise<UserSettings> => api.patch('v1/authentication/user/', settings)

export const sendVerifyEmail = (redirect = '/'): Promise<void> => api.post('v1/authentication/send-verify-email/', {redirect})
