import isNil from 'lodash/isNil'

export class AppStorage {
  localStorage: Storage

  constructor(storage: Storage) {
    this.localStorage = storage
  }

  getItem = (key: string, defaultValue: any = null): any => {
    if (isNil(key)) return null

    const item = this.localStorage.getItem(key)

    return isNil(item) ? defaultValue : JSON.parse(item)
  }

  setItem = (key: string, data: unknown): void => {
    if (isNil(key) || isNil(data)) return

    this.localStorage.setItem(key, JSON.stringify(data))
  }

  clear = (): void => {
    this.localStorage.clear()
  }
}
