import * as React from 'react'
import ReactDOM from 'react-dom'
import {Router, useLocation} from 'react-router-dom'
import {Provider} from 'react-redux'
import {
  ErrorBoundary,
  reactRouterV5Instrumentation,
  init as sentryInit,
  setUser,
} from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import {TransactionContext} from '@sentry/types'
// eslint-disable-next-line import/no-extraneous-dependencies
import {createBrowserHistory} from 'history'
import {PersistGate} from 'redux-persist/integration/react'
import {ThemeProvider} from 'styled-components'

import App from './App'
import ErrorBoundaryBanner from './ErrorBoundaryBanner'
import {unregister} from './registerServiceWorker'

import 'core/styles/main.scss'
import 'core/styles/_scaffolding.scss'
import {
  AppSession,
  AppStorage,
  GoogleTagManagerAgent,
  MixpanelAgent,
} from 'core/utils'
import {configureAppStore} from 'store/main'
import config, {APP_ENVS} from 'core/config'
import {defaultTheme} from 'core/theme'
import {ToastProvider} from 'modules/toast'

const history = createBrowserHistory({basename: '/app'})
const {store, persistor} = configureAppStore()

sentryInit({
  dsn: config.SENTRY_HOST,
  normalizeDepth: 10,
  release: config.RELEASE_ID,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: reactRouterV5Instrumentation(history),
      beforeNavigate: (context: TransactionContext): TransactionContext | undefined => ({
        ...context,
        // on page load sentry records it with the /app prefix
        // on navigation it removes the /app prefix
        // strip away /app for consistency on navigation or page load
        name: context.name.replace(/^\/app\/*/g, ''),
      }),
    }),
  ],
  tracesSampleRate: config.SENTRY_TRACING_RATE,
})

if (config.MIXPANEL_ACTIVE) {
  MixpanelAgent.config(config.MIXPANEL_TOKEN || '')
}
GoogleTagManagerAgent.config(config.USE_GTM)

const storage = new AppStorage(localStorage)
const session = new AppSession(storage)
setUser({id: session.getClientId()})

function ScrollToTop(): null {
  const {pathname} = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={({error}): JSX.Element => (
        <ErrorBoundaryBanner error={error} />
      )}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <ScrollToTop />
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <ThemeProvider theme={defaultTheme}>
              <ToastProvider>
                <App session={session} storage={storage} />
              </ToastProvider>
            </ThemeProvider>
            {
              // https://cloud.google.com/iap/docs/sessions-howto
              // see Adding an Iframe
              config.ENV && [APP_ENVS.GCLOUD_STAGE, APP_ENVS.DEMO].includes(config.ENV)
              && (
                <iframe
                  title="GCP session refresh"
                  src="/_gcp_iap/session_refresher"
                  style={{
                    width: 0,
                    height: 0,
                    border: 'none',
                    display: 'none',
                  }}
                />
              )
            }
          </Router>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  (document.getElementById('root')),
)

unregister()
