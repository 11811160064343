import noop from 'lodash/noop'

export const GoogleTagManagerAgent = {
  pageView: noop,
  setHWId: noop,
  captureEvent: noop,
  setUserMetaData: noop,

  config(shouldUseGTM?: boolean): void {
    if (!shouldUseGTM) return

    this.setUserMetaData = (firstName: string, lastName: string, email: string): void => {
      (window.dataLayer || []).push({
        firstName,
        lastName,
        email,
      })
    }

    this.setHWId = (hwId: string, other = {}): void => {
      (window.dataLayer || []).push({
        user_id: hwId,
        ...other,
      })
    }

    this.captureEvent = (eventName: string, eventCategory: string, other = {}): void => {
      (window.dataLayer || []).push({
        ...other,
        event: eventName,
        event_category: eventCategory,
      })
    }
  },
}
