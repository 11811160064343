import React from 'react'
import cx from 'classnames'

import Icon, {IconName} from 'core/components/icon/Icon'

import {EducationTypes, TaxBreakdownEducationContainer} from '../TaxBreakdown/TaxBreakdownEducation'

import styles from './_table.module.scss'

export type GridRow = {
  label: string;
  value: string;
  notApplicable?: boolean;
  subrows?: GridRow[];
}

type Props = {
  /** Array of objects containing the column data for each row in the table. */
  rows: GridRow[];
  /** Description for the table. */
  title?: string;
  hasEducation?: boolean;
}

type DataRowProp = {
  label: string;
  value: string;
  isSubrow?: boolean;
}

function DataRow({label, value, isSubrow = false}: DataRowProp): JSX.Element {
  return (
    <tr className={cx(styles.row, isSubrow && styles.subrow)}>
      <th className={styles.cell}>{label}</th>
      <td className={styles.cell}>{value}</td>
      {!isSubrow && <td className={styles.cell} />}
    </tr>
  )
}

function CollapsibleRow({
  label, value, notApplicable, subrows,
}: GridRow): JSX.Element {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
  return (
    <>
      <tr
        className={cx(styles.row, styles.collapsibleRow)}
        key={label}
        role="button"
        aria-label={`${isExpanded ? 'Hide' : 'Show'} ${label}`}
        onClick={(): void => setIsExpanded((prev) => !prev)}
      >
        <th className={styles.cell}>{label}</th>
        <td className={cx(styles.cell, notApplicable && styles.mutedCell)}>{value}</td>
        <td className={styles.cell}>
          <Icon
            className={cx(styles.dropdownCaret, isExpanded && styles.expanded)}
            name={IconName.MenuBack}
            size={10}
          />
        </td>
      </tr>
      {isExpanded && (subrows ?? []).map((subrow) => (
        <DataRow
          key={subrow.label}
          label={subrow.label}
          value={subrow.value}
          isSubrow
        />
      ))}
    </>
  )
}

export default function TwoColumnTable(
  {
    rows,
    title,
    hasEducation = false,
  }: Props,
): JSX.Element {
  const footerRow = rows[rows.length - 1]
  const bodyRows = rows.slice(0, rows.length - 1)

  return (
    <div className={styles.wrapper}>
      {title && <div className={styles.title}>{title}</div>}
      {hasEducation && (
        <TaxBreakdownEducationContainer
          educationType={EducationTypes.SALE_FEDERAL_LONG_TERM_CAPITAL_GAINS_TAX}
          isBracket
        />
      )}
      <div className={styles.tableWrapper}>
        <table className={cx(styles.table, styles.twoColumnTable)}>
          <tbody className={styles.tableBody}>
            {bodyRows.map(({
              label, value, notApplicable, subrows,
            }) => (
              subrows?.length
                ? (
                  <CollapsibleRow
                    key={label}
                    label={label}
                    value={value}
                    notApplicable={notApplicable}
                    subrows={subrows}
                  />
                )
                : <DataRow key={label} label={label} value={value} />
            ))}
          </tbody>
          <tfoot className={styles.tableFooter}>
            <tr key={footerRow.label} className={styles.row}>
              <th className={styles.cell}>{footerRow.label}</th>
              <td colSpan={2} className={styles.cell}>{footerRow.value}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}
