/* eslint-disable max-len */
import {ScenarioResultType} from 'models'

export const SCENARIO_HEADER_COPY = {
  [ScenarioResultType.HOLD_OVER_A_YEAR]: {
    OVERLINE: 'Minimize Capital Gains Tax',
    ONLY_OPTIONS: {
      TITLE: 'Exercise today and hold 1+ year before selling in [SALE_MONTH] [SALE_YEAR]',
      DESCRIPTION: 'Hold shares for 1+ year to take advantage of long-term capital gains (less than short-term capital gains rate). You’ll need cash to cover the exercise and tax costs.',
      AFTER_ONE_YEAR: {
        TITLE: 'Exercise [PURCHASE_MONTH] [PURCHASE_YEAR] and hold 1+ year before selling in [SALE_MONTH] [SALE_YEAR]',
      },
      EXERCISE_ONLY: {
        TITLE: 'Exercise today and hold for at least 1 year before selling',
        DESCRIPTION: 'Hold shares for at least 1 year to take advantage of long-term capital gains (less than ordinary income tax rate). You’ll need cash to cover the exercise and tax costs.',
      },
    },
    ONLY_RSU: {
      TITLE: 'Sell shares that you have held for 1+ year',
      DESCRIPTION: 'Hold shares for 1+ year after they’ve vested to take advantage of long-term capital gains (less than short-term capital gains rate).',
    },
    MIXED: {
      TITLE: 'Exercise today and hold 1+ year before selling in [SALE_MONTH] [SALE_YEAR]',
      DESCRIPTION: 'Hold shares for a year after exercising (options) or vesting (RSUs) to qualify for the favorable long-term capital gains rate. You’ll need cash to cover the exercise and tax costs.',
    },
  },
  [ScenarioResultType.HOLD_UNDER_A_YEAR]: {
    OVERLINE: 'Minimize Investment Risk',
    ONLY_OPTIONS: {
      TITLE: 'Wait to exercise and sell in [SALE_MONTH] [SALE_YEAR]',
      DESCRIPTION: 'Exercise after going public to avoid concentrating assets in a single illiquid stock. Sell immediately to cover exercise costs & taxes.',
      EXERCISE_ONLY: {
        TITLE: 'Wait to exercise until you are ready to sell',
        DESCRIPTION: 'Exercise just before you sell to avoid concentrating assets in a single illiquid stock. Sell immediately to cover exercise costs & taxes. Profits from exercising will be taxed as ordinary income.',
      },
    },
    ONLY_RSU: {
      TITLE: 'Sell all vested shares immediately',
      DESCRIPTION: 'Sell all vested shares after going public to avoid concentrating assets in a single illiquid stock and to cover any additional taxes.',
    },
    MIXED: {
      TITLE: 'Wait to exercise and sell all vested shares in [SALE_MONTH] [SALE_YEAR]',
      DESCRIPTION: 'Exercise after going public to avoid concentrating assets in a single illiquid stock. Sell all vested shares immediately to cover exercise costs & taxes.',
    },
  },
  [ScenarioResultType.AMT_BREAKEVEN]: {
    OVERLINE: 'Minimize AMT',
    TITLE: 'Exercise without triggering AMT, then sell in [SALE_MONTH] [SALE_YEAR]',
    DESCRIPTION: 'Exercise ISO’s tax-free by exercising the max number of ISOs without exceeding the AMT threshold for the tax year. Hold for a year to take advantage of favorable capital gains tax rate.',
    EXERCISE_ONLY: {
      TITLE: 'Exercise without triggering AMT, and hold for least 1 year before selling',
      DESCRIPTION: 'Exercise ISO’s tax-free by exercising the max number of ISOs without exceeding the AMT threshold for the tax year. Hold for at least 1 year for the favorable long-term capital gains tax rate.',
    },
  },
}

export const SCENARIO_MENU_OPTIONS = {
  HOLD_OVER_A_YEAR: '<b>Minimize Regular Taxes:</b> Exercise and hold for 1+ year',
  HOLD_UNDER_A_YEAR: '<b>Minimize Investment Risk:</b> Wait to exercise and sell post-event',
  AMT_BREAKEVEN: '<b>Minimize AMT:</b> Exercise without triggering AMT',
  LIMIT_EXERCISE_COSTS: '<b>Limit Exercise Costs:</b> Exercise costs within a budget (coming soon)',
}

export const SCENARIO_MENU_OPTIONS_V2 = {
  HOLD_OVER_A_YEAR: '<b>Minimize Capital Gains Tax:</b> Exercise and hold for 1+ year',
  HOLD_UNDER_A_YEAR: '<b>Minimize Investment Risk:</b> Wait to exercise and sell post-event',
  AMT_BREAKEVEN: '<b>Minimize AMT:</b> Exercise without triggering AMT',
  LIMIT_EXERCISE_COSTS: '<b>Limit Exercise Costs:</b> Exercise costs within a budget (coming soon)',
}

export const SCENARIO_MENU_OPTIONS_RSU_ONLY = {
  HOLD_OVER_A_YEAR: '<b>Minimize Capital Gains Tax:</b> Hold for 1+ year before selling',
  HOLD_UNDER_A_YEAR: '<b>Minimize Investment Risk:</b> Sell all vested shares after lockup',
}

export const CASH_FLOW_SUBTEXT = {
  [ScenarioResultType.HOLD_UNDER_A_YEAR]: '*Sell immediately to cover [AMOUNT]',
  [ScenarioResultType.HOLD_OVER_A_YEAR]: 'Out of pocket',
  [ScenarioResultType.AMT_BREAKEVEN]: 'Out of pocket',
}

export const TAXES_FROM_EXERCISE_SUBTEXT = {
  QUALIFIED_ISO_AMT: 'Qualified ISOs: Alternative Minimum Tax',
  QUALIFIED_ISO_NO_AMT: 'Qualified ISOs: No Alternative Minimum Tax',
  DISQUALIFIED_ISO: 'Disqualified ISOs: Taxes assessed on sale',
  NSO: 'NSOs: Taxed as ordinary income',
  NO_TAX: 'No tax liability from exercise',
}

export const TAXES_FROM_SALE_SUBTEXT = {
  STCG: 'Short-term capital gains',
  LTCG: 'Long-term capital gains',
  NO_TAX: 'No tax liability from sale',
  AMT: 'Alternative minimum tax',
}

export const INVESTMENT_RISK = {
  [ScenarioResultType.HOLD_UNDER_A_YEAR]: {
    VALUE: 'Lower risk',
    SUBTEXT: 'Exercise when shares are liquid',
  },
  [ScenarioResultType.HOLD_OVER_A_YEAR]: {
    VALUE: 'Higher risk',
    SUBTEXT: 'Hold illiquid shares for 1+ years',
  },
  [ScenarioResultType.AMT_BREAKEVEN]: {
    VALUE: 'Higher risk',
    SUBTEXT: 'Hold illiquid shares for 1+ years',
  },
}
