import React from 'react'
import cx from 'classnames'

import Link from 'core/components/link/Link'
import ExternalLink from 'core/components/link/ExternalLink'
import MailtoLink, {MailToAddresses} from 'core/components/link/MailtoLink'
import Icon, {IconName} from 'core/components/icon/Icon'

import styles from './_app.module.scss'

import TwitterIcon from 'assets/icons/twitter.svg'
import LinkedinIcon from 'assets/icons/linkedin.svg'
import InstagramIcon from 'assets/icons/instagram.svg'
import config from 'core/config'
import HubspotChatButton from 'core/legacy-components/ChatButton/HubspotChatButton'
import {RoutePaths} from 'core/constants/RoutePaths'

function AppFooter(): JSX.Element {
  return (
    <footer id="harness-wealth-footer" className={cx(styles.appFooter)}>
      <div className={styles.footerInner}>
        <div className={styles.footerSocialLinks}>
          <ExternalLink
            to={window.location.origin}
            sameTab
            aria-label="visit harness homepage"
          >
            <Icon
              name={IconName.HWLogo}
              fill="white"
              size="24"
              className={cx(styles.mobileFooterLogo)}
            />
          </ExternalLink>
          <ExternalLink
            className={styles.socialLink}
            to={config.URL_TWITTER}
            aria-label="twitter social link"
          >
            <img className={styles.socialLinkLogo} alt="Twitter Icon" src={TwitterIcon} />
          </ExternalLink>
          <ExternalLink
            className={styles.socialLink}
            to={config.URL_LINKEDIN}
            aria-label="linkedin social link"
          >
            <img className={styles.socialLinkLogo} alt="LinkedIn Icon" src={LinkedinIcon} />
          </ExternalLink>
          <ExternalLink
            className={styles.socialLink}
            to={config.URL_INSTAGRAM}
            aria-label="instagram social link"
          >
            <img className={styles.socialLinkLogo} alt="Instagram Icon" src={InstagramIcon} />
          </ExternalLink>
        </div>
        <p className={cx(styles.footerItemTitle, styles.questionsHeader)}>
          Questions:
        </p>
        <div className={styles.questionRow}>
          <Link className={styles.footerLink} to={RoutePaths.FEEDBACK}>
            Product Feedback
          </Link>
          <HubspotChatButton fill={false} className={styles.footerLink} label="Chat with us" />
          <p className={cx(styles.footerItemTitle, styles.footerItemContact)} />
          <MailtoLink mailTo={MailToAddresses.CONCIERGE} className={styles.footerLink}>Email Us</MailtoLink>
          <div className={styles.footerItem}>(646) 586-5427</div>
        </div>
        <p className={styles.securityMessage}>
          If you believe you’ve found a security vulnerability in one of our products or platforms please send it to us by emailing
          {' '}
          <MailtoLink
            mailTo={MailToAddresses.SECURITY}
            className={styles.securityLink}
            intent="underlined"
          >
            {MailToAddresses.SECURITY}
            .
          </MailtoLink>
        </p>
        <hr className={styles.divider} />
        <ExternalLink
          to={window.location.origin}
          sameTab
          aria-label="visit harness homepage"
          className={styles.footerLogo}
        >
          <Icon
            name={IconName.HWLogo}
            fill="white"
            size="24"
          />
        </ExternalLink>
        <p className={cx(styles.footerItemTitle, styles.resourcesHeader)}>
          Resources:
        </p>
        <div className={styles.resources}>
          <div className={styles.resourcesRow}>
            <ExternalLink className={styles.footerLink} to={config.URL_ABOUT_US}>
              About Us
            </ExternalLink>
            <ExternalLink className={styles.footerLink} to={config.URL_ARTICLES}>
              Articles
            </ExternalLink>
            <ExternalLink className={styles.footerLink} to={config.URL_PRIVACY_POLICY}>
              Privacy Policy
            </ExternalLink>
            <ExternalLink className={styles.footerLink} to={config.URL_GLB_PRIVACY_NOTICE}>
              GLB Privacy Notice
            </ExternalLink>
            <ExternalLink className={styles.footerLink} to={config.URL_TERMS_OF_SERVICE}>
              Terms of Service
            </ExternalLink>
            <ExternalLink className={styles.footerLink} to={config.URL_CRS_FORM}>
              Form CRS
            </ExternalLink>
            <ExternalLink className={styles.footerLink} to={config.URL_CRS_DISCLOSURES}>
              Client Disclosures
            </ExternalLink>
            <ExternalLink className={styles.footerLink} to={config.URL_DISCLOSURES}>
              General Disclosures
            </ExternalLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default AppFooter
