import React from 'react'
import cx from 'classnames'

import Icon, {IconName} from 'core/components/icon/Icon'

import {
  EventQuarterLabels, EventType, EventTypeLabels, LiquidityEventWithResults,
} from '../../models'

import l from './locale'
import styles from './_results.module.scss'

import {Button, KebabMenu} from 'core/components'
import useCompanyPricingInfo from 'core/hooks/useCompanyPricingInfo'
import {convertPriceToFormInput} from 'core/formatters'

type Props = {
  companyId: string;
  eventWithResults: LiquidityEventWithResults;
  zipCode?: string;
  householdIncome: Nullable<number>;
  onToggleEditLiquidityEvent: () => void;
  onToggleEditTaxProfile: () => void;
  unsupportedStates?: string;
  liquidityEventDatePassed: boolean;
}

export const LiquidityEventSummary = ({
  companyId,
  eventWithResults,
  zipCode,
  householdIncome,
  onToggleEditLiquidityEvent,
  onToggleEditTaxProfile,
  unsupportedStates,
  liquidityEventDatePassed,
}: Props): JSX.Element => {
  const {current409A} = useCompanyPricingInfo(companyId)

  const eventDate = eventWithResults.eventType === EventType.EXERCISE_OPTIONS_ONLY
    ? l.SUMMARY.EVENT_DATE.replace('[EVENT_TYPE]', 'Sale')
    : l.SUMMARY.EVENT_DATE.replace('[EVENT_TYPE]', EventTypeLabels[eventWithResults.eventType])

  return (
    <div className={styles.liquidityEventSummary}>
      <div className={styles.summaryDetails}>
        {eventWithResults.eventYear && (
        <div className={styles.detail}>
          <span className={cx(styles.label, liquidityEventDatePassed && styles.unsupportedField)}>
            {eventDate}
            {liquidityEventDatePassed && <Icon name={IconName.Warning} size={11} />}
          </span>
          <div className={cx(styles.value, liquidityEventDatePassed && styles.unsupportedField)}>
            {EventQuarterLabels[eventWithResults.eventQuarter].basic}
            ,
            {' '}
            {eventWithResults.eventYear}
          </div>
          <Button
            fill={false}
            onClick={onToggleEditLiquidityEvent}
            underlined
          >
            {l.SUMMARY.EDIT_CTA}
          </Button>
        </div>
        )}
        <div className={styles.detail}>
          <span className={styles.label}>{l.SUMMARY.SALE_PRICE}</span>
          <div className={styles.value}>
            {convertPriceToFormInput(Number(eventWithResults.salePrice ?? 0), '$')}
          </div>
          <Button
            fill={false}
            onClick={onToggleEditLiquidityEvent}
            underlined
          >
            {l.SUMMARY.EDIT_CTA}
          </Button>
        </div>
        <div className={cx(styles.detail, styles.hideOnMobile)}>
          <span className={cx(styles.label, unsupportedStates && styles.unsupportedField)}>
            {l.SUMMARY.ZIP_CODE}
            {unsupportedStates && <Icon name={IconName.Warning} size={11} />}
          </span>
          <div className={cx(styles.value, unsupportedStates && styles.unsupportedField)}>
            {zipCode ?? 'N/A'}
          </div>
          <Button
            fill={false}
            onClick={onToggleEditTaxProfile}
            underlined
          >
            {l.SUMMARY.EDIT_CTA}
          </Button>
        </div>
        <div className={cx(styles.detail, styles.hideOnTablet, styles.hideOnMobile)}>
          <span className={styles.label}>{l.SUMMARY.PRETAX_INCOME}</span>
          <div className={styles.value}>
            {convertPriceToFormInput(Number(householdIncome ?? 0), '$')}
          </div>
          <Button
            fill={false}
            onClick={onToggleEditTaxProfile}
            underlined
          >
            {l.SUMMARY.EDIT_CTA}
          </Button>
        </div>
        <div className={cx(styles.detail, styles.hideOnMobile)}>
          <span className={styles.label}>{l.SUMMARY.CURRENT_409A}</span>
          <div className={styles.value}>
            {convertPriceToFormInput(Number(current409A?.price ?? 0), '$')}
          </div>
        </div>
      </div>

      <div className={styles.editScenario}>
        <Button
          bold
          underlined
          label="Edit liquidity event"
          onClick={onToggleEditLiquidityEvent}
          className={styles.editScenarioBtn}
        />
        <Button
          bold
          underlined
          label="Edit tax profile"
          onClick={onToggleEditTaxProfile}
          className={styles.editScenarioBtn}
        />
      </div>

      <div className={styles.editMenu}>
        <KebabMenu options={[
          {name: l.EDIT_MENU.LIQUIDITY_EVENT, onClick: onToggleEditLiquidityEvent},
          {name: l.EDIT_MENU.TAX_PROFILE, onClick: onToggleEditTaxProfile},
        ]}
        />
      </div>
    </div>
  )
}
