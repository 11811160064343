import React from 'react'
import styled from 'styled-components'

import ScenarioBreakdown from './ScenarioBreakdown'
import {BreakdownChart} from './BreakdownChart'

import {ScenarioType} from 'store/scenario'

type Props = {
  scenarioType: ScenarioType;
  isGrantRSUOnly: boolean;
}

const ScenarioDetails = ({scenarioType, isGrantRSUOnly}: Props): JSX.Element => (
  <Container>
    <ScenarioBreakdown scenarioType={scenarioType} />
    <BreakdownChart scenarioType={scenarioType} isShortGraph={isGrantRSUOnly} />
  </Container>
)

export default React.memo(ScenarioDetails)

const Container = styled.div(({theme}) => `
  display: grid;
  grid-template-rows: 1fr;

  border-top: 1px solid ${theme.colors.darkBlue100};

  ${theme.above(theme.breakpoints.aboveMobile)} {
    grid-template-columns: 1fr 1fr;
  }

  > div:last-child {
    padding-top: ${theme.spacing.s}
  }
`)
