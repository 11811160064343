import React from 'react'

import TwoColumnTable, {GridRow} from './TwoColumnTable'

import {numberFormatter} from 'core/formatters/numberFormatter'
import {TaxScenarioResult} from 'models/liquidityEvent'

function buildNIIRows({taxBreakdown: {netInvestmentIncome}}: TaxScenarioResult): GridRow[] {
  return [
    {
      label: 'Taxable long-term capital gains from sale',
      value: numberFormatter(netInvestmentIncome.grossCapGains).currency,
    },
    {
      label: '- Payable state long-term capital gains tax from sale',
      value: numberFormatter(netInvestmentIncome.stateTaxesOwed * -1).currency,
    },
    {
      label: 'Net Investment Income',
      value: numberFormatter(netInvestmentIncome.netInvestmentIncome).currency,
    },
  ]
}

export default function NIITable({result}: {result: TaxScenarioResult}): JSX.Element {
  const rows = React.useMemo(() => buildNIIRows(result), [result])
  return (
    <TwoColumnTable rows={rows} title="Net Investment Income" />
  )
}
