/* eslint-disable max-len */
import track from '../track'

type Milestones = {
  keyLifeEvents: string[];
  majorFinancialChanges: string[];
  milestonesAndWindfall: string[];
  otherMilestones: string;
}

type WindfallTiming = {
  windfallTiming: string[];
}

type WindfallAmount = {
  windfallAmountMax: number | null;
}

type CurrentSolutions = {
  otherPersonalFinancesManagement: string;
  personalFinancesManagement: string[];
}

enum EngagementState {
  OPEN = 'open',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
}

type ProductType = {
  'global_get-started_select-product_selected': string;
}

type SelectNeedType = {
  'global_get-started_select-need_selected': string;
}

type TaxPlanningType = {
  'global_get-started_select-equity-tax-planning-solution_selected': string;
}

type ReferralCode = {
  code: string;
  source: string;
}

type SignUpEntryProps = {
  quote: string;
}

export const commonAnalyticsEvents = {
  signUpEntry: (properties: SignUpEntryProps): void => track.all('pre-reg_signup_view', properties),
  /*
    Global Onboarding
    TODO(zare): All events are prefixed with profile, but are not part of profile onboarding and should be prefixed with global
  */
  globalOnboardingMilestonesSaved: (properties: Milestones): void => track.mixpanel('profile-milestones_next', properties),
  // the event is intentionally named 'profile-windfall_amount_next' even though we are passing just the timing and not the amount
  globalOnboardingWindfallTimingSaved: (properties: WindfallTiming): void => track.mixpanel('profile-windfall_amount_next', properties),
  globalOnboardingV2WindfallTimingSaved: (properties: WindfallTiming): void => track.mixpanel('profile-windfall_timing', properties),
  globalOnboardingWindfallAmountSaved: (properties: WindfallAmount): void => track.mixpanel('profile-windfall_amount_next', properties),
  globalOnboardingV2WindfallAmountSaved: (properties: WindfallAmount): void => track.mixpanel('profile-windfall_max-amount_next', properties),
  globalOnboardingCurrentSolutionsSaved: (properties: CurrentSolutions): void => track.mixpanel('profile-current-solutions_next', properties),
  globalOnboardingProductSelected: (properties: ProductType): void => track.mixpanel('global_get-started_select-product_click', properties),
  globalOnboardingGetStartedSelectNeed: (properties: SelectNeedType): void => track.mixpanel('global_get-started_select-need_click', properties),
  globalOnboardingTaxPlanningSelected: (properties: TaxPlanningType): void => track.mixpanel('global_get-started_select-equity-tax-planning-solution_click', properties),
  globalOnboardingCompleted: (): void => track.mixpanel('global_global-onboarding-completed'),
  globalOnboardingWindfallBack: (): void => track.mixpanel('profile-current-solutions_back'),
  globalOnboardingCurrentSolutionsBack: (): void => track.mixpanel('profile-current-solutions_back'),
  globalOnboardingGetStartedBack: (): void => track.mixpanel('get-started_back'),
  globalReferralCodeSubmitted: (properties: ReferralCode): void => track.mixpanel('global_referral_code_submitted', properties),
  balanceSheetEmailOptInClicked: (): void => track.mixpanel('global_account-settings_balance-sheet-email-opt-in_click'),
  globalHomeDashboardActiveSessionClick: (props?: {sessionState: EngagementState; incompleteTasks: number; activeTasks: number}): void => track.mixpanel('global_home-dashboard-active-session_click', props),
}
