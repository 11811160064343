import {
  combineReducers, createReducer, unwrapResult,
} from '@reduxjs/toolkit'
import {PersistConfig, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as UserAction from './actions'

// This id is tax profile id. In future login and register should return user id not user tax profile id
const userId = createReducer('', (builder) => {
  builder.addCase(UserAction.setUser, (_, action) => unwrapResult(action))
})

const plaidToken = createReducer('', (builder) => {
  builder.addCase(UserAction.setPlaidLinkToken, (_, action) => unwrapResult(action))
})

const userReducer = combineReducers({
  id: userId,
  plaidToken,
})

export type UserReducer = ReturnType<typeof userReducer>;
const persistConfig: PersistConfig<UserReducer> = {
  key: 'user',
  storage,
}

export default persistReducer(persistConfig, userReducer)
