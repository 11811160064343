import {EventQuarter, EventQuarterLabels} from 'models/liquidityEvent'
import {SelectOptions} from 'core/legacy-components/Form/types'

export const eventQuarterBasicOptions: SelectOptions<EventQuarter> = [{
  label: EventQuarterLabels[EventQuarter.Q1].basic,
  value: EventQuarter.Q1,
}, {
  label: EventQuarterLabels[EventQuarter.Q2].basic,
  value: EventQuarter.Q2,
}, {
  label: EventQuarterLabels[EventQuarter.Q3].basic,
  value: EventQuarter.Q3,
}, {
  label: EventQuarterLabels[EventQuarter.Q4].basic,
  value: EventQuarter.Q4,
}]
