import {GoogleTagManagerAgent, MixpanelAgent} from 'core/utils'

export default {
  all: <T extends KeyedObject<any> | undefined>(eventName: string, value?: T, gtmCategory = ''): void => {
    GoogleTagManagerAgent.captureEvent(eventName, gtmCategory, value)
    MixpanelAgent.track(eventName, value)
  },
  mixpanel: <T extends KeyedObject<any> | undefined>(eventName: string, value?: T): void => {
    MixpanelAgent.track(eventName, value)
  },
  google: <T>(eventName: string, category = '', value?: T): void => {
    GoogleTagManagerAgent.captureEvent(eventName, category, value)
  },
}
