export enum AccountCategory {
  DEPOSITORY = 'Depository',
  INVESTMENT = 'Investment',
  LOANS = 'Loans',
  OTHER = 'Other',
  REVOLVING = 'Revolving',
}

export enum ItemConnectionStatus {
  GOOD = 'good',
  USER_ACTION_REQUIRED = 'user_action_required',
  USER_ACTION_NOT_REQUIRED = 'user_action_not_required',
  NO_ACCOUNTS = 'no_accounts',
}

export enum InstitutionConnectionStatus {
  HEALTHY = 'HEALTHY',
  DEGRADED = 'DEGRADED',
  DOWN = 'DOWN',
}

export type PlaidAccount = {
  accountType: string;
  accountSubtype: string;
  harnessCategory: AccountCategory;
  id: string;
  name: string;
  officialName: string;
  customName: string;
  customCategory: Nullable<AccountCategory>;
  customAccountSubtype: string;
  closedFromApplication: string | null; // date
}

export type PlaidInstitution = {
  name: string;
  institutionId: string;
  statuses: {
    transactionsUpdates?: {
      status: InstitutionConnectionStatus;
      lastStatusChange: string; // date
    };
  };
}

export type PlaidItem = {
  accounts: PlaidAccount[];
  id: string;
  institution: PlaidInstitution;
  lastFailedUpdateTime: string;
  lastSuccessfulUpdateTime: string;
  lastSync: string;
  lastSyncFromNow: string;
  status: ItemConnectionStatus;
}

export type PlaidAccountBalance = {
  account: PlaidAccount & {
    item: PlaidItem & {
      itemId: string;
    };
  };
  balanceDate: string;
  currentBalanceLocal: number;
  currentBalanceUsd: number;
  availableBalance: number;
  currencyCode: string;
}

export type PlaidLinkToken = {
  linkToken: string;
}
