import HttpClient, {FetchResponse} from 'models/helpers/httpClient'
import {RoutePaths} from 'core/constants'

const exceptionOptions = {captureExceptions: true, ignoredStatusCodes: [400]}

type RegisterData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  linkedinId?: string;
  googleId?: string;
  referralCode?: string;
  origin?: string;
}

export type UserSessionData = {
  verify2fa?: boolean;
  has2faEnabled?: boolean;
  backupCodesLeft?: number;
  expiration: string;
  id: string;
  meta: {
    ipDashboardOverlay?: boolean;
    fmaDashboardOverlay?: boolean;
    hideIntroLandingPage?: boolean;
    hideFindAdviserDashboardPrequalificationMessage?: boolean;
    didCompleteShortOnboarding?: boolean;
    twoFactorDontRemind?: boolean;
  };
  status?: string;
}

type RegisterError = {
  readonly email?: string[];
  readonly password: string[];
  readonly nonFieldErrors: unknown;
  betaInviteCode?: string;
}

export async function register(data: RegisterData): Promise<FetchResponse<UserSessionData, RegisterError>> {
  return HttpClient.post<UserSessionData, RegisterError>('/v1/authentication/register/', data, {}, exceptionOptions)
}

type LoginError = {
  email?: string;
  detail?: string;
}

export async function logIn(
  email: string,
  password: string,
  invitePid?: string | null,
): Promise<FetchResponse<UserSessionData, LoginError>> {
  return HttpClient.post<UserSessionData, LoginError>(
    '/v1/authentication/login/', {email, password, invitePid}, {}, exceptionOptions,
  )
}

const LINKEDIN_URL = 'https://www.linkedin.com/oauth/v2/authorization'
const LINKEDIN_RESPONSE_TYPE = 'code'
const LINKEDIN_SCOPE = [
  'r_liteprofile',
  'r_emailaddress',
]

type LinkedInProfile = {
  firstName: {
    localized: {
      en_US: string;
    };
  };
  lastName: {
    localized: {
      en_US: string;
    };
  };
  id: string;
  emailAddress: string;
  hasExistingAccount: boolean;
  hasExistingEmail: boolean;
}

export function getLinkedInAuthURL(
  clientId: string,
  origin: string,
  state: string,
  redirectRoutePath: string,
): string {
  const queryArgs = {
    state,
    response_type: LINKEDIN_RESPONSE_TYPE,
    client_id: clientId,
    scope: LINKEDIN_SCOPE.join(' '),
    redirect_uri: `${origin}/app${redirectRoutePath}`,
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  const queryString = new URLSearchParams(queryArgs).toString()
  return `${LINKEDIN_URL}?${queryString}`
}

// TODO(raylen): this is poor naming - you aren't registering with linkedin
// TODO: this is getting the profile
export async function registerWithLinkedIn(
  authorizationCode: string,
  origin: string,
  redirectPath: string,
): Promise<FetchResponse<LinkedInProfile>> {
  const body = {
    authorizationCode,
    redirectUri: `${origin}/app${redirectPath}`,
  }

  return HttpClient.post<LinkedInProfile>(
    '/v1/authentication/profile/linkedin/', body, {}, exceptionOptions,
  )
}

export async function loginWithLinkedIn(
  authorizationCode: string,
  origin: string,
  invitePid?: string | null,
): Promise<FetchResponse<UserSessionData>> {
  const body = {
    authorizationCode,
    redirectUri: `${origin}/app${RoutePaths.LOGIN}`,
    invitePid,
  }

  return HttpClient.post<UserSessionData>(
    '/v1/authentication/login/linkedin/', body, {}, exceptionOptions,
  )
}

export async function loginWithGoogle(
  idToken: string,
  invitePid?: string | null,
): Promise<FetchResponse<UserSessionData>> {
  return HttpClient.post<UserSessionData>(
    '/v1/authentication/login/google/', {idToken, invitePid}, {}, exceptionOptions,
  )
}

export type GoogleProfile = {
  givenName: string;
  familyName: string;
  email: string;
  userId: string;
  hasExistingAccount: boolean;
  hasExistingEmail: boolean;
}

export async function getGoogleProfile(
  idToken: string,
): Promise<FetchResponse<GoogleProfile>> {
  return HttpClient.post<GoogleProfile>(
    '/v1/authentication/profile/google/', {idToken}, {}, exceptionOptions,
  )
}

type SessionToken = {
  sessionToken: string;
}

type ResetPasswordRequestContract = SessionToken & {
  password: string;
}

type ResetPasswordErrorResponse = Nullable<{
  detail?: string;
}>

export async function resetPassword(
  body: ResetPasswordRequestContract,
): Promise<FetchResponse<unknown, ResetPasswordErrorResponse>> {
  return HttpClient.post<unknown, ResetPasswordErrorResponse>('/v1/authentication/password/reset/', body)
}

export async function verifyPasswordToken(linkToken: string): Promise<FetchResponse<SessionToken>> {
  return HttpClient.post<SessionToken>('/v1/authentication/password/verify/', {linkToken})
}

export async function sendPasswordResetLinkToEmail(email: string): Promise<FetchResponse<unknown>> {
  return HttpClient.post<unknown>('/v1/authentication/password/reset_link/', {email})
}

export async function logOut(): Promise<FetchResponse<unknown>> {
  return HttpClient.post<unknown>('/v1/authentication/logout/')
}
