import {createApi} from '@reduxjs/toolkit/query/react'

import {Notification, NotificationType} from './types'

import baseQuery from 'store/main/baseQuery'

// cache tag used for invalidation
const NOTIFICATIONS = 'notifications'

export const notificationCenterApi = createApi({
  reducerPath: 'notificationCenterApi',
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [NOTIFICATIONS],
  endpoints: (builder) => ({
    getNotifications: builder.query<Notification[], void>({
      query: () => 'v1/notifications/',
      providesTags: [NOTIFICATIONS],
    }),
    deleteNotification: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/v1/notifications/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [NOTIFICATIONS],
    }),
    createNotification: builder.mutation<void, NotificationType>({
      query: (notificationType) => ({
        url: '/v1/notifications/',
        method: 'POST',
        body: {
          notificationType,
        },
      }),
      invalidatesTags: [NOTIFICATIONS],
    }),
  }),
})

export const {
  useGetNotificationsQuery,
  useDeleteNotificationMutation,
  useCreateNotificationMutation,
} = notificationCenterApi
