import {captureException} from '@sentry/react'
import {useCallback} from 'react'
import camelCase from 'lodash/camelCase'

import {useGetStringsQuery} from 'store/dynamic-strings'

type ReturnType = {
  strings: Record<string, string>;
  getString: (label: string) => string;
  originalArgs?: unknown;
  currentData?: Record<string, string>;
  error?: unknown;
  requestId?: string;
  endpointName?: string;
  startedTimeStamp?: number;
  fulfilledTimeStamp?: number;
  isUninitialized?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  isSuccess?: boolean;
  isError?: boolean;

  refetch: () => void;
}

export const usePageStrings = (app: string, page: string): ReturnType => {
  const appName = camelCase(app)
  const {strings, isSuccess, ...queryRest} = useGetStringsQuery(app, {
    selectFromResult: ({data, ...rest}): {
      strings: KeyedObject<string>;
      isSuccess: boolean;
    } => ({
      strings: data?.[appName]?.[page] ?? {},
      ...rest,
    }),
  })

  const getString = useCallback((label: string): string => {
    if (strings[label]) return strings[label]

    if (isSuccess) {
      captureException(
        new Error(
          `Label ${label} is missing from dynamic strings object, intended app: ${app}, intended page: ${page}`,
        ),
      )
    }

    return ''
  }, [strings, app, page, isSuccess])

  return {
    strings,
    getString,
    isSuccess,
    ...queryRest,
  }
}
