export enum TaxScenarioGrantType {
  RSU = 'RSU',
  RSA = 'RSA',
  INCENTIVE_STOCK = 'INCENTIVE_STOCK',
  NON_QUALIFIED = 'NON_QUALIFIED',
}

export enum ScenarioGrantMixType {
  ONLY_OPTIONS = 'ONLY_OPTIONS',
  ONLY_RSU = 'ONLY_RSU',
  MIXED = 'MIXED',
}

export enum ScenarioType {
  HOLD_UNDER_A_YEAR = 'HOLD_UNDER_A_YEAR',
  HOLD_OVER_A_YEAR = 'HOLD_OVER_A_YEAR',
  AMT_BREAKEVEN = 'AMT_BREAKEVEN',
}

export enum ScenarioNames {
  HOLD_UNDER_A_YEAR = 'Minimize investment risk',
  HOLD_OVER_A_YEAR = 'Minimize capital gains tax',
  AMT_BREAKEVEN = 'Minimize AMT',
}

export enum ScenarioNamesShort {
  HOLD_UNDER_A_YEAR = 'Min.investment risk',
  HOLD_OVER_A_YEAR = 'Min. capital gains tax',
  AMT_BREAKEVEN = 'Minimize AMT',
}

export interface TaxTotals {
  federal: number;
  federalOrdinary: number;
  federalLongTermCapGains: number;
  socialSecurity: number;
  medicare: number;
  netInvestmentIncome: number;
  state: number;
  stateOrdinary: number;
  stateLongTermCapGains: number;
  total: number;
  amt: number;
}

export enum Visibility {
  ISO_ONLY = 'ISO',
  NSO_ONLY = 'NSO',
  RSU_ONLY = 'RSU',
  ALWAYS = 'ALWAYS',
}

export enum OptionType {
  ISO = 'INCENTIVE_STOCK',
  NON_QUALIFIED = 'NON_QUALIFIED',
  NA = '',
}

export enum ScenarioOrdering {
  'HOLD_OVER_A_YEAR' = 1, // Minimize Capital Gains
  'AMT_BREAKEVEN' = 2,
  'HOLD_UNDER_A_YEAR' = 3, // Minimize Investment Risk
}

export interface Scenario {
  name: 'Minimize capital gains tax' | 'Minimize investment risk' | 'Minimize AMT';
  nameShort: ScenarioNamesShort;
  order: number;
  type: ScenarioType;
  grantType: ScenarioGrantMixType;
  isGrantRSUOnly: boolean;
  eventYear: number;
  netProfit: number;
  netProfitFormatted: string;
  isEmpty: boolean;
  optionsAllExpired: boolean;
  optionsNotVested: boolean;
  optionsCancelled: boolean;
  unvestedAwards: number;
  vestedOptions: number;
  unvestedSharesSum: number;
  vestedSharesSum: number;
  sharesSoldForScenarioSum: number;
  remainingEquity: number;
  remainingAwards: number;
  remainingEquityFormatted: string;
  optionTypes: OptionType[] | undefined;
  purchaseDate: {
    date: string;
    year: number;
  };
  saleDate: {
    date: string;
    year: number;
  };
  isLockupPeriodLongerThanYear: boolean;
  exercise: {
    cost: number; // exerciseCost
    costFormatted: string;
    taxTotals: TaxTotals | null;
    taxTotalsFormatted: string;
    optionsExercised: number;
    optionsExercisedFormatted: string;
    optionsExercisable: number;
    optionsLeft: number;
    summary: TaxGrantExerciseSummary[];
  };
  sale: {
    grossProceeds: number; // grossProceeds
    grossProceedsFormatted: string;
    taxTotals: TaxTotals;
    taxTotalsFormatted: string;
    sharesSold: number;
    sharesSoldFormatted: string;
    sharesLeft: number;
    sharesLeftFormatted: string;
    salePrice: number;
    summary: TaxGrantSaleSummary[];
  };
  rsu: {
    incomeTaxes: TaxTotals;
  };
  warnings: {
    doubleTriggerRSUsSupport?: number;
    unsupportedAssets?: {
      RSU: number;
      RSA: number;
    };
    optionsStatusType?: OptionsStatusType;
    noAvailableRSUsShares?: boolean;
    noLongTermCapitalGainsRSUs?: boolean;
  };
  unsupportedAssets: {
    RSU: number;
    RSA: number;
  };
  doubleTriggerRSUsVestedQuantity?: number;
}

export interface ScenarioGrantSummary {
  sale: TaxGrantSaleSummary[];
  purchase: TaxGrantExerciseSummary[];
}

export interface OptionsStatus {
  optionsVested: boolean;
  optionsNotExpired: boolean;
  optionsCancelled: boolean;
}

export type GrantSummary = {
  grantDate: string;
  grantType: TaxScenarioGrantType;
  vestedOptions: number;
  vestedShares: number;
  unvestedOptions: number;
  unvestedShares: number;
  cancelledOptions: number;
  exercisableOptions: number;
  isEarlyExercisable: number;
}

export type TaxGrantExerciseSummary = GrantSummary & {
  optionsExercisedForScenario: number;
  exerciseCostForScenario: number;
  exercisePrice: number;
}

export type TaxGrantSaleSummary = GrantSummary & {
  sharesSoldForScenario: number;
  saleProceedsForScenario: number;
  salePrice: number;
  isDoubleTrigger?: boolean;
}

export type ScenarioHeaderData = {
  title: string;
  subtitle: string;
  description: string;
}

export type ScenarioBreakdownData = {
  exerciseLabel: string;
  saleLabel: string;
  remainingLabel: string;
}

export type EducationContentItems = {
  categoryLabel: string;
  titleLabel: string;
  contentLabel: string;
  visible: Visibility;
}

export enum GrantType {
  RSU = 'RSU',
  RSA = 'RSA',
  EQUITY = 'EQUITY',
}

export enum ShareClass {
  COMMON = 'COMMON',
  PREFERRED = 'PREFERRED',
}

export enum VestingCliff {
  NONE = '',
  ONE_YEAR = 'YEARS_1',
}

export enum VestingCadence {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}

export enum OptionsStatusType {
  OPTIONS_CANCELLED = 'OPTIONS_CANCELLED',
  OPTIONS_NOT_VESTED = 'OPTIONS_NOT_VESTED',
  OPTIONS_EXPIRED = 'OPTIONS_EXPIRED',
}

export type Grant = {
  id: string;
  company: string;
  grantType: GrantType;
  grantDate: string;
  isOption: boolean;
  optionType?: OptionType;
  perShareCostBasis?: number;
  quantity: number;
  shareClass: ShareClass;
  vestingCliff?: VestingCliff;
  vestingStartDate: string;
  vestingCadence: VestingCadence;
  vestingPeriod: number;
  isEarlyExercise?: boolean;
  expirationDate?: Nullable<string>;
  isDoubleTrigger?: boolean;
};
