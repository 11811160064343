/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {Selector, createSelector} from '@reduxjs/toolkit'

import {QuickAnalysis, ScenarioGrantMixType, ScenarioHeader} from './types'
import {
  generateMenuOptions,
  generateQuickAnalysis,
  generateScenarioHeaderContent,
} from './utils'

import {
  OptionsStatus,
  ScenarioResultType,
  TaxScenarioGrantType,
} from 'models'
import {RootState} from 'store/main'
import {ScenarioResultSummary, processScenarioResults} from 'pages/company/helpers'

export const liquidityEventSelector = (state: RootState): RootState['liquidityEvent'] => state.liquidityEvent

export const getScenarios = createSelector(
  [liquidityEventSelector],
  ({scenarios}) => scenarios,
)

export const getScenarioOrder = createSelector(
  [liquidityEventSelector],
  ({order}) => order,
)

export const getScenariosAsArray = createSelector(
  [liquidityEventSelector], ({scenarios, order}) => order
    .map((type: ScenarioResultType) => scenarios[type])
    .filter((scenario, i) => i <= 1 && scenario !== undefined),
)

export const isRsuOnly = (scenarioType: ScenarioResultType): Selector<RootState, boolean> => createSelector(
  [liquidityEventSelector], (liquidityEvent) => liquidityEvent.scenarios[scenarioType].mixType === ScenarioGrantMixType.ONLY_RSU,
)

export const getRsuTotalIncomeTax = (
  scenarioType: ScenarioResultType,
): Selector<RootState, number> => createSelector(
  [liquidityEventSelector], (liquidityEvent) => liquidityEvent.scenarios[scenarioType].taxTotalsFromRsuIncome?.total || 0,
)

export const isScenarioEmpty
  = (scenarioType: ScenarioResultType): Selector<RootState, boolean> => createSelector(
    [liquidityEventSelector],
    ({scenarios}): boolean => scenarios[scenarioType].isEmpty,
  )

export const isScenarioNegativeProfit
  = (scenarioResultType: ScenarioResultType): Selector<RootState, boolean> => createSelector(
    [liquidityEventSelector],
    ({taxScenarioResults}): boolean => {
      const result = processScenarioResults(taxScenarioResults)
      const scenarioResult = result.find(({scenarioType}) => scenarioType === scenarioResultType)
      return scenarioResult ? scenarioResult.netProfit < 0 : false
    },
  )

export const isScenarioAllOptionsExpired
  = (scenarioType: ScenarioResultType): Selector<RootState, boolean> => createSelector(
    [liquidityEventSelector],
    ({scenarios}): boolean => Boolean(scenarios[scenarioType].optionsAllExpired),
  )

export const isScenarioOptionsNotVested
  = (scenarioType: ScenarioResultType): Selector<RootState, boolean> => createSelector(
    [liquidityEventSelector],
    ({scenarios}): boolean => Boolean(scenarios[scenarioType].optionsNotVested),
  )

export const getScenarioOptionsStatus
  = (scenarioType: ScenarioResultType): Selector<RootState, OptionsStatus | undefined> => createSelector(
    [liquidityEventSelector],
    ({scenarios}): OptionsStatus | undefined => {
      if (scenarios[scenarioType].optionsNotVested) return OptionsStatus.OPTIONS_EXPIRED
      if (scenarios[scenarioType].optionsAllExpired) return OptionsStatus.OPTIONS_NOT_VESTED
      if (scenarios[scenarioType].optionsCancelled) return OptionsStatus.OPTIONS_CANCELLED
      return undefined
    },
  )

export const getScenarioSummaries = createSelector(
  [liquidityEventSelector],
  ({taxScenarioResults}): ScenarioResultSummary[] => processScenarioResults(
    taxScenarioResults,
  ),
)

export const selectScenarioHeader = (
  scenarioType: ScenarioResultType,
): Selector<RootState, ScenarioHeader> => createSelector(
  [liquidityEventSelector],
  // NOTE: taxScenarioResults[scenarioType] can be empty for unsupported zip codes (states).
  // In that case we still want to show parts of the header with some default content.
  ({info, taxScenarioResults, scenarios}) => generateScenarioHeaderContent(
    info.eventType,
    scenarioType,
    info.lockupPeriod ?? 0,
    taxScenarioResults[scenarioType]?.purchaseDate ?? '',
    taxScenarioResults[scenarioType]?.saleDate ?? '',
    scenarios[scenarioType]?.mixType ?? ScenarioGrantMixType.ONLY_OPTIONS,
  ),
)

export const selectScenarioMenuOptions = (
  rsuSupport: boolean,
): Selector<RootState, Record<string, string>> => createSelector(
  [liquidityEventSelector],
  ({scenarios}) => generateMenuOptions(scenarios, rsuSupport),
)

export const selectQuickAnalysis = (
  rsuSupport: boolean,
  scenarioType: ScenarioResultType,
): Selector<RootState, QuickAnalysis> => createSelector(
  [liquidityEventSelector],
  ({scenarios}) => generateQuickAnalysis(rsuSupport, scenarios[scenarioType]),
)

export const selectDoubleTriggerRSUQuantity = createSelector(
  [liquidityEventSelector],
  ({doubleTriggerRSUQuantity}) => doubleTriggerRSUQuantity,
)

export const selectSharesSoldForScenario = (
  scenarioType: ScenarioResultType,
  grantType: TaxScenarioGrantType,
): Selector<RootState, number> => createSelector(
  [liquidityEventSelector],
  ({scenarios}) => {
    const shareCount = scenarios[scenarioType].scenario.grantSummary.sale
      .filter((grant) => grant.grantType === grantType)
      .reduce((sum, sale) => sum + sale.sharesSoldForScenario, 0)
    return shareCount
  },
)
