import React from 'react'
import cx from 'classnames'

import styles from './_header.module.scss'

export type Props = {
  /** Header content. */
  header: React.ReactNode;
  /** Detailed description text. */
  description?: React.ReactNode;
  /** Optional Tag component */
  tag?: React.ReactElement;
  /** Optional container className */
  className?: string;
  /** Full Width Header */
  fullWidth?: boolean;
}

function LargeHeader({
  header,
  description,
  tag,
  className = '',
  fullWidth,
}: Props): JSX.Element {
  return (
    <div className={cx(styles.largeHeader, className, fullWidth && styles.fullWidth)}>
      <h4>
        {header}
        {tag && <span className={styles.headerTag}>{tag}</span>}
      </h4>
      <p>{description}</p>
    </div>
  )
}

export default React.memo(LargeHeader)
