import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'

import {RoutePaths} from 'core/constants'
import {useGetUserQuery} from 'store/user/userSlice'

type Props = {
  children: React.ReactNode;
}

const EmailVerifyGuard = ({children}: Props): JSX.Element => {
  const {data: user, isSuccess} = useGetUserQuery()
  const {pathname} = useLocation()

  if (isSuccess && !user?.isEmailVerified) {
    return (
      <Redirect to={`${RoutePaths.VERIFY_EMAIL}?path=${encodeURIComponent(pathname)}`} />
    )
  }

  return <>{children}</>
}

export default React.memo(EmailVerifyGuard)
