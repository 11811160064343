import {EnvConfig, Environment} from './types'

const localConfig: EnvConfig = {
  ENV: Environment.LOCAL,
  MIXPANEL_TOKEN: '17151ba843dbc436c62f705a5afb2ad5',
  MIXPANEL_ACTIVE: false, // set true to debug available on mixpanel staging
  HELLO_SIGN_CLIENT_ID: '89f35fd3317dfbc74e4677509fba6b24',
  LINKEDIN_CLIENTID: '77hhvsma7le3ku',
  FB_PIXEL_ID: '511999109346196',
  OPEN_HUBSPOT_CHAT: false,
  VEZGO_CLIENT_ID: '1gse42uph5u09he2sujges929q',
  HELLO_SIGN_SKIP_DOMAIN_VERIFY: true,
  HUBSPOT_QUESTIONNAIRE_FORM_ID: 'bfc1a24c-c9e9-4b08-b9bf-032aa9f591cd',
}

export default localConfig
