import React from 'react'
import {Markup} from 'interweave'

import styles from './_taxBreakdownEducation.module.scss'
import {ExerciseAndSaleStateOrdinaryIncomeTax as locale} from './locale'

const ExerciseAndSaleStateOrdinaryIncomeTaxEdu = (): JSX.Element => (
  <div className={styles.educationContainer}>
    <p className={styles.educationText}>
      <Markup noWrap content={locale.PARAGRAPH_1} />
    </p>
  </div>
)

export default ExerciseAndSaleStateOrdinaryIncomeTaxEdu
