import {
  format,
  getYear,
  parseISO,
} from 'date-fns'
import differenceInYears from 'date-fns/differenceInYears'
import {enUS} from 'date-fns/locale'

import {
  NormalizedScenario,
  QuickAnalysis,
  ScenarioData,
  ScenarioGrantMixType,
  ScenarioHeader,
  TaxEfficiencyScore,
} from './types'
import {
  CASH_FLOW_SUBTEXT,
  INVESTMENT_RISK,
  SCENARIO_HEADER_COPY,
  SCENARIO_MENU_OPTIONS,
  SCENARIO_MENU_OPTIONS_RSU_ONLY,
  SCENARIO_MENU_OPTIONS_V2,
  TAXES_FROM_EXERCISE_SUBTEXT,
  TAXES_FROM_SALE_SUBTEXT,
} from './constants'

import {
  EventType,
  IncomeSource,
  IncomeSourceSubCategory,
  OptionDisposition,
  OptionDispositionType,
  ScenarioResultType,
  TaxBracketOrdering,
  TaxScenario,
  TaxScenarioGrantType,
  TaxTypeTotals,
  getTaxTotals,
  getTaxTypeTotals,
} from 'models'

export const normalizeScenarioData = (type: string, scenario: TaxScenario): ScenarioData => {
  const purchaseDate = parseISO(scenario.purchaseDate)
  const purchaseYear = purchaseDate.getFullYear()
  const saleDate = parseISO(scenario.saleDate)
  const saleYear = saleDate.getFullYear()

  // Total Proceedsbefore costs & taxes
  const grossProceeds = scenario.grantSummary.sale.reduce((sum, {saleProceedsForScenario}) => (
    sum + saleProceedsForScenario), 0)

  const exerciseCost = scenario.grantSummary.purchase.reduce((sum, {exerciseCostForScenario}) => (
    sum + exerciseCostForScenario), 0)

  const taxTotalsFromExercise = !scenario.purchaseDate ? null : getTaxTotals(
    scenario[purchaseYear],
    true,
  )

  const rsuIncomeTaxes = getTaxTypeTotals(scenario[saleYear], TaxBracketOrdering.RSU_VESTING, false)
  const taxTotalsFromSale = getTaxTotals(scenario[saleYear], false)
  const netProfit = grossProceeds - exerciseCost - (taxTotalsFromExercise?.total ?? 0) - taxTotalsFromSale.total - rsuIncomeTaxes.total
  const isEmpty = exerciseCost <= 0 && netProfit <= 0

  const optionsExercised = scenario.grantSummary.purchase.reduce((sum, d) => sum + d.optionsExercisedForScenario, 0)
  const optionsLeft = scenario.grantSummary.purchase.reduce((sum, d) => (
    sum + (d.vestedOptions + d.unvestedOptions - d.optionsExercisedForScenario)
  ), 0)
  const sharesSold = scenario.grantSummary.sale.reduce((sum, d) => sum + d.sharesSoldForScenario, 0)
  const sharesLeft = scenario.grantSummary.sale.reduce((sum, d) => sum + d.unvestedShares, 0)

  // if all optionsExercisedForScenario is 0 with no prior exercise
  // check if no options have been vested
  // check if all options have expired

  let optionsVested = false
  let optionsNotExpired = false
  let optionsCancelled = false

  scenario.grantSummary.purchase.forEach((grantSummary) => {
    // TODO(raylen): handle case for RSUs/RSAs
    if (![TaxScenarioGrantType.INCENTIVE_STOCK, TaxScenarioGrantType.NON_QUALIFIED].includes(grantSummary.grantType)) {
      return
    }

    if (grantSummary.vestedShares || grantSummary.optionsExercisedForScenario) {
      optionsVested = true
      optionsNotExpired = true
      optionsCancelled = !!grantSummary.cancelledOptions
      return
    }

    if (grantSummary.cancelledOptions) {
      optionsCancelled = true
    }

    if (!grantSummary.cancelledOptions) {
      optionsNotExpired = true
    }

    if (grantSummary.vestedOptions) {
      optionsVested = true
    }
  })

  // Use the grant summary table to determine if user holds only options, only RSUs, or a mix of both.
  const hasRSU = scenario.grantSummary.sale.find((item) => item.grantType === TaxScenarioGrantType.RSU)
  const hasOption = [
    ...scenario.grantSummary.purchase,
    ...scenario.grantSummary.sale,
  ].find((item) => (
    [TaxScenarioGrantType.INCENTIVE_STOCK, TaxScenarioGrantType.NON_QUALIFIED].includes(item.grantType)
  ))
  let mixType: ScenarioGrantMixType
  if (hasRSU && hasOption) mixType = ScenarioGrantMixType.MIXED
  else if (hasRSU) mixType = ScenarioGrantMixType.ONLY_RSU
  else mixType = ScenarioGrantMixType.ONLY_OPTIONS

  return {
    exerciseCost: Math.round(exerciseCost),
    grossProceeds: Math.round(grossProceeds),
    scenario,
    scenarioType: type as ScenarioResultType,
    taxTotalsFromExercise,
    taxTotalsFromSale,
    taxTotalsFromRsuIncome: rsuIncomeTaxes,
    isEmpty,
    optionsAllExpired: !optionsNotExpired,
    optionsNotVested: !optionsVested,
    optionsCancelled,
    mixType,
    netProfit: Math.round(netProfit),
    optionsExercised,
    optionsLeft,
    sharesSold,
    sharesLeft,
    purchaseDate: scenario.purchaseDate,
    saleDate: scenario.saleDate,
  }
}

export const generateScenarioHeaderContent = (
  eventType: EventType,
  scenarioType: ScenarioResultType,
  lockupPeriod: number,
  purchaseDateISO: string,
  saleDateISO: string,
  mixType: ScenarioGrantMixType,
): ScenarioHeader => {
  const purchaseDate = parseISO(purchaseDateISO)
  const saleDate = parseISO(saleDateISO)
  const purchaseMonth = purchaseDateISO ? format(purchaseDate, 'LLLL', {locale: enUS}) : ''
  const purchaseYear = purchaseDateISO ? purchaseDate.getFullYear().toString() : ''
  const saleMonth = saleDateISO ? format(saleDate, 'LLLL', {locale: enUS}) : ''
  const saleYear = saleDateISO ? saleDate.getFullYear().toString() : ''
  const exerciseOnly = eventType === EventType.EXERCISE_OPTIONS_ONLY

  const hasLockup = lockupPeriod > 0
  const holdsOnlyOptions = mixType === ScenarioGrantMixType.ONLY_OPTIONS
  const isSaleMoreThanYear = differenceInYears(saleDate, new Date()) > 0

  const overline = SCENARIO_HEADER_COPY[scenarioType].OVERLINE
  let title = ''
  let description = ''

  const actionMoment = ((): string => {
    if (!isSaleMoreThanYear) {
      return '(after 1 year)'
    }

    return hasLockup ? '(after lockup period)' : '(after going public)'
  })()

  if (scenarioType === ScenarioResultType.AMT_BREAKEVEN) {
    if (exerciseOnly) {
      title = SCENARIO_HEADER_COPY.AMT_BREAKEVEN.EXERCISE_ONLY.TITLE
      description = SCENARIO_HEADER_COPY.AMT_BREAKEVEN.EXERCISE_ONLY.DESCRIPTION
    } else {
      title = SCENARIO_HEADER_COPY.AMT_BREAKEVEN.TITLE
      description = SCENARIO_HEADER_COPY.AMT_BREAKEVEN.DESCRIPTION

      // AMT scenario when sale is more than a year away
      title += ` ${actionMoment}`
    }
  } else if (scenarioType === ScenarioResultType.HOLD_UNDER_A_YEAR) {
    if (exerciseOnly && holdsOnlyOptions) {
      title = SCENARIO_HEADER_COPY.HOLD_UNDER_A_YEAR[mixType].EXERCISE_ONLY.TITLE
      description = SCENARIO_HEADER_COPY.HOLD_UNDER_A_YEAR[mixType].EXERCISE_ONLY.DESCRIPTION
    } else {
      description = SCENARIO_HEADER_COPY.HOLD_UNDER_A_YEAR[mixType].DESCRIPTION
      title = SCENARIO_HEADER_COPY.HOLD_UNDER_A_YEAR[mixType].TITLE
      title += hasLockup ? ' (after lockup period)' : ' (after going public)'
    }
  } else if (scenarioType === ScenarioResultType.HOLD_OVER_A_YEAR) {
    if (exerciseOnly && holdsOnlyOptions) {
      title = SCENARIO_HEADER_COPY.HOLD_OVER_A_YEAR[mixType].EXERCISE_ONLY.TITLE
      description = SCENARIO_HEADER_COPY.HOLD_OVER_A_YEAR[mixType].EXERCISE_ONLY.DESCRIPTION
    } else {
      title = `${SCENARIO_HEADER_COPY.HOLD_OVER_A_YEAR[mixType].TITLE} upon liquidity`
      description = SCENARIO_HEADER_COPY.HOLD_OVER_A_YEAR[mixType].DESCRIPTION

      if (isSaleMoreThanYear) {
        title = SCENARIO_HEADER_COPY.HOLD_OVER_A_YEAR.ONLY_OPTIONS.AFTER_ONE_YEAR.TITLE
      }
    }
  }

  // inject purchase & sale month/year (if applicable)
  title = title
    .replace('[PURCHASE_MONTH]', purchaseMonth)
    .replace('[PURCHASE_YEAR]', purchaseYear)
    .replace('[SALE_MONTH]', saleMonth)
    .replace('[SALE_YEAR]', saleYear)

  return {
    overline,
    title,
    description,
  }
}

export const generateMenuOptions = (
  scenarios: NormalizedScenario,
  rsuSupport: boolean,
): Record<string, string> => {
  const options = rsuSupport ? {...SCENARIO_MENU_OPTIONS_V2} : {...SCENARIO_MENU_OPTIONS}
  if (rsuSupport) {
    Object.keys(scenarios).forEach((scenarioType) => {
      const scenario = scenarios[scenarioType] as ScenarioData
      if (scenario.mixType === ScenarioGrantMixType.ONLY_RSU) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        options[scenarioType] = SCENARIO_MENU_OPTIONS_RSU_ONLY[scenarioType]
      }
    })
  }
  return options
}

export type TaxOnExerciseEfficiency = {
  hasAMT: boolean;
  hasQualifiedISOs: boolean;
  hasDisqualifiedISOs: boolean;
  hasNSOs: boolean;
  hasTaxes: boolean;
}

export function getTaxOnExerciseEfficiencyScore({
  hasAMT,
  hasQualifiedISOs,
  hasDisqualifiedISOs,
  hasNSOs,
}: TaxOnExerciseEfficiency): TaxEfficiencyScore {
  // Note: consider using a truth-table?
  if (hasNSOs) {
    if (hasQualifiedISOs) return TaxEfficiencyScore.MEDIUM
    return TaxEfficiencyScore.LOW
  }
  if (hasAMT) return TaxEfficiencyScore.MEDIUM
  if (hasDisqualifiedISOs) return TaxEfficiencyScore.NA
  if (hasQualifiedISOs) return TaxEfficiencyScore.HIGH

  return TaxEfficiencyScore.NA
}

export function getTaxOnExerciseEfficiency(
  scenario: TaxScenario,
  taxTotalsFromExercise: Nullable<TaxTypeTotals>,
): [Nullable<TaxEfficiencyScore>, Nullable<TaxOnExerciseEfficiency>] {
  if (!scenario.purchaseDate || !taxTotalsFromExercise) {
    return [null, null]
  }
  const purchaseYear = getYear(parseISO(scenario.purchaseDate))
  const saleYear = getYear(parseISO(scenario.saleDate))

  // IMPORTANT!
  // sale data is not known at the time of purchase
  // to cover the case of categorizing unsold and sold ISO options for
  // the purchase year we can just copy over the option data from the sale
  // year since it is known then
  const {federal: federalTaxBreakdown} = scenario[saleYear].taxBreakdown
  const {incomeSources} = federalTaxBreakdown.ordinaryIncome
  const exercisedISOs = incomeSources
    .filter(({subCategory, incomeSource}) => (
      subCategory === IncomeSourceSubCategory.ISO_EXERCISE)
      && getYear(parseISO((incomeSource as OptionDisposition).exerciseDate)) === purchaseYear)

  const exercisedNSOs = incomeSources
    .filter(({subCategory, incomeSource}) => (
      subCategory === IncomeSourceSubCategory.NSO_EXERCISE)
      && getYear(parseISO((incomeSource as OptionDisposition).exerciseDate)) === purchaseYear)

  const qualifiedISOs = exercisedISOs.filter(({incomeSource}) => (
    (incomeSource as OptionDisposition).dispositionType === OptionDispositionType.QUALIFIED
  ))

  const disqualifiedISOs = exercisedISOs.filter(({incomeSource}) => (
    (incomeSource as OptionDisposition).dispositionType === OptionDispositionType.NON_QUALIFIED
  ))

  const incomeFromNSOs = exercisedNSOs.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
  const hasQualifiedISOs = qualifiedISOs.length > 0
  const hasDisqualifiedISOs = disqualifiedISOs.length > 0
  const hasNSOs = incomeFromNSOs > 0
  const hasAMT = taxTotalsFromExercise.amt > 0

  const table = {
    hasAMT,
    hasQualifiedISOs,
    hasDisqualifiedISOs,
    hasNSOs,
    hasTaxes: hasQualifiedISOs || hasDisqualifiedISOs || hasNSOs || hasAMT,
  }
  const score = getTaxOnExerciseEfficiencyScore(table)

  return [score, table]
}

export type TaxOnSaleEfficiency = {
  hasLongTermGains: boolean;
  hasShortTermGains: boolean;
  hasAMTFromSale: boolean;
  hasTaxes: boolean;
}

export function getTaxOnSaleEfficiencyScore({
  hasLongTermGains,
  hasShortTermGains,
  hasAMTFromSale,
}: TaxOnSaleEfficiency): TaxEfficiencyScore {
  let score = TaxEfficiencyScore.NA
  if ((hasLongTermGains && hasShortTermGains) || hasAMTFromSale) {
    score = TaxEfficiencyScore.MEDIUM
  } else if (hasLongTermGains) {
    score = TaxEfficiencyScore.HIGH
  } else if (hasShortTermGains) {
    score = TaxEfficiencyScore.LOW
  }
  return score
}

const equitySales = (incomeSources: IncomeSource[]): IncomeSource[] => (
  incomeSources.filter(({subCategory}) => subCategory === IncomeSourceSubCategory.EQUITY_SALE)
)

const grossIncomeTotal = (incomeSources: IncomeSource[]): number => (
  incomeSources.reduce((sum, {grossIncome}) => sum + grossIncome, 0)
)

const incomeCompensationTotal = (incomeSources: IncomeSource[]): number => (
  incomeSources.reduce((sum, {incomeCompensation}) => sum + incomeCompensation, 0)
)

export function getTaxOnSaleEfficiency(scenario: TaxScenario): [TaxEfficiencyScore, TaxOnSaleEfficiency] {
  const saleYear = getYear(parseISO(scenario.saleDate))
  const {capitalGains, ordinaryIncome} = scenario[saleYear].taxBreakdown.federal

  const stcgSources = equitySales(ordinaryIncome.incomeSources)
  const ltcgSources = equitySales(capitalGains.incomeSources)
  const hasShortTermGains = incomeCompensationTotal(stcgSources) > 0 || grossIncomeTotal(stcgSources) > 0
  const hasLongTermGains = grossIncomeTotal(ltcgSources) > 0
  const hasAMTFromSale = scenario[saleYear].taxSummary.amtTax > 0

  const table = {
    hasShortTermGains,
    hasLongTermGains,
    hasAMTFromSale,
    hasTaxes: (
      hasShortTermGains
      || hasLongTermGains
      || hasAMTFromSale
    ),
  }
  const score = getTaxOnSaleEfficiencyScore(table)
  return [score, table]
}

export function generateQuickAnalysis(rsuSupport: boolean, scenario: ScenarioData): QuickAnalysis {
  const cashFlow = scenario.scenarioType !== ScenarioResultType.HOLD_UNDER_A_YEAR
    ? scenario.exerciseCost + (scenario.taxTotalsFromExercise?.total ?? 0)
    : 0

  const netProfit = scenario.netProfit > 0 ? scenario.netProfit : 0
  const [fromExerciseScore, fromExercise] = getTaxOnExerciseEfficiency(
    scenario.scenario,
    scenario.taxTotalsFromExercise,
  )
  const exerciseSubtext = !fromExercise ? [] : [
    fromExercise.hasQualifiedISOs && fromExercise.hasAMT ? TAXES_FROM_EXERCISE_SUBTEXT.QUALIFIED_ISO_AMT : '',
    fromExercise.hasQualifiedISOs && !fromExercise.hasAMT ? TAXES_FROM_EXERCISE_SUBTEXT.QUALIFIED_ISO_NO_AMT : '',
    fromExercise.hasDisqualifiedISOs ? TAXES_FROM_EXERCISE_SUBTEXT.DISQUALIFIED_ISO : '',
    fromExercise.hasNSOs ? TAXES_FROM_EXERCISE_SUBTEXT.NSO : '',
  ].filter((v) => !!v)

  const [fromSaleScore, fromSale] = getTaxOnSaleEfficiency(scenario.scenario)
  let saleSubtext: string[] = [TAXES_FROM_SALE_SUBTEXT.NO_TAX]
  if (fromSale.hasTaxes) {
    saleSubtext = [
      fromSale.hasLongTermGains ? TAXES_FROM_SALE_SUBTEXT.LTCG : '',
      fromSale.hasShortTermGains ? TAXES_FROM_SALE_SUBTEXT.STCG : '',
      fromSale.hasAMTFromSale ? TAXES_FROM_SALE_SUBTEXT.AMT : '',
    ].filter((v) => !!v)
  }

  const onlyRSU = rsuSupport && scenario.mixType === ScenarioGrantMixType.ONLY_RSU

  return {
    netProfit: {
      value: netProfit,
    },
    exerciseTaxLiability: !fromExerciseScore ? undefined : {
      value: fromExerciseScore,
      subText: exerciseSubtext,
    },
    saleTaxLiability: {
      value: fromSaleScore,
      subText: saleSubtext,
    },
    investmentRisk: {
      value: INVESTMENT_RISK[scenario.scenarioType].VALUE,
      subText: INVESTMENT_RISK[scenario.scenarioType].SUBTEXT,
    },
    cashFlowFromExercise: onlyRSU ? undefined : {
      value: cashFlow,
      subText: CASH_FLOW_SUBTEXT[scenario.scenarioType],
    },
  }
}
