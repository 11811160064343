import {createAction} from '@reduxjs/toolkit'

import {OnboardingData} from './types'

import {ClientProfile} from 'models'

// TODO(zare): Remove when release marketplace return experience
export const updateClientProfile = createAction<OnboardingData>('UPDATE_CLIENT_PROFILE')
export const setInitialClientProfile = createAction<OnboardingData>('SET_INITIAL_CLIENT_PROFILE')

export const setInitialProfile = createAction<ClientProfile | undefined>('SET_INITIAL_PROFILE')
