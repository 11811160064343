import {EnvConfig, Environment} from './types'

const testsConfig: EnvConfig = {
  ENV: Environment.TEST,
  HELLO_SIGN_CLIENT_ID: '89f35fd3317dfbc74e4677509fba6b24',
  MARKETING_SITE_URL: 'MARKETING_SITE_URL',
  MARKETING_SITE_OUR_ADVISERS_URL: 'MARKETING_SITE_OUR_ADVISERS_URL',
  MARKETING_SITE_IMPACT_PLANNING_URL: 'MARKETING_SITE_IMPACT_PLANNING_URL',
  MARKETING_SITE_ABOUT_US_URL: 'MARKETING_SITE_ABOUT_US_URL',
  URL_TERMS_OF_SERVICE: 'URL_TERMS_OF_SERVICE',
  URL_PRIVACY_POLICY: 'URL_PRIVACY_POLICY',
  URL_IN_THE_NEWS: 'URL_IN_THE_NEWS',
  URL_LINKEDIN: 'URL_LINKEDIN',
  URL_TWITTER: 'URL_TWITTER',
  LINKEDIN_CLIENTID: 'NOOP',
  HUBSPOT_QUESTIONNAIRE_FORM_ID: 'bfc1a24c-c9e9-4b08-b9bf-032aa9f591cd',
}

export default testsConfig
