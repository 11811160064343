import React, {
  CSSProperties,
  useCallback,
  useMemo,
  useState,
} from 'react'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import getQuarter from 'date-fns/getQuarter'

import Select from 'core/components/input/Select'
import NumericInput from 'core/components/input/NumericInput'
import CurrencyInput from 'core/components/input/CurrencyInput'

import {strings} from './const'
import editModalStyles from './_editLiquidityEvent.module.scss'
import {getEventYearOptions} from './utils/getEventYearOptions'
import {validateLockupPeriod} from './utils/validateLockupPeriod'
import {eventQuarterBasicOptions} from './utils/eventQuarterBasicOptions'

import styles from 'core/legacy-components/FormModal/_styles.module.scss'
import {
  convertFormattedStringToNumber,
  convertPriceToFormInput,
  formatNumberWithCommas,
} from 'core/formatters'
import {
  EventQuarter,
  EventQuarterLabels,
  EventType,
  LiquidityEvent,
  LiquidityEventWithResults,
  generateLiquidityEventScenario,
  updateLiquidityEvent,
} from 'models'
import FormModal from 'core/legacy-components/FormModal/FormModal'
import FormSubmit from 'core/legacy-components/Form/FormSubmit'
import FormField from 'core/legacy-components/Form/FormField'
import {
  ValidationsSchema,
  getFormErrors,
  validatePrice,
} from 'core/legacy-components/Form/validators'
import {SelectOptions, selectStyles} from 'core/legacy-components/Form/types'

type Props = {
  companyId: string;
  liquidityEvent: LiquidityEvent | LiquidityEventWithResults;
  onClose: () => void;
  /**
   * submit a liquidity event update without regenerating a scenario result
   */
  onSubmit?: (liquidityEvent: LiquidityEvent) => void;
  /**
   * Submit a liquidity event update and regenerate a scenario result
   */
  onSubmitAndRerun?: (liquidityEvent: LiquidityEventWithResults) => void;
  includeResults?: boolean;
}

type LiquidityEventEditForm = {
  eventType: Nullable<EventType>;
  eventQuarter: Nullable<EventQuarter>;
  eventYear: Nullable<number>;
  lockupPeriod: string;
  salePrice: string;
  networkError: string;
  errors: {
    eventType?: string;
    eventQuarter?: string;
    eventYear?: string;
    lockupPeriod?: string;
    salePrice?: string;
  };
  submitting: boolean;
}

const eventOptions = [
  {
    label: 'Company going public (IPO, DPO)',
    value: EventType.IPO,
  },
  {
    label: 'Exercise options only (Early exercise, regular exercise)',
    value: EventType.EXERCISE_OPTIONS_ONLY,
  },
  {
    label: 'Acquisition (includes SPAC)',
    value: EventType.ACQUISITION,
    isDisabled: true,
  },
  {
    label: 'Secondary offering (e.g. private sale, tender offer)',
    value: EventType.SECONDARY_OFFERING,
    isDisabled: true,
  },
]

export const eventTimingSelectStyles: KeyedObject = {
  ...selectStyles,
  menu: (provided: CSSProperties) => ({
    ...provided,
    fontWeight: 'normal',
    textAlign: 'left',
  }),
}

const eventYearOptions: SelectOptions<number> = getEventYearOptions()

const EditLiquidityEventModal = ({
  companyId,
  liquidityEvent,
  onClose,
  onSubmit,
  onSubmitAndRerun,
  includeResults = false,
}: Props): JSX.Element => {
  const [formState, setFormState] = useState<LiquidityEventEditForm>({
    eventType: liquidityEvent.eventType,
    eventQuarter: liquidityEvent.eventQuarter,
    eventYear: liquidityEvent.eventYear,
    lockupPeriod: liquidityEvent.lockupPeriod ? formatNumberWithCommas(liquidityEvent.lockupPeriod) : '',
    salePrice: liquidityEvent.salePrice ? convertPriceToFormInput(liquidityEvent.salePrice) : '',
    networkError: '',
    errors: {},
    submitting: false,
  })

  const isExerciseOnly = formState.eventType === EventType.EXERCISE_OPTIONS_ONLY

  const validations: ValidationsSchema = useMemo(() => ({
    eventType: ['Event type', true, []],
    eventQuarter: ['Event quarter', true, [
      (name: string, value: string): null | string => {
        const selectedQuarter = Number(value.split('Q')[1])
        const currentQuarter = getQuarter(new Date())
        if (selectedQuarter < currentQuarter && formState.eventYear === new Date().getFullYear()) {
          return 'Event timing must be the current quarter or later'
        }

        return null
      },
    ]],
    eventYear: ['Event year', true, []],
    lockupPeriod: ['Lockup period', false, [validateLockupPeriod]],
    salePrice: ['Sale price', true, [validatePrice]],
  }), [formState.eventYear])

  const handleSelectChange = <T extends unknown>(key: string) => ({
    value,
  }: {value: T}): void => {
    setFormState((prevData) => ({
      ...prevData,
      [key]: value,
      errors: {
        ...prevData.errors,
        [key]: '',
      },
    }))
  }

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      errors: {
        ...prev.errors,
        [e.target.name]: '',
      },
    }))
  }, [])

  const handleSubmit = useCallback<React.FormEventHandler>(async (e) => {
    e.preventDefault()

    const errors = getFormErrors(validations, formState)
    if (!isEmpty(errors)) {
      setFormState((prev) => ({...prev, errors}))
      return
    }

    setFormState((prev) => ({...prev, submitting: true}))

    const {data, error} = await updateLiquidityEvent(companyId, {
      eventType: formState.eventType ?? undefined,
      eventQuarter: formState.eventQuarter ?? undefined,
      eventYear: formState.eventYear ?? undefined,
      lockupPeriod: convertFormattedStringToNumber(formState.lockupPeriod),
      salePrice: convertFormattedStringToNumber(formState.salePrice),
    })

    if (!data || error) {
      setFormState((prev) => (
        {
          ...prev,
          submitting: false,
          networkError: 'Something went wrong. Refresh the page and try again',
        }))
      return
    }

    if (includeResults && onSubmitAndRerun) {
      const {data: generatedData, error: generatedError} = await generateLiquidityEventScenario(companyId)

      if (!generatedData || generatedError) {
        setFormState((prev) => (
          {
            ...prev,
            submitting: false,
            networkError: 'Something went wrong. Refresh the page and try again',
          }))
        return
      }
      setFormState((prev) => ({...prev, submitting: false}))
      onSubmitAndRerun(generatedData)
    } else if (onSubmit) {
      setFormState((prev) => ({...prev, submitting: false}))
      onSubmit(data)
    }
  }, [
    validations,
    formState,
    companyId,
    includeResults,
    onSubmitAndRerun,
    onSubmit,
  ])

  type Option = {
    value: EventType;
    label: string;
    isDisabled?: boolean;
  }

  const handleEventTypeChange = useCallback((option: Option) => {
    setFormState(({lockupPeriod, ...rest}) => ({
      ...rest,
      eventType: option.value,
      lockupPeriod: isExerciseOnly ? '' : lockupPeriod,
    }))
  }, [isExerciseOnly])

  return (
    <FormModal
      id="edit-liquidity-event"
      title="Sale Details"
      onCancel={onClose}
    >
      <form onSubmit={handleSubmit}>
        <div className={cx(styles.modalForm, styles.collapseGridGap)}>
          <FormField
            label="Planning for:"
            tooltipLabel=""
          >
            <Select
              name="liquidity-event-type"
              placeholder="Select event type"
              onChange={handleEventTypeChange}
              styles={eventTimingSelectStyles}
              options={eventOptions}
              value={formState.eventType ? {
                value: formState.eventType,
                label: eventOptions.find((option) => option.value === formState.eventType)?.label,
              } : null}
              data-cy="liquidity-event-type-select"
            />
          </FormField>
          <hr />
          <FormField
            label={strings.EVENT_TIMING.LABEL.replace('[EVENT_TYPE]',
              formState.eventType === EventType.EXERCISE_OPTIONS_ONLY ? 'sale' : 'public offering')}
            tooltip={strings.EVENT_TIMING.TOOLTIP}
            tooltipLabel="Event timing tooltip"
            className={styles.modalInlineSelectField}
          >
            <div className={editModalStyles.inlineSelectField}>
              <Select
                name="liquidity-event-quarter"
                placeholder="Select quarter"
                onChange={handleSelectChange<EventQuarter>('eventQuarter')}
                styles={eventTimingSelectStyles}
                options={eventQuarterBasicOptions}
                value={formState.eventQuarter ? {
                  value: formState.eventQuarter,
                  label: EventQuarterLabels[formState.eventQuarter].basic,
                } : null}
                error={formState.errors.eventQuarter}
                data-cy="liquidity-event-quarter-select"
              />
              <Select
                name="liquidity-event-year"
                placeholder="Select year"
                onChange={handleSelectChange<number>('eventYear')}
                styles={eventTimingSelectStyles}
                options={eventYearOptions}
                value={formState.eventYear ? {
                  value: formState.eventYear,
                  label: formState.eventYear.toString(),
                } : null}
                error={formState.errors.eventYear}
                data-cy="liquidity-event-year-select"
              />
            </div>
          </FormField>
          <hr style={{width: '100%'}} />
          {!isExerciseOnly && (
            <>
              <FormField
                label={strings.LOCKUP_PERIOD.LABEL}
                tooltip={strings.LOCKUP_PERIOD.TOOLTIP}
                tooltipLabel="lockup period tooltip"
              >
                <NumericInput
                  id="liquidity-event-lockup-period"
                  name="lockupPeriod"
                  onChange={handleInputChange}
                  placeholder="180"
                  suffix="days"
                  value={formState.lockupPeriod ? formState.lockupPeriod : ''}
                  labelTooltipAriaLabel="lockup period input"
                  error={formState.errors.lockupPeriod}
                />
              </FormField>
              <hr />
            </>
          )}
          <FormField
            label={strings.SALE_PRICE.LABEL}
            tooltip={strings.SALE_PRICE.TOOLTIP}
            tooltipLabel="Sale price tooltip"
          >
            <CurrencyInput
              name="salePrice"
              id="liquidity-event-sale-price"
              prefix={formState.salePrice ? '$' : ''}
              suffix="per share"
              value={formState.salePrice}
              numDecimals={2}
              labelTooltipAriaLabel="Sale price"
              onChange={handleInputChange}
              error={formState.errors.salePrice}
            />
          </FormField>
        </div>
        <FormSubmit
          proceedText="Save & Rerun"
          cancelText="Cancel"
          loading={formState.submitting}
          disableConfirm={formState.submitting}
          disableCancel={formState.submitting}
          onCancel={onClose}
        />
      </form>
    </FormModal>
  )
}

export default React.memo(EditLiquidityEventModal)
