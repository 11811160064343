import React, {useMemo} from 'react'
import {parseISO} from 'date-fns'

import AMTIncomeDisplayTable from '../tables/AMTIncomeDisplayTable'
import AMTLiabilityTable from '../tables/AMTLiabilityTable'
import l from '../locale'

import IncomeFromExercises from './IncomeFromExercises'
import TaxBracketTable from './TaxBracketTable'
import {
  EducationTypes,
  TaxBreakdownEducationContainer,
} from './TaxBreakdownEducation'
import CollapsibleTaxRow from './CollapsibleTaxRow'
import CapitalGainsIncome from './CapitalGainsIncome'

import {
  IncomeSource,
  OptionDisposition,
  OptionType,
  TaxBracketType,
  TaxScenarioResult,
  getAmtIncomeSources,
} from 'models'

type Props = {
  result: TaxScenarioResult;
  purchaseDate: string;
  taxYear: number;
  scenarioNumber: number;
  overrideScenarioResult?: Nullable<TaxScenarioResult>;
  incomePriorExerciseNSO: number;
  isExercise: boolean;
  noSharesSold: boolean;
  hasPriorExerciseFedSTCG: boolean;
  hasPriorExerciseFedLTCG: boolean;
  federalSTCGIncomeSources: IncomeSource[];
  federalLTCGIncomeSources: IncomeSource[];
}

function getExerciseOnlyFromTaxYear(exercises: IncomeSource[], taxYear: number): IncomeSource[] {
  return exercises.filter(({incomeSource}) => (
    parseISO((incomeSource as OptionDisposition).exerciseDate).getFullYear() === taxYear))
}

export default function AMTTaxOverview({
  result,
  purchaseDate,
  scenarioNumber,
  overrideScenarioResult,
  taxYear,
  incomePriorExerciseNSO,
  isExercise,
  noSharesSold,
  hasPriorExerciseFedSTCG,
  hasPriorExerciseFedLTCG,
  federalSTCGIncomeSources,
  federalLTCGIncomeSources,
}: Props): JSX.Element {
  const {taxBreakdown, taxSummary, incomeSources: {optionGrant}} = result
  const federalTaxBreakdown = taxBreakdown.federal
  const amtIncomeSources = useMemo(
    () => getAmtIncomeSources(purchaseDate, federalTaxBreakdown.amt),
    [purchaseDate, federalTaxBreakdown.amt],
  )
  const {
    amtIsoExerciseIncomeSources: tmpIsoExerciseIncomeSources,
    amtIsoPriorExerciseIncomeSources: tmpIsoPriorExerciseIncomeSources,
  } = amtIncomeSources

  let isoExerciseIncomeSources = tmpIsoExerciseIncomeSources
  let isoPriorExerciseIncomeSources = tmpIsoPriorExerciseIncomeSources

  // IMPORTANT!
  // sale data is not known at the time of purchase
  // to cover the case of categorizing unsold and sold ISO options for
  // the purchase year we can just copy over the option data from the sale
  // year since it is known then
  if (overrideScenarioResult) {
    const {taxBreakdown: isoOverride} = overrideScenarioResult
    const amtIsoOverride = getAmtIncomeSources(purchaseDate, isoOverride.federal.amt)
    isoExerciseIncomeSources = amtIsoOverride.amtIsoExerciseIncomeSources
    isoPriorExerciseIncomeSources = amtIsoOverride.amtIsoPriorExerciseIncomeSources
  }

  const priorISOExerciseOnlyTaxYear = useMemo(() => (
    getExerciseOnlyFromTaxYear(isoPriorExerciseIncomeSources, taxYear)
  ), [isoPriorExerciseIncomeSources, taxYear])

  const onlyHoldsNSOs = optionGrant.every(
    ({optionType}) => optionType === OptionType.NON_QUALIFIED,
  )

  /* eslint-disable @typescript-eslint/no-unnecessary-condition */
  const totalOwedFromEvent = (
    isExercise
      ? federalTaxBreakdown.amt?.taxesOwedByOrdering?.SCENARIO_EXERCISE
      : federalTaxBreakdown.amt?.taxesOwedByOrdering?.SCENARIO_SALE
  )
  /* eslint-enable @typescript-eslint/no-unnecessary-condition */

  return (
    <CollapsibleTaxRow
      label={l.TAX_OVERVIEW.ALTERNATIVE_MINIMUM_TAX.TITLE}
      totalOwed={taxSummary.amtTax}
      totalOwedFromEvent={
        Math.min(totalOwedFromEvent ?? 0, taxSummary.amtTax)
      }
    >
      <TaxBreakdownEducationContainer
        educationType={EducationTypes.EXERCISE_FEDERAL_ALTERNATIVE_MINIMUM_TAX}
      />

      {isExercise && !onlyHoldsNSOs && !!priorISOExerciseOnlyTaxYear.length && (
        <IncomeFromExercises
          isPriorExercise
          hasPriorExercise
          exercises={priorISOExerciseOnlyTaxYear}
          purchaseDate={purchaseDate}
          scenarioNumber={scenarioNumber}
          taxYear={taxYear}
          isExercise={isExercise}
          isAMT
        />
      )}
      {!onlyHoldsNSOs && (
        <IncomeFromExercises
          hasPriorExercise={!isExercise && !!isoPriorExerciseIncomeSources.length}
          exercises={
            isoExerciseIncomeSources
              .concat(!isExercise ? isoPriorExerciseIncomeSources : [])
          }
          purchaseDate={purchaseDate}
          scenarioNumber={scenarioNumber}
          taxYear={taxYear}
          isExercise={isExercise}
          isAMT
        />
      )}
      {
        !isExercise && (
          <>
            <CapitalGainsIncome
              noSharesSold={noSharesSold}
              sales={federalSTCGIncomeSources}
              purchaseDate={purchaseDate}
              hasPriorExercise={hasPriorExerciseFedSTCG}
              id={`amt-fed-stcg-${scenarioNumber}`}
              isAMT
              isShortTerm
            />
            <CapitalGainsIncome
              noSharesSold={noSharesSold}
              sales={federalLTCGIncomeSources}
              purchaseDate={purchaseDate}
              hasPriorExercise={hasPriorExerciseFedLTCG}
              id={`amt-fed-ltcg-${scenarioNumber}`}
              isAMT
            />
          </>

        )
      }

      <AMTIncomeDisplayTable
        amtBreakdown={federalTaxBreakdown.amt}
        taxSummary={taxSummary}
        amtIncomeSources={{
          ...amtIncomeSources,
          amtIsoExerciseIncomeSources: isoExerciseIncomeSources,
          amtIsoPriorExerciseIncomeSources: isoPriorExerciseIncomeSources,
        }}
        optionGrant={optionGrant}
        taxYear={taxYear}
        scenarioNumber={scenarioNumber}
      />
      <TaxBracketTable
        breakdown={federalTaxBreakdown.amt}
        deductions={result.taxBreakdown.federal.amt.deductions[0]?.amount}
        result={result}
        scenarioNumber={scenarioNumber}
        taxType={TaxBracketType.AMT}
        incomePriorExerciseNSO={incomePriorExerciseNSO}
        isAMT
      />
      <AMTLiabilityTable result={result} />
    </CollapsibleTaxRow>
  )
}
