import React, {useCallback} from 'react'
import cx from 'classnames'

import Button from 'core/components/button/Button'
import Icon, {IconName} from 'core/components/icon/Icon'

import {useScenarioFlyout} from '../hooks/ScenarioFlyoutProvider'
import {taxBreakdownCardLocale as locale} from '../locale'
import styles from '../_scenarioTaxBreakdown.module.scss'

import {numberFormatter} from 'core/formatters'
import {TaxScenarioResult, TaxTypeTotals} from 'models'

type Props = {
  scenario: TaxScenarioResult;
  purchaseDate: string;
  taxTotalsFromEvent: TaxTypeTotals;
  isExercise?: boolean;
  index: number;
}

const TaxesList = ({
  scenario,
  purchaseDate,
  taxTotalsFromEvent,
  isExercise = false,
  index,
}: Props): JSX.Element => {
  const {handleToggleFlyout, handleSetScenario} = useScenarioFlyout()

  const onToggleFlyout = useCallback(() => {
    handleToggleFlyout()
    handleSetScenario(index, isExercise)
  }, [handleToggleFlyout, handleSetScenario, index, isExercise])

  const total = taxTotalsFromEvent.federal + taxTotalsFromEvent.state

  return (
    <>
      <div className={cx(styles.headerRow, index ? styles.scenarioOne : styles.scenarioTwo)}>
        <b>{`${isExercise ? locale.TAXES_FROM_EXERCISE : locale.TAXES_FROM_SALE} (${purchaseDate})`}</b>
        <span>
          {numberFormatter(total).intCurrency}
        </span>
      </div>
      <div className={styles.row}>
        <span>{locale.FEDERAL_TAXES}</span>
        <span>
          {numberFormatter(taxTotalsFromEvent.federal).intCurrency}
        </span>
      </div>
      <div className={styles.row}>
        <span>{`${scenario.taxProfile.states[0]} ${locale.STATE_TAXES}`}</span>
        <span>
          {numberFormatter(taxTotalsFromEvent.state).intCurrency}
        </span>
      </div>
      <Button
        className={cx(styles.row, styles.flyoutBreakdownCta)}
        onClick={onToggleFlyout}
      >
        <u>{locale.VIEW_FULL_BREAKDOWN}</u>
        <Icon name={IconName.CaretRight} />
      </Button>
    </>
  )
}

export default React.memo(TaxesList)
