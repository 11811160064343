/* eslint-disable max-len */
import track from '../track'
import {EquityType, EventLocation} from '../types'

export const toolsAnalyticsEvents = {
  grantAdded: (): void => track.mixpanel('tools-options-grant-add'),

  /** Equity */
  addEquityClick: (): void => track.mixpanel('tools-add-equity_click'),
  equityAdded: (properties: EquityType): void => {
    track.all<EquityType>('tools-equity_added', properties)
    track.google('tools_balance-sheet-item_added')
  },

  /** Account */
  connectAccountClicked: (properties: EventLocation): void => track.mixpanel<EventLocation>('tools-connect-account_clicked', properties),
  accountConnected: (): void => {
    track.mixpanel('tools-account_connected')
    track.google('tools_balance-sheet-item_added')
  },
  accountRemoved: (): void => track.mixpanel('tools-connected-accounts-remove-account'),
  plaidItemRemoved: (): void => track.mixpanel('tools-connected-accounts-remove-institution'),
  accountConnectFailed: (): void => track.mixpanel('tools-connect-account_failed'),

  /** Wallet */
  connectWalletClicked: (): void => track.mixpanel('tools-connect-wallet_clicked'),
  walletConnected: (): void => {
    track.mixpanel('tools-wallet_connected')
    track.google('tools_balance-sheet-item_added')
  },
  connectWalletFailed: (): void => track.mixpanel('tools-connect-wallet_failed'),

  /** Company */
  addCompanyClicked: (properties: EventLocation): void => track.mixpanel<EventLocation>('tools-add-company_clicked', properties),
  companyAdded: (): void => track.mixpanel('tools-company_added'),
  addCompanyFailed: (): void => track.mixpanel('tools-add-company_failed'),

  /** Property */
  addPropertyClicked: (): void => track.mixpanel('tools-add-real-estate-property_clicked'),
  propertyAdded: (): void => {
    track.mixpanel('tools-add-real-estate-property_added')
    track.google('tools_balance-sheet-item_added')
  },
  addPropertyFailed: (): void => track.mixpanel('tools-add-real-estate-property_failed'),
  propertyViewed: (): void => track.mixpanel('tools-real-estate-property_viewed'),

  /** Balance sheet */
  balanceSheetEmailClicked: (): void => track.mixpanel('tools_balance-sheet_email-CTA_click'),
}
