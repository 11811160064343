import maxBy from 'lodash/maxBy'

import {RoutePaths} from 'core/constants'
import {apiSlice} from 'store/main/apiSlice'
import {TaxProfile, TaxProfileResidency} from 'models'

const taxProfileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTaxProfiles: builder.query<TaxProfile | undefined, string>({
      query: () => 'v1/tax_profile/',
      providesTags: ['TaxProfile'],
      transformResponse: (response: TaxProfile[]): TaxProfile | undefined => maxBy(response, ({taxYear}) => taxYear),
      onQueryStarted: async (companyId, {queryFulfilled}) => {
        try {
          await queryFulfilled
        } catch (e) {
          // missing tax profile, redirect them to fill out necessary data
          window.location.replace(RoutePaths.LIQUIDITY_EVENT_START.replace(':companyId', companyId))
        }
      },
    }),
    getTaxProfileResidency: builder.query<TaxProfileResidency | undefined, {taxYear: number; companyId: string}>({
      query: ({taxYear}) => `/v1/tax_profile/${taxYear}/residency/`,
      providesTags: ['TaxProfileResidency'],
      transformResponse: (response: TaxProfileResidency[]): TaxProfileResidency | undefined => response[response.length - 1],
      onQueryStarted: async ({companyId}, {queryFulfilled}) => {
        try {
          await queryFulfilled
        } catch (e) {
          // missing residency, they need to fill in the residency for the current tax year
          window.location.replace(RoutePaths.LIQUIDITY_EVENT.FORM_TAX_RESIDENCY.replace(':companyId', companyId))
        }
      },
    }),
  }),
})

export const {useGetTaxProfilesQuery, useGetTaxProfileResidencyQuery} = taxProfileSlice
