import React, {
  Children, PropsWithChildren, ReactElement, cloneElement, useState,
} from 'react'
import styled from 'styled-components'

import Overline from 'core/components/display/Overline'
import Tag from 'core/components/tag/Tag'

import {ColumnContainer, RowContainer} from 'core/styled/blocks'

type AccordionItemType = {
  children: React.ReactNode;
  title: string;
  isActive?: boolean;
  onClick?: VoidFunc;
  className?: string;
}

type Props = {
  heading: string;
  children: ReactElement<PropsWithChildren<AccordionItemType>>[];
  expandLabel?: string;
  collapseLabel?: string;
  className?: string;
  hasExpandAll?: boolean;
  tag?: string;
}

export const Accordion = ({
  heading,
  children,
  expandLabel = 'Expand all',
  collapseLabel = 'Collapse all',
  className,
  hasExpandAll = false,
  tag,
}: Props): JSX.Element => {
  const [activeItems, setActiveItems] = useState<number[]>([])
  const [allExpanded, setAllExpanded] = useState(false)

  const toggleItem = (index: number): void => (activeItems.includes(index)
    ? setActiveItems(activeItems.filter((id) => id !== index))
    : setActiveItems((prevState) => [...prevState, index]))

  const toggleItems = (): void => {
    setAllExpanded(!allExpanded)
    return allExpanded
      ? setActiveItems([])
      : setActiveItems(Children.toArray(children).map((_, index) => index))
  }

  return (
    <Outer className={className}>
      <Wrapper>
        <Header justify="space-between" onClick={hasExpandAll ? toggleItems : undefined}>
          <ColumnContainer gap="8px">
            {tag && <StyledTag text={tag} />}
            <h3>{heading}</h3>
          </ColumnContainer>
          {hasExpandAll && (
          <button type="button" onClick={toggleItems}>
            <StyledOverline text={!allExpanded ? expandLabel : collapseLabel} />
            <ExpandIcon className="fak fa-plus-nocircle" hide={allExpanded} />
            <ExpandIcon className="fak fa-minus" hide={!allExpanded} />
          </button>
          )}
        </Header>

        <Items>
          {Children.map(children, (child, index) => {
            // const item = child as ReactElement<PropsWithChildren<AccordionItemType>>
            const isActive = activeItems.includes(index)
            const onClick = (): void => toggleItem(index)
            return cloneElement(child, {isActive, onClick})
          })}
        </Items>
      </Wrapper>
    </Outer>
  )
}

Accordion.Item = ({
  children, title, isActive = false, onClick, className,
}: AccordionItemType): JSX.Element => (
  <div className={className}>
    <StyledItem justify="space-between" onClick={onClick}>
      <h4>{title}</h4>
      <button type="button">
        <ExpandIcon className="fak fa-plus-nocircle" hide={isActive} />
        <ExpandIcon className="fak fa-minus" hide={!isActive} />
      </button>
    </StyledItem>
    {isActive && (<Content>{children}</Content>)}
  </div>
)

const StyledTag = styled(Tag)`
  background: #5eb27333;
  width: fit-content;
`

const StyledItem = styled(RowContainer)`
  cursor: pointer;
  justify-content: space-between;
`

const Outer = styled.div(({theme}) => `
  padding: ${theme.spacing.xxl};

  ${theme.above(theme.breakpoints.tablet)} {
    padding: ${theme.spacing['5xl']} 0;
  }
`)

const Wrapper = styled(ColumnContainer)(({theme}) => `
  gap: ${theme.spacing.xl};
`)

const Header = styled(RowContainer)<{hasExpandAll?: boolean}>(({hasExpandAll}) => `
  cursor: ${hasExpandAll ? 'pointer' : 'default'};
`)

const ExpandIcon = styled.i<{hide: boolean}>`
  font-size: 1rem;
  ${({hide}): string => (hide ? 'display:none;' : '')}
`

const StyledOverline = styled(Overline)(({theme}) => `
  padding-right: ${theme.spacing.s};
`)

const Items = styled(ColumnContainer)(({theme}) => `
  > div {
    padding: ${theme.spacing.l} 0;
    border-top: 1px solid ${theme.colors.grey200};

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.grey200};
    }
  }
`)

const Content = styled.div(({theme}) => `
  margin: ${theme.spacing.xl} 0 0 0;
`)
