import React from 'react'
import {Link} from 'react-router-dom'
import cx from 'classnames'

import styles from './_link.module.scss'

export type RoutePath = string | {
  pathname: string;
  search?: string;
  state?: {
    [key: string]: any;
  };
}

export type LinkProps = {
  active?: boolean;
  /** Whether the link text is bold. */
  bold?: boolean;
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
  id?: string;
  label?: string;
  newTab?: boolean;
  onClick?(e: React.SyntheticEvent<HTMLAnchorElement>): void;
  to: RoutePath;
  // TODO(steph): deprecate inline, underlined, nav
  intent?: 'inline' | 'underlined' | 'nav' | 'primary' | 'secondary' | 'tertiary';
  /** Whether to shrink the size of the link. */
  small?: boolean;
  /** Whether the link text is underlined. */
  underlined?: boolean;
}

const NEW_TAB_PROPS = {target: '_blank', rel: 'noopener noreferrer'}
const SAME_TAB_PROPS = {target: '_self'}

/**
 * HWLink is for internal app routing only. Requires a parent <Router> in order to be used.
 */
export default function HWLink(
  {
    active,
    bold,
    className,
    children,
    label,
    intent,
    newTab,
    small,
    to = '',
    underlined,
    ...rest
  }: LinkProps,
): JSX.Element {
  const tabProps = newTab ? NEW_TAB_PROPS : SAME_TAB_PROPS

  return (
    <Link
      data-theme="default"
      className={cx(styles.link, intent && styles[intent], className, {
        [styles.active]: active,
        [styles.bold]: bold,
        [styles.small]: small,
        [styles.underlined]: underlined,
      })}
      to={to}
      {...tabProps}
      {...rest}
    >
      {children || label}
    </Link>
  )
}
