import track from '../track'

import {ClientProfile} from 'models'
import {
  Engagement, Task, Taxpayer,
} from 'store/advisor-sessions'
import {HarnessFile} from 'store/file-upload'

// where the upload modal was launched from:
//  'my documents - tax documents', 'my documents - permanent records', 'engagement'
type UploadLocation = 'my documents - tax documents' | 'my documents - permanent records' | 'engagement';

type ClientUploadedDocumentEventProperties = {
  myDocumentsTab?: 'tax documents' | 'permanent records' | 'n/a';
  engagement?: Engagement;
  taxpayer?: Taxpayer;
  engagements?: Engagement[];
  task?: Task;
  profile?: ClientProfile;
  uploadedFile: HarnessFile;
  usedAutoCategorization?: boolean;
  otherTagExplanation?: string;
}

export const uploadEvents = {
  ceClientUploadedDocs: ({
    myDocumentsTab = 'n/a',
    engagement,
    taxpayer,
    engagements,
    task,
    profile,
    uploadedFile,
    usedAutoCategorization,
    otherTagExplanation,
  }: ClientUploadedDocumentEventProperties): void => {
    let uploadSource = 'task'

    if (!task && !!engagement) {
      uploadSource = 'engagement'
    } else if (!task && !engagement) {
      uploadSource = 'my documents - personal'
    }

    let advisorName = ''
    let advisorTeamName = ''
    let taxpayerType = ''
    let taxpayerName = ''

    if (engagement?.meta) {
      advisorName = engagement?.meta?.advisor ?? ''
      advisorTeamName = engagement?.meta?.advisorTeamName ?? ''
      taxpayerType = engagement?.meta?.taxpayerType ?? ''
      taxpayerName = engagement?.meta?.taxpayerName ?? ''
    }

    if (taxpayer) {
      uploadSource = 'my documents - taxpayer'
      advisorTeamName = taxpayer.advisorTeams?.[0] ?? ''
      advisorName = taxpayer.advisor?.name ?? ''
      taxpayerType = taxpayer.type ?? ''
      taxpayerName = taxpayer.name ?? ''
    }

    track.mixpanel('ce_client_uploads_client-uploded-document_FE', {
      myDocumentsTab,
      activeEngagements: engagements ? engagements.filter((e) => e.isActive).map((e) => e.displayName).join(',') : '',
      advisorName,
      advisorTeamName,
      email: profile?.email ?? '',
      engagementPid: engagement?.pid ?? '',
      engagementType: engagement?.engagementType ?? '',
      engagementTaxYear: engagement?.year ?? '',
      taxpayerName,
      taxpayerPid: engagement?.aeTaxpayerPid ?? taxpayer?.pid ?? '',
      taxpayerType,
      fileId: uploadedFile.uid ?? '',
      fileName: uploadedFile.name,
      filePid: uploadedFile.pid ?? '',
      fileTags: uploadedFile.tags ? uploadedFile.tags.join(',') : '',
      fileUploadTimestamp: uploadedFile.createdAt ?? '',
      fileUploadedBy: 'CLIENT',
      inactiveEngagements: engagements ? engagements.filter((e) => !e.isActive).map((e) => e.displayName).join(',') : '',
      isAdhocTask: task?.isAdhocTask ?? '',
      taskPid: task?.pid ?? '',
      taskType: task?.taskType ?? '',
      taskVariation: task?.variation ?? '',
      taskTypeLabel: `${task?.taskType ?? ''}${task?.variation ? `:${task.variation}` : ''}`,
      userName: profile ? `${profile.firstName} ${profile.lastName}` : '',
      uploadSource,
      usedAutoCategorization: usedAutoCategorization ?? false,
      otherTagExplanation: otherTagExplanation ?? '',
      formType: uploadedFile.predictionMetadata?.documentType ?? '',
      formIssuer: uploadedFile.predictionMetadata?.issuer?.value ?? '',
      taxYear: uploadedFile.predictionMetadata?.taxYear?.value ?? '',
    })
  },
  portalUploadModalOpenClick: ({
    location,
    engagement,
    engagements,
    task,
    profile,
  }: {
    location: UploadLocation;
    engagement?: Engagement;
    engagements?: Engagement[];
    task?: Task;
    profile?: ClientProfile;
  }): void => {
    track.mixpanel('portal_upload-modal_open_click', {
      location,
      advisorTeamName: `${engagement?.meta?.advisorTeamName ?? ''}`,
      activeEngagements: engagements ? engagements.filter((e) => e.isActive).map((e) => e.displayName).join(',') : '',
      taxpayerPid: engagement?.aeTaxpayerPid ?? '',
      email: profile?.email ?? '',
      engagementType: engagement?.engagementType ?? '',
      taskType: task?.taskType ?? '',
      taskVariation: task?.variation ?? '',
      taskTypeLabel: `${task?.taskType ?? ''}${task?.variation ? `:${task.variation}` : ''}`,
      isAdhocTask: task?.isAdhocTask ?? '',
    })
  },
  portalUploadModalCloseClick: ({
    location,
    engagement,
    engagements,
    task,
    profile,
    fileWasUploaded,
  }: {
    location: UploadLocation;
    engagement?: Engagement;
    engagements?: Engagement[];
    task?: Task;
    profile?: ClientProfile;
    fileWasUploaded: boolean;
  }): void => {
    track.mixpanel('portal_upload-modal_close_click', {
      fileWasUploaded: fileWasUploaded ? 'yes' : 'no',
      location,
      advisorTeamName: `${engagement?.meta?.advisorTeamName ?? ''}`,
      activeEngagements: engagements ? engagements.filter((e) => e.isActive).map((e) => e.displayName).join(',') : '',
      taxpayerPid: engagement?.aeTaxpayerPid ?? '',
      email: profile?.email ?? '',
      engagementType: engagement?.engagementType ?? '',
      taskType: task?.taskType ?? '',
      taskVariation: task?.variation ?? '',
      taskTypeLabel: `${task?.taskType ?? ''}${task?.variation ? `:${task.variation}` : ''}`,
      isAdhocTask: task?.isAdhocTask ?? '',
    })
  },
  portalClientUploadsEditCategory: ({
    previousCategory,
    newCategory,
    file,
    engagement,
    uploadSource,
    location,
  }: {
    previousCategory?: string;
    newCategory: string;
    uploadSource: string;
    file: HarnessFile;
    engagement?: Engagement;
    location: string;
  }): void => {
    track.mixpanel('portal_client-uploads_edit-category', {
      previousCategory,
      newCategory,
      fileName: file.name,
      filePid: file.pid,
      engagementType: engagement?.engagementType,
      uploadSource,
      location,
    })
  },
}
