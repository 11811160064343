import range from 'lodash/range'

import {SelectOptions} from 'core/legacy-components/Form/types'

// current year + 10 years into the future
const YEARS_TO_FETCH = 11
export const getEventYearOptions = (): SelectOptions<number> => {
  const currentYear = new Date().getFullYear()
  return range(currentYear, currentYear + YEARS_TO_FETCH).map((year) => ({label: year.toString(), value: year}))
}
