/* eslint-disable max-len */
import React, {Suspense, lazy} from 'react'

import CaretDownIcon from './CaretDown'
import CaretRightIcon from './CaretRight'
import InfoIcon from './Info'

// TODO(steph): update props after standardization is complete
// TODO(steph) new component for spot icons

/* Standard Icons */
const ArrowPointerIcon = lazy(() => import(/* webpackChunkName: "ArrowPointer" */ './ArrowPointer'))
const ArrowRightCircledIcon = lazy(() => import(/* webpackChunkName: "ArrowRightCircled" */ './ArrowRightCircled'))
const AssetsIcon = lazy(() => import(/* webpackChunkName: "Assets" */ './Assets'))
const BadConnectionIcon = lazy(() => import(/* webpackChunkName: "BadConnection" */ './BadConnection'))
const BadSignalIcon = lazy(() => import (/* webpackChunkName: "BadSignal" */ './BadSignal'))
const BuildingIcon = lazy(() => import (/* webpackChunkName: "Building" */ './Building'))
const CancelIcon = lazy(() => import(/* webpackChunkName: "Cancel" */ './Cancel'))
const CashDoc = lazy(() => import(/* webpackChunkName: "Cancel" */ './CashDocument'))
const ChatBubbleIcon = lazy(() => import(/* webpackChunkName: "ChatBubble" */ './ChatBubble'))
const ChartOutlineIcon = lazy(() => import(/* webpackChunkName: "ChartOutline" */ './ChartOutline'))
const ChartSolidIcon = lazy(() => import(/* webpackChunkName: "ChartSolid" */ './ChartSolid'))
const CheckIcon = lazy(() => import(/* webpackChunkName: "Check" */ './Check'))
const ChessPawnIcon = lazy(() => import(/* webpackChunkName: "ChessPawn" */ './ChessPawn'))
const ConciergeOutlineIcon = lazy(() => import(/* webpackChunkName: "ConciergeOutline" */ './ConciergeOutline'))
const ConciergeSolidIcon = lazy(() => import(/* webpackChunkName: "ConciergeSolid" */ './ConciergeSolid'))
const ConnectionIcon = lazy(() => import (/* webpackChunkName: "Connection" */ './Connection'))
const CopyIcon = lazy(() => import (/* webpackChunkName: "Copy" */ './Copy'))
const ExternalLinkIcon = lazy(() => import(/* webpackChunkName: "ExternalLink" */ './ExternalLink'))
const EyeIcon = lazy(() => import(/* webpackChunkName: "Eye" */ './Eye'))
const FeedbackOutlineIcon = lazy(() => import(/* webpackChunkName: "FeedbackOutline" */ './FeedbackOutline'))
const FeedbackSolidIcon = lazy(() => import(/* webpackChunkName: "FeedbackSolid" */ './FeedbackSolid'))
const FeedbackNewIcon = lazy(() => import(/* webpackChunkName: "FeedbackNew" */ './FeedbackNew'))
const FeesIcon = lazy(() => import(/* webpackChunkName: "Fees" */ './Fees'))
const FirmsOutlineIcon = lazy(() => import(/* webpackChunkName: "FirmsOutline" */ './FirmsOutline'))
const FirmsSolidIcon = lazy(() => import(/* webpackChunkName: "FirmsSolid" */ './FirmsSolid'))
const FriendsIcon = lazy(() => import(/* webpackChunkName: "Friends" */ './Friends'))
const GoodConnectionIcon = lazy(() => import(/* webpackChunkName: "GoodConnection" */ './GoodConnection'))
const GoodSignalIcon = lazy(() => import(/* webpackChunkName: "GoodSignal" */ './GoodSignal'))
const GrowthIcon = lazy(() => import (/* webpackChunkName: "Growth" */ './Growth'))
const HandsIcon = lazy(() => import (/* webpackChunkName: "Hands" */ './Hands'))
const HeadsetIcon = lazy(() => import (/* webpackChunkName: "Headset" */ './Headset'))
const HomeOutlineIcon = lazy(() => import(/* webpackChunkName: "HomeOutline" */ './HomeOutline'))
const HomeSolidIcon = lazy(() => import(/* webpackChunkName: "HomeSolid" */ './HomeSolid'))
const HomeNewIcon = lazy(() => import(/* webpackChunkName: "HomeNew" */ './HomeNew'))
const HotelIcon = lazy(() => import(/* webpackChunkName: "Hotel" */ './Hotel'))
const HWLogoIcon = lazy(() => import(/* webpackChunkName: "HWLogo" */ './HWLogo'))
const ImpactIcon = lazy(() => import (/* webpackChunkName: "Impact" */ './Impact'))
const InsightSendMessageIcon = lazy(() => import(/* webpackChunkName: "InsightSendMessage" */ './InsightSendMessage'))
const InsightOutlineIcon = lazy(() => import(/* webpackChunkName: "InsightOutline" */ './InsightOutline'))
const InsightSolidIcon = lazy(() => import(/* webpackChunkName: "InsightSolid" */ './InsightSolid'))
const InsuranceIcon = lazy(() => import(/* webpackChunkName: "Insurance" */ './Insurance'))
const InviteFriendIcon = lazy(() => import(/* webpackChunkName: "InviteFriend" */ './InviteFriend'))
const KebabIcon = lazy(() => import(/* webpackChunkName: "Kebab" */ './Kebab'))
const LegalScaleIcon = lazy(() => import (/* webpackChunkName: "LegalScale" */ './LegalScale'))
const LockIcon = lazy(() => import(/* webpackChunkName: "Lock" */ './Lock'))
const LockedIcon = lazy(() => import(/* webpackChunkName: "Locked" */ './Locked'))
const UnlockIcon = lazy(() => import(/* webpackChunkName: "Unlock" */ './Unlock'))
const MenuAddPlusIcon = lazy(() => import(/* webpackChunkName: "MenuAddPlus" */ './MenuAddPlus'))
const MenuBackIcon = lazy(() => import(/* webpackChunkName: "MenuBack" */ './MenuBack'))
const MenuCancelIcon = lazy(() => import(/* webpackChunkName: "MenuCancel" */ './MenuCancel'))
const NoConnectionIcon = lazy(() => import(/* webpackChunkName: "NoConnection" */ './NoConnection'))
const PersonalBalanceSheetIcon = lazy(() => import(/* webpackChunkName: "PersonalBalanceSheet" */ './PersonalBalanceSheet'))
const PlusIcon = lazy(() => import(/* webpackChunkName: "Plus" */ './Plus'))
const PoorConnectionIcon = lazy(() => import(/* webpackChunkName: "PoorConnection" */ './PoorConnection'))
const PoorSignalIcon = lazy(() => import(/* webpackChunkName: "PoorSignal" */ './PoorSignal'))
const LocationIcon = lazy(() => import(/* webpackChunkName: "Location" */ './Location'))
const ProfileOutlineIcon = lazy(() => import(/* webpackChunkName: "ProfileOutline" */ './ProfileOutline'))
const ProfileSolidIcon = lazy(() => import(/* webpackChunkName: "ProfileSolid" */ './ProfileSolid'))
const ProfileNewIcon = lazy(() => import(/* webpackChunkName: "ProfileNew" */ './ProfileNew'))
const RibbonOutlineIcon = lazy(() => import(/* webpackChunkName: "RibbonOutline" */ './RibbonOutline'))
const RibbonSolidIcon = lazy(() => import(/* webpackChunkName: "RibbonSolid" */ './RibbonSolid'))
const ServingDomeOutlineIcon = lazy(() => import(/* webpackChunkName: "ServingDomeOutline" */ './ServingDomeOutline'))
const SettingsIcon = lazy(() => import(/* webpackChunkName: "Settings" */ './Settings'))
const SettingsNewIcon = lazy(() => import(/* webpackChunkName: "SettingsNew" */ './SettingsNew'))
const ShieldIcon = lazy(() => import (/* webpackChunkName: "Shield" */ './Shield'))
const SignOutIcon = lazy(() => import (/* webpackChunkName: "SignOut" */ './SignOut'))
const TrendingIcon = lazy(() => import(/* webpackChunkName: "Trending" */ './Trending'))
const TrophyIcon = lazy(() => import (/* webpackChunkName: "Trophy" */ './Trophy'))
const WarningIcon = lazy(() => import(/* webpackChunkName: "Warning" */ './Warning'))
const LiquidityPlannerOutlineIcon = lazy(() => import(/* webpackChunkName: "LiquidityPlannerOutlineIcon" */ './LiquidityPlannerOutline'))
const LiquidityPlannerSolidIcon = lazy(() => import(/* webpackChinkName: "LiquidityPlannerSolidIcon" */ './LiquidityPlannerSolid'))
const PieIcon = lazy(() => import(/* webpackChunkName: "Pie" */ './Pie'))
const RoadIcon = lazy(() => import(/* webpackChunkName: "Road" */ './Road'))
const PlantIcon = lazy(() => import(/* webpackChunkName: "Plant" */ './Plant'))
const MoneyBookIcon = lazy(() => import(/* webpackChunkName: "MoneyBook" */ './MoneyBook'))
const HandsAroundHouseIcon = lazy(() => import(/* webpackChunkName: "HandsAroundHouse" */ './HandsAroundHouse'))
const BookmarkIcon = lazy(() => import(/* webpackChunkName: "Bookmark" */ './Bookmark'))
const StarIcon = lazy(() => import(/* webpackChunkName: "Star" */ './Star'))
const EyeV2Icon = lazy(() => import(/* webpackChunkName: "EyeV2" */ './EyeV2'))

/* Spot Icons */
const AssetManagementSpotIcon = lazy(() => import(/* webpackChunkName: "AssetManagement" */ './spot-icon/AssetManagement'))
const AssetsSpotIcon = lazy(() => import(/* webpackChunkName: "Assets" */ './spot-icon/Assets'))
const BusinessOwnerSpotIcon = lazy(() => import(/* webpackChunkName: "BusinessOwner" */ './spot-icon/BusinessOwner'))
const ChildrenSpotIcon = lazy(() => import(/* webpackChunkName: "Children" */ './spot-icon/Children'))
const DigitalAssetSpotIcon = lazy(() => import(/* webpackChunkName: "DigitalAsset" */ './spot-icon/DigitalAsset'))
const DivorceSpotIcon = lazy(() => import(/* webpackChunkName: "Divorce" */ './spot-icon/Divorce'))
const EmployeeSpotIcon = lazy(() => import(/* webpackChunkName: "Employee" */ './spot-icon/Employee'))
const EquityPartnerSpotIcon = lazy(() => import(/* webpackChunkName: "EquityPartner" */ './spot-icon/EquityPartner'))
const EquitySpotIcon = lazy(() => import(/* webpackChunkName: "Equity" */ './spot-icon/Equity'))
const FileSpotIcon = lazy(() => import(/* webpackChunkName: "File" */ './spot-icon/File'))
const FounderSpotIcon = lazy(() => import(/* webpackChunkName: "Founder" */ './spot-icon/Founder'))
const HouseSpotIcon = lazy(() => import(/* webpackChunkName: "House" */ './spot-icon/House'))
const InheritanceSpotIcon = lazy(() => import(/* webpackChunkName: "Inheritance" */ './spot-icon/Inheritance'))
const InternationalSpotIcon = lazy(() => import(/* webpackChunkName: "International" */ './spot-icon/International'))
const MarriageSpotIcon = lazy(() => import(/* webpackChunkName: "Marriage" */ './spot-icon/Marriage'))
const MovingSpotIcon = lazy(() => import(/* webpackChunkName: "Moving" */ './spot-icon/Moving'))
const NewJobSpotIcon = lazy(() => import(/* webpackChunkName: "NewJob" */ './spot-icon/NewJob'))
const NewJobAltSpotIcon = lazy(() => import(/* webpackChunkName: "NewJobAlt" */ './spot-icon/NewJobAlt'))
const PromotionSpotIcon = lazy(() => import(/* webpackChunkName: "Promotion" */ './spot-icon/Promotion'))
const RetiredSpotIcon = lazy(() => import(/* webpackChunkName: "Retired" */ './spot-icon/Retired'))
const RealEstateSpotIcon = lazy(() => import(/* webpackChunkName: "RealEstate" */ './spot-icon/RealEstate'))
const TaxesSpotIcon = lazy(() => import(/* webpackChunkName: "Taxes" */ './spot-icon/Taxes'))
const TechnologySpotIcon = lazy(() => import(/* webpackChunkName: "Technology" */ './spot-icon/Technology'))
const AdvisorAvatarSpotIcon = lazy(() => import(/* webpackChunkName: "AdvisorAvatar" */ './spot-icon/AdvisorAvatar'))
const LinkAccountSpotIcon = lazy(() => import(/* webpackChunkName: "LinkAccount" */ './spot-icon/LinkAccount'))

export enum IconName {
  Assets = 'assets',
  ArrowPointer = 'arrow-pointer',
  ArrowRightCircled = 'arrow-right-circled',
  BadConnection = 'bad-connection',
  BadSignal = 'bad-signal',
  Building = 'building',
  Cancel = 'cancel',
  CaretRight = 'caret-right',
  CaretDown = 'caret-down',
  CashDocument = 'cash-document',
  ChatBubble = 'chat-bubble',
  ChartOutline = 'chart-outline',
  ChartSolid = 'chart-solid',
  Check = 'check',
  ConciergeOutline = 'concierge-outline',
  ConciergeSolid = 'concierge-solid',
  Connection = 'connection',
  Copy = 'copy',
  ExternalLink = 'external-link',
  Eye = 'eye',
  HomeOutline = 'home-outline',
  HomeSolid = 'home-solid',
  HomeNew = 'home-new',
  Hotel = 'hotel',
  FeedbackOutline = 'feedback-outline',
  FeedbackSolid = 'feedback-solid',
  FeedbackNew = 'feedback-new',
  Fees = 'fees',
  FirmsOutline = 'firms-outline',
  FirmsSolid = 'firms-solid',
  Friends = 'friends',
  GoodConnection = 'good-connection',
  GoodSignal = 'good-signal',
  Growth = 'growth',
  Hands = 'hands',
  Headset = 'headset',
  HWLogo = 'hw-logo',
  Impact = 'impact',
  Info = 'info',
  InsightSendMessage = 'insight-send-message',
  InsightOutline = 'insight-outline',
  InsightSolid = 'insight-solid',
  Insurance = 'insurance',
  InviteFriend = 'invite-friend',
  Kebab = 'kebab',
  LegalScale = 'legal-scale',
  Lock = 'lock',
  Locked = 'locked',
  Unlock = 'unlock',
  Location = 'location',
  MenuAddPlus = 'menu-add-plus',
  MenuBack = 'menu-back',
  MenuCancel = 'menu-cancel',
  NoConnection = 'no-connection',
  PersonalBalanceSheet = 'personal-balance-sheet',
  Plus = 'plus',
  PoorConnection = 'poor-connection',
  PoorSignal = 'poor-signal',
  ProfileOutline = 'profile-outline',
  ProfileSolid = 'profile-solid',
  ProfileNew = 'profile-new',
  RibbonOutline = 'ribbon-outline',
  RibbonSolid = 'ribbon-solid',
  Settings = 'settings',
  SettingsNew = 'settings-new',
  ServingDomeOutline = 'serving-dome-outline',
  Shield = 'shield',
  SignOut = 'sign-out',
  Trending = 'trending',
  Trophy = 'trophy',
  Warning = 'warning',
  LiquidityPlannerOutline = 'liquidity-planner-outline',
  LiquidityPlannerSolid = 'liquidity-planner-solid',
  // TODO(steph) update with correct names below
  Pie = 'pie',
  Road = 'road',
  Plant = 'plant',
  MoneyBook = 'money-book',
  HandsAroundHouse = 'hands-around-house',
  ChessPawn = 'chess-pawn',
  Bookmark = 'bookmark',
  Star = 'star',
  EyeV2 = 'eyeV2',
}

export enum SpotIconName {
  Assets = 'spot-assets',
  AssetManagement = 'asset-management',
  BusinessOwner = 'business-owner',
  Children = 'children',
  DigitalAsset = 'digial-asset',
  Divorce = 'divorce',
  Employee = 'employee',
  EquityPartner = 'equity-partner',
  Equity = 'equity',
  File = 'file',
  Founder = 'founder',
  House = 'house',
  Inheritance = 'inheritance',
  International = 'international',
  Marriage = 'marriage',
  Moving = 'moving',
  NewJob = 'new-job',
  NewJobAlt = 'new-job-alt',
  Promotion = 'promotion',
  RealEstate = 'real-estate',
  Retired = 'retired',
  Taxes = 'taxes',
  Technology = 'technology',
  AdvisorAvatar = 'advisor-avatar',
  LinkAccount = 'link-account',
}

export type Props = {
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string;
  /**
   * Color (HTML color names, rgb, rgba, hex values) or SVG paint servers (gradients, patterns, hatches) used to paint
   * the inside of the object.
   * */
  fill?: string;
  /** Descriptor representing the element's glyph. */
  name: IconName | SpotIconName;
  /**
   * Value to set the element's size. *Note:* size alters both `height` and `width` based on the element's viewbox,
   * preserving aspect ratio. Number value will default to pixels.
   * Explicit units may be set with string value (px, em, rem, vw, etc.).
   * */
  size?: number | string;
  /**
   * Color (HTML color names, rgb, rgba, hex values) or SVG paint servers (gradients, patterns, hatches) used to paint
   * the object outline.
   * */
  stroke?: string;
}

function renderIcon({name, size, ...rest}: Props): JSX.Element | null {
  switch (name) {
    case IconName.Assets:
      return <AssetsIcon size={size} {...rest} />
    case IconName.ArrowPointer:
      return <ArrowPointerIcon size={size} {...rest} />
    case IconName.ArrowRightCircled:
      return <ArrowRightCircledIcon size={size} {...rest} />
    case IconName.BadConnection:
      return <BadConnectionIcon size={size} {...rest} />
    case IconName.BadSignal:
      return <BadSignalIcon size={size} {...rest} />
    case IconName.Building:
      return <BuildingIcon size={size} {...rest} />
    case IconName.Cancel:
      return <CancelIcon size={size} {...rest} />
    case IconName.CashDocument:
      return <CashDoc size={size} {...rest} />
    case IconName.CaretRight:
      return <CaretRightIcon size={size} {...rest} />
    case IconName.CaretDown:
      return <CaretDownIcon size={size} {...rest} />
    case IconName.ChatBubble:
      return <ChatBubbleIcon size={size} {...rest} />
    case IconName.ChartOutline:
      return <ChartOutlineIcon size={size} {...rest} />
    case IconName.ChartSolid:
      return <ChartSolidIcon size={size} {...rest} />
    case IconName.Check:
      return <CheckIcon size={size} {...rest} />
    case IconName.ConciergeOutline:
      return <ConciergeOutlineIcon size={size} {...rest} />
    case IconName.ConciergeSolid:
      return <ConciergeSolidIcon size={size} {...rest} />
    case IconName.Connection:
      return <ConnectionIcon size={size} {...rest} />
    case IconName.Copy:
      return <CopyIcon size={size} {...rest} />
    case IconName.ExternalLink:
      return <ExternalLinkIcon size={size} {...rest} />
    case IconName.Eye:
      return <EyeIcon size={size} {...rest} />
    case IconName.FeedbackOutline:
      return <FeedbackOutlineIcon size={size} {...rest} />
    case IconName.FeedbackSolid:
      return <FeedbackSolidIcon size={size} {...rest} />
    case IconName.FeedbackNew:
      return <FeedbackNewIcon size={size} {...rest} />
    case IconName.Fees:
      return <FeesIcon size={size} {...rest} />
    case IconName.FirmsOutline:
      return <FirmsOutlineIcon size={size} {...rest} />
    case IconName.FirmsSolid:
      return <FirmsSolidIcon size={size} {...rest} />
    case IconName.Friends:
      return <FriendsIcon size={size} {...rest} />
    case IconName.GoodConnection:
      return <GoodConnectionIcon size={size} {...rest} />
    case IconName.GoodSignal:
      return <GoodSignalIcon size={size} {...rest} />
    case IconName.Growth:
      return <GrowthIcon size={size} {...rest} />
    case IconName.Hands:
      return <HandsIcon size={size} {...rest} />
    case IconName.Headset:
      return <HeadsetIcon size={size} {...rest} />
    case IconName.HomeOutline:
      return <HomeOutlineIcon height={size} {...rest} />
    case IconName.HomeSolid:
      return <HomeSolidIcon height={size} {...rest} />
    case IconName.HomeNew:
      return <HomeNewIcon size={size} {...rest} />
    case IconName.Hotel:
      return <HotelIcon size={size} {...rest} />
    case IconName.HWLogo:
      return <HWLogoIcon size={size} {...rest} />
    case IconName.Impact:
      return <ImpactIcon size={size} {...rest} />
    case IconName.Info:
      return <InfoIcon size={size} {...rest} />
    case IconName.InsightSendMessage:
      return <InsightSendMessageIcon size={size} {...rest} />
    case IconName.InsightOutline:
      return <InsightOutlineIcon size={size} {...rest} />
    case IconName.InsightSolid:
      return <InsightSolidIcon size={size} {...rest} />
    case IconName.Insurance:
      return <InsuranceIcon size={size} {...rest} />
    case IconName.InviteFriend:
      return <InviteFriendIcon size={size} {...rest} />
    case IconName.Kebab:
      return <KebabIcon size={size} {...rest} />
    case IconName.LegalScale:
      return <LegalScaleIcon size={size} {...rest} />
    case IconName.Lock:
      return <LockIcon size={size} {...rest} />
    case IconName.Unlock:
      return <UnlockIcon size={size} {...rest} />
    case IconName.Locked:
      return <LockedIcon size={size} {...rest} />
    case IconName.Location:
      return <LocationIcon size={size} {...rest} />
    case IconName.MenuAddPlus:
      return <MenuAddPlusIcon size={size} {...rest} />
    case IconName.MenuBack:
      return <MenuBackIcon size={size} {...rest} />
    case IconName.MenuCancel:
      return <MenuCancelIcon size={size} {...rest} />
    case IconName.NoConnection:
      return <NoConnectionIcon size={size} {...rest} />
    case IconName.PersonalBalanceSheet:
      return <PersonalBalanceSheetIcon size={size} {...rest} />
    case IconName.Plus:
      return <PlusIcon size={size} {...rest} />
    case IconName.PoorConnection:
      return <PoorConnectionIcon size={size} {...rest} />
    case IconName.PoorSignal:
      return <PoorSignalIcon size={size} {...rest} />
    case IconName.ProfileOutline:
      return <ProfileOutlineIcon size={size} {...rest} />
    case IconName.ProfileSolid:
      return <ProfileSolidIcon size={size} {...rest} />
    case IconName.ProfileNew:
      return <ProfileNewIcon size={size} {...rest} />
    case IconName.RibbonOutline:
      return <RibbonOutlineIcon size={size} {...rest} />
    case IconName.RibbonSolid:
      return <RibbonSolidIcon size={size} {...rest} />
    case IconName.Settings:
      return <SettingsIcon size={size} {...rest} />
    case IconName.SettingsNew:
      return <SettingsNewIcon size={size} {...rest} />
    case IconName.ServingDomeOutline:
      return <ServingDomeOutlineIcon size={size} {...rest} />
    case IconName.Shield:
      return <ShieldIcon size={size} {...rest} />
    case IconName.SignOut:
      return <SignOutIcon size={size} {...rest} />
    case IconName.Trending:
      return <TrendingIcon size={size} {...rest} />
    case IconName.Trophy:
      return <TrophyIcon size={size} {...rest} />
    case IconName.Warning:
      return <WarningIcon size={size} {...rest} />
    case IconName.LiquidityPlannerOutline:
      return <LiquidityPlannerOutlineIcon size={size} {...rest} />
    case IconName.LiquidityPlannerSolid:
      return <LiquidityPlannerSolidIcon size={size} {...rest} />
    case IconName.Pie:
      return <PieIcon size={size} {...rest} />
    case IconName.Road:
      return <RoadIcon size={size} {...rest} />
    case IconName.Plant:
      return <PlantIcon size={size} {...rest} />
    case IconName.HandsAroundHouse:
      return <HandsAroundHouseIcon size={size} {...rest} />
    case IconName.ChessPawn:
      return <ChessPawnIcon size={size} {...rest} />
    case IconName.MoneyBook:
      return <MoneyBookIcon size={size} {...rest} />
    case IconName.Bookmark:
      return <BookmarkIcon size={size} {...rest} />
    case IconName.Star:
      return <StarIcon size={size} {...rest} />
    case IconName.EyeV2:
      return <EyeV2Icon size={size} {...rest} />
    /* Spot Icons */
    case SpotIconName.AssetManagement:
      return <AssetManagementSpotIcon height={size} {...rest} />
    case SpotIconName.Assets:
      return <AssetsSpotIcon height={size} {...rest} />
    case SpotIconName.BusinessOwner:
      return <BusinessOwnerSpotIcon height={size} {...rest} />
    case SpotIconName.Children:
      return <ChildrenSpotIcon height={size} {...rest} />
    case SpotIconName.DigitalAsset:
      return <DigitalAssetSpotIcon size={size} {...rest} />
    case SpotIconName.Divorce:
      return <DivorceSpotIcon height={size} {...rest} />
    case SpotIconName.Employee:
      return <EmployeeSpotIcon height={size} {...rest} />
    case SpotIconName.Equity:
      return <EquitySpotIcon height={size} {...rest} />
    case SpotIconName.EquityPartner:
      return <EquityPartnerSpotIcon height={size} {...rest} />
    case SpotIconName.File:
      return <FileSpotIcon height={size} {...rest} />
    case SpotIconName.Founder:
      return <FounderSpotIcon height={size} {...rest} />
    case SpotIconName.House:
      return <HouseSpotIcon height={size} {...rest} />
    case SpotIconName.Inheritance:
      return <InheritanceSpotIcon height={size} {...rest} />
    case SpotIconName.International:
      return <InternationalSpotIcon height={size} {...rest} />
    case SpotIconName.Marriage:
      return <MarriageSpotIcon height={size} {...rest} />
    case SpotIconName.Moving:
      return <MovingSpotIcon height={size} {...rest} />
    case SpotIconName.NewJob:
      return <NewJobSpotIcon height={size} {...rest} />
    case SpotIconName.NewJobAlt:
      return <NewJobAltSpotIcon height={size} {...rest} />
    case SpotIconName.Promotion:
      return <PromotionSpotIcon height={size} {...rest} />
    case SpotIconName.Retired:
      return <RetiredSpotIcon height={size} {...rest} />
    case SpotIconName.RealEstate:
      return <RealEstateSpotIcon size={size} {...rest} />
    case SpotIconName.Taxes:
      return <TaxesSpotIcon height={size} {...rest} />
    case SpotIconName.Technology:
      return <TechnologySpotIcon height={size} {...rest} />
    case SpotIconName.AdvisorAvatar:
      return <AdvisorAvatarSpotIcon height={size} {...rest} />
    case SpotIconName.LinkAccount:
      return <LinkAccountSpotIcon {...rest} />
    default:
      return null
  }
}

/**
 * **Renders a dynamic SVG image in React**
 *
 * Adding New Icons:
 * <ul>
 *  <li>
 *    Process new svgs through https://jakearchibald.github.io/svgomg/ to strip away unnecessary attributes, such as
 *    doctype, xml instructions, xmlns, useless/empty elements and attributes (elements/attributes with no effect), etc.
 *  </li>
 *  <li>Be sure not to remove viewbox in order to preserve the svg's aspect ratio</li>
 *  <li>
 *     Keep nomenclature consistent with design system for communication with product/design and to make relevant
 *     design specs easily discoverable
 *  </li>
 *  <li>
 *    Always add a `rest` variable to pass additional props to the svg in order to give the consumer the ability to
 *    add aria attributes and other props not explicitly defined in prop types
 *  </li>
 *  <li>
 *    Default to a single `size` prop rather than height & width to ensure height & width are equal. Height & width can be
 *    still passed in addition to `size` and they will take precedence
 *  </li>
 *  <li>
 *    Define a `title` element and aria attributes for svgs that are typically used within interactive elements, such as arrows
 *    and menus, to make the icons more accessible (particularly important if the svgs are used without text)
 *  </li>
 *  <li>Add an example of the new icon to Icon.stories.tsx and link to the associated spec in figma</li>
 * </ul>
 * */
export default function Icon(props: Props): JSX.Element {
  return (
    <Suspense fallback={null}>
      {renderIcon(props)}
    </Suspense>
  )
}
