/* eslint-disable react/button-has-type */
import React from 'react'
import cx from 'classnames'

import LoadingSpinner from '../loaders/LoadingSpinner'

import styles from './_button.module.scss'

export type ButtonIntent = 'primary' | 'secondary' | 'tertiary';

export type Props = React.AriaAttributes & {
  /** Whether to bold the button text. */
  bold?: boolean;
  /**
   * Contents of the button.
   * @ignore
   * */
  children?: React.ReactNode | React.ReactNode[];
  /** Space-separated list of classes to pass to the underlying element. */
  className?: string;
  /** Whether to prevent interactivity. */
  disabled?: boolean;
  /** Whether to expand the button to fill all available horizontal space.
   * Default `true` on mobile.
   * */
  fill?: boolean;
  /** Unique identifier for the button. */
  id?: string;
  /** Visual style to convey the action's significance. */
  intent?: ButtonIntent;
  /** Text describing the button's action */
  label?: string;
  /** Whether to display loading spinner in place of button contents. */
  loading?: boolean;
  /**
   * Work around for KebabMenu accessibility (should be phased out)
   * @ignore */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  /**
   * Callback to invoke when button is clicked.
   * A button with type `submit` may forgo an onClick handler.
   * */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** Whether to shrink the size of the button. */
  small?: boolean;
  /** HTML type of the underlying `<button>`. */
  type?: 'button' | 'submit';
  /** Whether to underline the button text. */
  underlined?: boolean;
  title?: string;
}

/**
 * **Triggers an action when pressed**
 */
export default function Button(
  {
    bold,
    className = '',
    children,
    disabled = false,
    fill,
    id,
    intent,
    label,
    loading = false,
    onClick,
    small,
    type = 'button',
    underlined,
    title = '',
    ...rest
  }: Props,
): JSX.Element {
  return (
    <button
      type={type}
      id={id}
      disabled={disabled || loading} // disable button while in loading state
      onClick={onClick}
      title={title}
      className={cx(styles.btn, intent && styles[intent], {
        [styles.fill]: fill === true,
        [styles.noFill]: fill === false,
        [styles.loading]: loading,
        [styles.small]: small,
        [styles.bold]: bold,
        [styles.underlined]: underlined,
      }, className)}
      {...rest}
    >
      {loading ? (
        <LoadingSpinner size="small" color={intent === 'tertiary' ? 'blue' : 'white'} />
      ) : (
        children || label
      )}
    </button>
  )
}
