import {
  LiquidityEvent,
  LiquidityEventPayload,
  LiquidityEventWithResults,
  TaxProfile,
  TaxProfileResidency,
  TaxProfileResidencyPayload,
  TaxScenarioGrantType,
  TaxScenarioResults,
  UpdateTaxProfilePayload,
} from './types'

import HttpClient, {
  AttributeError,
  FetchResponse,
} from 'models/helpers/httpClient'

export function updateTaxProfile(
  taxYear: number, payload: UpdateTaxProfilePayload,
): Promise<FetchResponse<TaxProfile>> {
  return HttpClient.patch(`/v1/tax_profile/${taxYear}/`, {...payload})
}

export function createTaxProfile(taxYear: number): Promise<FetchResponse<TaxProfile>> {
  return HttpClient.post('/v1/tax_profile/', {taxYear})
}

export function findTaxProfile(
  taxYear: number,
  ignoredStatusCodes: number[] = [],
): Promise<FetchResponse<TaxProfile>> {
  return HttpClient.get(`/v1/tax_profile/${taxYear}/`, {}, {
    captureExceptions: true,
    ignoredStatusCodes,
  })
}

export function getTaxProfiles(): Promise<FetchResponse<TaxProfile[]>> {
  return HttpClient.get('/v1/tax_profile/')
}

/**
 *
 * @param company string - The unique ID of the company on which to create a liquidity scenario
 * @param taxProfile string - The tax profile ID
 * @returns a newly created liquidity event
 *
 */
export function createLiquidityEvent(company: string, taxProfile: string): Promise<FetchResponse<LiquidityEvent>> {
  return HttpClient.post('/v1/liquidity_event/', {company, taxProfile})
}

export function updateLiquidityEvent(
  company: string, payload: Partial<LiquidityEventPayload>,
): Promise<FetchResponse<LiquidityEvent>> {
  return HttpClient.patch(`/v1/liquidity_event/${company}/`, {...payload})
}

const findLiquidityEventExceptionOptions = {
  captureExceptions: true,
  ignoredStatusCodes: [404],
}

export function findLiquidityEvent(
  company: string,
): Promise<FetchResponse<LiquidityEvent>> {
  return HttpClient.get(
    `/v1/liquidity_event/${company}/`,
    {},
    findLiquidityEventExceptionOptions,
  )
}

/**
 * Removes all RSA and isDoubleTrigger from scenarios to prevent
 * @param company
 * @returns
 */
export async function findLiquidityEventWithResults(
  company: string,
): Promise<FetchResponse<LiquidityEventWithResults>> {
  const response = await HttpClient.get<LiquidityEventWithResults>(
    `/v1/liquidity_event/${company}/?results=true`,
    {},
    findLiquidityEventExceptionOptions,
  )

  if (!response.data?.results) {
    return response
  }

  if (response.data) {
    const scenarios = Object.entries(response.data.results)
      .reduce((acc, [type, scenario]) => {
        acc[type] = {
          ...scenario,
          grantSummary: {
            purchase: scenario.grantSummary.purchase,
            sale: scenario.grantSummary.sale
              .filter((grant) => !grant.isDoubleTrigger && grant.grantType !== TaxScenarioGrantType.RSA),
          },
        }

        return acc
      }, {} as TaxScenarioResults)

    return {
      ...response,
      data: {
        ...response.data,
        results: scenarios,
      },
    }
  }

  return response
}

export function getLiquidityEvents(
  queryParams?: KeyedObject<any>,
): Promise<FetchResponse<LiquidityEventWithResults | LiquidityEventWithResults[]>> {
  const queryString = new URLSearchParams(queryParams).toString()
  return HttpClient.get(`/v1/liquidity_event/${queryParams ? `?${queryString}` : ''}`)
}

export function generateLiquidityEventScenario(company: string): Promise<FetchResponse<LiquidityEventWithResults>> {
  return HttpClient.post(`/v1/liquidity_event/${company}/scenario/`)
}

export type TaxProfileResidencyErrorResponse = {
  zipCode: AttributeError[];
}

export function createTaxProfileResidency(
  taxYear: number, payload: TaxProfileResidencyPayload,
): Promise<FetchResponse<TaxProfileResidency, TaxProfileResidencyErrorResponse>> {
  return HttpClient.post(`/v1/tax_profile/${taxYear}/residency/`, {...payload})
}

export function updateTaxProfileResidency(
  taxYear: number,
  taxResidencyId: string,
  payload: Partial<TaxProfileResidencyPayload>,
): Promise<FetchResponse<TaxProfileResidency, TaxProfileResidencyErrorResponse>> {
  return HttpClient.patch(`/v1/tax_profile/${taxYear}/residency/${taxResidencyId}/`, {...payload})
}

export function findTaxProfileResidency(taxYear: number): Promise<FetchResponse<TaxProfileResidency[]>> {
  return HttpClient.get(`/v1/tax_profile/${taxYear}/residency/`)
}
