import React, {CSSProperties} from 'react'

import GrantTable from './GrantTable'
import {PAYABLE_NIIT as locale} from './locale'

import {numberFormatter} from 'core/formatters/numberFormatter'
import {TaxScenarioResult} from 'models/liquidityEvent'

type Props = {
  result: TaxScenarioResult;
  scenarioNumber: number;
}

const leftAlignedStyle: CSSProperties = {textAlign: 'left'}
export default function PayableNIITTable(
  {
    result: {taxBreakdown: {netInvestmentIncome}},
    scenarioNumber,
  }: Props,
): JSX.Element {
  const niiUpToThreshold = Math.min(
    netInvestmentIncome.netInvestmentIncome || 0,
    netInvestmentIncome.agiIncomeOverThreshold || 0,
  )

  let disclaimerCopy = ''
  if (!niiUpToThreshold) disclaimerCopy = locale.DISCLAIMER.NO_TAXES_OWED
  else if (niiUpToThreshold === netInvestmentIncome.agiIncomeOverThreshold) {
    disclaimerCopy = locale.DISCLAIMER.THRESHOLD_MET
  }

  const headerRows = [
    {
      id: `payable-niit-${scenarioNumber}-header`,
      cells: [
        {label: ''},
        {label: locale.HEADER.INCOME_BETWEEN, style: leftAlignedStyle},
        {label: locale.HEADER.TAXABLE_NII, style: leftAlignedStyle},
        {label: locale.HEADER.TAXED_AT},
        {label: locale.HEADER.PAYABLE},
      ],
    },
  ]

  const bodyRows = [
    {
      id: `payable-niit-${scenarioNumber}-body-1`,
      cells: [
        {label: '1.'},
        {
          label: netInvestmentIncome.agiIncomeOverThreshold
            ? `$0 - ${numberFormatter(netInvestmentIncome.agiIncomeOverThreshold).intCurrency}`
            : '$0',
          style: leftAlignedStyle,
        },
        {
          label: numberFormatter(niiUpToThreshold).intCurrency,
          style: leftAlignedStyle,
        },
        {label: `${(netInvestmentIncome.rate * 100 || 0).toFixed(2)}%`},
        {label: numberFormatter(netInvestmentIncome.taxesOwed).intCurrency},
      ],
    },
    {
      id: `payable-niit-${scenarioNumber}-body-2`,
      cells: [
        {label: '2.'},
        {
          label: `Over ${numberFormatter(netInvestmentIncome.agiIncomeOverThreshold).intCurrency}`,
          style: leftAlignedStyle,
        },
        {
          label: numberFormatter(netInvestmentIncome.netInvestmentIncome - niiUpToThreshold).intCurrency,
          style: leftAlignedStyle,
        },
        {label: '0.00%'},
        {label: '$0'},
      ],
    },
  ]

  const footerRows = [
    {
      id: `payable-niit-${scenarioNumber}-footer`,
      cells: [
        {label: locale.FOOTER.TOTAL, colSpan: 4},
        {label: numberFormatter(netInvestmentIncome.taxesOwed).intCurrency},
      ],
    },
  ]

  return (
    <GrantTable
      id="payable niit"
      title={locale.TITLE}
      bodyRows={bodyRows}
      footerRows={footerRows}
      headerRows={headerRows}
      footNote={disclaimerCopy}
    />
  )
}
