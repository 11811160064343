import {
  useCallback,
  useEffect,
  useState,
} from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import get from 'lodash/get'

import {ErrorCode} from '../types'

import {
  LiquidityEventWithResults,
  findLiquidityEvent,
  findLiquidityEventWithResults,
  generateLiquidityEventScenario,
} from 'models'
import {FetchResponseError} from 'models/helpers'
import {LiquidityEventActions} from 'store/liquidity-event'
import {oxfordJoin} from 'core/helpers'
import {STATE_ABBREV_TO_NAME} from 'core/constants/states'

type LocationState = {
  rerunScenario?: boolean;
}

export type ReturnType = {
  eventWithResults: LiquidityEventWithResults;
  scenarioRerunLoading: boolean;
  unsupportedStates: string;
  rerunScenario: () => Promise<void>;
  setLiquidityEvent: (arg: LiquidityEventWithResults) => void;
}

export function useLiquidityEventWithResults(
  companyId: string,
  onNetworkError: (error: Nullable<FetchResponseError<unknown>>) => void,
): ReturnType {
  const location = useLocation<LocationState>()
  const dispatch = useDispatch()
  const [eventWithResults, setEventWithResults] = useState<LiquidityEventWithResults>({} as LiquidityEventWithResults)
  const [scenarioRerunLoading, setScenarioRerunLoading] = useState<boolean>(false)
  const [unsupportedStates, setUnsupportedStates] = useState<string>('')

  const rerunScenario = useCallback(async () => {
    setScenarioRerunLoading(true)
    const {data: liqResultsData, error: liqResultsError} = await generateLiquidityEventScenario(companyId)
    const errorCode: string = get(liqResultsError, ['data', 'code'], '') as string
    // if err code denotes unsupported state, get liq event without results in order
    // to display page header with inputs so user can modify inputs to rerun scenarios
    if (errorCode === ErrorCode.UNSUPPORTED_STATES) {
      const stateAbbreviations = get(liqResultsError, ['data', 'states'], []) as string[]
      /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
      const states = stateAbbreviations.map((s) => STATE_ABBREV_TO_NAME[s]) || ['your state']
      const {data: liqData, error: liqError} = await findLiquidityEvent(companyId)
      if (!liqData || liqError) {
        onNetworkError(liqError)
      } else {
        setUnsupportedStates(oxfordJoin(states))
        /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        setEventWithResults((liqData || {}) as LiquidityEventWithResults)
        dispatch(LiquidityEventActions.setEventData(liqData))
      }
    } else if (!liqResultsData || liqResultsError) {
      onNetworkError(liqResultsError)
    } else {
      setEventWithResults(liqResultsData)

      const {results, ...rest} = liqResultsData
      dispatch(LiquidityEventActions.setScenarios({results}))
      dispatch(LiquidityEventActions.setEventData(rest))
      dispatch(LiquidityEventActions.fetchDoubleTriggerRSUQuantity(liqResultsData))
      setUnsupportedStates('')
    }
    setScenarioRerunLoading(false)
  }, [companyId, dispatch, onNetworkError])

  useEffect(() => {
    const fetchLiquidityEventWithResults = async (): Promise<void> => {
      const {data, error} = await findLiquidityEventWithResults(companyId)
      if (!data || error) {
        onNetworkError(error)
      } else {
        // results are empty, rerun the scenario to capture and display possible errors
        if (Object.keys(data.results).length === 0) {
          void rerunScenario()
          return
        }
        setEventWithResults(data)

        const {results, ...rest} = data
        dispatch(LiquidityEventActions.setScenarios({results}))
        dispatch(LiquidityEventActions.setEventData(rest))
        dispatch(LiquidityEventActions.fetchDoubleTriggerRSUQuantity(data))
      }
    }

    // regenerate liquidityEvent if routed here with rerunScenario=true
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (location.state?.rerunScenario) {
      void rerunScenario()
    } else {
      void fetchLiquidityEventWithResults()
    }
  }, [
    companyId,
    location,
    rerunScenario,
    dispatch,
    onNetworkError,
  ])

  const setLiquidityEvent = ({results, ...rest}: LiquidityEventWithResults): void => {
    setEventWithResults({results, ...rest})
    dispatch(LiquidityEventActions.setScenarios({results}))
    dispatch(LiquidityEventActions.setEventData(rest))
  }

  return {
    eventWithResults,
    scenarioRerunLoading,
    unsupportedStates,
    rerunScenario,
    setLiquidityEvent,
  }
}
