import React from 'react'
import {Markup} from 'interweave'
import styled, {useTheme} from 'styled-components'

import Icon, {IconName} from 'core/components/icon/Icon'

import {RowContainer} from 'core/styled/blocks'

type Props = {
  children?: React.ReactNode;
  className?: string;
  content?: string;
}

export const Warning = React.memo(({
  children = null,
  className = '',
  content = '',
}: Props): JSX.Element | null => {
  const theme = useTheme()

  return (
    <Container className={className}>
      <IconContainer>
        <Icon
          size={20}
          fill={theme.colors.warning}
          name={IconName.Warning}
        />
      </IconContainer>
      <Content>
        <Markup content={content} />
        {children}
      </Content>
    </Container>
  )
})

const Container = styled(RowContainer)(({theme}) => `
  padding: ${theme.spacing.m};
  background: #ffc14933;
  font-size: ${theme.fontSize.desktop.body};
`)

const IconContainer = styled.div`
  min-width: 40px;
  max-width: 40px;
  margin-right: 8px;
  padding-top: 6px;
`

const Content = styled.div``
