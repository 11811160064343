import React from 'react'
import {Markup} from 'interweave'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'

import {usePageStrings} from 'core/hooks'
import {DynamicStringsApp} from 'store/dynamic-strings'
import {Accordion} from 'core/ui'
import {
  selectEquityGuideData, useGetLiquidityEventQuery,
} from 'store/scenario'

export const EquityGuide = (): JSX.Element => {
  const {companyId} = useParams<{companyId: string}>()

  const {getString} = usePageStrings(DynamicStringsApp.SCENARIOS, 'results')
  const {data: items} = useGetLiquidityEventQuery(companyId, {
    selectFromResult: (result) => ({
      data: result.data ? selectEquityGuideData(result.data.scenarios) : [],
    }),
  })

  return (
    <StyledAccordion heading="Your Equity Guide" tag="recommended for you">
      {items.map(({titleLabel, contentLabel}) => (
        <Accordion.Item key={titleLabel} title={getString(titleLabel)}>
          <Markup noWrap content={getString(contentLabel)} />
        </Accordion.Item>
      ))}
    </StyledAccordion>
  )
}

// TODO(zare): Update accordion to work when children are undefined or null
const StyledAccordion = styled(Accordion)(({theme}) => `
  max-width: ${theme.maxWidth};
  width: 100%;
  background: ${theme.colors.white100};
  ${theme.above(theme.breakpoints.largePhone)} {
    border-radius: 12px;
  }
  
  padding: ${theme.spacing.l} 56px;
  ${theme.below(theme.breakpoints.largePhone)} {
    padding: ${theme.spacing.l}
  }
`)
