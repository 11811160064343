import {ScenarioResultType, TaxBracketType} from 'models'

export default {
  SCENARIO_CARD_TITLE: 'Scenario [INDEX]',
  SCENARIO_TYPE_LABEL: {
    [ScenarioResultType.HOLD_UNDER_A_YEAR]: {
      LABEL: 'Wait to exercise and sell ',
      EXAMPLE: 'e.g. exercise and sell on [SALE_DATE]',
      AFTER_LOCKUP: 'after lockup period',
      AFTER_PUBLIC: 'after going public',
    },
    [ScenarioResultType.HOLD_OVER_A_YEAR]: {
      LABEL: 'Exercise and hold for 1+ year before selling',
      EXAMPLE: 'e.g. exercise on [EXERCISE_DATE] and sell in 1 year, on [SALE_DATE]',
    },
  },
  TAX_OVERVIEW: {
    TAXES_FROM_EXERCISE: 'Taxes from exercise ([TAX_YEAR])',
    TAXES_FROM_SALE: 'Taxes from sale ([TAX_YEAR])',
    TAXES_OWED: 'owe [TAXES_OWED]',
    [TaxBracketType.FEDERAL]: {
      TITLE: 'Federal taxes',
    },
    [TaxBracketType.STATE]: {
      TITLE: 'State taxes',
    },
    MEDICARE: 'Medicare tax',
    NIIT: 'Net investment income tax',
    ORDINARY_INCOME_TAX: 'Ordinary income tax',
    SOCIAL_SECURITY_TAX: 'Social security tax',
    COLUMNS: {
      TOTAL_TAXES: {
        LABEL: 'Total Taxes',
        TOOLTIP_TEXT: 'Total [YEAR] taxes owed, including taxes that may have been withheld by your employer.',
        TOOLTIP_LIST_CAPTION: 'This number <b>includes</b>:',
        TOOLTIP_LIST_ITEMS: [
          'Taxes owed from your regular income',
          'Taxes owed from prior exercises/sales in [YEAR]',
          'Taxes owed from the exercise and sale proposed in this scenario in [YEAR]',
        ],
      },
      FROM_EXERCISE: {
        LABEL: 'From Exercise',
        TOOLTIP_TEXT: '[YEAR] taxes owed from exercising the options (ISOs or NSOs) proposed in this scenario.',
        TOOLTIP_LIST_CAPTION: 'This number <b>excludes</b>:',
        TOOLTIP_LIST_ITEMS: [
          'Taxes owed from your regular income',
          'Taxes owed from prior exercises/sales',
          'Taxes from vesting RSUs',
          'Taxes from selling the shares proposed in this scenario',
        ],
      },
      FROM_SALE: {
        LABEL: 'From Sale',
        TOOLTIP_TEXT: '[YEAR] taxes owed from selling the shares proposed in this scenario.',
        TOOLTIP_LIST_CAPTION: 'This number <b>excludes</b>:',
        TOOLTIP_LIST_ITEMS: [
          'Taxes owed from your regular income',
          'Taxes owed from prior exercises/sales',
          'Taxes from vesting RSUs',
          'Taxes from exercising the options proposed in this scenario',
        ],
      },
    },
    /* eslint-disable no-multi-str */
    FROM_EXERCISE_DESCRIPTION: ` \
      When exercising Non-qualified Stock Options (NSOs), the spread between the option’s exercise price and the fair \
      market value (409a) at the time of exercise is considered income and taxed at ordinary income tax rates. \
      In the case of Incentive Stock Options (ISOs), this spread is only counted as income to calculate your \
      alternative minimum tax (AMT) liability, and not taxed as ordinary income.
      Note: Exercising options does not contribute to your capital gains. \
      Capital gains are assessed when selling shares.`,
    /* eslint-disable no-multi-str */
    FROM_SALE_DESCRIPTION: ' \
      Upon sale, taxable income can be generated in two ways: capital gains or ordinary income. \
      Capital gains are profits from shares that are sold for more than they were acquired for. Shares that are held \
      for less than a year earn “short-term capital gains”, which are taxed as ordinary income. Shares held for more \
        than a year earn “long-term capital gains”, which are subject to more favorable federal and \
      state long-term capital gains tax rates. Ordinary income can also be generated by ISOs when sold as a \
      disqualified disposition',
    ALTERNATIVE_MINIMUM_TAX: {
      TITLE: 'Alternative minimum tax',
      DISCLAIMER: 'Alternative Minimum Tax support coming soon!',
    },
  },
  INCOME_FROM_SCENARIO_SALE_LABEL_MAGI: 'Income and capital gains from scenario [SCENARIO_NUMBER] sale',
  SCENARIO_TITLE: {
    [ScenarioResultType.HOLD_UNDER_A_YEAR]: 'Minimize Investment Risk',
    [ScenarioResultType.HOLD_OVER_A_YEAR]: 'Minimize Regular Taxes',
    [ScenarioResultType.AMT_BREAKEVEN]: 'Minimize AMT',
  },
}
