import React, {forwardRef} from 'react'
import Tippy, {TippyProps} from '@tippy.js/react'

/**
 * Event handler prevents triggering other click/touch/change events when text control is rendered within another
 * interactive element, such as a checkbox.
 * */
function handleControlEvent(e: React.SyntheticEvent<HTMLSpanElement>): void {
  e.preventDefault()
}

type TooltipControlProps = {
  /** Contents of the tooltip control. */
  children: React.ReactNode;
}

const TooltipControl = forwardRef<HTMLSpanElement, TooltipControlProps>(({children}: TooltipControlProps, ref) => (
  <span
    onClick={handleControlEvent}
    onKeyPress={handleControlEvent}
    ref={ref}
    role="button"
    tabIndex={0}
  >
    {children}
  </span>
))

export interface TooltipProps extends Partial<TippyProps> {
  /** Space-separated list of classes to pass to the tooltip control. */
  className?: string;
  /** Content of the tooltip control. */
  children: React.ReactElement;
  /** Content of the tooltip. */
  content: React.ReactChild | React.ReactChild[];
}

/**
 * Presents hidden, secondary information about a particular control element upon interaction with the control via
 * click, focus or hover. Often used with an information icon to serve as a visual cue indicating its
 * purpose of providing more information.
 *
 * The Tooltip component is a wrapper around Tippy.js React. See
 * [Tippy docs](https://atomiks.github.io/tippyjs/v6/all-props/) for full prop documentation and additional features.
 * The tooltip theme is defined as 'light' by default in the component. To add or modify themes see
 * src/hw-web/styles/_overrides.scss
 * */
export default function Tooltip(
  {
    children,
    content,
    placement = 'top',
    ...rest
  }: TooltipProps,
): JSX.Element {
  const ref = React.useRef<HTMLSpanElement>(null)

  return (
    <Tippy
      content={content}
      placement={placement}
      theme="light"
      trigger="click focus mouseenter"
      interactive
      {...rest}
    >
      <TooltipControl ref={ref}>
        {children}
      </TooltipControl>
    </Tippy>
  )
}
