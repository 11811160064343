import {createSelector} from '@reduxjs/toolkit'

import {
  CompanyValuationFormType, EmploymentFormType, GrantTypeForm, NameFormValueType, PostEmploymentForm, ProgressStatus,
} from './types'

import type {RootState} from 'store/main'

export const companySelector = (state: RootState): RootState['company'] => state.company

export const tasksSelector = (state: RootState): RootState['company']['tasks'] => state.company.tasks

export const getNumberOfFinishedTasks = createSelector(
  [tasksSelector], (tasks): number => (tasks ? Object.keys(tasks)
    .reduce((acc, current) => (tasks[current] === ProgressStatus.COMPLETED ? acc + 1 : acc), 0) : 0),
)

export const isGuideCompleted = createSelector([
  getNumberOfFinishedTasks], (numOfCompletedTasks) => numOfCompletedTasks === 3)

export const getTasks = createSelector([tasksSelector], (tasks) => tasks)

export const getUserCompaniesName = createSelector(
  [companySelector],
  ({userCompanies}): string[] => userCompanies.map((company) => company.name),
)

export const getCompanyName = createSelector(
  [companySelector],
  ({addCompanyForm: {companyName}}): NameFormValueType => ({companyName}),
)

export const getEmploymentData = createSelector(
  [companySelector],
  ({addCompanyForm}): EmploymentFormType => ({
    companyName: addCompanyForm.companyName,
    stillEmployed: addCompanyForm.stillEmployed ? 'true' : '',
    hasBeenEmployed: addCompanyForm.hasBeenEmployed ? 'true' : '',
    employmentEndDate: addCompanyForm.employmentEndDate,
  }),
)

export const getEmploymentStatus = createSelector(
  [companySelector],
  ({addCompanyForm: {hasBeenEmployed}}): boolean => !!hasBeenEmployed,
)

export const getPostEmployment = createSelector(
  [companySelector],
  ({addCompanyForm: {postEmploymentOptionExpiration}}): PostEmploymentForm => ({postEmploymentOptionExpiration}),
)

export const getPrice = createSelector(
  [companySelector],
  ({addCompanyForm: {price}}): CompanyValuationFormType => ({price}),
)

export const getGrantType = createSelector(
  [companySelector],
  ({addCompanyForm: {grantType}}): GrantTypeForm => ({grantType}),
)

export const getCurrentStep = createSelector(
  [companySelector],
  ({currentStep}): number => currentStep,
)

export const showLoading = createSelector(
  [companySelector], ({isSubmitInProgress}): boolean => isSubmitInProgress,
)

export const getEquityModalState = createSelector(
  [companySelector], ({equityModal}): boolean => equityModal,
)
