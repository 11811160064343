export default {
  NO_SALE_TEXT: 'No sale in [YEAR]',
  NO_EXERCISE_TEXT: 'No exercise in [YEAR]',
  REGULAR_INCOME_LABEL: 'Regular [YEAR] income',
  HOUSEHOLD_INCOME_LABEL: 'Household income',
  INCOME_FROM_PRIOR_EXERCISE_LABEL: 'Income from prior [YEAR] exercise',
  DEDUCTIONS_LABEL: 'Standardized deductions',
  INCOME_FROM_SCENARIO_EXERCISE_LABEL: 'Income from scenario [SCENARIO_NUMBER] exercise',
  ISO_INCOME_FROM_SCENARIO_EXERCISE_LABEL: 'ISO income from scenario [SCENARIO_NUMBER] exercise',
  NSO_INCOME_FROM_SCENARIO_EXERCISE_LABEL: 'NSO income from scenario [SCENARIO_NUMBER] exercise',
  INCOME_FROM_SCENARIO_SALE_LABEL: 'Income from scenario [SCENARIO_NUMBER] sale',
  LTCG_FROM_SCENARIO_SALE_LABEL: 'Long-term capital gains from scenario [SCENARIO_NUMBER] sale',
  STCG_FROM_SCENARIO_SALE_LABEL: 'Short-term capital gains from scenario [SCENARIO_NUMBER] sale',
  ISO_INCOME_FROM_SCENARIO_SALE_LABEL: 'ISO income from scenario [SCENARIO_NUMBER] sale',
}

export const PAYABLE_NIIT = {
  TITLE: 'Payable Net Investment Income Tax',
  HEADER: {
    INCOME_BETWEEN: 'Net investment income between',
    TAXABLE_NII: 'Your net investment income',
    TAXED_AT: 'Tax rate',
    PAYABLE: 'Payable',
  },
  FOOTER: {
    TOTAL: 'Payable net investment income tax',
  },
  DISCLAIMER: {
    NO_TAXES_OWED: 'No net investment income tax owed',
    THRESHOLD_MET: '*NIIT threshold met',
  },
}

export const AMT_INCOME_DISPLAY_TABLE = {
  NO_SALE_TEXT: 'No sale in [YEAR]',
  NO_EXERCISE_TEXT: 'No exercise in [YEAR]',
  REGULAR_INCOME_LABEL: 'Regular [YEAR] income',
  HOUSEHOLD_INCOME_LABEL: 'Household income',
  ISO_INCOME_FROM_PRIOR_EXERCISE_LABEL: 'ISO AMT income from prior [YEAR] exercise',
  NSO_INCOME_FROM_PRIOR_EXERCISE_LABEL: 'NSO income from prior [YEAR] exercise',
  DEDUCTIONS_LABEL: 'AMT exemptions',
  INCOME_FROM_SCENARIO_EXERCISE_LABEL: 'Income from scenario [SCENARIO_NUMBER] exercise',
  ISO_INCOME_FROM_SCENARIO_EXERCISE_LABEL: 'ISO AMT income from scenario [SCENARIO_NUMBER] exercise',
  NSO_INCOME_FROM_SCENARIO_EXERCISE_LABEL: 'NSO income from scenario [SCENARIO_NUMBER] exercise',
  INCOME_FROM_SCENARIO_SALE_LABEL: 'Income from scenario [SCENARIO_NUMBER] sale',
  LTCG_FROM_SCENARIO_SALE_LABEL: 'Long-term capital gains from scenario [SCENARIO_NUMBER] sale',
  STCG_FROM_SCENARIO_SALE_LABEL: 'Short-term capital gains from scenario [SCENARIO_NUMBER] sale',
  ISO_INCOME_FROM_SCENARIO_SALE_LABEL: 'ISO AMT income from scenario [SCENARIO_NUMBER] sale',
}

export const AMT_LIABILITY_TABLE = {
  TOTAL_AMT: 'Total alternative minimum tax [YEAR]',
  FEDERAL_LIABILITY: 'Federal tax liability [YEAR]',
  TOTAL_AMT_LIABILITY: 'AMT liability in addition to regular federal liability',
}
