import React, {useState} from 'react'
import cx from 'classnames'
import isNumber from 'lodash/isNumber'

import Icon, {IconName} from 'core/components/icon/Icon'

import styles from './_taxBreakdown.module.scss'

import {numberFormatter} from 'core/formatters'

type Props = {
  children: React.ReactNode;
  label: string;
  totalOwed?: number | string;
  totalOwedFromEvent?: number | string;
}
export default function CollapsibleTaxRow({
  label,
  children,
  totalOwed = 0,
  totalOwedFromEvent = 0,
}: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <div className={styles.collapsibleTaxRow}>
      <button
        aria-label={`${isExpanded ? 'Hide' : 'Show'} ${label}`}
        className={styles.collapsibleTaxRowBtn}
        onClick={(): void => setIsExpanded((prev) => !prev)}
        type="button"
      >
        <span>{label}</span>
        <span className={styles.totalTaxes}>
          { isNumber(totalOwed) ? numberFormatter(totalOwed).intCurrency : totalOwed }
        </span>
        <span>
          { isNumber(totalOwedFromEvent)
            ? numberFormatter(totalOwedFromEvent).intCurrency
            : totalOwedFromEvent }
        </span>
        <Icon
          className={cx(styles.dropdownCaret, isExpanded && styles.expanded)}
          name={IconName.MenuBack}
          size={12}
        />
      </button>
      {isExpanded && children}
    </div>
  )
}
