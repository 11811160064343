import React, {useCallback, useEffect, useRef} from 'react'

import Input, {InputProps} from './Input'

/**
 * @param value
 * Format integer with correct commas
 */
const formatter = new Intl.NumberFormat('en-US')
function formatCommas(value: string): string {
  return formatter.format(Number(value))
}

function formatNumber(originalValue: string, numDecimals, isOnBlur = false): string {
  if (!originalValue) return ''

  // Remove any commas from string
  const unformattedValue = originalValue.replace(/,/g, '')

  if (unformattedValue === '.') return numDecimals ? '0.' : ''

  const decimalPosition = unformattedValue.indexOf('.')
  if (decimalPosition >= 0) {
    let int = unformattedValue.substring(0, decimalPosition)
    let decimal = unformattedValue.substring(decimalPosition + 1, unformattedValue.length)
    int = int ? formatCommas(int) : '0'
    // Return whole number if no decimals
    if (!numDecimals) return int

    if (isOnBlur) {
      const decimalLength = decimal.length
      // Remove trailing decimal point on blur
      if (decimalLength === 0) return int
    }

    // Chop off extra decimals
    decimal = decimal.substring(0, numDecimals)
    return `${int}.${decimal}`
  }

  return formatCommas(unformattedValue)
}

export type Props = InputProps & {
  /** Number of decimals to enforce. If unspecified, only whole numbers will be permitted. */
  numDecimals?: number;
  /** @ignore */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * String representing the input's numeric value.
   * @ignore
   */
  value: string;
}

/**
 * Renders a text input with formatting for numeric fields. Defaults to whole numbers. Supports decimals if
 * `numDecmals` is specified.
 * */
export default function NumericInput({
  className = '',
  error,
  fieldTooltip,
  fieldTooltipAriaLabel,
  id,
  label,
  labelTooltip,
  labelTooltipAriaLabel,
  name,
  optional = false,
  prefix,
  suffix,
  handleBlur,
  placeholder,
  value,
  onChange,
  numDecimals = 0,
  ...rest
}: Props): JSX.Element {
  const inputRef = useRef<Nullable<HTMLInputElement>>(null)
  const previousPriceData = useRef<{
    originalCursorPosition: number;
    originalPriceLength: number;
  }>({
    originalCursorPosition: 0,
    originalPriceLength: 0,
  })

  useEffect(() => {
    // Handle positioning the user's cursor position in same place where it left off
    // otherwise it will jump around while editing
    if (!value) {
      return
    }

    const {originalCursorPosition, originalPriceLength} = previousPriceData.current
    const cursorPosition: number = value.length - originalPriceLength + originalCursorPosition

    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
    }
  }, [value])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    e.persist()

    // Don't record any input that's not a number, comma or decimal
    if (e.target.value !== '' && !/^[\d,.]+$/.test(e.target.value)) return

    // Disallow multiple decimal points
    if ((e.target.value.match(/\./g)?.length || 0) > 1) return

    // Keep a reference to the user's cursor position
    previousPriceData.current = {
      originalCursorPosition: inputRef.current?.selectionStart || 0,
      originalPriceLength: e.target.value.length,
    }

    e.target.value = formatNumber(e.target.value, numDecimals, false)

    onChange(e)
  }, [onChange, numDecimals])

  const handleBlurWithEvent = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    // On an onBlur, the user is finished editing the field. This runs the value in the
    // field through the formatter so that when we do the automatic onBlur validation
    // it is at least trying to validate a value that is formatted correctly.
    const newValue = formatNumber(e.target.value, numDecimals, false)

    e.target.value = newValue

    onChange(e)
    if (handleBlur && name) handleBlur(name, newValue)
  }, [handleBlur, name, onChange, numDecimals])

  return (
    <Input
      type="text"
      className={className}
      placeholder={placeholder}
      label={label}
      labelTooltip={labelTooltip}
      id={id}
      name={name}
      value={value}
      prefix={prefix}
      onChange={handleChange}
      error={error}
      fieldTooltip={fieldTooltip}
      fieldTooltipAriaLabel={fieldTooltipAriaLabel}
      labelTooltipAriaLabel={labelTooltipAriaLabel}
      handleBlurWithEvent={handleBlurWithEvent}
      suffix={suffix}
      optional={optional}
      pattern="^\d{1,3}(,\d{3})*(\.\d+)?$"
      inputRef={inputRef}
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    />
  )
}
