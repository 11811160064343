import styled from 'styled-components'

import {Overline, Subheader} from 'core/theme'

export const Container = styled.div<{maxWidth?: string}>(() => `
  background: white;
  height: auto;
  flex-grow: 0!important;


`)

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Overline} {
    margin-bottom: ${({theme}): string => theme.spacing.m};
  }

  button {
    width: auto;
  }
`

export const ContentContainer = styled.div<{maxWidth?: string}>(({
  theme: {
    fontFamily, spacing, colors, breakpoints, above,
  },
  maxWidth,
}) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  grid-gap: 40px;
  padding: 24px 0px 40px 0px;
  text-align: center;

  img {
    width: 100%;
    max-width: 419px;
  }

  ${Subheader} {
    font-family: ${fontFamily.primary};
    margin-bottom: ${spacing.xs};
    color: ${colors.darkBlue600};
  }

  ${above(breakpoints.largePhone)} {

    max-width: ${maxWidth || 'auto'};

    img {
      width: auto;
      max-width: 419px;
    }
  }
`)
