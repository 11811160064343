export const taxBreakdownCardLocale = {
  TAX_HEADER: '',
  VIEW_FULL_BREAKDOWN: 'View full tax breakdown',
  TOTAL_TAXES: 'Total Taxes',
  FROM_EXERCISE: 'Total Exercise',
  FEDERAL_TAXES: 'Federal Taxes',
  STATE_TAXES: 'State Taxes',
  TAXES_FROM_SALE: 'Taxes from sale',
  TAXES_FROM_EXERCISE: 'Taxes from exercise',
  TAXES_FROM_EVENT: {
    SALE: 'From sale',
    EXERCISE: 'From exercise',
  },
}
